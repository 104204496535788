import React, { useMemo } from 'react';

export default function CategoryDropdown(props) {

  const _handleChange = (e) => {
    props.handleCategoryChange(e.target.value, props.id);
  }

  const options = useMemo(() => {
    const opts = [
        <option key={"ld"} id={"ld"}>Light Duty</option>,
        <option key={"mdhd"} id={"mdhd"}>Medium/Heavy Duty</option>
    ]
    return opts
  }, [])

    return (<select id={props.id} 
                  className="swt-category-selector"
                  style={props.styles}
                  defaultValue={props.defaultValue}
                  onChange={_handleChange}>
    {options} </select>);
}
