import React from 'react';
import { Link } from "react-router-dom";
import * as S from "../../styles/ezev-fx-styles/Links-styles"

export default function LinkTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-fx/vehicle-results" }}>Table</Link>
    </div>
  )
}

export function LinkBackToTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-fx/vehicle-results" }}>Back</Link>

    </div>
  )
}

export function LinkHome(props) {
  return (
    <div className={`ezev-link-wrapper ${props.addClass}`}>
      <Link className="ezev-link" to={{ pathname: "/ezev-fx/landing" }}>Home</Link>
    </div>
  )
}

export function LinkSelectGroupLanding(props) {
  return (
    <div className={`ezev-link-wrapper`} onClick={props.onClick}>
      < Link className="ezev-link" to={{ pathname: "/ezev-fx/landing" }} >Select</Link>
    </div>
  )
}

export function LinkBack() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-fx/landing" }}>Back</Link>
    </div>
  )
}

export function LinkSingleVcl() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-fx/vehicle" }}>Back</Link>
    </div>
  )
}


export function LinkInputs() {
  return (
    <div className="ezev-link-wrapper ezev-inputs-button">
      <Link
        className="ezev-link"
        data-testid="inputsView-testid"
        to={{ pathname: "/ezev-fx/inputs" }}
      >
        View Inputs
      </Link>
    </div>
  )
}

export const LinkShowVehicles = () => (
  <S.ShowVehiclesLink
    to={"/ezev-fx/vehicle-results"}
    className="ezev-button-2 ezev-link-wrapper ezev-button-font-2"
    data-testid="showVehiclesButton-testid"
  >
    Show Vehicles
  </S.ShowVehiclesLink>
)

export function LinkEditInputs() {
  // for admins, redirects to dashboard admin cog, settings/inputs edit
  return (
    <div className="ezev-link-wrapper ezev-inputs-button">
      <Link
        className="ezev-link"
        data-testid="inputsEdit-testid"
        to={{
          pathname: "/admin/settings",
          state: { redirectFrom: "/ezev-fx/group-select" }
        }}
      >
        Edit Inputs
      </Link>
    </div>
  )
}

export function LinkLocationEditTool() {

  return (
      <Link to={"/location-edit-tool"}>
        Location Edit Tool
      </Link>
  )
}
