import React, {PureComponent} from 'react';
import { SMALL_RADIUS } from '../../styles/emit-styles/CardStyles';
import { BarChart, Bar, XAxis, YAxis, Legend, Cell, Label, ReferenceLine } from "recharts";
import { filterDataTypes, formatMonthLabelShort, formatWeekLabelShort } from './UtilityFunctions';
import { dark_gray } from '../../styles/emit-styles/ColorScheme';
import { cardSubtitleStyle, cardTitleStyle, cardTitleWrapperStyle, infoCardStyle } from '../../styles/emit-styles/PdfStylesheet';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';

const LABEL_FONT_SIZE = 10;

const styles = StyleSheet.create({
    infoCard: infoCardStyle,
    cardTitle: cardTitleStyle,
    cardSubtitle: cardSubtitleStyle,
    cardTitleWrapper: cardTitleWrapperStyle
});

const PdfGraphCard = ({ data, height, width, title, subtitle, barInfo, yAxisLabel, xAxisLabel, hasReductionData, viewDataByMonth, splitByDrivetrain, combinedView, displayInLbs }) => {

    const x_axis_key = 'month';
    const [filteredDataTypes] = filterDataTypes(data, barInfo);
    
    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, payload } = this.props;
            return (
                <g transform={`translate(${x + 6},${y})`}>
                    <text
                        fontSize={LABEL_FONT_SIZE}
                        fontFamily='Inter'
                        fill={dark_gray}
                        x={0}
                        y={3}
                        textAnchor='end'
                        transform='rotate(-65)'>
                        {viewDataByMonth ? formatMonthLabelShort(payload.value) : formatWeekLabelShort(payload.value)}
                    </text>
                </g>
            );
        }
    }

    return (
        <View style={[styles.infoCard, { width: width, height: height }]}>
            <Text style={styles.cardTitleWrapper}>
                <Text style={styles.cardTitle}>{title}</Text> <Text style={styles.cardSubtitle}>{subtitle}</Text>
            </Text>
            <ReactPDFChart>
                <BarChart
                    width={width - 16}
                    height={height - 30}
                    data={data}
                    margin={{ left: (combinedView ? -48 : -36) + (displayInLbs ? 16 : 0), top: 0, right: combinedView ? -24 : -8, bottom: combinedView ? 8 : 2 }}
                >
                    {filteredDataTypes.map((b) => {
                        return <Bar
                            isAnimationActive={false}
                            key={b.id}
                            xAxisId={_determineXAxisId(hasReductionData, b.id)}
                            dataKey={b.id}
                            radius={_calculateRadius(splitByDrivetrain, hasReductionData)}
                            maxBarSize={28}
                            stackId={_determineXAxisId(hasReductionData, b.id)}
                            name={b.label}
                            fill={b.color}
                        >
                            {data.map((entry, index) => {
                                const barKey = `${b.id}-${index}`; // ensure key is actually unique to prevent props warning
                                return (<Cell key={barKey} fill={b.color}/>);
                            })}
                        </Bar>
                    })}
                    <XAxis
                        tick={<CustomizedAxisTick />}
                        xAxisId={0}
                        dataKey={x_axis_key}
                        fontSize={LABEL_FONT_SIZE}
                        strokeWidth={0.5}
                        axisLine={false}
                        tickLine={false}
                        interval={data.length < 30 ? 0 : (data.length <= 48 ? 1 : 2)} // If more than 2.5 years of data displayed, increment the ticks by 1. If more than 4 years of data, increment by 2.
                        tickMargin={0}
                    >
                        <Label position={'centerBottom'}>{xAxisLabel}</Label>
                        {/* The x-axis label is currently only set up to adjust for weekly date listings since this is the only time it will be shown. If further tick labeling configs are added, it may need to have some conditional rendering set up on the y offset. */}
                    </XAxis>
                    {hasReductionData && <XAxis
                        dataKey={x_axis_key}
                        xAxisId={1}
                        hide
                    />}
                    <YAxis
                        fontSize={LABEL_FONT_SIZE}
                        tickFormatter={(value) => value.toLocaleString()} // Ensure that commas are displayed on graph tick labels
                        axisLine={false}
                        tickLine={false}
                    >
                        {combinedView ?
                            // Splitting out into separate labels for the combined view vs single axis graph to make margin adjustment easier.
                            <>
                                <Label
                                    value={yAxisLabel}
                                    fontSize={LABEL_FONT_SIZE}
                                    angle={-90}
                                    position='left'
                                    // These hard coded offsets are absolutely atrocious. Unfortunately, react-pdf-charts currently does not work with the angle prop as expected so hardcoding is the best solution I have found.
                                    dx={-20 + (displayInLbs ? -16 : 0)}
                                    dy={-120}
                                />
                                {hasReductionData &&
                                    <>
                                        <Label
                                            value='Generated'
                                            fontSize={LABEL_FONT_SIZE}
                                            angle={-90}
                                            position='left'
                                            dx={50 + (displayInLbs ? -16 : 0)}
                                            dy={-119}
                                        />
                                        <Label
                                            value='Avoided'
                                            fontSize={LABEL_FONT_SIZE}
                                            angle={-90}
                                            position='left'
                                            dx={-96 + (displayInLbs ? -16 : 0)}
                                            dy={-119}
                                        />
                                    </>
                                }
                            </>
                            :
                            <Label
                                value={yAxisLabel}
                                fontSize={LABEL_FONT_SIZE}
                                angle={-90}
                                position='left'
                                dx={-32 + (displayInLbs ? -12 : 0)}
                                dy={-107}
                            />

                        }
                    </YAxis>
                    {hasReductionData &&
                        <ReferenceLine
                            stroke={dark_gray}
                            strokeWidth={1.5}
                            y={0}
                            strokeLinecap='round'
                            isAnimationActive={false}
                        />
                    }
                    {combinedView &&
                        <Legend
                            verticalAlign='middle'
                            iconType='circle'
                            iconSize={10}
                        />
                    }
                </BarChart>
            </ReactPDFChart>
        </View>
    );
};

export default PdfGraphCard;

const _determineXAxisId = (hasReductionData, barId) => {
    if (barId.toLowerCase().includes('avoided') && hasReductionData) return 1;
    else return 0;
}

const _calculateRadius = (splitByDrivetrain, showMixedRange) => {
    const r = SMALL_RADIUS;
    if (splitByDrivetrain) return 0;
    else return showMixedRange ? [r, r, 0, 0] : r;
}