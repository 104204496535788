import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import {
    Route,
    useLocation,  
    Redirect,
    Switch,
    useHistory, } from "react-router-dom";
import LandingPage from "./ezio-components/LandingPage";
import LocationsDisplay from "./ezio-components/LocationsDisplay";
import LocationSummary from "./ezio-components/LocationSummary";
import Loading from "./ezio-components/Loading";
import AboutPage from "./ezio-components/AboutPage";
import Header from "./ezio-components/Header";
import Controls from "./ezio-components/Controls";
import LocationDetail from "./ezio-components/LocationDetail";
import VehiclesPage from "./ezio-components/VehiclesPage";
import * as S from "../styles/ezio-styles/Ezio-styles";
import "../styles/ezio-styles/ezio.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { processApiResponse, processRateTime, dateAsNonTZString } from "../utils/ezio-utils/ConformUnits";
import { consistentElectrificationSort } from "../utils/ezio-utils/CustomSortUtils";
import ActiveProcessingPage from "./ezio-components/ActiveProcessingPage";
import { DateTime } from "luxon"
import CircularWithValueLabel from "./ezio-components/CircularDeterminateIndicator";
import CircularIndeterminate from "./ezio-components/CircularInderterminateIndicator";

const LARGE_DATA_SET_THRESHOLD = 50000; //charge event count that limits initial date range
const MINIMUM_DAYS_FOR_MONTH = 2; //if we don't have at least this many days in the last month, don't show
const LARGE_DATE_SET_INIT_DAYS = 180; //days to load for large data set on init
const SECONDS_PER_DAY = 86400;
const INIT_ELEC_LEVEL = 50; //electrification level at init
const MAX_WORKERS = 8;
const MULTI_THREADING_ENABLED = true;
const PROCESS_LOOP_CADENCE = 50; //cadence at which to check completness and kickoff workers in milliseconds
const MINIMUM_EVENT_KWH = 0.5; //min kWh for a charge event to be valid
const MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED = 5;
const UPDATE_MILLISECONDS = 1000;

let immutableChargeLocations = new Map();
let active_workers = [];
let processing = false;
let benchmark = false;
let locsFinished = 0; // track number of locations that are done being processed

const ALL_LOCATIONS_ID : string = "All Locations";

const CATEGORIES = [
    {id: 0, label: "All Categories"},
    {id: 1, label: "Light Duty"},
    {id: 2, label: "Medium and Heavy Duty"}
]

export type kWhUsageSummaries = {
    monthlyPeaks: any,
    todSummaries: any
}

export type ChargeLocation = {
    pkid: number,
    uuid: string,
    address: string,
    nickname: string,
    latitude: number,
    longitude: number,
    chargingVehiclesCount: number,
    inBoundChargingVehiclesCount: number,
    chargingVehiclesVins?: Array<string>,
    chargeDurationSeconds: number,
    chargeDayCount: number,
    chargeEvents: Array<Object>, //charge events within filters
    charge_events: Array<Object>,
    peakKw: number,
    //smoothedPeakKW: number,
    peakCost: number,
    monthlyPeaks: any,
    todSummaries: any,
    todSmartSummaries: any,
    todPeakSmoothedSummaries: any,
    evRecommendationCount: number,
    maxVehiclesCharging: number,
    chargeEventCount: number,
    vehicleResults: Array<any>,
    presentationEvents: Array<Object>,
    vins: Array<String>,
    summary: any,
    pkids: Array<number>,
    isGrouped: boolean,
    location_ids: Array<number>,
    locationEmpty: boolean,
    atLocationChargingKwh: number,
    notAtLocationChargingKwh: number,
    drawHistogram: Array<number>
}

export type KwhDetails = {
    kWh: number,
    cost: number,
    localMonth: string
}

export type ChangeElectrificationAction = {
    electrification: number
}
  
export type ChangeClassAction = {
    classes: Array<any>
}
  
export type ChangeLocationAction = {
    location: number
}

export type ChangeLocationTypeAction = {
    loadAllLocations: boolean
}

export type ChangeCategoryAction = {
    category: string
}
  
export type ChangeGroupAction = {
    groups: Array<any>
}
export type ControlsState = ChangeElectrificationAction & ChangeClassAction & ChangeLocationAction & ChangeLocationTypeAction & ChangeCategoryAction & ChangeGroupAction

export type GraphProps = {
    dbName: string
    dateBounds: { min: string, max: string }
    user: {
      token: string
    }
    apiURL: string
}

function EzioApp({ apiURL, dbName, user, dbDisplayName, devState, products }: any){
    const [apiError, setApiError] = useState(false);
    const [groupError, setGroupError] = useState(false);
    const [isAggregateProcessing, setIsAggregateProcessing] = useState(false);
    const [isAnalyticsProcessing, setIsAnalyticsProcessing] = useState(false);
    const [processingCountdownFinished, setProcessingCountdownFinished] = useState(null);
    const [minDate, setMinDate] = useState<DateTime>();
    const [maxDate, setMaxDate] = useState<DateTime>();
    const [selectedBeginDate, setSelectedBeginDate] = useState<DateTime>();
    const [selectedEndDate, setSelectedEndDate] = useState<DateTime>();
    const [utcOffset, setUtcOffset] = useState<number>();
    const [dstOffset, setDstOffset] = useState<number>();
    const [standardOffset, setStandardOffset] = useState<number>();
    const [limitedDateRange, setLimitedDateRange] = useState<boolean>(false);
    const [isLd, setIsLd] = useState<any>(null);
    const [vehicles, setVehicles] = useState<Array<any>>();

    const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);
    const [tempSelectedCategory, setTempSelectedCategory] = useState(CATEGORIES[0]);
    const [groups, setGroups] = useState<Array<any>>([]);
    const [electrification, setElectrification] = useState<number>(INIT_ELEC_LEVEL);
    const [tempElectrification, setTempElectrification] = useState<number>(INIT_ELEC_LEVEL);
    const [selectedGroups, setSelectedGroups] = useState<any>();
    const [tempSelectedGroups, setTempSelectedGroups] = useState<any>();
    const [vehicleClassesPresent, setVehicleClassesPresent] = useState<Array<string>>();
    const [selectedVehicleClasses, setSelectedVehicleClasses] = useState<Array<string>>([]);
    const [tempSelectedVehicleClasses, setTempSelectedVehicleClasses] = useState<Array<string>>([]);
    const [totalVehicleCount, setTotalVehicleCount] = useState<number>(0);

    const [chargeLocations, setChargeLocations] = useState<Map<string, ChargeLocation>>();
    const [primaryParkingLocations, setPrimaryParkingLocations] = useState<Array<number>>([]);
    const [selectedChargeLocation, setSelectedChargeLocation] = useState<string>(ALL_LOCATIONS_ID);
    const [tempSelectedChargeLocation, setTempSelectedChargeLocation] = useState<string>(ALL_LOCATIONS_ID);
    const [applyControl, setApplyControl] = useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [loadChargeLocations, setLoadChargeLocations] = useState<boolean>(false);
    const [datesChanged, setDatesChanged] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadAllLocations, setLoadAllLocations] = useState<boolean>(false);

    const [locationsResponse, setLocationsResponse] = useState<any>(null);
    const [vehicleResultsResponse, setVehicleResultsResponse] = useState<any>(null);
    const [vehicleClassesResponse, setVehicleClassesResponse] = useState<any>(null);

    const [simpleKWHRate, setSimpleKWHRate] = useState<number>(null);
    const [rateSchedules, setRateSchedules] = useState<Array<any>>([]);

    const [selectedVins, setSelectedVins] = useState<Array<string>>([]); // Selected vins based off of controls, to be prop drilled to childred

    const [renderApp, setRenderApp] = useState<boolean>(false); // used to determine whether the app should render or wait for the progress indicator
    const [showQuickLoad, setShowQuickLoad] = useState<boolean>(false); // used to render a "quick load" which will occur when worker is processing for a single location
    // eslint-disable-next-line -- disabling linting here (variable is unreferenced but used to update state)
    const [processingCounter, setProcessingCounter] = useState<number>(0); // Note this is only used to force a state update to re-render progress bar

    const location = useLocation();
    const history = useHistory();
    const count = useRef(0);
    const totalEventCount = useRef(0);
    const threads_required = useRef(0);
    const workers = useRef([]);
    const now = useRef(DateTime.now());

    if(devState.toLowerCase() !== "production")benchmark = true;

    const req = useMemo(() => {
        return { user: user,
                 dbName: dbName,
                 apiURL: apiURL, 
                 beginDate: selectedBeginDate,
                 endDate: selectedEndDate,
                 simpleKWHRate: simpleKWHRate,
                 utcOffset: utcOffset,
                 rateSchedules: rateSchedules,
                 dstOffset: dstOffset,
                 standardOffset: standardOffset,
                 MINIMUM_EVENT_KWH: MINIMUM_EVENT_KWH 
                }
      }, [user, dbName, apiURL, selectedBeginDate, selectedEndDate, simpleKWHRate, rateSchedules, utcOffset, dstOffset, standardOffset]);

    const isDaylightSavings = (date) => {
        const dstDates = [
          {start: '2024-03-10', end: '2024-11-03'},
          {start: '2025-03-09', end: '2025-11-02'},
          {start: '2026-03-08', end: '2026-11-01'},
          {start: '2027-03-14', end: '2027-11-07'}
        ];
        let dst = false;
        dstDates.forEach((d) => {
            if(date.ts >= DateTime.fromISO(d.start).setZone("UTC-7").ts // Use MT 
            && date.ts <= DateTime.fromISO(d.end).setZone("UTC-7").ts)dst = true;
        });
        return dst;
      }
    const processUTCDateBounds = (dates) => {
        if(!dates || !dates.data){
          return {'utcStart': DateTime.utc(), 'utcEnd': DateTime.utc()};
        };
        const o = dates.data[0];
        const eventOffset = isDaylightSavings(DateTime.now().setZone("UTC-7")) ? o.min_offset : o.max_offset;
        let zone = `UTC-0`;
        const utcStart = DateTime.fromMillis(o.min_epoch).setZone(zone);
        const utcEnd = DateTime.fromMillis(o.max_epoch).setZone(zone);
        let hours = eventOffset / 60;
        zone = (hours >= 0) ? `UTC+${hours}` : `UTC${hours}`;
        //add in the offset millis because the bespoke endpoint is sending midnight utc as a date part
        //the intention is we start at local midnight of the relevant day, not re-adjusted by zone
        //but we still want it to be timezone aware.
        const eventStart = DateTime.fromMillis(o.min_epoch + (-1*hours*60*60*1000)).setZone(zone);
        const eventEnd = DateTime.fromMillis(o.max_epoch + (-1*hours*60*60*1000)).setZone(zone);
        return {'utcStart': utcStart, 'utcEnd': utcEnd, eventStart: eventStart, eventEnd: eventEnd};
    }

    const locationPen = useMemo(() => {
        const m = new Map();
        return m;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadAllLocations]);

    useEffect(() => {
        // Interval to re-render the app to update progress indicator
        const processingInterval = setInterval(() => {
            count.current++;
            if(!renderApp)
                setProcessingCounter(count.current);
        }, UPDATE_MILLISECONDS);
      
        return () => {
            // clean up the interval
            clearInterval(processingInterval);
        };
    }, [renderApp]);

    useEffect(() => {
        // progress indicator flag control logic
        if(!chargeLocations) return; // if charge locations are not initialized (init load or charge event re-fetch)
        if(selectedChargeLocation !== "All Locations") {
            // if we are processing all locations, display progress indicator
            setRenderApp(true);
            return
        }
        if(chargeLocations.size > 0 && locsFinished === immutableChargeLocations.size) {
            // if charge locations are initialized and processing is complete
            const timeout = setTimeout(() => {
                setRenderApp(true);
            }, 250)

            return () => {
                // clean up timeout
                clearTimeout(timeout);
            }
        }
    }, [chargeLocations, selectedChargeLocation])

    //this hook cleans up after the app is closed
    //only really testable in the dashboard.
    useEffect(() => {
        return () => {
            if(benchmark)console.info("clean up", workers.current.length);
            workers.current.forEach((cl) => cl.terminate());
            workers.current = [];
        }
    }, []);

    useEffect(() => {
        //return empty if Worker is not a thing, ie in a testing env
        if(typeof Worker === 'undefined')return;
        while((active_workers.length + workers.current.length) < MAX_WORKERS){
            const options = {name: `worker-${workers.current.length+1}`};
            const clworker = new Worker(new URL('./ezio-components/cl-worker.js', import.meta.url), options);
            clworker.onmessage = function(e:any) {
                handleChargeLocations(e.data, this);
            }
            workers.current.push(clworker);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processing]);

    useEffect(() => {
        if (processingCountdownFinished) {
            let failures_to_update = 0;
            const interval = setInterval(async () => {
                const isProcessing = await getAnalyticsProcessingState();
                
                if (!isProcessing) { 
                    // Set various states when isProcessing is false
                    setIsAnalyticsProcessing(false);
                    setProcessingCountdownFinished(null);
                    clearInterval(interval); // Break out of the interval loop
                    return; // Exit early since we no longer need to continue the interval
                } else {
                    failures_to_update += 1;
                }
                
                if (failures_to_update >= MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED) {
                    if (isProcessing) {
                        setApiError(true); // If still processing after 5 seconds, set ApiError to true
                    }
                    clearInterval(interval); // Stop the interval after 5 seconds
                }
            }, UPDATE_MILLISECONDS); // Every 1 second
            
            return () => clearInterval(interval); // Cleanup
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processingCountdownFinished]); // Dependency array

    useEffect(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        else {
        let p = [
            fetch(`${req.apiURL}getPeriodObserved?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getSettings?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getKwhRates?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getEzioSelectedVehicles?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getPrimaryParkingLocations?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json())
        ];
        Promise.all(p)
            .then(([boundsResp, vehicleClassesResp, settingsResp, kwhRatesResp, vehicleCountResp, pplResp]) => {
                setPrimaryParkingLocations(pplResp.data);
                const dates = processUTCDateBounds(boundsResp);
                setMinDate(dates.utcStart);
                setMaxDate(dates.utcEnd);
                setSelectedBeginDate(dates.utcStart)
                setSelectedEndDate(dates.utcEnd);

                kwhRatesResp.data.forEach((r) => {
                    r = processApiResponse(user.userSettings, r);
                    r = processRateTime(user.userSettings, r);
                })
                const classes = vehicleClassesResp.data.map((c: any) => {return c.vehicle_class});
                setVehicleClassesPresent(classes);
                setSelectedVehicleClasses(["All Classes"]);
                setTempSelectedVehicleClasses(["All Classes"]);

                if(settingsResp.data.length > 0 && settingsResp.data[0]){
                    // conform db settings to user settings
                    settingsResp.data[0] = processApiResponse(user.userSettings, settingsResp.data[0]);
                    if (settingsResp.data[0].local_kwh_cost !== undefined && settingsResp.data[0].local_kwh_cost !== null) {
                        setSimpleKWHRate(settingsResp.data[0].local_kwh_cost);
                    }
                    else {
                        console.error("Settings did not return a valid local_kwh_cost");
                    }
                    if (settingsResp.data[0].electrification_pct !== undefined && settingsResp.data[0].electrification_pct !== null)
                        setElectrification(settingsResp.data[0].electrification_pct);
                        setTempElectrification(settingsResp.data[0].electrification_pct);
                }
                const sortedKwhRatesResp = sortRates(kwhRatesResp.data);
                setRateSchedules(sortedKwhRatesResp);
                setTotalVehicleCount(vehicleCountResp.data.length);           
            })
            .catch((err) => {
                console.error(err);
                setApiError(true);
            });
        }
            
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [req.user, req.apiURL, req.dbName, user.userSettings,isAnalyticsProcessing, isAggregateProcessing, apiError]);

    useMemo(()=>{
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        let p = [
            fetch(`${req.apiURL}getGroups?dbName=${req.dbName}&isLd=${isLd}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
        ]
        if(isLd === null){
            p = [
                fetch(`${req.apiURL}getGroups?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            ] 
        }
        Promise.all(p)
            .then(([groupsResp]) => {
                // set name and label of swt-vehicles to 'All Groups'
                let idx = groupsResp.data.findIndex((g: any) => g.id === "swt-vehicles")
                if (idx !== -1) {
                    groupsResp.data[idx].label = 'All Groups';
                    groupsResp.data[idx].name = 'All Groups';
                }
                return groupsResp
            }).then((groupsResp) => {
                if(groupsResp.data.length === 0) return setGroupError(true)
                setGroups(groupsResp.data);
                if(selectedGroups && groupsResp.data.find(
                    (g: any) => selectedGroups.some(
                        (selectedGroup: any) => selectedGroup.pkid === g.pkid)
                    )) {
                    //pass
                }else{
                    if(groupsResp.data.find((g: any) => g.id === "swt-vehicles")){
                        setSelectedGroups([groupsResp.data.find((g: any) => g.id === "swt-vehicles")]);
                        setTempSelectedGroups([groupsResp.data.find((g: any) => g.id === "swt-vehicles")]);
                        return;
                    }
                    setSelectedGroups([groupsResp.data[0]]);
                    setTempSelectedGroups([groupsResp.data[0]]);
                }
            })
            .catch((err) => {
                console.error(err);
                setGroupError(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[req.user, req.apiURL, req.dbName, isLd, isAggregateProcessing,isAnalyticsProcessing, apiError])

    useMemo(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        if(!minDate || !maxDate)return;
        const s = dateAsNonTZString(minDate);
        const e = dateAsNonTZString(maxDate);
        fetch(`${req.apiURL}getChargeEventStats?dbName=${req.dbName}&start=${s}&stop=${e}`, {headers: { Authorization: `Bearer ${req.user.token}` }})
            .then((resp) => resp.json())
            .then((data) => {
                // Max offset will always be DST offset (+1 hour ahead of standard)
                // In the event of only one offset for min/max, this logic will self handle
                const standardOffset = data.data[0].minOffset;
                const dstOffset = data.data[0].maxOffset;
                const utcOffset = isDaylightSavings(DateTime.now().setZone("UTC-7")) ? dstOffset : standardOffset;
                setDstOffset(dstOffset);
                setStandardOffset(standardOffset);
                setUtcOffset(utcOffset);
                const count = parseInt(data.data[0].count);
                if(count > LARGE_DATA_SET_THRESHOLD){
                    // TODO: Tag up with Matt and review this logic. - LS 1/11/24
                    let d = DateTime.fromMillis(maxDate.ts).setZone('UTC'); // Deep copy the max date
                    if(d.day < MINIMUM_DAYS_FOR_MONTH){
                        const oneMonthBack = d.minus({ months: 1 }); // Go back one month
                        d = oneMonthBack.endOf('month'); // Set the day to the last day of the month
                    }
                    //calc days to go back
                    const m = DateTime.fromMillis(minDate.ts).setZone('UTC'); // Deep copy the min date
                    const tsd = (d.ts-m.ts)/1000/SECONDS_PER_DAY;//delta in days
                    const initDays = Math.min(tsd, LARGE_DATE_SET_INIT_DAYS);
                    const initDaysMillis = initDays * 1000 * SECONDS_PER_DAY;
                    //set new min
                    const max = DateTime.fromMillis(maxDate.ts).setZone('UTC');
                    const newMin = DateTime.fromMillis((max.ts - initDaysMillis)).setZone('UTC'); //roll back the date by our days const (using millis so luxon doesn't get upset)
                    //update state
                    setSelectedBeginDate(newMin);
                    setSelectedEndDate(d);
                    setLimitedDateRange(true)
                }
                setTimeout(()=>setLoadChargeLocations(true), 300);//this is a terrible way to handle a race condition
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minDate, maxDate, isAggregateProcessing, isAnalyticsProcessing, apiError]);

    useEffect(()=>{
        // on initialization, check the aggregate processing state, and then if all clear, check the analytics processing state.
        getAggregateProcessingState().then(aggregateProcessing => {
            if (!aggregateProcessing) {
                getAnalyticsProcessingState();
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const preprocessChargeLocations = useCallback((locationsResponse, vehicleResultsResponse, vehicleClassesResponse) => {
        const locs: any[] = [{ uuid: ALL_LOCATIONS_ID, address: "All Locations", nickname: "All Locations", latitude: 0, longitude: 0, vehicleResults: [], chargeEvents: [], vins: [] }];
        let eventCount = 0;
        vehicleResultsResponse.data.forEach((v) => {
            // Ensure fresh charge event array
            v.charge_events = [];
        })
        locationsResponse.data.forEach((l: { uuid: string, parking_loc: number; location_ids: Array<any>; charge_events: Array<any>; vins: Array<string>; vehicleResults: Array<any> })=>{  
            
            // Match PPL ID to location UUID
            let match;
            match = primaryParkingLocations.find(ppl => ppl === l.parking_loc); // Ungrouped locations
            if(!match) match = primaryParkingLocations.find(ppl => l.location_ids?.includes(ppl)); // Grouped locations

            if(!loadAllLocations && !match) return // If we are loading just primary locations and the location doesn't match our PPL list, continue the loop without this location

            l.vins.forEach((v: any)=>{
                //append to catch all location object too
                if(locs[0].vins.indexOf(v) === -1)locs[0].vins.push(v);
            });
            l.vehicleResults = [];
            
            // Get list of vehicles that have their primary parking location at this location
            const primaryVcls = vehicleResultsResponse.data.filter(v => v.parking_id === l.parking_loc || l.location_ids?.includes(v.parking_id)).map(v => v.vin);

            //attach charge events to vehicle objects(for homebase/non charging calcs)
            l.charge_events.forEach((ce: {vin: string, parking_loc: string, is_primary: boolean, local_start: DateTime, local_stop: DateTime, modeled: boolean, vehicle_moved: DateTime, kwh: number, charger_level: string}) => {
                ce.parking_loc = l.uuid;
                ce.is_primary = primaryVcls.includes(ce.vin); // Assign flag to charge event to denote if it is a CE for a primary location vehicle
                const vcl = vehicleResultsResponse.data.find((v: any)=> v.vin === ce.vin);
                if(vcl){
                    vcl.charge_events.push(ce);
                }
            });
            locs.push(l)
            //increment eventCount
            eventCount += l.charge_events.length;

            //append to charge events to catch all location object
            //instantiate if the arrays don't exist yet
            if(!locs[0].charge_events)locs[0].charge_events = [];
            locs[0].charge_events = [...locs[0].charge_events, ...l.charge_events];

        });
        eventCount += locs[0].charge_events.length;

        vehicleResultsResponse.data.forEach((v: any)=>{
            v = processApiResponse(user.userSettings, v);

            const vehicleClassProps = vehicleClassesResponse.data.find((vc: any) => vc.vehicle_class === v.vehicle_class);
            v.default_kw_draw = vehicleClassProps ? vehicleClassProps[vehicleClassProps.default_rate] : "NA"; // Note this backup to NA should only occur for the "Equipment" class. All other vehicle classes have default rates populated

            //guarantee uniqueness of vin, even though fresh obj
            if(!locs[0].vehicleResults.find((lvr: any) => lvr.vin === v.vin)){
                locs[0].vehicleResults.push(JSON.parse(JSON.stringify(v)));
            }

            locs.forEach((l)=>{
                //check vehicle belongs to location
                if(l.uuid !== ALL_LOCATIONS_ID && l.vins && l.vins.indexOf(v.vin) > -1){
                    //guarantee uniqueness, because this location has been copied
                    //this will cause dupes if we don't
                    if(!l.vehicleResults.find((lvr: any)=> lvr.vin === v.vin)){
                        if (loadAllLocations) {
                            // All locations set to true, no need to filter vehicle results
                            l.vehicleResults.push(JSON.parse(JSON.stringify(v)));
                        }
                        else {
                            // Otherwise we want to filter out vehicles that don't have this location as their primary parking location
                            if(v.parking_id === l.parking_loc || l.location_ids?.includes(v.parking_id))
                                l.vehicleResults.push(JSON.parse(JSON.stringify(v)));
                        }
                    }
                };
            });
        });
        if(benchmark){
            let d = DateTime.local();
            let tsd = d.ts - now.current.ts;
            console.info(`data loading finished: ${tsd} ms --> Events Received: ${eventCount}`);
        }
        totalEventCount.current = eventCount;
        setVehicles(vehicleResultsResponse.data);

        initializeChargeLocations(locs);
        //setChargeLocations(chargingLocationsMap);
        setApplyControl(true);
        setLoadChargeLocations(false);
        setIsLoading(false);
    }, [user.userSettings, primaryParkingLocations, loadAllLocations])

    useEffect(() => {
        if(!locationsResponse || !vehicleResultsResponse || !vehicleClassesResponse) return;
        preprocessChargeLocations(locationsResponse, vehicleResultsResponse, vehicleClassesResponse);
    }, [loadAllLocations, locationsResponse, vehicleResultsResponse, vehicleClassesResponse, preprocessChargeLocations])

    useEffect(()=>{
        if (isAggregateProcessing || isAnalyticsProcessing || apiError || !loadChargeLocations) return;
        getAnalyticsProcessingState().then(analyticsProcessing => {
            if(!analyticsProcessing) {
                //compile locations
                const controller = new AbortController();
                const signal = controller.signal;

                if((!req.beginDate || !req.endDate) || applyControl)return;
                setLocationsResponse(null);
                setVehicleResultsResponse(null);
                setVehicleClassesResponse(null);
                setIsLoading(true);
                setRenderApp(false);
                
                //request time bounds
                let reqBegin = req.beginDate;
                let reqEnd = req.endDate;


                const s = dateAsNonTZString(reqBegin);
                const e = dateAsNonTZString(reqEnd);
                let kwDemandRequestURL = `${req.apiURL}getChargeLocationsAndEvents?dbName=${req.dbName}&start=${s}&stop=${e}&kwhLimit=${MINIMUM_EVENT_KWH}`;

                if(benchmark){
                    console.info(`loading data: ${reqBegin} - ${reqEnd}`);
                    now.current = DateTime.now();
                }
                //keep track of how many events(including duplications) we have
                const promises = [
                    fetch(kwDemandRequestURL,
                        {
                            method: 'GET', 
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}`, "Content-Type": "application/json"}, 
                        })
                        .then((resp)=>resp.json()), 
                    fetch(`${req.apiURL}getEzioVehicleResults?dbName=${req.dbName}`,
                        {
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}` }
                        })
                        .then((resp)=>resp.json()),
                    fetch(`${req.apiURL}getVehicleClasses?dbName=${req.dbName}`,
                        {
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}` }
                        })
                        .then((resp)=> resp.json())
                ]

                Promise.all(promises)
                    .then(([locationsResponse, vehicleResultsResponse, vehicleClassesResponse]) => {
                        setLocationsResponse(locationsResponse);
                        setVehicleResultsResponse(vehicleResultsResponse);
                        setVehicleClassesResponse(vehicleClassesResponse);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    })
                    .catch((err)=>{
                        console.error(err);
                        setApiError(true);
                    });
                    return () => controller.abort();
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadChargeLocations, isAggregateProcessing, isAnalyticsProcessing, apiError]);

    useMemo(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        //control plane
        if(!chargeLocations || !electrification || !applyControl)return;
        if(benchmark)now.current = DateTime.local();
        locsFinished = 0;
        if(selectedChargeLocation === "All Locations") setRenderApp(false); // if we are processing all locations, display the progress indicator
        else setShowQuickLoad(true); // otherwise display the quick load (single locations can take a second at long date ranges)
        let obj: any = electrifiedVINS(electrification);
        let vins = obj.vins;
        let nonElectrifiedVins = obj.allVins;

        nonElectrifiedVins = vinsAtLocation(nonElectrifiedVins);
        nonElectrifiedVins = vinsInSelectedGroups(nonElectrifiedVins);
        nonElectrifiedVins = vinsInCategory(nonElectrifiedVins);
        nonElectrifiedVins = vinsInSelectedClasses(nonElectrifiedVins);
        
        vins = vinsAtLocation(vins);
        vins = vinsInSelectedGroups(vins);
        vins = vinsInCategory(vins);
        vins = vinsInSelectedClasses(vins);
        setSelectedVins(vins);
        const locs = Array.from(immutableChargeLocations.values());

        setChargeLocations(new Map());
        
        const kickoffWorker = ( chargeLocation: ChargeLocation, vins: string[], nonElectrifiedVins: string[], req: any, database: string) => {
            if(workers.current.length > 0){
                //don't do work on extra locations
                if(selectedChargeLocation !== ALL_LOCATIONS_ID && selectedChargeLocation !== chargeLocation.uuid){
                    return;
                }
                const worker = workers.current.pop();
                active_workers.push(worker);
                if(worker)worker.postMessage([[chargeLocation], vins, nonElectrifiedVins, req, database, loadAllLocations]);
            }
        };

        const timer = (milleseconds:number) => new Promise(res => setTimeout(res, milleseconds));

        const process = async () => {
            let i = 0;
            while(i < locs.length) {
                const l = locs[i];
                if(workers.current.length > 0){
                    kickoffWorker(l, vins, nonElectrifiedVins, req, dbName);
                    i++;
                }else{
                    await timer(PROCESS_LOOP_CADENCE);
                }
            }
        }
        
        if(MULTI_THREADING_ENABLED){
            threads_required.current = Math.max(locs.length, threads_required.current);//
            if(selectedChargeLocation !== ALL_LOCATIONS_ID)threads_required.current = 1;
            count.current = 0;
            processing = true;
            process();
        }
        else{
            threads_required.current = 1;
            processing = true;
            const worker = workers.current.pop();
            if(worker)worker.postMessage([locs, vins, req]);
        }

        setApplyControl(false);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyControl, isAnalyticsProcessing, isAggregateProcessing, apiError]);

    const handleControls = useCallback((controls) => {
        //this all needs to handle in order...
        if(processing){
            //kill all workers
            kill()
                .then(() => {
                    setChargeLocations(new Map(immutableChargeLocations));
                    handleControls(controls);
                })
            return;
        }
        if(chargeLocations.size === 0)setChargeLocations(new Map(immutableChargeLocations));
        controlSwitch(controls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[groups, chargeLocations, CATEGORIES]);

    const settingsFullOn = useMemo(()=>{
        if(selectedChargeLocation === ALL_LOCATIONS_ID &&
           selectedCategory.id === 0 &&
           electrification === 100 &&
           selectedVehicleClasses.indexOf('All Classes') > -1 &&
           loadAllLocations &&
           (selectedGroups && selectedGroups.some((group: any) => group.id === "swt-vehicles"))) return true;
        return false;
    },[selectedCategory, selectedChargeLocation, electrification, selectedGroups, selectedVehicleClasses, loadAllLocations])

    const getAggregateProcessingState = () => {
        const url = `${req.apiURL}isAggregateProcessing?dbName=${req.dbName}`;
        return fetch(url, {headers: { Authorization: `Bearer ${req.user.token}` }})
        .then(res => res.json())
        .then((data) => {
            let processing = data.data[0].aggregate_processing;
            setIsAggregateProcessing(processing);
            return Promise.resolve(processing);
        })
        .catch((err) => {
            console.error(err);
            setApiError(true);
            return Promise.reject(err);
        });
    };

    const getAnalyticsProcessingState = () => {
        const url = `${req.apiURL}isAnalyticsProcessing?dbName=${req.dbName}`;
        return fetch(url, { headers: { Authorization: `Bearer ${req.user.token}` } })
            .then(res => res.json())
            .then((data) => {
                let processing = data.data[0].analytics_processing;
                // only set isAnalyticsProcessing if the value has changed from previous, to prevent re-triggering of other hooks with isAnalyticsProcessing as a dependency
                if (processing !== isAnalyticsProcessing) setIsAnalyticsProcessing(processing);
                return Promise.resolve(processing);
            })
            .catch((err) => {
                console.error(err);
                setApiError(true);
                return Promise.reject(err);
            });
    };

    const handleChargeLocations = (data: Map<number, ChargeLocation>, worker:any) => {
        const pen = locationPen;
        const arr = Array.from(data.values());
        arr.forEach((cl) => {
            locsFinished++; // increment locations finished for each one we get back from the worker
            pen.set(cl.uuid, cl);
        });
        active_workers.pop();
        workers.current.push(worker);
        threads_required.current--;
        threads_required.current = Math.max(threads_required.current, 0);
        if(threads_required.current === 0){
            processing = false;
            setChargeLocations(pen);
            setShowQuickLoad(false);
            // Clean up workers after processing is complete
            // Otherwise can cause a memory leak
            
            workers.current.forEach((cl) => cl.terminate());
            workers.current = [];
            if(benchmark){
                const d = DateTime.local();
                const tsd = d.ts - now.current.ts;
                console.info(`workers finished: ${tsd} ms`);
            }
        }
        //if(chargeLocations && chargeLocations.size > 0 && chargeLocations.size === pen.size)setChargeLocations(pen);
    };

    const sortRates = (rateResp) => {
        if(!rateResp || rateResp.length < 1)return rateResp;
        let sortedRates = [];
        // filter TOD rates
        let tod = rateResp.filter(r => (r.startHour !== null && r.stopHour !== null && r.startMinute !== null && r.stopMinute !== null));
        // order TOD rates by shortest duration
        let sortedTod = tod.sort((a, b) => {
                let durationA = (a.stopHour - a.startHour) + ((a.stopMinute - a.startMinute)/60);
                let durationB = (b.stopHour - b.startHour) + ((b.stopMinute - b.startMinute)/60);
                if(durationA < durationB)return -1;
                if(durationA > durationB)return 1;
                // potentially add secondary sort (would need to add to datalayer also)
                return 0;
            }
        );
        // add sorted TOD rates to sorted rates array
        if(sortedTod.length > 0) sortedRates = sortedRates.concat(sortedTod);
        // filter DOW rates - no TOD set, only DOW set
        let dow = rateResp.filter(r => (r.startHour === null && r.stopHour === null &&
                                        r.startMinute === null && r.stopMinute === null &&
                                        (r.mondays || r.tuesdays || r.wednesdays || r.thursdays || r.fridays || r.saturdays || r.sundays)));
        // order DOW rates by kwh_rate descending
        let sortedDow = dow.sort((a, b) => {
                if(a.kwhRate < b.kwhRate)return 1;
                if(a.kwhRate > b.kwhRate)return -1;
                return 0;
            }
        )
        // add sorted DOW rates to sorted rates array
        if(sortedDow.length > 0) sortedRates = sortedRates.concat(sortedDow);
        // filter seasonal rates - rates with no TOD or DOW set
        let seasonal = rateResp.filter(r => (r.startMonth !== null && r.stopMonth !== null &&
                                             r.startHour === null && r.stopHour === null &&
                                             r.startMinute === null && r.stopMinute === null &&
                                             (!r.mondays && !r.tuesdays && !r.wednesdays && !r.thursdays && !r.fridays && !r.saturdays && !r.sundays)));
        // order seasonal rates by duration & cost
        let sortedSeasonal = seasonal.sort((a, b) => {
            return seasonalRateSort(a, b);
        })
        // add sorted seasonal rates to sorted rates array
        if(sortedSeasonal.length > 0) sortedRates = sortedRates.concat(sortedSeasonal);
        return sortedRates;
    }

    function seasonalRateSort(a, b) {
        // order by rate with shortest duration
        if(rateDuration(a) < rateDuration(b))return -1;
        if(rateDuration(a) > rateDuration(b))return 1;
        // if the same, return highest rate
        if(a.kwhRate < b.kwhRate)return 1;
        if(a.kwhRate > b.kwhRate)return -1;
        return 0;
    }

    const rateDuration = (rate) => {
        // sum up duration of seasonal rate
        // NOTE this function doesn't cover edge cases, which is fine for this purpose
        // but could be improved in future
        let startMonth = rate.startMonth;
        let startDay = rate.startDayOfMonth;
        let stopMonth = rate.stopMonth;
        let stopDay = rate.stopDayOfMonth;
        let yr = DateTime.utc().year;
        let startYr = yr;
        // if rate goes over a year, update start year
        if (startMonth > stopMonth)startYr = yr-1;
        const strt = DateTime.utc(startYr, startMonth, startDay);
        const stp = DateTime.utc(yr, stopMonth, stopDay);
        let diff = Math.abs(stp.ts - strt.ts);
        // diff = Math.ceil(diff / (1000 * 60 * 60 * 24)); // convert to days
        return diff
    }

    function initializeChargeLocations(locs){
        const chargingLocationsMap = new Map();
        
        locs.forEach((l: ChargeLocation)=>{
            const m = l.vehicleResults ? l.vehicleResults.filter((v: { is_ev_recommendation: boolean; })=> v.is_ev_recommendation===true) : [];

            //homebase calculations from API response

            l?.vehicleResults?.forEach((v) => {
                // match up the vehicle's parking ID to the corresponding location UUID and display name
                const vpkid = v.parking_id;
                const parking_loc = locs.find(l => l.pkid === vpkid || l.location_ids?.includes(vpkid));
                v.parking_loc_uuid = parking_loc?.uuid;
                v.ezio_parking_loc_string = parking_loc?.nickname ? parking_loc?.nickname : parking_loc?.address;

                if(l.location_ids) {
                    // Grouped location, check for inclusivity on HB id
                    v.is_past_homebase = false; // default to non-HB
                    if(l.location_ids.includes(v.parking_id)) v.is_homebase_location = true; // If the locationIds array includes the homebase ID
                    else v.is_homebase_location = false;
                    if(!v.is_homebase_location) {
                        l.location_ids.forEach((id) => {
                            if(v?.homebases?.indexOf(id) > -1) v.is_past_homebase=true; // Check existence of each location_id in the historical homebases list
                        })
                    }
                }
                else {
                    if(v.parking_id === l.pkid || l.pkid === -1)v.is_homebase_location = true;
                    else v.is_homebase_location = false;
                    if(!v.is_homebase_location && v?.homebases?.indexOf(l.pkid) > -1)v.is_past_homebase=true;
                    else v.is_past_homebase = false;
                }
            });
            l.chargingVehiclesCount = l.vins ? l.vins.length : 0;
            l.inBoundChargingVehiclesCount = l.vins ? l.vins.length : 0;
            l.evRecommendationCount = m.length;
            l.chargeEventCount = l.charge_events ? l.charge_events.length : 0;
            l.summary = null;
            l.peakKw = 0;
            chargingLocationsMap.set(l.uuid, l);
        });
        immutableChargeLocations = new Map(chargingLocationsMap);
        setChargeLocations(chargingLocationsMap);
    }

    function electrifiedVINS(electrificationPercent: number){
        if(!chargeLocations)return [];
        const arr = Array.from(chargeLocations.values());

        let vins: any[] = [];
        let allVins: any[] = [];

        arr.forEach((a)=>{
            if(a.vehicleResults && a.uuid !== ALL_LOCATIONS_ID){
                a.vehicleResults.forEach((v: any)=>{
                    const included = vins.some(x => x.vin === v.vin);
                    if(!included)vins.push({vin: v.vin, overall: v.overall, energy: v.energy, economics: v.economics});
                });
            }
        });
        allVins = vins;
        consistentElectrificationSort(vins);
        const vclCount = vehicles ? vehicles.length : vins.length;
        const slice2Take = vclCount * (electrificationPercent/100);
        const r = vins.slice(0, slice2Take);
        return({ vins: r.map((v)=> {return v.vin}), allVins: allVins.map((v) => {return v.vin})});
    }

    function vinsInSelectedGroups(vins: Array<any>){
        if(!selectedGroups || !selectedGroups.some(group => group.vehicles))return vins; // ensure that at least one selected group has vehicles
        const r: Array<any> = [];
        vins.forEach((v)=>{
            // Check if any selected group contains the vin
        if (selectedGroups.some((group: any) => 
            group.vehicles && group.vehicles.findIndex((i: any) => i.vin === v) > -1
        )) {
            r.push(v);
        }
        })
        return r;
    }

    function forceNavigate(id: string) {
        if (id === selectedChargeLocation) return; // we don't need to rerun controls updates if the new ID matches the already selected ID
        const locationExists = chargeLocations?.has(id);
        if (locationExists) {
            setSelectedChargeLocation(id);
            setTempSelectedChargeLocation(id);
            handleControls({location:id});
            setApplyControl(true);
            setSubmitButtonDisabled(true);
        } else {
            console.error('An invalid location ID was provided to the forceNavigate function! Redirecting to landing page.')
            history.push("/ezio");
        }
    }

    function vinsInCategory(vins: Array<any>){
        if(!selectedCategory || !vehicles)return vins;
        const r: Array<any> = [];
        let b: Array<any> = [];
        if(selectedCategory.id === 0)return vins;
        if(selectedCategory.id === 1)b = vehicles.filter((v) => v.is_ld === (true || null));
        if(selectedCategory.id === 2)b = vehicles.filter((v) => v.is_ld === false);
        b = b.map((v) => {return v.vin});
        vins.forEach((v) => {if(b.indexOf(v) > -1)r.push(v)});
        return r;
    }

    function vinsAtLocation(vins: Array<any>){
        if(!selectedChargeLocation || selectedChargeLocation === ALL_LOCATIONS_ID)return vins;
        const r: Array<any> = [];
        const cl = chargeLocations?.get(selectedChargeLocation);
        if(cl)vins.forEach((v) => {if(cl.vins.indexOf(v) > -1)r.push(v)});
        return r;
    }

    function vinsInSelectedClasses(vins: Array<any>){
        if(!vehicles || !selectedVehicleClasses || selectedVehicleClasses.includes('All Classes'))return vins;
        const r: Array<any> = [];
        let b = vehicles.filter((v) => selectedVehicleClasses.indexOf(v.vehicle_class) > -1);
        b = b.map((v) => {return v.vin});
        vins.forEach((v) => {if(b.indexOf(v) > -1)r.push(v)});
        return r;
    }

    //kill all workers
    const kill = () => {
        return new Promise((resolve) => {
            if(active_workers.length === 0){
                threads_required.current = 0;
                processing = false;
                resolve(null);
                return
            }
            while(active_workers.length > 0){
                active_workers[0].terminate();
                active_workers.splice(0, 1);
                if(active_workers.length === 0){
                    threads_required.current = 0;
                    processing = false;
                    resolve(null);
                    return;
                }
            }
        })
    }

    const controlSwitch = (controls) => {
        // Reflect control changes in temporary holding state variables
        // Temp variables will be used to populate the actual control state upon "Update Controls" button click
        for(const c in controls){
            if(c === 'electrification'){
                setTempElectrification(controls[c]);
            };
            if(c === 'groups'){
                const foundGroups = [];
                controls[c].forEach((groupId) => {
                    const foundGroup = groups.find((group) => group.id === groupId);
                    foundGroups.push(foundGroup)
                })
                setTempSelectedGroups(foundGroups);
            }
            if(c === 'category'){
                const cat = CATEGORIES.find((i) => i.label === controls[c]);
                if(cat?.id === 2)setIsLd(false);
                if(cat?.id === 1)setIsLd(true);
                if(cat?.id === 0)setIsLd(null);
                if(cat)setTempSelectedCategory(cat);
            }
            if(c === 'location'){
                if(!chargeLocations)return;
                const arr = Array.from(chargeLocations.values());
                const l = arr.find((i) => i.uuid === controls[c]);
                if(l){
                    setTempSelectedChargeLocation(l.uuid);
                }
            }
            if(c === 'classes'){
                setTempSelectedVehicleClasses(controls[c]);
            }
        }
        setSubmitButtonDisabled(false);
    }

    const handleDateChange = (key: string, newDate: DateTime) =>{
        if(!key)return;
        if(key==='min')setSelectedBeginDate(newDate);
        if(key==='max')setSelectedEndDate(newDate);
        setDatesChanged(true);
        setSubmitButtonDisabled(false);
    }

    if(isAnalyticsProcessing || isAggregateProcessing || apiError || groupError){
        return(
            <S.ProductWrapper>
                <ActiveProcessingPage
                    user={user}
                    apiURL={apiURL}
                    dbDisplayName={dbDisplayName}
                    dbName={dbName}
                    setProcessingCountdownFinished={setProcessingCountdownFinished}
                    apiError={apiError}
                    aggregateProcessing={isAggregateProcessing}
                    groupError={groupError}
                />
            </S.ProductWrapper>
        );
    }

    if(!chargeLocations){
        return(
            <S.ProductWrapper>
                <S.LoadingContainer>
                    <Loading/>
                </S.LoadingContainer>
            </S.ProductWrapper>
        );
    }

    const enableControls = (threads_required.current < 1 && !isLoading && renderApp) ? true : false;
    const aboutPageDisplayed = location.pathname.includes('about') ? true : false;
    return(
        <S.ProductWrapper>
            <Header displayName={dbDisplayName}/>
            <S.HeaderRule />
            {
                (enableControls && vehicleClassesPresent && selectedVehicleClasses && !aboutPageDisplayed) ? 
                    <Controls
                        groups={groups}
                        chargeLocations={chargeLocations} 
                        classes={vehicleClassesPresent}
                        onChangeControls={(c:any)=>{handleControls(c)}}
                        selectedBeginDate={selectedBeginDate}
                        selectedEndDate={selectedEndDate}
                        handleDateChange={handleDateChange}
                        setApplyControl={setApplyControl}
                        setDatesChanged={setDatesChanged}
                        setLoadChargeLocations={setLoadChargeLocations}
                        tempSelectedChargeLocation={tempSelectedChargeLocation}
                        tempSelectedCategory={tempSelectedCategory}
                        tempSelectedVehicleClasses={tempSelectedVehicleClasses}
                        tempSelectedGroups={tempSelectedGroups}
                        tempSelectedElectrification={tempElectrification}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedVehicleClasses={setSelectedVehicleClasses}
                        setSelectedGroups={setSelectedGroups}
                        setSelectedElectrification={setElectrification}
                        setSelectedChargeLocation={setSelectedChargeLocation}
                        setTempSelectedChargeLocation={setTempSelectedChargeLocation}
                        loadAllLocations={loadAllLocations}
                        setLoadAllLocations={setLoadAllLocations}
                        submitButtonDisabled={submitButtonDisabled}
                        setSubmitButtonDisabled={setSubmitButtonDisabled}
                        datesChanged={datesChanged}
                        minDate={minDate}
                        maxDate={maxDate}
                        location={location.pathname}
                        userSettings={user.userSettings}
                        limitedDateRange={limitedDateRange}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />
                :
                    <S.ControlsPlaceholder hidden={aboutPageDisplayed}/>
            }
            <S.HeaderRule hidden={aboutPageDisplayed}/>

            {(chargeLocations.size === 0 || threads_required.current !== 0 || isLoading) && isLoading && 
                // Initial data fetch from API
                // Unable to determine ETA here so render an indeterminate progress indicator
                <S.LoadingContainer>
                    <CircularIndeterminate/>
                    <S.LoadingCaption>Loading data, please wait</S.LoadingCaption>
                </S.LoadingContainer>
            }

            {!renderApp && !isLoading && selectedChargeLocation === "All Locations" &&
                // Actual worker processing is occurring
                // Render a determinate progress indicator with progress = number of locations processed / total locations
                <S.LoadingContainer>
                    <CircularWithValueLabel progress={locsFinished / immutableChargeLocations.size}></CircularWithValueLabel>
                    <S.LoadingCaption>Processing data</S.LoadingCaption>
                </S.LoadingContainer>
            }

            {!isLoading && selectedChargeLocation !== "All Locations" && showQuickLoad && 
                // Indeterminate "quick load" to display when processing a single location
                <S.LoadingContainer>
                    <CircularIndeterminate/>
                </S.LoadingContainer>
            }

            {chargeLocations.size > 0 && selectedCategory && selectedGroups && !isLoading && renderApp &&
            // App is ready for render
            <Switch>
                <Route exact path="/"><Redirect to="/ezio/"></Redirect></Route>
                <Route path="/ezio/locations/list">
                    <LocationsDisplay 
                        dbName={dbName} 
                        chargeLocations={chargeLocations} 
                        viewMode={"list"} 
                        selectedChargeLocation={selectedChargeLocation}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        category={selectedCategory.label}
                        groups={selectedGroups}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        loadAllLocations={loadAllLocations}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />
                </Route>
                <Route path="/ezio/locations/map">
                    <LocationsDisplay 
                        dbName={dbName}
                        chargeLocations={chargeLocations} 
                        viewMode={"map"} 
                        selectedChargeLocation={selectedChargeLocation}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        loadAllLocations={loadAllLocations}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />
                </Route>
                <Route path="/ezio/locations/:urlId">
                    {selectedChargeLocation && <LocationSummary
                        totalVehicleCount={totalVehicleCount}
                        selectedChargeLocation={selectedChargeLocation}
                        chargeLocations={chargeLocations}
                        req={req}
                        category={selectedCategory.label}
                        groups={selectedGroups}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        loadAllLocations={loadAllLocations}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />}
                </Route>
                <Route path="/ezio/kw-demand-monthly/:urlId">
                    <LocationDetail 
                        viewMode={"Monthly"}
                        req={req}
                        totalVehicleCount={totalVehicleCount}
                        chargeLocations={chargeLocations}
                        selectedChargeLocation={selectedChargeLocation}
                        category={selectedCategory.label}
                        groups={selectedGroups}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        user={user}
                        selectedVins={selectedVins}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        loadAllLocations={loadAllLocations}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />
                </Route>
                <Route path="/ezio/kw-demand-daily/:urlId">
                    <LocationDetail 
                        viewMode={"TimeOfDay"}
                        req={req}
                        totalVehicleCount={totalVehicleCount}
                        chargeLocations={chargeLocations}
                        selectedChargeLocation={selectedChargeLocation}
                        category={selectedCategory.label}
                        groups={selectedGroups}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        user={user}
                        selectedVins={selectedVins}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        loadAllLocations={loadAllLocations}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                    />
                </Route>
                <Route exact path="/ezio">
                    <LandingPage 
                        apiUrl={apiURL}
                        user={user}
                        dbName={dbName}
                        selectedLocationId={selectedChargeLocation}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                        chargeLocations={chargeLocations}
                        totalVehicleCount={totalVehicleCount}
                        noGroups={false}
                        selectedCategory={selectedCategory}
                        settingsFullOn={settingsFullOn}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        selectedGroups={selectedGroups}
                        selectedClasses={selectedVehicleClasses}
                        selectedElectrification={electrification}
                        loadAllLocations={loadAllLocations}
                    />
                </Route>
                <Route exact path="/ezio/about">
                    <AboutPage
                        user={user}
                        settingsKwhRate={simpleKWHRate}
                        kwhRates={rateSchedules}
                        products={products}
                    />
                </Route>
                <Route exact path="/ezio/vehicles">
                    <VehiclesPage
                        chargeLocations={chargeLocations}
                        selectedChargeLocation={selectedChargeLocation}
                        req={req}
                        category={selectedCategory.label}
                        groups={selectedGroups}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        ALL_LOCATIONS_ID={ALL_LOCATIONS_ID}
                        loadAllLocations={loadAllLocations}
                        forceNavigate={(id: string)=>forceNavigate(id)}
                    />
                </Route>
                {/* Catch-all route for undefined paths to redirect to landing page.
                    Note that this can really only happen when running the local repo - post-auth redirect prevents this in the dashboard
                    Adding this now for if we add support in the future for post-auth return to URL,
                    and to prevent weirdness when developing locally - NK 9/24
                */}
                <Route
                  render={() => 
                    <Redirect to="/ezio" />
                  }
                />
            </Switch>
            }
        </S.ProductWrapper>
    )
}

export default function Ezio(props: any) {
    return (
        <EzioApp {...props} />
    )
}