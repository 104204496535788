import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { sawatch_blue, white } from '../../styles/emit-styles/ColorScheme';

const copyright = `\u00A9\uFE0F`;
const trademark = `\u2122`;

const styles = StyleSheet.create({
  footerContainer: {
    backgroundColor: sawatch_blue,
    height: 35,
    width: '100vw',
    position: 'absolute',
    bottom: 0,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.5
  },
  footerText: {
    fontSize: 8,
    textAlign: 'center',
  },
  footerLink: {
    textDecoration: 'none',
    color: white
  }
})

export default function PdfFooter() {
  return (
    <View style={styles.footerContainer} fixed>
      <Text style={styles.footerText}><Link style={styles.footerLink} src={'mailto:info@sawatchlabs.com/'}>info@sawatchlabs.com</Link> | (303) 578-2465 | <Link style={styles.footerLink} src={'https://sawatchlabs.com/'}>sawatchlabs.com</Link></Text>
      <Text style={styles.footerText}>{copyright} {new Date().getFullYear()} Sawatch Labs, a WEX{trademark} company. All rights reserved.</Text>
    </View>
  );
}

