import styled from 'styled-components';
import { dark_gray, off_black, stroke_gray, white } from './ColorScheme';
import { MEDIUM_RADIUS, TRANSITION_LENGTH } from './CardStyles';

export const ChartHover = styled.div`
    background-color: ${white};
    padding: 10px;
    border: 1px solid ${stroke_gray};
    border-radius: ${MEDIUM_RADIUS}px;
    color: ${dark_gray};
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(5px);
    transition: background-color 0.3s ease;
    transition: opacity ${TRANSITION_LENGTH} ease-in-out;
`;

export const HoverTitle = styled.span`
    font-size: 1.1rem;
    font-weight: 500;
    color: ${({ colorOverride }) => colorOverride || off_black} ;
`;

export const HoverContent = styled.span`
    font-size: 1.0rem;
    font-weight: 400;
    color: ${({ colorOverride }) => colorOverride || dark_gray};
`;