import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {ReactComponent as SearchIcon} from '../../images/Search.svg';
import { blue_green, light_gray, off_black } from '../../styles/emit-styles/ColorScheme';
import { MEDIUM_RADIUS, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';

const FOCUSED_COLOR = blue_green;
const ELEMENT_COLOR = light_gray;

const SearchBar = styled.div`
    position: relative;
`;

const SearchInput = styled.input`
    width: 245px;
    height: 32px;
    border: 2px solid;
    border-color: ${ELEMENT_COLOR};
    border-radius: ${MEDIUM_RADIUS}px;
    color: ${off_black};
    margin: 0;
    padding: 0;
    text-indent: 5px;
    font-size: 14px;
    outline: none;
    padding-left: 24px;
    font-family: inherit; // inputs do not inherit font by default, apparently
    box-sizing: border-box;
    transition: border-color ${TRANSITION_LENGTH} ease;
    &:focus {
        border-color: ${FOCUSED_COLOR}
    }
`;

const IconWrapper = styled.div`
  fill: ${ELEMENT_COLOR};
  position: absolute;
  height: 18px;
  width: 18px;
  left: 8px;
  top: 8px;
  transition: fill ${TRANSITION_LENGTH} ease;
  ${SearchBar}:focus-within & {
    fill: ${FOCUSED_COLOR};
  }
`;

const Search = ({allValues, setMatchingValues, width, height}) => {
    const[activeSearch, setActiveSearch] = useState(undefined);

    useEffect(()=>{
            let matching = allValues;
            if (activeSearch !== undefined) {
              let searchTerm = activeSearch.toString().toLowerCase();
                const matchingValues = matching?.filter((entry) =>
                Object.values(entry).some((val) =>
                  `${val}`.toLowerCase().includes(searchTerm)
              ));
              setMatchingValues(matchingValues)
            } else {
              return setMatchingValues(allValues);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch, allValues]);

    return(
      <SearchBar>
        <IconWrapper>
            <SearchIcon/>
        </IconWrapper>
        <SearchInput value={activeSearch ? activeSearch : ""} onChange={(e) => setActiveSearch(e.target.value)} placeholder = 'Search'/>
      </SearchBar>
    );
};

export default Search;