import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { infoCardStyle } from '../../styles/emit-styles/PdfStylesheet';
import { dark_gray, darkest_gray, off_black } from '../../styles/emit-styles/ColorScheme';

Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
    infoCardWrapper: {
        ...infoCardStyle,
        justifyContent: 'center'
    },
    value: {
        fontSize: 18,
        fontWeight: 700,
        color: off_black,
        marginBottom: 4
    },
    label: {
        fontSize: 15,
        fontWeight: 500,
        color: darkest_gray
    },
    caption: {
        fontSize: 11,
        fontWeight: 400,
        color: darkest_gray,
        marginBottom: 2
    },
    subcaption: {
        fontSize: 8,
        fontWeight: 400,
        color: dark_gray
    }
});

export default function PdfInfoCard({ valuePrefix, value, label, caption, subcaption, height, width, minWidth, maxWidth }) {

    return (
        <View
            style={[
                styles.infoCardWrapper,
                { height: height, width: width, minWidth: minWidth, maxWidth: maxWidth }
            ]}
        >
            <View style={styles.infoCardValueContainer}>
                <Text style={styles.caption}>{caption}</Text>
                <Text style={styles.value}>{valuePrefix}{value}<Text style={styles.label}> {label}</Text></Text>
                {subcaption && <View style={styles.subcaption} ><Text>{subcaption}</Text></View>}
            </View>
        </View>
    )
}