import styled from 'styled-components';
import { dark_gray, darkest_gray, off_black, stroke_gray } from './ColorScheme';

export const SMALL_RADIUS = 5;
export const MEDIUM_RADIUS = 8;
export const PDF_RADIUS = 12;
export const LARGE_RADIUS = 16;
export const TRANSITION_LENGTH = "0.2s";
export const TRANSITION_LENGTH_INT = 2000;

export const InfoCardStyled = styled.div`
    background-color: white;
    border: 1px solid ${stroke_gray};
    box-sizing: border-box;
    padding: 18px;
    margin-top: 24px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: ${LARGE_RADIUS}px;
    height: ${({ height }) => height || 'auto'};
    width: ${({ width }) => width || 'auto'};
    min-width: ${({ minWidth }) => minWidth || 'auto'};
    max-width: ${({ maxWidth }) => maxWidth || 'auto'};
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
    display: flex;
    flex-direction: column;
    justify-content: ${({ align }) => align || 'center'};
    align-items: start;
    gap: ${({ gap }) => gap || '8px'};
    user-select: ${({ disableSelection }) => disableSelection ? 'none' : 'auto'};

`;

export const CardTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

export const CardTitle = styled.div`
    font-size: 1.25rem;
    font-weight: 500;
    color: ${off_black};
`;

export const CardSubtitle = styled.div`
    font-size: 1.25rem;
    font-weight: 400;
    color: ${darkest_gray};
`;

export const CardBody = styled.div`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 4px;
  color: ${dark_gray};
`;