import React from 'react';
import Loading from "./loading";
import { FormatCommas, FormatTemperatureRange, FormatGhgReductionAmt, FormatMonetaryValue } from './FormatAttributes';
import { calculateGhgReductionAmt, calculateProjectedSavings, calculateTotalDistance} from './HelperFunctions';

class InfoCardLanding extends React.Component {

  render() {
    let tempRange = null;
    let ghgReductionTotal = null;
    let tcoSavings = null;
    let totalDistance = null;
    const noGroups = this.props.noGroupsAssigned;

    function valuesLoaded() {
      if (tempRange === null || ghgReductionTotal === null || tcoSavings === null || totalDistance === null) {
        return false
      } else {
      return true
      }
    }


    let dateBounds = '';
    if (this.props.groupMinDate && this.props.groupMaxDate) {
      dateBounds = `${this.props.groupMinDate.date} - ${this.props.groupMaxDate.date}`
    }
    
    if(!this.props.dataLoading && !noGroups) {
      tempRange = FormatTemperatureRange(this.props.fleetEzevMetrics.min_celsius, this.props.fleetEzevMetrics.max_celsius, this.props.userSettings.use_celsius);
      ghgReductionTotal = FormatGhgReductionAmt(calculateGhgReductionAmt(this.props.activity, this.props.userSettings), this.props.userSettings);
      tcoSavings = FormatMonetaryValue(calculateProjectedSavings(this.props.activity, this.props.userSettings),this.props.userSettings.currency_symbol);
      totalDistance = FormatCommas(calculateTotalDistance(this.props.activity, this.props.userSettings))
    }
  
    if(!noGroups && (this.props.dataLoading || !valuesLoaded())) {
      return (
        <div className="ezev-information-card single" data-testid="landingPageInfoCard-testid">
          <div className="ezev-card-content">
          <div className="ezev-infocard-loading-wrapper">
            <Loading />
          </div>
        </div>
      </div>
      )
    }

    return (
      <div className="ezev-information-card single">
        <div className="ezev-card-content">
          <p>Client: <span data-testid="clientName-testid" className="ezEV-table-text-right-aligned">{this.props.dbDisplayName}</span></p>
          <div className="ezev-divider"></div>
          <p>Operational Input: <span data-testid="operationalInput-testid" className="ezEV-table-text-right-aligned">Fuel Transaction Data</span></p>
          <div className="ezev-divider"></div>
          <p>Vehicles: <span data-testid="vehicleCount-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : FormatCommas(this.props.vehicleCount)}</span></p>
          <div className="ezev-divider"></div>
          <p>Period of observation: <span data-testid="observationPeriod-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : dateBounds}</span></p>
          <div className="ezev-divider"></div>
          <p>Temperature range: <span data-testid="temperatureRange-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : tempRange}</span></p>
          <div className="ezev-divider"></div>
          <p><span data-testid="distanceLabel-testid">{`Total ${this.props.userSettings.distance_labels.longPlural}`}</span>: <span data-testid="totalDistance-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : totalDistance}</span></p>
          <div className="ezev-divider"></div>
          <p>Recommended EVs: <span data-testid="recommendedEvCount-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : this.props.groupRecommendedCount}</span></p>
          <div className="ezev-divider"></div>
          <p>TCO Savings: <span data-testid="tcoSavings-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : tcoSavings}</span></p>
          <div className="ezev-divider"></div>
          <p>GHG Emissions Reduction: <span data-testid="emissionsReduction-testid" className="ezEV-table-text-right-aligned">{noGroups ? "N/A" : ghgReductionTotal}</span></p>
        </div>
      </div>
    );
  }
}

export default InfoCardLanding;
