export const dark_aqua = "#0b6787";
export const dark_turquoise = "#035e5b";
export const light_aqua = "#259cc4";
export const darkest_gray = "#2f2f30";
export const dark_gray = "#4c4c4d";
export const ezev_dark_gray = "#4A4A4A"
export const charcoal_gray = "#4c4c4d";
export const medium_gray = "#74787d";
export const light_gray = "#b0b2b5"
export const ezev_light_gray = "#F5F5F5"
export const lightest_gray = "#e5e4e3";
export const light_gray_blue = "#b6bcc1";
export const very_light_gray_blue = "#dfe6ed";
export const dark_blue = "#1e2b43";
export const blue_purple = "#34497c";
export const blue_grey = "#979a9e";
export const purple = "#915693";
export const blue_green = "#13908b";
export const white = "#ffffff";
export const black = "#000000";
export const overall = "rgb(249, 169, 59)";
export const confidence = "rgb(236, 140, 86)";
export const energy = "rgb(234, 108, 106)";
export const economics = "rgb(145, 86, 147)";
export const parking = "rgb(52, 73, 124)";
export const graphLight = "#adc8e0";
export const graphLightHover = "#90b4d4";
export const graphDark = "#34497c";
export const graphDarkHover = "#2a3a63";
export const dark_red = '#E12F2C';
export const medium_red = '#ea6c6a';
export const light_red = '#F09796';