import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { blue_green } from '../../styles/emit-styles/ColorScheme';

const LoadingIndeterminate = () => {
  return (
    <Box height='200px' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: blue_green}}>
      <CircularProgress size="100px" color="inherit"/>
    </Box>
  );
}

export default LoadingIndeterminate;