import { MidGrid } from "../../styles/emit-styles/Emit-styles";
import CardGrid from "./CardGrid";
import DistributionCard from "./DistributionCard";
import GraphCard from "./GraphCard";
import { compileAltFuelCompositionCardData, compileAvoidedEmissionsByDrivetrainData, compileAvoidedEmissionsViewGraphData, compileDistanceBreakdownByAltDrivetrainData, FormatSummaryData } from "./UtilityFunctions";
import { altDrivetrains, AVOIDED_TABLE_TYPE, combinedEmissionsBars, emissionsByDrivetrainBars, UNITS_GRAMS, UNITS_POUNDS, UNITS_TONS } from "../swt-emit";
import TableCard from "./TableCard";
import { useEffect, useState } from "react";


const AvoidedEmissionsView = ({ emissionsSummary, displayInLbs, dbDisplayName, group, beginDate, endDate, selectedVehicleClasses, vehicleClasses, mappedData, rawData, handleNoDataMessageText, setMatchingValues, graphData, defaultToMonthView, isTd, methodology }) => {

    const [viewDataByMonth, setViewDataByMonth] = useState(defaultToMonthView);
    const [avoidedEmissionsData, setAvoidedEmissionsData] = useState([]);
    const [showDiscreteData, setShowDiscreteData] = useState(true);
    const [splitByDrivetrain, setSplitByDrivetrain] = useState(false);
    const [drivetrainsWithData, setDrivetrainsWithData] = useState({});
    const [hasCngVehicles, setHasCngVehicles] = useState(false);

    useEffect(() => {
        setHasCngVehicles(emissionsSummary.cng > 0);
    }, [emissionsSummary]);

    useEffect(() => {
        setAvoidedEmissionsData([]); // wipe graph data array

        setDrivetrainsWithData(splitByDrivetrain ?
            {
                "bevAvoided": emissionsSummary.bevGHGTonsSaved !== 0,
                "phevAvoided": emissionsSummary.phevGHGTonsSaved !== 0,
            } : {
                "avoided": emissionsSummary.ghgTonsSaved !== 0,
            }
        );
        
        const updatedGraphData = compileAvoidedEmissionsViewGraphData(graphData,viewDataByMonth,splitByDrivetrain,showDiscreteData);
        setAvoidedEmissionsData(updatedGraphData);
    }, [graphData, emissionsSummary, viewDataByMonth, showDiscreteData, splitByDrivetrain]);

    const units = displayInLbs ? UNITS_POUNDS : UNITS_TONS;
    const ghgSaved = displayInLbs ? emissionsSummary.ghgLbsSaved : emissionsSummary.ghgTonsSaved;
    const co2Saved = displayInLbs ? emissionsSummary.CO2LbsSaved : emissionsSummary.CO2TonsSaved;
    const ch4Saved = displayInLbs ? emissionsSummary.ch4LbsSaved : emissionsSummary.ch4TonsSaved;
    const bioCO2Saved = displayInLbs ? emissionsSummary.bioCO2LbsSaved : emissionsSummary.bioCO2TonsSaved;


    const emissionsCardData = () => {
        if (methodology.value === 'moves3') {
            return (
                [
                    {
                        primaryValue: FormatSummaryData('ghg', ghgSaved),
                        primaryLabel: units,
                        subtitle: "Avoided GHG Emissions",
                        note: "(Including GHG from kWh gen.)"
                    },
                    {
                        primaryValue: FormatSummaryData('co2', co2Saved),
                        primaryLabel: units,
                        subtitle: "Carbon Dioxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('coLbs', emissionsSummary.coLbsSaved),
                        primaryLabel: UNITS_POUNDS,
                        subtitle: "Carbon Monoxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('noxLbs', emissionsSummary.noxLbsSaved),
                        primaryLabel: UNITS_POUNDS,
                        subtitle: "Nitrous Oxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('pm10Grams', emissionsSummary.pm10GramsSaved),
                        primaryLabel: UNITS_GRAMS,
                        subtitle: "Particulate Matter 10\u00B5m",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('pm25Grams', emissionsSummary.pm25GramsSaved),
                        primaryLabel: UNITS_GRAMS,
                        subtitle: "Particulate Matter 2.5\u00B5m",
                        note: "(Tailpipe)"
                    },
                ]
            )
        } else {
            return (
                [
                    {
                        primaryValue: FormatSummaryData('ghg', ghgSaved),
                        primaryLabel: units,
                        subtitle: "Avoided GHG Emissions",
                        note: "(Including GHG from kWh gen.)"
                    },
                    {
                        primaryValue: FormatSummaryData('co2', co2Saved),
                        primaryLabel: units,
                        subtitle: "Carbon Dioxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('ch4Saved', ch4Saved),
                        primaryLabel: units,
                        subtitle: "Methane",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('noxLbs', emissionsSummary.noxLbsSaved),
                        primaryLabel: UNITS_POUNDS,
                        subtitle: "Nitrous Oxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('bioCO2Saved', bioCO2Saved),
                        primaryLabel: units,
                        subtitle: <div>Biofuel CO<sub>2</sub></div>,
                        note: "(Tailpipe)"
                    }
                ]
            )
        }
    }

    const altFuelCompositionCardData = compileAltFuelCompositionCardData(emissionsSummary);

    const distanceBreakdownByAltDrivetrainData = compileDistanceBreakdownByAltDrivetrainData(emissionsSummary);

    const avoidedEmissionsByDrivetrainData = compileAvoidedEmissionsByDrivetrainData(emissionsSummary,units);

    return (
        <>
            <CardGrid
                cardInfoArray={emissionsCardData()}
                cardMinWidth="215px"
                cardMaxWidth="250px"
                cardHeight="130px"
                gridLayout="repeat(auto-fit, minmax(215px,1fr))"
            />
            <MidGrid
                columnLayout="2fr minmax(350px, 1fr)"
            >
                <GraphCard
                    height="auto"
                    minWidth="500px"
                    maxWidth="1200px"
                    title={`${viewDataByMonth ? "Monthly" : "Weekly"} Avoided Emissions`}
                    subtitle={`${splitByDrivetrain ? "By Drivetrain" : ""}${(splitByDrivetrain && !showDiscreteData) ? " " : ""}${!showDiscreteData ? "(Cumulative)" : ""}`}
                    data={avoidedEmissionsData}
                    barInfo={splitByDrivetrain ? emissionsByDrivetrainBars : combinedEmissionsBars}
                    yAxisLabel={`GHG (${units})`}
                    xAxisKey="month"
                    units={units}
                    showHoverLabels={false}
                    viewDataByMonth={viewDataByMonth}
                    xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                    showDiscreteData={showDiscreteData}
                    splitByDrivetrain={splitByDrivetrain}
                    setViewDataByMonth={setViewDataByMonth}
                    setShowDiscreteData={setShowDiscreteData}
                    setSplitByDrivetrain={setSplitByDrivetrain}
                    drivetrainsWithData={drivetrainsWithData}
                />
                <div>
                    <DistributionCard
                        title="Fleet Composition"
                        subtitle="Alternative Fuel Vehicles"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={altDrivetrains}
                        data={altFuelCompositionCardData}
                        dataType="altFuelVehicles"
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        title="Mileage Breakdown"
                        subtitle="By Drivetrain"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={altDrivetrains}
                        data={distanceBreakdownByAltDrivetrainData}
                        dataType="altFuelMileage"
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        title="Avoided Emissions"
                        subtitle="By Drivetrain"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={altDrivetrains}
                        data={avoidedEmissionsByDrivetrainData}
                        displayInLbs={displayInLbs}
                        dataType="avoidedEmissions"
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        height="55px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={altDrivetrains}
                        data={altFuelCompositionCardData}
                        hasCngVehicles={hasCngVehicles}
                        onlyLegend
                    />
                </div>
            </MidGrid>
            <TableCard
                maxHeight="600px"
                width="auto"
                title="Avoided Emissions Data"
                subtitle="for alternative fuel vehicles"
                tableType={AVOIDED_TABLE_TYPE}
                beginDate={beginDate}
                endDate={endDate}
                mappedData={mappedData}
                rawData={rawData}
                group={group}
                selectedVehicleClasses={selectedVehicleClasses}
                vehicleClasses={vehicleClasses}
                displayInLbs={displayInLbs}
                dbDisplayName={dbDisplayName}
                displayUnits={displayInLbs ? UNITS_POUNDS : UNITS_TONS}
                handleNoDataMessageText={handleNoDataMessageText}
                setMatchingValues={setMatchingValues}
                hasCngVehicles={hasCngVehicles}
                methodology={methodology}
                isTd={isTd}
            />
        </>
    )
};

export default AvoidedEmissionsView;






