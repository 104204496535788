// ** MACROS **

const POUNDS2TONS = function (lbs) { return lbs * 0.0005 };
const LITERS2GALS = function (liters) { return liters * 0.264172 };
const KM2MILES = function (km) { return km * 0.621371 };
const GRAMS2POUNDS = function (grams) { return grams * 0.00220462 };
const POUNDS2GRAMS = function (lbs) { return lbs * 453.59237 }

//TODO: These constants to be moved into API Service Models and removed from here
const CO2_LBSPERGALLON = 19.592;
const CO2_LBSPERGALLON_AVOIDED = 19.592;
const CO2_LBSPERGALLONCNG = 13.6202; //6178.047 grams per gallon equivilant
const CO2_LBSPERGALLONDIESEL = 22.443;


const EMIT_VEHICLE_URL = "emissions-for-vehicle";
const EMIT_VEHICLE_LIST_URL = "emissions-for-vehicle-list";


class EmitProcessor {
  constructor(req, model, settings) {
    this.token = req.user.token;
    this.emitApi = req.emitApi;
    this.model = model;
    this.calcByFuel = true;
    this.preprocessedVehicles = [];

    //fleet level settings
    this.gramsGHGPerKwh = settings.ghg_kwh_gm; //fleet level gramsghg/kwh 
    this.kwhCost = settings.local_kwh_cost; //MH Feb 23, 2025 local_kwh_cost is the value set in the settings view, local_kwh_usd also exists, but doesn't update
    this.fuelCostPerGallon = settings.fuel_cost; //TODO: we should differentiate diesel vs gasoline liquid fuel cost
  }
  setPreprocessedVehicles(vehicleList){
    this.preprocessedVehicles = vehicleList;
  }
  
  //TODO: vehicleSettings as an argument here is confusing because we also have "vehicle.settings" in this module.
  //maybe rename this arg to vehicleOptions(or remove it.)
  async calculateEmissions(vehicle, vehicleSettings) {
    let calcByMile = false;
    if (typeof vehicleSettings !== 'undefined' &&
      vehicleSettings.hasOwnProperty('calcByFuel') &&
      vehicleSettings.calcByFuel === false) {
      calcByMile = true;
    }
    vehicle = this.conformVehicleSettings(vehicle);
    vehicle.ghgLbsSavings = 0;
    vehicle.ghgTonsSavings = 0;
    vehicle.CO2LbsSavings = 0;
    vehicle.CO2TonsSavings = 0;
    vehicle.coLbsSavings = 0;
    vehicle.pm10LbsSavings = 0;
    vehicle.pm25LbsSavings = 0;
    vehicle.pm10GramsSavings = 0;
    vehicle.pm25GramsSavings = 0;
    vehicle.noxLbsSavings = 0;
    vehicle.fuelGallonsSavings = 0;
    vehicle.fuelCostSavings = 0;
    vehicle.ch4LbsSavings = 0;
    vehicle.ch4TonsSavings = 0;
    // TODO: Reinstate bioCO2 when fully supported in emit-service
    vehicle.bioCO2LbsSavings = null;
    vehicle.bioCO2TonsSavings = null;

    //REQUIRED BY EMIT API (miles and fuel gallon terms) Imperial units chosen for API interface for customer cohort reasons
    vehicle.miles = vehicle.miles ? vehicle.miles : KM2MILES(vehicle.km ? vehicle.km : 0)
    vehicle.fuel_gallons = vehicle.fuel_gallons ? vehicle.fuel_gallons : LITERS2GALS(vehicle.fu_liters ? vehicle.fu_liters : 0)

    //Use Sawatch actual estimates in the case of PHEV
    if(vehicle.is_phev){
      vehicle.fuel_gallons = LITERS2GALS(vehicle.fu_liters_actual ? vehicle.fu_liters_actual : 0)
    }

    let emissionsResponse = null;

    //only call the api on an empty preprocessedlist
    //functionally this only supports the unit tests right now.  the app always attempts to preprocess data
    if (calcByMile === false && this.preprocessedVehicles.length < 1) emissionsResponse = await this._emissionsByFuel(vehicle, this.model);
    // by mile calculation is supported by code and API but not avaialble through this interface
    if (calcByMile === true && this.preprocessedVehicles.length < 1) emissionsResponse = await this._emissionsByMile(vehicle, this.model);

    //find the relevant vehicle from the preprocessed (via the api) vehicle so it can be completed.
    if (calcByMile === false && this.preprocessedVehicles.length > 0){
      const a = this.preprocessedVehicles.filter(v => v.vin === vehicle.vin);
      if (a.length > 0)emissionsResponse = a[0];
    }
    //return an empty vehicle if not in our list. included for error handling. shouldn't happen
    //TODO: handle error and communicate to user something unexpected has occured.
    if(!emissionsResponse)return vehicle;

    vehicle.CO2Lbs = emissionsResponse.CO2Lbs;
    vehicle.ch4Lbs = emissionsResponse.ch4Lbs;
    vehicle.ghgLbs = emissionsResponse.CO2Lbs; //TODO Other models than MOVES 3 complicate this
    vehicle.pm25Lbs = emissionsResponse.pm25Lbs;
    vehicle.pm10Lbs = emissionsResponse.pm10Lbs;
    vehicle.pm10Grams = POUNDS2GRAMS(emissionsResponse.pm10Lbs);
    vehicle.pm25Grams = POUNDS2GRAMS(emissionsResponse.pm25Lbs);
    vehicle.coLbs = emissionsResponse.coLbs
    vehicle.noxLbs = emissionsResponse.noxLbs
    vehicle.CO2LbsKWHGeneration = emissionsResponse.CO2LbsKWHGeneration;

    vehicle.miles = parseInt(KM2MILES(vehicle.km));
    vehicle.fuelEcon = parseInt((vehicle.miles) / (LITERS2GALS(vehicle.fu_liters_actual)))

    vehicle.ghgTons = POUNDS2TONS(vehicle.ghgLbs);
    vehicle.CO2Tons = POUNDS2TONS(vehicle.CO2Lbs);
    vehicle.ch4Tons = POUNDS2TONS(emissionsResponse.ch4Lbs);

    if (vehicle.is_bev) {
      let emissionsBackcast = {};
      const iceComp = {vin: `${vehicle.vin}-comp`, vehicle_class: vehicle.vehicle_class, year: vehicle.year, fuel_gallons: LITERS2GALS(vehicle.fu_liters), miles: KM2MILES(vehicle.km), is_gasoline: true}
      if (calcByMile === false) emissionsBackcast = await this._emissionsByFuel(iceComp, this.model)
      // by mile calculation is supported by code and API but not avaialble through this interface
      else emissionsBackcast = await this._emissionsByMile(iceComp, this.model, { calc_by_fuel: this.calcByFuel });   

      emissionsBackcast.ghgLbs = emissionsBackcast.CO2Lbs; //right now co2 and ghg are 1 to 1 tacking onto the backcast object to keep names aligned
      vehicle.ghgLbsSavings = Math.max(0, emissionsBackcast.ghgLbs - vehicle.CO2LbsKWHGeneration);
      vehicle.ghgTonsSavings = Math.max(0, POUNDS2TONS(vehicle.ghgLbsSavings));
      vehicle.ghgLbs = vehicle.CO2LbsKWHGeneration;
      vehicle.ghgTons = POUNDS2TONS(vehicle.ghgLbs);
      vehicle.CO2LbsSavings = Math.max(0, emissionsBackcast.CO2Lbs);
      vehicle.CO2TonsSavings = Math.max(0, POUNDS2TONS(emissionsBackcast.CO2Lbs));
      vehicle.coLbsSavings = Math.max(0, emissionsBackcast.coLbs);
      vehicle.pm10LbsSavings = Math.max(0, emissionsBackcast.pm10Lbs);
      vehicle.pm25LbsSavings = Math.max(0, emissionsBackcast.pm25Lbs);
      vehicle.pm10GramsSavings = Math.max(0, POUNDS2GRAMS(emissionsBackcast.pm10Lbs));
      vehicle.pm25GramsSavings = Math.max(0, POUNDS2GRAMS(emissionsBackcast.pm25Lbs));
      vehicle.noxLbsSavings = Math.max(0, emissionsBackcast.noxLbs);
      vehicle.ch4LbsSavings = Math.max(0, emissionsBackcast.ch4Lbs);
      vehicle.ch4TonsSavings = Math.max(0, POUNDS2TONS(emissionsBackcast.ch4Lbs));
      // vehicle.bioCO2LbsSavings = Math.max(0, emissionsBackcast.bioCO2Lbs);
      // vehicle.bioCO2TonsSavings = Math.max(0, POUNDS2TONS(emissionsBackcast.bioCO2Lbs));
      // TODO: Reinstate above calc once biofuel supported in emit-service
      vehicle.bioCO2LbsSavings = null;
      vehicle.bioCO2TonsSavings = null;
      vehicle.CO2Lbs = 0;
      vehicle.coLbs = 0;
      // NOTE: Setting these to null as they do not apply to BEVs under the current methodologies
      // TODO: Remove null assignment once brake and tire emissions are fully supported - LS 3/27/25
      vehicle.pm10Lbs = null; //TODO: This should be in the service-api's domain as brake and tire wear can contribute on a per mile basis
      vehicle.pm25Lbs = null; //TODO: This should be in the service-api's domain as brake and tire wear can contribute on a per mile basis
      vehicle.pm10Grams = null; //TODO: This should be in the service-api's domain as brake and tire wear can contribute on a per mile basis
      vehicle.pm25Grams = null; //TODO: This should be in the service-api's domain as brake and tire wear can contribute on a per mile basis
      vehicle.noxLbs = 0;
      //TODO: Fuel Cost Savings are not net of kwh costs and probably should be
      vehicle.fuelCostSavings = (vehicle.backcasted_fuel_cost && vehicle.fu_liters)? (vehicle.backcasted_fuel_cost * LITERS2GALS(vehicle.fu_liters))  : 0;
      vehicle.fuelGallonsSavings = LITERS2GALS(vehicle.fu_liters);
    }
    if (vehicle.is_phev) {
      let emissionsBackcast = {};
      const iceComp = {vin: `${vehicle.vin}-comp`, vehicle_class: vehicle.vehicle_class, year: vehicle.year, fuel_gallons: LITERS2GALS(vehicle.fu_liters), miles: KM2MILES(vehicle.km), is_gasoline: true}
      if (calcByMile === false) emissionsBackcast = await this._emissionsByFuel(iceComp, this.model)
      // by mile calculation is supported by code and API but not avaialble through this interface
      else emissionsBackcast = await this._emissionsByMile(iceComp, this.model, { calc_by_fuel: this.calcByFuel });
      
      //adding the kwh generation ghg to the fuel ghg for phevs
      vehicle.ghgLbs += vehicle.CO2LbsKWHGeneration;

      vehicle.ghgTons = Math.max(POUNDS2TONS(vehicle.ghgLbs));
      vehicle.ghgLbsSavings = Math.max(emissionsBackcast.CO2Lbs - emissionsResponse.CO2Lbs - vehicle.CO2LbsKWHGeneration);
      vehicle.ghgTonsSavings = Math.max(POUNDS2TONS(vehicle.ghgLbsSavings));
      vehicle.coLbsSavings = Math.max(emissionsBackcast.coLbs - emissionsResponse.coLbs);
      vehicle.CO2LbsSavings = Math.max(emissionsBackcast.CO2Lbs - emissionsResponse.CO2Lbs - vehicle.CO2LbsKWHGeneration);
      vehicle.CO2TonsSavings = Math.max(POUNDS2TONS(vehicle.CO2LbsSavings));
      vehicle.pm10LbsSavings = Math.max(emissionsBackcast.pm10Lbs - emissionsResponse.pm10Lbs);
      vehicle.pm25LbsSavings = Math.max(emissionsBackcast.pm25Lbs - emissionsResponse.pm25Lbs);
      vehicle.pm10GramsSavings = Math.max(POUNDS2GRAMS(emissionsBackcast.pm10Lbs - emissionsResponse.pm10Lbs));
      vehicle.pm25GramsSavings = Math.max(POUNDS2GRAMS(emissionsBackcast.pm25Lbs - emissionsResponse.pm25Lbs));
      vehicle.noxLbsSavings = Math.max(emissionsBackcast.noxLbs - emissionsResponse.noxLbs);
      //TODO: Fuel Cost Savings are not net of kwh costs and probably should be
      // NOTE: what if fu_liters_actual is actually 0?
      vehicle.fuelCostSavings = (vehicle.backcasted_fuel_cost && vehicle.fu_liters && vehicle.fu_liters_actual) ? (vehicle.backcasted_fuel_cost * LITERS2GALS(vehicle.fu_liters - vehicle.fu_liters_actual))  : 0;
      vehicle.fuelGallonsSavings = LITERS2GALS(vehicle.fu_liters - vehicle.fu_liters_actual)
    }

    if (isNaN(vehicle.fuelEcon) || vehicle.is_bev) vehicle.fuelEcon = 'N/A';
    vehicle.emit = (((parseFloat(POUNDS2TONS(vehicle.ghgLbs))
      + parseFloat(vehicle.pm25Lbs)
      + parseFloat(vehicle.pm10Lbs)
      + parseFloat(POUNDS2TONS(vehicle.coLbs))) / vehicle.miles) * 1000);
    return vehicle;
  }

  //calc group level emissions figures
  summarizeEmissions(emissionsData, idleData, fleetInfo, dateRange){
    if(!emissionsData) return;
    const obj = {
      dateRange: dateRange,
      displayName: fleetInfo.display_name ? fleetInfo.display_name : "Fleet",
      CO2Tons: 0,
      CO2Lbs: 0,
      CO2TonsSaved: 0,
      CO2LbsSaved: 0,
      ghgTons: 0,
      ghgLbs: 0,
      ghgTonsSaved: 0,
      ghgLbsSaved: 0,
      noxLbs: 0,
      noxLbsSaved: 0,
      coLbs: 0,
      coLbsSaved: 0,
      pm10Lbs: 0,
      pm10Grams: 0,
      pm10GramsSaved: 0,
      pm10LbsSaved: 0,
      pm25Lbs: 0,
      pm25LbsSaved: 0,
      pm25Grams: 0,
      pm25GramsSaved: 0,
      vehicles: 0,
      ice: 0,
      diesel: 0,
      cng: 0,
      phev: 0,
      bev: 0,
      totalMiles: 0,
      tradFuelMiles: 0,
      altFuelMiles: 0,
      idle: 0,
      gasolineGHGLbs: 0,
      gasolineGHGTons: 0,
      gasolineGallons: 0,
      gasolineGallonsSaved: 0,
      gasolineMiles: 0,
      dieselGHGLbs: 0,
      dieselGHGTons: 0,
      dieselGallons: 0,
      dieselGallonsSaved: 0,
      dieselMiles: 0,
      cngGHGLbs: 0,
      cngGHGTons: 0,
      cngGallons: 0,
      cngGallonsSaved: 0,
      cngMiles: 0,
      bevGHGLbs: 0,
      bevGHGTons: 0,
      bevGHGLbsSaved: 0,
      bevGHGTonsSaved: 0,
      bevFuelGallonsSaved: 0,
      bevMiles: 0,
      phevGHGLbs: 0,
      phevGHGTons: 0,
      phevGHGTonsSaved: 0,
      phevGHGLbsSaved: 0,
      phevFuelGallonsSaved: 0,
      phevCO2LbsKWHGeneration: 0,
      phevCO2LbsGasoline: 0,
      phevGallons: 0,
      phevMiles: 0,
      phevKwh: 0,
      emitRatio: 0,
      mpg: 0,
      fuelCostSaved: 0,
      fuelGallonsSaved: 0,
      ch4Lbs: 0,
      ch4Tons: 0,
      ch4LbsSaved: 0,
      ch4TonsSaved: 0,
      // TODO: Reinstate bioCO2 when fully supported in emit-service
      bioCO2Lbs: null,
      bioCO2Tons: null,
      bioCO2LbsSaved: null,
      bioCO2TonsSaved: null,
      soxLbs: 0,
      vocLbs: 0
    }
    if(emissionsData) {
    emissionsData.forEach((r) => {
      let idle = 0;
      if (idleData) {
        const i = idleData.filter(id => { return id.vin === r.vin });
        idle = (i[0] && i[0].hours) ? i[0].hours : 0;
      }
      r.idle = parseFloat(idle);
      //handle NaNs from API:
      const values = this._handleVehicleNaNs(r);

      obj.CO2Lbs += parseFloat(values.CO2Lbs);
      obj.CO2Tons += parseFloat(POUNDS2TONS(values.CO2Lbs));

      obj.ghgTons += parseFloat(POUNDS2TONS(values.ghgLbs));
      obj.ghgLbs += parseFloat(values.ghgLbs);
      obj.noxLbs += parseFloat(values.noxLbs);
      obj.coLbs += parseFloat(values.coLbs);
      obj.pm10Lbs += parseFloat(values.pm10Lbs);
      obj.pm25Lbs += parseFloat(values.pm25Lbs);
      obj.totalMiles += parseFloat(values.miles);
      obj.ch4Lbs += parseFloat(values.ch4Lbs);
      obj.ch4Tons += parseFloat(POUNDS2TONS(values.ch4Lbs));
      // TODO: Reinstate bioCO2 when fully supported in emit-service
      // obj.bioCO2Lbs += parseFloat(values.bioCO2Lbs);
      // obj.bioCO2Tons += parseFloat(POUNDS2TONS(values.bioCO2Lbs));
      obj.soxLbs += parseFloat(values.soxLbs);
      obj.vocLbs += parseFloat(values.vocLbs);


      obj.CO2TonsSaved += parseFloat(POUNDS2TONS(r.CO2LbsSavings));
      obj.CO2LbsSaved += parseFloat(r.CO2LbsSavings);
      obj.ghgTonsSaved += parseFloat(POUNDS2TONS(r.ghgLbsSavings));
      obj.ghgLbsSaved += parseFloat(r.ghgLbsSavings);
      obj.coLbsSaved += parseFloat(r.coLbsSavings);
      obj.noxLbsSaved += parseFloat(r.noxLbsSavings);
      obj.pm25LbsSaved += parseFloat(r.pm25LbsSavings);
      obj.pm10LbsSaved += parseFloat(r.pm10LbsSavings);
      obj.ch4LbsSaved += parseFloat(r.ch4LbsSavings);
      obj.ch4TonsSaved += parseFloat(POUNDS2TONS(r.ch4LbsSavings));
      // TODO: Reinstate bioCO2 when fully supported in emit-service
      // obj.bioCO2LbsSaved += parseFloat(r.bioCO2LbsSavings);
      // obj.bioCO2TonsSaved += parseFloat(POUNDS2TONS(r.bioCO2LbsSavings));
      //sum obj vals
      obj.pm10Grams = POUNDS2GRAMS(obj.pm10Lbs);
      obj.pm25Grams = POUNDS2GRAMS(obj.pm25Lbs)
      obj.pm10GramsSaved = POUNDS2GRAMS(obj.pm10LbsSaved);
      obj.pm25GramsSaved = POUNDS2GRAMS(obj.pm25LbsSaved);
  
      if (r.is_phev === true) {
        obj.phev++;
        // obj.gasolineGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        // obj.gasolineGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        // obj.gasolineGHGLbs += parseFloat(r.ghgLbs);
        obj.phevGHGTons += parseFloat(POUNDS2TONS(values.ghgLbs));
        obj.phevGHGLbs += parseFloat(values.ghgLbs);
        obj.phevCO2LbsGasoline += parseFloat(r.CO2Lbs);
        obj.phevCO2LbsKWHGeneration += parseFloat(r.CO2LbsKWHGeneration);
        obj.phevGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        obj.phevKwh += parseFloat(values.kwh);
        obj.altFuelMiles += parseFloat(values.miles);
        obj.phevMiles += parseFloat(values.miles);
        obj.fuelCostSaved += parseFloat(r.fuelCostSavings);
        obj.fuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.phevFuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.phevGHGLbsSaved += parseFloat(r.ghgLbsSavings);
        obj.phevGHGTonsSaved = parseFloat(POUNDS2TONS(obj.phevGHGLbsSaved));
      }
      if (r.is_diesel === true) {
        obj.diesel++;
        obj.dieselGallons += parseFloat(LITERS2GALS(r.fu_liters));
        obj.dieselGHGTons += parseFloat(POUNDS2TONS(values.ghgLbs));
        obj.dieselGHGLbs += parseFloat(values.ghgLbs);
        obj.tradFuelMiles += parseFloat(values.miles);
        obj.dieselMiles += parseFloat(values.miles);
      }
      if (r.is_bev === true){ 
        obj.bev++;
        obj.bevGHGTons += parseFloat(POUNDS2TONS(values.ghgLbs));
        obj.bevGHGLbs += parseFloat(values.ghgLbs);
        obj.altFuelMiles += parseFloat(values.miles)
        obj.bevMiles += parseFloat(values.miles);
        obj.fuelCostSaved += parseFloat(r.fuelCostSavings);
        obj.fuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.bevFuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.bevGHGLbsSaved += parseFloat(r.ghgLbsSavings);
        obj.bevGHGTonsSaved = parseFloat(POUNDS2TONS(obj.bevGHGLbsSaved));
      }
      if ((r.is_cng === true)){
        obj.cng++;
        obj.cngGallons += parseFloat(LITERS2GALS(r.fu_liters));
        obj.cngGHGTons += parseFloat(POUNDS2TONS(values.ghgLbs));
        obj.cngGHGLbs += parseFloat(values.ghgLbs);
        obj.altFuelMiles += parseFloat(values.miles);
        obj.cngMiles += parseFloat(values.miles);
      }
      if (r.is_gasoline === true){
        obj.ice++;
        obj.gasolineGallons += parseFloat(LITERS2GALS(r.fu_liters));
        obj.gasolineGHGTons += parseFloat(POUNDS2TONS(values.ghgLbs));
        obj.gasolineGHGLbs += parseFloat(values.ghgLbs);
        obj.tradFuelMiles += parseFloat(values.miles);
        obj.gasolineMiles += parseFloat(values.miles);
      }
    });
  }

    obj.emitRatio = (((parseFloat(obj.ghgTons) + parseFloat(obj.pm25Lbs) + parseFloat(obj.pm10Lbs) + parseFloat(POUNDS2TONS(obj.coLbs))) /
      obj.miles) * 1000).toPrecision(2);
    if (isNaN(obj.emitRatio)) obj.emitRatio = 0;
    if (!isFinite(obj.emitRatio)) obj.emitRatio = 0;
    if (typeof emitRatio === "string") obj.emitRatio = parseFloat(obj.emitRatio);
    obj.gallons = (obj.gasolineGallons + obj.dieselGallons + obj.phevGallons + obj.cngGallons);
    obj.vehicles = emissionsData ? emissionsData.length : null;
    obj.mpg = obj.miles / obj.gallons;
    if(isNaN(obj.mpg)) obj.mpg = 0;
    return obj;
  }

  //TODO: properly the emit-service's job to calculate
  co2LbsPerLiter(liters, cof){
    cof = cof ? cof : CO2_LBSPERGALLON;
    cof = isFinite(cof) ? cof : CO2_LBSPERGALLON;
    cof = !isNaN(cof) ? cof : CO2_LBSPERGALLON;
    return (LITERS2GALS(liters) * cof);
  }

  //TODO: properly the emit-service's job to calculate
  co2LbsPerLiterCNG(liters, cof){
    cof = cof ? cof : CO2_LBSPERGALLONCNG;
    cof = isFinite(cof) ? cof : CO2_LBSPERGALLONCNG;
    cof = isNaN(cof)? cof : CO2_LBSPERGALLONCNG;
    return (LITERS2GALS(liters) * cof);
  }
  //TODO: properly the emit-service's job to calculate
  co2LbsPerLiterDiesel(liters, cof){
    cof = cof ? cof : CO2_LBSPERGALLONDIESEL;
    cof = isFinite(cof) ? cof : CO2_LBSPERGALLONDIESEL;
    cof = !isNaN(cof) ? cof : CO2_LBSPERGALLONDIESEL;
    return (LITERS2GALS(liters) * cof);
  }
  //TODO: properly the emit-service's job to calculate
  co2LbsPerLiterAvoided(liters, cof){
    cof = cof ? cof : CO2_LBSPERGALLON_AVOIDED;
    cof = isFinite(cof) ? cof : CO2_LBSPERGALLON_AVOIDED;
    cof = !isNaN(cof) ? cof : CO2_LBSPERGALLON_AVOIDED;
    return (LITERS2GALS(liters) * cof);
  }

  //TODO: properly the emit-service's job to calculate
  kwhToGHGLbs(kwh, gramsGHGPerKwh) {
    gramsGHGPerKwh = gramsGHGPerKwh ? gramsGHGPerKwh : this.gramsGHGPerKwh;
    return (GRAMS2POUNDS(kwh * gramsGHGPerKwh));
  }

  conformVehicleSettings(vehicle) {
    //USE FLEET DEFAULT SETTING VALUES IF VEHICLE LACKS
    vehicle.backcasted_fuel_cost = vehicle.backcasted_fuel_cost ? vehicle.backcasted_fuel_cost : this.fuelCostPerGallon;
    vehicle.backcasted_kwh_cost = vehicle.backcasted_kwh_cost ? vehicle.backcasted_kwh_cost : this.kwhCost;
    vehicle.backcasted_ghg_kwh_gm = vehicle.backcasted_ghg_kwh_gm ? vehicle.backcasted_ghg_kwh_gm : this.gramsGHGPerKwh;
    //TODO some code golf is warrented here. Doing it the dumb way to make sure it works
    if(vehicle.is_gasoline){
      vehicle.is_diesel = false;
      vehicle.is_cng = false;
      vehicle.is_bev = false;
      vehicle.is_phev = false;
    }
    if(vehicle.is_bev){
      vehicle.is_cng = false;
      vehicle.is_diesel = false;
      vehicle.is_phev = false;
      vehicle.is_gasoline = false;
    }
    if(vehicle.is_diesel){
      vehicle.is_cng = false;
      vehicle.is_bev = false;
      vehicle.is_phev = false;
      vehicle.is_gasoline = false;
    }
    if(vehicle.is_phev){
      vehicle.is_cng = false;
      vehicle.is_diesel = false;
      vehicle.is_bev = false;
      vehicle.is_gasoline = false;
    }
    if(vehicle.is_cng){
      vehicle.is_phev = false;
      vehicle.is_diesel = false;
      vehicle.is_bev = false;
      vehicle.is_gasoline = false;
    }

    //bundle ghg intensity into a settings object as expected by emit-service
    vehicle.settings = { grams_ghg_per_kwh: vehicle.backcasted_ghg_kwh_gm}//TODO: refactor the ghg intensity variable names
    return vehicle;
  }

  async preprocessVehicleList(vehicleList, model){
    return await this._vehicleListEmissionsByFuel(vehicleList, model);
  }

  //list of vehicles emissions call
  async _vehicleListEmissionsByFuel(vehicleList, model){
    const headers = {"Authorization": `Bearer ${this.token}`, "Content-Type": "application/json"};
    const requestBody = [];
    vehicleList.forEach(vehicle => {
      vehicle = this.conformVehicleSettings(vehicle);
      vehicle.emissions_model = model;

      //REQUIRED BY EMIT API (miles and fuel gallon terms) Imperial units chosen for API interface for customer cohort reasons
      vehicle.miles = vehicle.miles ? vehicle.miles : KM2MILES(vehicle.km ? vehicle.km : 0)
      vehicle.fuel_gallons = vehicle.fuel_gallons ? vehicle.fuel_gallons : LITERS2GALS(vehicle.fu_liters ? vehicle.fu_liters : 0);
      
      //Use Sawatch actual estimates in the case of PHEV
      if(vehicle.is_phev){
        vehicle.fuel_gallons = LITERS2GALS(vehicle.fu_liters_actual ? vehicle.fu_liters_actual : 0)
      }
      //default to gasoline
      //TODO this is duplicated in emit-processor and should be consolidated
      //TODO remove default gasoline and make explicit or reject vehicle
      if(!vehicle.is_diesel && !vehicle.is_phev && !vehicle.is_bev && !vehicle.is_cng && !vehicle.is_propane)vehicle.is_gasoline = true;
      requestBody.push(vehicle);
    });
    const body = JSON.stringify({data: requestBody});
    return fetch(`${this.emitApi}${EMIT_VEHICLE_LIST_URL}`, {
      headers: headers, body: body, method: "POST"})
      .then((resp) => resp.json())
      .then((data) => {return data})
      .catch((err) => {console.error('error', err)});
  }

  //single vehicle emissions call
  async _emissionsByFuel(vehicle, model) {
    const headers = {"Authorization": `Bearer ${this.token}`, "Content-Type": "application/json"};
    vehicle.emissions_model = model;
    const body = JSON.stringify(vehicle);
    return fetch(`${this.emitApi}${EMIT_VEHICLE_URL}`, {
      headers: headers, body: body, method: "POST"})
      .then((resp) => resp.json())
      .then((data) => {return data})
      .catch((err) => {console.error('error', err)});
  }
  // by mile calculation is supported by code and API but not avaialble through this interface
  async _emissionsByMile(vehicle, model) {
    let headers = {"Authorization": `Bearer ${this.token}`, "Content-Type": "application/json"};
    const body = JSON.stringify(vehicle);
    return fetch(`${this.emitApi}${EMIT_VEHICLE_URL}`, {
      headers: headers, body: body, method: "POST"})
      .then((resp) => resp.json())
      .then((data) => {return data})
      .catch((err) => {console.error('error', err)});    
  }

  //convert nulls to 0s for now.
  //TODO: communicate that unsupported vehicle types are not 0s, but are not included or are unknown.
  _handleVehicleNaNs(vehicle){
    const obj = {}
    obj.CO2Lbs = isNaN(parseFloat(vehicle.CO2Lbs)) ? 0 : parseFloat(vehicle.CO2Lbs);
    obj.ghgLbs = isNaN(parseFloat(vehicle.ghgLbs)) ? 0 : parseFloat(vehicle.ghgLbs);
    obj.noxLbs = isNaN(parseFloat(vehicle.noxLbs)) ? 0 : parseFloat(vehicle.noxLbs);
    obj.coLbs = isNaN(parseFloat(vehicle.coLbs)) ? 0 : parseFloat(vehicle.coLbs);
    obj.pm10Lbs = isNaN(parseFloat(vehicle.pm10Lbs)) ? 0 : parseFloat(vehicle.pm10Lbs);
    obj.pm25Lbs = isNaN(parseFloat(vehicle.pm25Lbs)) ? 0 : parseFloat(vehicle.pm25Lbs);
    obj.miles = isNaN(parseFloat(vehicle.miles)) ? 0 : parseFloat(vehicle.miles);
    obj.ch4Lbs = isNaN(parseFloat(vehicle.ch4Lbs)) ? 0 : parseFloat(vehicle.ch4Lbs);
    obj.bioCO2Lbs = isNaN(parseFloat(vehicle.bioCO2Lbs)) ? 0 : parseFloat(vehicle.bioCO2Lbs);
    obj.soxLbs = isNaN(parseFloat(vehicle.soxLbs)) ? 0 : parseFloat(vehicle.soxLbs);
    obj.vocLbs = isNaN(parseFloat(vehicle.vocLbs)) ? 0 : parseFloat(vehicle.vocLbs);
    return obj;
  }
}



export default EmitProcessor;