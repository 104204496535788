import React, { useRef, useState } from 'react';
import { InfoCardStyled, SMALL_RADIUS, CardTitleWrapper, CardTitle, CardSubtitle, TRANSITION_LENGTH, TRANSITION_LENGTH_INT, MEDIUM_RADIUS } from '../../styles/emit-styles/CardStyles';
import { HoverContent, ChartHover, HoverTitle } from '../../styles/emit-styles/ChartStyles';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, Label, ResponsiveContainer, ReferenceLine, Surface, Symbols } from "recharts";
import { filterDataTypes, formatMonthLabelLong, formatMonthLabelShort, FormatSummaryData, formatWeekLabelLong, formatWeekLabelShort } from './UtilityFunctions';
import { dark_gray, darkest_gray, off_black, light_gray, white } from '../../styles/emit-styles/ColorScheme';
import { useEffect } from 'react';
import { ReactComponent as SettingsIcon } from '../../images/Tune.svg';
import { FilterPopoverContainer } from "../../styles/emit-styles/Emit-styles";
import GraphControls from "./GraphControls";
import styled from 'styled-components';
import { UNITS_TONS } from '../swt-emit';

const FONT_SIZE = 13;

const StyledLegend = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const IconWrapper = styled.div`
  fill: ${darkest_gray};
  height: 20px;
  width: 20px;
  margin-right: ${({ margin }) => margin || '2px'};
  display: flex;
  align-items: center;
  :hover, :active {
    fill: ${off_black};
    cursor: pointer;
  }
`;

const CardHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PopoverWrapper = styled.div`
    position: relative;
`;

const GraphCard = ({ data, height, width, minWidth, maxWidth, title, subtitle, barInfo, yAxisLabel, xAxisLabel, hasReductionData, units, viewDataByMonth, showDiscreteData, splitByDrivetrain, setViewDataByMonth, setShowDiscreteData, setSplitByDrivetrain, combinedView, drivetrainsWithData }) => {
    const [hoveredBar, setHoveredBar] = useState(null);
    // the following nifty logic block allows for the fade in/fade out effects on the tooltip.
    // This was surprising difficult to create in recharts natively, due to the behavior of the tooltip being that
    // it is immediately destroyed when the user moves away from the target element it's being displayed for.
    const [tooltipActive, setTooltipActive] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const [filteredDataTypes, setFilteredDataTypes] = useState([]);
    const [openSettingsPopover, setOpenSettingsPopover] = useState(false);
    const [showMixedRange, setShowMixedRange] = useState(hasReductionData);

    const timeoutRef = useRef(null);
    const settingsRef = useRef(null);

    useEffect(() => {
        let [filteredTypes] = filterDataTypes(data, barInfo)
        setFilteredDataTypes(filteredTypes);
    }, [barInfo, data]);

    useEffect(() => {
        setShowMixedRange(hasReductionData);
    }, [hasReductionData])

    const [barStatus, setBarStatus] = useState(
        barInfo.reduce(
            (_, { id }) => {
                _[id] = true;
                return _;
            },
            { hover: null }
        )
    );

    useEffect(() => {
        setBarStatus(barInfo.reduce(
            (_, { id }) => {
                _[id] = true;
                return _;
            },
            { hover: null }
        ));
    }, [barInfo]);


    const selectBar = (id) => {
        setBarStatus({
            ...barStatus,
            [id]: !barStatus[id],
            hover: null
        });
    };

    const x_axis_key = viewDataByMonth ? "month" : "week";

    const handleBarMouseEnter = () => {
        clearTimeout(timeoutRef.current); // Clear any pending timeouts
        setTooltipActive(true);
        setTooltipVisible(true);
    };

    const handleBarMouseLeave = () => {
        setTooltipVisible(false); // Immediately hide the tooltip

        timeoutRef.current = setTimeout(() => {
            setTooltipActive(false); // After fade transition ends, set tooltipActive to false
        }, TRANSITION_LENGTH_INT);
    };

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current); // Clear timeout on unmount
    },);

    const handleLegendMouseEnter = (id) => {
        if (barStatus[id]) {
            setBarStatus({ ...barStatus, hover: id });
        }
    };

    const handleLegendMouseLeave = () => {
        setBarStatus({ ...barStatus, hover: null });
    };

    useOutsideAlerter(settingsRef, () => setOpenSettingsPopover(false));

    function useOutsideAlerter(ref, cb) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && event.target.id !== 'graph-settings-button') {
                    cb()
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, cb]);
    }

    useEffect(() => {
        if (drivetrainsWithData) {
            const avoidedKeys = Object.entries(drivetrainsWithData).filter(([key,value]) => key.toLowerCase().includes('avoided') && value).map(([key]) => key);;
            const avoidedHidden = avoidedKeys.every(key => barStatus[key] === false);
    
            const generatedKeys = Object.entries(drivetrainsWithData).filter(([key,value]) => key.toLowerCase().includes('generated') && value).map(([key]) => key);;
            const generatedHidden = generatedKeys.every(key => barStatus[key] === false);
    
            if (avoidedKeys.length > 0 && generatedKeys.length > 0) {
                if (avoidedHidden || generatedHidden) setShowMixedRange(false);
                else setShowMixedRange(true);
            }
        }
    }, [drivetrainsWithData, barStatus])

    const CustomTooltip = ({ active, payload }) => {
        const pointLabel = payload[0]?.payload[x_axis_key];
        if (active) return (
            <ChartHover>
                {payload[0]?.payload && <>
                    <div><HoverTitle>{viewDataByMonth ? formatMonthLabelLong(pointLabel) : `Week of ${formatWeekLabelLong(pointLabel)}`}</HoverTitle></div>

                    {["generated", "avoided"].map((category) => { // Iterate through categories
                        const categoryItems = filteredDataTypes.filter(bar => bar.id.toLowerCase().includes(category)); // Filter bars by category
                        
                        // Check if any item in the category has a non-zero value
                        const categoryHasValues = categoryItems.length > 0 && categoryItems.some(bar => {
                            const value = payload[0]?.payload[bar.id];
                            return value && value !== 0;
                        });

                        if (categoryHasValues) { // Only render section if values exist for this category
                            return (
                                <div
                                    key={category}
                                    style={{ marginTop: 4 }}
                                >
                                    {splitByDrivetrain && <HoverContent>{!showDiscreteData && "Total "}{category === "generated" ? "Generated" : "Avoided"} Emissions</HoverContent>}
                                    {categoryItems.map(bar => {
                                        const value = payload[0]?.payload[bar.id];
                                        const valueMultipler = (combinedView && bar.valueMultipler) ? bar.valueMultipler : 1; // only want to multiply by -1 if showing combined view
                                        const labelPrefix = `${(!showDiscreteData && !splitByDrivetrain) ? "Total " : ""}${bar.label}:`;
                                        const hidden = barStatus[bar.id] === false;
                                        if (value) { //don't show this row if 0, undefined, or null
                                            return (
                                                <div key={bar.id}>
                                                    <HoverContent colorOverride={hidden ? light_gray : bar.color}>
                                                        {labelPrefix} {FormatSummaryData("ghg", (valueMultipler * value))} {units}
                                                    </HoverContent>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            );
                        } else {
                            return null; // If no bars for this category, return null
                        }
                    })}
                </>}
            </ChartHover>
        )
        else return null;
    };

    const CustomLegend = ({ payload }) => {
        // Legend click to toggle dataset effect is not baked in to recharts.
        // // Issue thread here: https://github.com/recharts/recharts/issues/590
        // My custom legend code comes in part from these sources:
        // https://codesandbox.io/p/sandbox/recharts-with-legend-toggle-dqlts
        // https://codesandbox.io/p/sandbox/k5no4l0n97?file=%2FSampleChart.jsx

        return <StyledLegend>
            <span
                style={{
                    display: 'flex',
                    gap: '8px',
                    ...(!splitByDrivetrain ? { //Show avoided & generated in one container if not split out by drivetrain...
                        border: `2px solid ${light_gray}`,
                        borderRadius: MEDIUM_RADIUS,
                        display: 'flex',
                        gap: `8px`,
                        padding: '6px'
                    } : {})
                }}>
                {["generated", "avoided"].map((category) => { // Iterate through categories
                    const categoryItems = payload.filter(entry => entry.id.toLowerCase().includes(category)); // Filter bars by category
                    if (categoryItems.length > 0) { // Only render if items exist for this category
                        return (
                            <div
                                key={category}
                                style={{ // Otherwise, seperate into seperate containers by avoided vs generated if split out by drivetrain
                                    ...(splitByDrivetrain ? {
                                        border: `2px solid ${light_gray}`,
                                        borderRadius: MEDIUM_RADIUS,
                                        display: 'flex',
                                        gap: `8px`,
                                        padding: '6px'
                                    } : {})
                                }}
                            >
                                {splitByDrivetrain && <HoverContent>{category === "generated" ? "Generated" : "Avoided"} Emissions:</HoverContent>}
                                {categoryItems.map(entry => {
                                    const hidden = barStatus[entry.id] === false;
                                    return (
                                        <div
                                            key={entry.id}
                                            style={{
                                                display: 'flex',
                                                gap: 3,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Surface width={14} height={14} >
                                                <Symbols cx={7} cy={7} type="circle" size={100} fill={entry.color} />
                                                {hidden && (
                                                    <Symbols
                                                        cx={7}
                                                        cy={7}
                                                        type="circle"
                                                        size={45}
                                                        fill={white}
                                                    />
                                                )}
                                            </Surface>
                                            <div
                                                onClick={() => selectBar(entry.id)}
                                                onMouseEnter={() => handleLegendMouseEnter(entry.id)}
                                                onMouseLeave={() => handleLegendMouseLeave()}
                                                style={{ color: hidden ? light_gray : entry.color, cursor: "pointer" }}
                                                title={`Click to toggle visibility of ${entry.value} ${category === "generated" ? "Generated" : "Avoided"} Emissions`}
                                            >
                                                {entry.value}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    } else return null;
                })}
            </span>
        </StyledLegend>
    };

    return (
        <InfoCardStyled
            height={height}
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            disableSelection={true}
        >
            <CardHeader>
                <CardTitleWrapper>
                    <CardTitle>{title}</CardTitle>
                    <CardSubtitle>{subtitle}</CardSubtitle>
                </CardTitleWrapper>
                <PopoverWrapper>
                    <IconWrapper
                        fill={dark_gray}
                    >
                        <SettingsIcon
                            id='graph-settings-button'
                            onClick={() => setOpenSettingsPopover(!openSettingsPopover)}
                        />
                    </IconWrapper>
                    <FilterPopoverContainer
                        openPopover={openSettingsPopover}
                        width='262px'
                        ref={settingsRef}
                    >
                        <GraphControls
                            viewDataByMonth={viewDataByMonth}
                            showDiscreteData={showDiscreteData}
                            splitByDrivetrain={splitByDrivetrain}
                            setViewDataByMonth={setViewDataByMonth}
                            setShowDiscreteData={setShowDiscreteData}
                            setSplitByDrivetrain={setSplitByDrivetrain}
                            setOpenSettingsPopover={setOpenSettingsPopover}
                        />
                    </FilterPopoverContainer>
                </PopoverWrapper>
            </CardHeader>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width="100%"
                    height="100%"
                    data={data}
                    margin={{ left: 12, right: (showMixedRange ? 28 : 8), bottom: (viewDataByMonth ? 24 : 68), top: 16 }}
                >
                    {barInfo.map((b) => {
                        return <Bar
                            key={b.id}
                            xAxisId={_determineXAxisId(hasReductionData, b.id)}
                            dataKey={b.id}
                            radius={_calculateRadius(splitByDrivetrain, showMixedRange)}
                            maxBarSize={28}
                            style={{ cursor: 'pointer', transition: `fill-opacity ${TRANSITION_LENGTH} ease` }}
                            onMouseEnter={handleBarMouseEnter}
                            onMouseLeave={handleBarMouseLeave}
                            stackId={_determineXAxisId(hasReductionData, b.id)}
                            hide={barStatus[b.id] === false}
                            fillOpacity={barStatus.hover === b.id || !barStatus.hover ? 1 : 0.6}
                        >
                            {data.map((entry, index) => {
                                const barKey = `${b.id}-${index}`; // ensure key is actually unique to prevent props warning
                                const hoverKey = index; // we want to color both elements of the graph when hovered, so just set the *hover* key to the index to allow for selection of both.

                                return (<Cell
                                    key={barKey}
                                    fill={hoveredBar === hoverKey ? b.hoverColor : b.color}
                                    onMouseEnter={() => setHoveredBar(hoverKey)}
                                    onMouseLeave={() => setHoveredBar(null)}
                                />);
                            })}
                        </Bar>
                    })}
                    <XAxis
                        xAxisId={0}
                        dataKey={x_axis_key}
                        fontSize={FONT_SIZE}
                        strokeWidth={0.5}
                        axisLine={false}
                        tickLine={false}
                        angle={-60}
                        tickMargin={viewDataByMonth ? 15 : 25}
                        dy={4} // shift ticks slightly down
                        dx={viewDataByMonth ? -4 : -12} // shift ticks slightly left to center them below their bars (more if weekly)
                        tickFormatter={(value) => { return viewDataByMonth ? formatMonthLabelShort(value) : formatWeekLabelShort(value) }}
                    >
                        <Label
                            position={'centerBottom'}
                            dy={60}
                            fontSize={FONT_SIZE}
                        >
                            {xAxisLabel}
                        </Label>
                        {/* The x-axis label is currently only set up to adjust for weekly date listings since this is the only time it will be shown.
                         If further tick labeling configs are added, it may need to have some conditional rendering set up on the y offset. */}
                    </XAxis>
                    {hasReductionData && <XAxis
                        dataKey={x_axis_key}
                        xAxisId={1}
                        hide
                    />}
                    <YAxis
                        fontSize={FONT_SIZE}
                        tickFormatter={(value) => value.toLocaleString()} // Ensure that commas are displayed on graph tick labels
                        axisLine={false}
                        tickLine={false}
                    >
                        <Label
                            angle={-90}
                            position={'left'}
                            style={{ textAnchor: 'middle' }}
                            fontSize={FONT_SIZE}
                            dx={units === UNITS_TONS ? 10 : 0} // shift the label in a bit if displaying tons (much smaller tick values, generally)
                        >
                            {yAxisLabel}
                        </Label>
                        {showMixedRange && <Label
                            angle={-90}
                            position='insideTop'
                            fontSize={FONT_SIZE}
                            dx={-38 + (units === UNITS_TONS ? 10 : 0)} // shift the label in a bit if displaying tons (much smaller tick values, generally)
                            dy={24}
                        >
                            Generated
                        </Label>}
                        {showMixedRange && <Label
                            angle={-90}
                            position='insideBottom'
                            dx={-30 + (units === UNITS_TONS ? 10 : 0)}
                            dy={-16}
                            fontSize={FONT_SIZE}
                        >
                            Avoided
                        </Label>}
                    </YAxis>
                    {showMixedRange && <ReferenceLine stroke={dark_gray} strokeWidth={1.5} y={0} strokeLinecap='round' />}
                    <Tooltip
                        shared={false}
                        cursor={false}
                        content={CustomTooltip}
                        active={tooltipActive} // force the tooltip to rely on our custom state management, to allow for smooth fade in/fade out.
                        wrapperStyle={{
                            opacity: tooltipVisible ? 1 : 0, // Initially hidden
                            transition: `opacity ${TRANSITION_LENGTH} ease, transform 0.4s ease-in-out` // Smooth transition (transform @ 0.4s is default, but it's easier to just declare it again than try to pull in existing wrapper styling)
                        }}
                    />
                    {combinedView && <Legend
                        content={CustomLegend}
                        iconSize={12}
                        payload={filteredDataTypes.map(bar => ({
                            id: bar.id,
                            value: bar.label,
                            type: 'circle',
                            color: bar.color
                        }))}
                        wrapperStyle={{ marginBottom: viewDataByMonth ? -24 : -68 }}
                    />}
                </BarChart>
            </ResponsiveContainer>
        </InfoCardStyled>
    );
};

export default GraphCard;

const _determineXAxisId = (hasReductionData, barId) => {
    if (barId.toLowerCase().includes("avoided") && hasReductionData) return 1;
    else return 0;
}

const _calculateRadius = (splitByDrivetrain, showMixedRange) => {
    const r = SMALL_RADIUS;
    if (splitByDrivetrain) return 0;
    else return showMixedRange ? [r, r, 0, 0] : r;
}