import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro"
import { blue_green, blue_green_hover, darkest_gray, light_gray, white } from '../../styles/emit-styles/ColorScheme';
import { MEDIUM_RADIUS, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';

const PRIMARY_COLOR = blue_green;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlsWrapper = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
    color: ${darkest_gray};
`;

const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterButton = styled.button`
  width: 120px;
  height: 36px;
  background: ${blue_green};
  border-radius: ${MEDIUM_RADIUS}px;
  border: none;
  color: ${white};
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  transition: background-color ${TRANSITION_LENGTH} ease;
  &:hover {
    background-color: ${blue_green_hover};
  }
  &:disabled {
    background-color: ${light_gray};
    cursor: initial;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: start;
  margin-right: 12px;
  margin-top: 6px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${darkest_gray};
  border-radius: 50%;
  margin-right: 4px;
  margin-left: 0px;
  margin-top: 2px;
  cursor: pointer;
  transition: border 0.1s ease-in-out;

  &:checked {
    border: 5px solid ${PRIMARY_COLOR};
  }
`;

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionLabel = styled.label`
  font-weight: 500;
`;

const GraphControls = ({ viewDataByMonth, showDiscreteData, splitByDrivetrain, setViewDataByMonth, setShowDiscreteData, setSplitByDrivetrain, setOpenSettingsPopover }) => {

  // Temp state values used to update values within the component before saving
  const [tempViewDataByMonth, setTempViewDataByMonth] = useState(viewDataByMonth);
  const [tempShowDiscreteData, setTempShowDiscreteData] = useState(showDiscreteData);
  const [tempSplitByDrivetrain, setTempSplitByDrivetrain] = useState(splitByDrivetrain);

  const [disableButtons, setDisableButtons] = useState(true);

  useEffect(() => {
    if(tempShowDiscreteData === showDiscreteData && tempViewDataByMonth === viewDataByMonth && tempSplitByDrivetrain === splitByDrivetrain) {
      setDisableButtons(true);
    } else {
      setDisableButtons(false);
    }
  },[tempShowDiscreteData, tempViewDataByMonth, tempSplitByDrivetrain, showDiscreteData, viewDataByMonth, splitByDrivetrain]);

  function handleSubmit(e) {
    e.preventDefault();
    setViewDataByMonth(tempViewDataByMonth);
    setShowDiscreteData(tempShowDiscreteData);
    setSplitByDrivetrain(tempSplitByDrivetrain);
    setOpenSettingsPopover(false);
  }

  function resetSettings(e) {
    e.preventDefault();
    setTempShowDiscreteData(showDiscreteData);
    setTempSplitByDrivetrain(splitByDrivetrain);
    setTempViewDataByMonth(viewDataByMonth);
  }

  return (
    <ControlsWrapper
      onSubmit={(e) => handleSubmit(e)}
    >
      <RadioContainer>
        <SectionLabel>Data Aggregation:</SectionLabel>
        <RadioGroupContainer>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={tempShowDiscreteData}
              onChange={() => setTempShowDiscreteData(true)}
            />
            Discrete
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={!tempShowDiscreteData}
              onChange={() => setTempShowDiscreteData(false)}
            />
            Cumulative
          </RadioLabel>
        </RadioGroupContainer>
      </RadioContainer>
      {/* Removing option for weekly data display for now. Changes to backend need to be made to improve accuracy of numbers - JJ */}
      {/* <RadioContainer>
        <SectionLabel>Data Volume:</SectionLabel>
        <RadioGroupContainer>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={!tempViewDataByMonth}
              onChange={() => setTempViewDataByMonth(false)}
            />
            Weekly
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={tempViewDataByMonth}
              onChange={() => setTempViewDataByMonth(true)}
            />
            Monthly
          </RadioLabel>
        </RadioGroupContainer>
      </RadioContainer> */}
      <RadioContainer>
        <SectionLabel>Bar Display:</SectionLabel>
        <RadioGroupContainer>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={!tempSplitByDrivetrain}
              onChange={() => setTempSplitByDrivetrain(false)}
            />
            Condensed
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              checked={tempSplitByDrivetrain}
              onChange={() => setTempSplitByDrivetrain(true)}
            />
            All Drivetrains
          </RadioLabel>
        </RadioGroupContainer>
      </RadioContainer>
      <FilterButtonContainer>
        <FilterButton disabled={disableButtons} onClick={(e) => resetSettings(e)}>Reset</FilterButton>
        <FilterButton disabled={disableButtons} type='submit'>Update</FilterButton>
      </FilterButtonContainer>
    </ControlsWrapper>
  );
}

export default GraphControls;