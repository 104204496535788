import { UNITS_GALLONS, UNITS_GRAMS, UNITS_POUNDS, UNITS_TONS } from "../swt-emit";

//Default column width for table is 100
const MED_COL = 150;
const LARGE_COL = 200;

const _decimalStringSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];
    if (typeof a === 'string') {
      return parseFloat(a) > parseFloat(b) ? 1 : -1;
    } else {
      return a > b ? 1 : -1;
    }
};

const _stringSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId]?.toLowerCase();
    const b = rowB.values[columnId]?.toLowerCase();
    return a > b ? 1 : -1;
}

const sharedColumns = (isTd) => {
  let sharedColumns =  [
    { Header: "Asset ID", accessor: "asset_id", sortType: _stringSort, width: MED_COL },
    { Header: "VIN", accessor: "vin", sortType: _stringSort, width: LARGE_COL },
    { Header: "Year", accessor: "year", sortType: "basic" },
    { Header: "Make", accessor: "make", sortType: _stringSort, width: MED_COL },
    { Header: "Model", accessor: "model", sortType:_stringSort, width: LARGE_COL },
    { Header: "Vehicle Class", accessor: "vehicle_class", sortType: _stringSort, width: MED_COL},
    { Header: "Drivetrain", accessor: "drivetrain", sortType: _stringSort },
  ]
  if(isTd) {
    sharedColumns = [...sharedColumns, { Header: "Miles", accessor: "miles", sortType: _decimalStringSort }]
  }
  return sharedColumns;
};

const tdSpecificColumns = (isTd) => {
  if(isTd) {
    return ([
    { Header: "Emission Ratio", accessor: "emit", sortType: _decimalStringSort, showToolTip: () => {} },
    { Header: "Fuel Economy\n(MPG)", accessor: "fuelEcon", sortType: "alphanumeric" },
    { Header: "Idle Hours", accessor: "idle", sortType: _decimalStringSort },
    ]
    )
  } else {
    return [];
  }
}

export const allVehiclesTableColumns = (displayInLbs, isTd, methodology) => {
  if(methodology?.value === 'moves3') {
    return (
      [
        ...sharedColumns(isTd),
        ...tdSpecificColumns(isTd),
        { Header: displayInLbs ? `GHG \n(${UNITS_POUNDS})` : `GHG \n(${UNITS_TONS})`, accessor: displayInLbs ? "ghgLbs" : "ghgTons", sortType: _decimalStringSort },
        { Header: <div>CO<sub>2</sub><br />({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CO₂ \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? "CO2Lbs" : "CO2Tons", sortType: _decimalStringSort, width: MED_COL },
        { Header: `CO \n(${UNITS_POUNDS})`, accessor: "coLbs", sortType: _decimalStringSort, width: MED_COL },
        { Header: <div>NO<sub>x</sub><br />({UNITS_POUNDS})</div>, simpleHeader: `NOₓ \n(${UNITS_POUNDS})`, accessor: "noxLbs", sortType: _decimalStringSort },
        { Header: `PM 10\u00B5m \n(${UNITS_GRAMS})`, accessor: 'pm10Grams', sortType: _decimalStringSort },
        { Header: `PM 2.5\u00B5m \n(${UNITS_GRAMS})`, accessor: 'pm25Grams', sortType: _decimalStringSort }
      ]
    )
  } else {
    return (
      [
        ...sharedColumns(isTd),
        ...tdSpecificColumns(isTd),
        { Header: displayInLbs ? `GHG \n(${UNITS_POUNDS})` : `GHG \n(${UNITS_TONS})`, accessor: displayInLbs ? "ghgLbs" : "ghgTons", sortType: _decimalStringSort },
        { Header: <div>CO<sub>2</sub><br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CO₂ \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? "CO2Lbs" : "CO2Tons", sortType: _decimalStringSort, width: MED_COL },
        { Header: <div>CH<sub>4</sub><br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CH₄ \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? 'ch4Lbs' : 'ch4Tons', sortType: _decimalStringSort},
        { Header: <div>NO<sub>x</sub><br />({UNITS_POUNDS})</div>, simpleHeader: `NOₓ \n(${UNITS_POUNDS})`, accessor: "noxLbs", sortType: _decimalStringSort },
        { Header: <div>Biofuel CO<sub>2</sub><br />({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `Biofuel CO₂ \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? 'bioCO2Lbs' : 'bioCO2Tons', sortType: _decimalStringSort},
      ]
    )
  }
}

export const altFuelVehiclesTableColumns  = (displayInLbs, isTd, methodology) => {
  if(methodology?.value === 'moves3') {
    return (
  [
    ...sharedColumns(isTd),
    { Header: `GHG Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? "ghgLbsSavings" : "ghgTonsSavings", sortType: _decimalStringSort },
    { Header: <div>CO<sub>2</sub> Reduction <br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CO₂ Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`,  accessor: displayInLbs ? "CO2LbsSavings" : "CO2TonsSavings", sortType: _decimalStringSort, width: LARGE_COL },
    { Header: `CO Reduction \n(${UNITS_POUNDS})`, accessor: "coLbsSavings", sortType: _decimalStringSort, width: LARGE_COL },
    { Header: <div>NO<sub>x</sub> Reduction<br /> ({UNITS_POUNDS})</div>, simpleHeader: `NOₓ Reduction \n(${UNITS_POUNDS})`, accessor: "noxLbsSavings", sortType: _decimalStringSort },
    { Header: `PM 10\u00B5m Reduction \n(${UNITS_GRAMS})`, accessor: "pm10GramsSavings", sortType: _decimalStringSort, width: LARGE_COL },
    { Header: `PM 2.5\u00B5m Reduction \n(${UNITS_GRAMS})`, accessor: "pm25GramsSavings", sortType: _decimalStringSort, width: LARGE_COL },
    { Header: `Fuel Reduction \n(${UNITS_GALLONS})`, accessor: "fuelGallonsSavings", sortType: _decimalStringSort },
    { Header: "Fuel Savings", accessor: "fuelCostSavings", sortType: _decimalStringSort }
  ]
)
  } else {
    return (
      [
        ...sharedColumns(isTd),
        { Header: `GHG Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? "ghgLbsSavings" : "ghgTonsSavings", sortType: _decimalStringSort },
        { Header: <div>CO<sub>2</sub> Reduction <br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CO₂ Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? "CO2LbsSavings" : "CO2TonsSavings", sortType: _decimalStringSort, width: LARGE_COL },
        { Header: <div>CH<sub>4</sub> Reduction<br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `CH₄ Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? 'ch4LbsSavings' : 'ch4TonsSavings', sortType: _decimalStringSort, width: LARGE_COL},
        { Header: <div>NO<sub>x</sub> Reduction<br /> ({UNITS_POUNDS})</div>, simpleHeader: `NOₓ Reduction \n(${UNITS_POUNDS})`, accessor: "noxLbsSavings", sortType: _decimalStringSort },
        { Header: <div>Biofuel CO<sub>2</sub> Reduction<br /> ({displayInLbs ? UNITS_POUNDS : UNITS_TONS})</div>, simpleHeader: `Biofuel CO₂ Reduction \n(${displayInLbs ? UNITS_POUNDS : UNITS_TONS})`, accessor: displayInLbs ? 'bioCO2LbsSavings' : 'bioCO2TonsSavings', sortType: _decimalStringSort, width: LARGE_COL},
        { Header: `Fuel Reduction \n(${UNITS_GALLONS})`, accessor: "fuelGallonsSavings", sortType: _decimalStringSort },
        { Header: "Fuel Savings", accessor: "fuelCostSavings", sortType: _decimalStringSort }
      ]
    )
  }
}
