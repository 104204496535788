import React, { useEffect, useState } from "react";
import Loading from "../swt-loading";
import "@sawatchlabs/swt-components/dist/index.css";
import { DoublePaneMultiSelectDropdown } from "@sawatchlabs/swt-components";
import * as S from '../../../../styles/core-styles/AdminTools-styles';

export default function UpdateUserGroupsModal(props) {
  const [groupsForUpdate, setGroupsForUpdate] = useState(null);
  const [allGroups, setAllGroups] = useState();
  const [usersGroups, setUsersGroups] = useState();
  
  const { apiURL, db, user, selectedUser, groups } = props;

  useEffect(() => {
    let groupObjs = [];
    if (typeof groups !== "undefined" || groups !== null) {
      groups.sort((a,b) => a.name.localeCompare(b.name)).map((g) => {
        if (g.name === 'All Vehicles') {
         return groupObjs.unshift({id: g.id, label: g.name})
        } else {
        return groupObjs.push({id: g.id, label: g.name})
        }
      })
    }
    setAllGroups(groupObjs)
  }, [groups])

  useEffect(() => {
    let selectedGroupsIds = []
    let groupObj = []
    const groupsForUpdate = JSON.parse(JSON.stringify(groups));
    const url = `${apiURL}getGroupAccess?ident=${db}&username=${selectedUser}`;
    fetch(url, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        //Find all groups User belongs to
        data.groups.map((g) => {
          return selectedGroupsIds.push(g.group_id)
        })
        //Go through all groups and filter the groups the user belongs to
        const filteredGroups = groupsForUpdate.filter((g) => {
          return selectedGroupsIds.includes(g.id)
      })
      // Map through the filtered groups and shape the data for MultiSelect component
        filteredGroups.map((i) => {
          return groupObj.push({id: i.id, label: i.name})
      })
        setUsersGroups(groupObj)
        setGroupsForUpdate(groupsForUpdate)
      });
  }, [apiURL, db, groups, user.token, selectedUser]);

  const _handleSave = (e) => {
    e.preventDefault();
    let url = `${apiURL}updateUserGroups?dbName=${db}&username=${selectedUser}`;
    const grps = groupsForUpdate.filter((g) => {
      //API checks for isMember property to determine if group is deleted or inserted
      g.isMember = usersGroups.some((i) => {
        if (g.id === i.id) {
        return true;
        } else {
        return false;
        }
      })
      return g;
    });

    const groupIds = grps.map(group => ({
      id: group.id,
      isMember: group.isMember
    }));
  
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        dbName: db,
        groups: groupIds,
        user: selectedUser,
      }),
    })
    .then(res => res.json())
    .then((data) => {
      if (data.status === "error")
        window.alert('Error on group membership update.');
      else 
        window.alert('Group memberships updated.');
    })
    props.showUpdateGroupsModal();
  }

  return (
    (!groups) ?
      <Loading />
      :
    <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={props.showUpdateGroupsModal}>Close</S.ModalCloseButton>
        <S.ModalHeader> Update groups for <b>{props.selectedUser}</b>.</S.ModalHeader>
        {groups.length === 0
        ?
        <div>You can only assign users to groups that you are a member of. Please reach out to your account manager to request group access.</div>
        :
        <S.DatabaseModalForm onSubmit={_handleSave}>
          <S.DatabaseModalFormInnerWrapper>
            <DoublePaneMultiSelectDropdown
              showSelectAll={false}
              items={allGroups}
              selectedItems={usersGroups}
              onChange={(e) => setUsersGroups(e)}
            />
          </S.DatabaseModalFormInnerWrapper>
          <S.ModalFormButtonWrapper>
            <S.ModalSaveButton type="submit">Save</S.ModalSaveButton>
          </S.ModalFormButtonWrapper>
        </S.DatabaseModalForm>
        }
      </S.ModalContainer>
      </S.ModalDisplay>
    );
}
