import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { MultiSelectDropdown, SingleSelectDropdown } from '@sawatchlabs/swt-components';
import styled from "styled-components/macro"
import { blue_green, blue_green_hover, blue_green_semitransparent, darkest_gray, light_gray, off_black, stroke_gray, white } from '../../styles/emit-styles/ColorScheme';
import { MEDIUM_RADIUS, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';

const PRIMARY_COLOR = blue_green;
const PRIMARY_SELECTED = blue_green_semitransparent;

const DropdownContainer = styled.div`
`;

const ControlsWrapper = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    color: ${darkest_gray};
`;

const ControlsLabel = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
`;

const SelectDropdown = styled.select`
  margin-top: 4px;
  color: #4a4a4a;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 38px;
  width: 250px;
  font-size: 16px;
`;

const CheckboxWrapper = styled.div`
    height: 17px;
    display: flex;
    align-items: center;
`;

const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: -5px;

  &:checked:before {
    content: '•';
    color: ${blue_green};
    font-size: 25px;
    padding-right: 2px;
  }
  &:before {
    content: '•';
    color: transparent;
    font-size: 25px;
    padding-right: 2px;
  }
`;

const SectionSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${stroke_gray};
`;

const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FilterButton = styled.button`
  margin-top: 8px;
  margin-bottom: 2px;
  width: 120px;
  height: 36px;
  background: ${blue_green};
  border-radius: ${MEDIUM_RADIUS}px;
  border: none;
  color: ${white};
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  transition: background-color ${TRANSITION_LENGTH} ease;
  &:hover {
    background-color: ${blue_green_hover};
  }
  &:disabled {
    background-color: ${light_gray};
    cursor: initial;
  }
`;

const ControlFilters = ({ group, groups, handleGroupChange, vehicleClasses, selectedVehicleClasses, handleClassChange, calcByFuel, displayInLbs, handleModelChange, handleUnitChange, setOpenFilterPopover, methodology, setMethodology }) => {

  // let location = useLocation();

  const [groupDetails, setGroupDetails] = useState([]);
  //Temp state values used to update values within the component before saving. Only want to update parent component state once Submit button is clicked to prevent re-rendering this component when input values change.
  const [tempGroup, setTempGroup] = useState([]);
  const [tempVehicleClasses, setTempVehicleClasses] = useState([]);
  const [tempDisplayInLbs, setTempDisplayInLbs] = useState();
  const [tempMethodology, setTempMethodology] = useState();
  const [disableButtons, setDisableButtons] = useState(true);

  useEffect(() => {
    setTempGroup(group);
    setTempVehicleClasses(selectedVehicleClasses);
    setTempDisplayInLbs(displayInLbs);
    setTempMethodology(methodology);
  },[group, selectedVehicleClasses, displayInLbs, methodology])

  function checkVehicleClassesMatch(newList, originalList) {
    if(!newList || !originalList) return;
    if(newList.length !== originalList.length) return false;
    let result = true;
    for(let i = 0; i < newList.length; i++) {
      if(!originalList.includes(newList[i])) {
        return false;
      }
    }
    return result;
  }

  useEffect(() => {
    let classesCheck = checkVehicleClassesMatch(tempVehicleClasses, selectedVehicleClasses)
    if(tempGroup === group && tempDisplayInLbs === displayInLbs && classesCheck && methodology.value === tempMethodology.value) {
      setDisableButtons(true);
    } else {
      setDisableButtons(false);
    }
  },[group, tempGroup, selectedVehicleClasses, tempVehicleClasses, displayInLbs, tempDisplayInLbs, methodology, tempMethodology])

  //Need to filter groups to get full group object for formatting the select component value
  //TODO: Refactor code so group, tempGroup, and groupDetails all use same format and don't require groupDetails for select component display value - JJ
  useEffect(() => {
    let selected = groups.filter((g) => g.id === tempGroup);
    setGroupDetails(selected)
  }, [group, groups, tempGroup])

  const _handleModelChange = (change) => {
    if (change === 'Miles Traveled') handleModelChange(false);
    else handleModelChange(true);
  }

  const _handleUnitChange = (change) => {
    if (!handleUnitChange) return;
    if (change === 'pounds') setTempDisplayInLbs(true);
    else setTempDisplayInLbs(false);
  }

  const customValueRenderer = (selected, _options) => {
    if (selected[0].value === 'All Classes' && selected.length === 1) {
      return <p>All Classes</p>
    } else if (selected.length === 1) {
      return <p>1 Class</p>
    } else {
      return <p>{selected.length} Classes</p>
    }
  };

  const formatSelectValues = (selectedClasses) => {

    let classArr = [];
    if (selectedClasses.length === vehicleClasses.length || selectedClasses[selectedClasses.length - 1] === 'All Classes') {
      classArr.push({ value: 'All Classes', label: 'All Classes' })
    } else {
      selectedClasses.filter((word) => word !== 'All Classes').map((item) => {
        return classArr.push({ label: item, value: item })
      })
    }
    return classArr;
  }
  const _handleClassChange = (e) => {
    if (e.length === 0) {
      return;
    } else if (e[e.length - 1].value === 'All Classes') {
      setTempVehicleClasses(vehicleClasses)
    } else {
      let filteredArr = e.filter((obj) => obj.value !== 'All Classes')
      setTempVehicleClasses(filteredArr.map((item) => { return item.value }))
    }
  }

  const customItemRenderer = ({ option, checked, onClick }) => {
    return (
      <CheckboxWrapper>
        <StyledCheckbox type='checkbox' checked={checked} onChange={onClick} />
        {option.label}
      </CheckboxWrapper>
    )
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleGroupChange(tempGroup);
    handleClassChange(tempVehicleClasses);
    handleUnitChange(tempDisplayInLbs);
    setMethodology(tempMethodology);
    setOpenFilterPopover(false);
  }

  function resetFilterValues(e) {
    e.preventDefault();
    setTempGroup(group);
    setTempVehicleClasses(selectedVehicleClasses);
    setTempDisplayInLbs(displayInLbs);
    setTempMethodology(methodology);
  }

  return (
    <ControlsWrapper onSubmit={(e) => handleSubmit(e)}>
      <DropdownContainer>
        <ControlsLabel>Methodology:</ControlsLabel>
        <SingleSelectDropdown
          bulletColor={PRIMARY_COLOR}
          selectedOptionBgColor={PRIMARY_SELECTED}
          color={off_black}
          onChange={(e) => {setTempMethodology(e)}}
          value={tempMethodology}
          options={[
            { value: 'moves3', label: 'MOVES3' },
            { value: 'ghgprotocol_2.0.0', label: 'GHG Protocol 2.0.0'}
          ]}
        />
      </DropdownContainer>
      <SectionSeparator />
      <DropdownContainer>
        <ControlsLabel>Group:</ControlsLabel>
        <SingleSelectDropdown
          bulletColor={PRIMARY_COLOR}
          selectedOptionBgColor={PRIMARY_SELECTED}
          color={off_black}
          onChange={(e) => setTempGroup(e.value)}
          value={groupDetails.map((g) => {
            return ({ value: g.id, label: g.name })
          })}
          options={groups.map((g) => {
            return (
              { value: g.id, label: g.name }
            )
          })}
        />
      </DropdownContainer>
      <SectionSeparator />
      <DropdownContainer>
        <ControlsLabel>Vehicle Classes:</ControlsLabel>
        <MultiSelectDropdown
          valueRenderer={customValueRenderer}
          itemRenderer={customItemRenderer}
          hoverColor={white}
          color={off_black}
          borderColor={stroke_gray}
          height={'38px'}
          placeholder={'No Classes'}
          hasSelectAll={false}
          ClearSelectedIcon={null}
          selectedOptionBackground={PRIMARY_SELECTED}
          onChange={(e) => { _handleClassChange(e) }}
          value={formatSelectValues(tempVehicleClasses)}
          options={['All Classes', ...vehicleClasses].map((item) => {
            return { value: item, label: item }
          })}
        />
      </DropdownContainer>
      <SectionSeparator />
      {/* turning the miles/fuel calc selection off til we get the messaging on it better */}
      {false && <DropdownContainer>
        <ControlsLabel>Calculate On: &nbsp;</ControlsLabel>
        <SelectDropdown
          value={calcByFuel ? 'Fuel Consumed' : 'Miles Traveled'}
          onChange={(e) => _handleModelChange(e.target.value)}
        >
          <option value='Fuel Consumed'>Fuel Consumed</option>
          <option value='Miles Traveled'>Miles Traveled</option>
        </SelectDropdown>
      </DropdownContainer>}
      <DropdownContainer>
        <ControlsLabel>Drivetrain:</ControlsLabel>
        <SingleSelectDropdown
          // color={off_black}
          bulletColor={PRIMARY_COLOR}
          isDisabled={true}
          selectedOptionBgColor={PRIMARY_SELECTED}
          onChange={() => { }}
          value={{ label: 'All', value: 'all' }}
          options={[]}
        />
      </DropdownContainer>
      <SectionSeparator />
      <DropdownContainer>
        <ControlsLabel>Display Units (Carbon Dioxide & GHG):</ControlsLabel>
        <SingleSelectDropdown
          color={off_black}
          bulletColor={PRIMARY_COLOR}
          selectedOptionBgColor={PRIMARY_SELECTED}
          onChange={(e) => _handleUnitChange(e.value)}
          value={tempDisplayInLbs ? [{ label: 'Pounds', value: 'pounds' }] : [{ label: 'Tons', value: 'tons' }]}
          options={[
            { label: 'Tons', value: 'tons' },
            { label: 'Pounds', value: 'pounds' }
          ]}
        />
      </DropdownContainer>
      <FilterButtonContainer>
        <FilterButton disabled={disableButtons} onClick={(e) => resetFilterValues(e)}>Reset</FilterButton>
        <FilterButton disabled={disableButtons} type='submit'>Update</FilterButton>
      </FilterButtonContainer>
    </ControlsWrapper>
  );
}

export default ControlFilters;