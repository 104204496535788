import React, { useEffect, useMemo, useCallback, useState, useRef, Suspense } from "react";
import AdminTableView from "./AdminTableView";
import VehicleClassDropdown from "../../dev-tool-components/VehicleClassDropdown.jsx";
import Loading from "../swt-loading";
import Search from "./search";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import UpfitsDropdown from "../../dev-tool-components/UpfitsDropdown";
import { conformPostRequest, processApiResponse } from "../../utility-components/ConformUnits";
import { preventNonIntegers, preventPasteNonIntegers, formatDecimal, roundObjValues, determineVehicleClassValue } from "../UtilityFunctions";
import { columnsToExcelHeaders, vehiclesTableColumns, rowsToData } from "./TableHelpers";
import CategoryDropdown from "./CategoryDropdown";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

const EV_CAND_BATTERY_BOUND_MAX = 1.2;
const EV_CAND_BATTERY_BOUND_MIN = 0.8;

const null2TrueBoolKeys = ["is_ld", "owned"];
const null2FalseBoolKeys = ["is_ezio_selected", "selected", "is_bev", "is_cng", "is_propane", "is_diesel", "is_phev", "is_gasoline"];

const TOOLTIP_OFFSET_TOP = -122;
const TOOLTIP_OFFSET_LEFT = 44;

const RUN_ANALYTICS_BUTTON_TOOL_TIP = "This will update analytic results with any new or changed vehicle attributes."
const SERVER_ERRORED_MESSAGE = "There was a server error during your request."
const ANALYTICS_IN_PROCESS_MESSAGE = "Analytics is already reprocessing data.";

export default function VehicleManagementTable(props){
  const [mousePos, setMousePos] = useState({});
  const [matchingVehicles, setMatchingVehicles] = React.useState([]);
  const [bevCandidates, setBEVCandidates] = React.useState(null);
  const [phevCandidates, setPHEVCandidates] = React.useState(null);
  const [iceCandidates, setICECandidates] = React.useState(null);
  const [vehicles, setVehicles] = React.useState([]);
  const [immutableVehicles, setImmutableVehicles] = React.useState([])
  const [vehicleClasses, setVehicleClasses] = React.useState([]);
  const [reload, setReload] = useState(true);
  const { apiURL, db, user, dbDisplayName } = props;
  const [infoShown, setInfoShown] = useState(false);
  const [showVehicleProblems, setShowVehicleProblems] = useState(false);
  const [showRunAnalyticsHover, setShowRunAnalyticsHover] = useState(false);
  const [hoveredVehicle, setHoveredVehicle] = useState(null);
  const [upfits, setUpfits] = useState([]);
  const [incompleteVehicles, setIncompleteVehicles] = useState([]);
  const [incompleteIds, setIncompleteIds] = useState([]);
  const [showIncompleteVehicles, setShowIncompleteVehicles] = useState(false);
  const userSettings = props.user.userSettings;
  const dbSettings = props.dbSettings;

  const skipPageResetRef = useRef(false);

  useEffect(() => {
    document.onmousemove = (event) =>{
      handleMouseMove(event)
    }
  });

  const handleMouseMove = (event) => {
    setMousePos({ x: event.clientX, y: event.clientY });
  };

  const tableColumns = vehiclesTableColumns(dbSettings,userSettings);

  useEffect(() => {
    const url = `${apiURL}getVehicleClasses?dbName=${db}`;
    fetch(url, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        if (data.status === "success") {
          setVehicleClasses([...new Set(
            (data.data ?? []).map((item) => {let n = item.vehicle_class.replace("EV", "");n = item.vehicle_class.replace("ICE", "");return n.trim()})
          )]);
        }
        else {
          alert(SERVER_ERRORED_MESSAGE);
        }
      });
  }, [apiURL, db, user.token]);

  useEffect(() => {
    if(!reload)return;
    const url = `${apiURL}getVehicles?dbName=${db}`;
    fetch(url, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        if (data.status === "success") {
          if (data.data) {
            data.data.forEach(vehicle => null2TrueBoolKeys.forEach(key => vehicle[key] = (vehicle[key] == null ? true : vehicle[key])));
            data.data.forEach(vehicle => null2FalseBoolKeys.forEach(key => vehicle[key] = (vehicle[key] == null ? false : vehicle[key])));
            data.data.forEach(vehicle => vehicle.mutated = false);
            fetch(`${apiURL}getVehicleUpfits?dbName=${db}`, { headers: { 'Authorization': `Bearer ${user.token}` }})
              .then(res => res.json())
              .then(upfitRes => {
                if (upfitRes.status === "success") {
                  data.data.map((v) => {
                    let upfits = upfitRes['data'].filter(u => u.vin === v.vin);
                    v.upfits = upfits === null ? [] : upfits;
                    v.required_upfits = upfits.filter(u => u.required);
                    v.optional_upfits = upfits.filter(u => !u.required);
                    return null;
                  })
    
                  data.data.forEach((v) => {
                    v = processApiResponse(userSettings, v, false)
                    v = roundObjValues(v)
                  })
                  //Get initial list of ID's for all the incomplete vehicles in the db
                  let incompleteVehicleIds = [];
                  data.data.forEach((v) => {
                      let vehicleProblems = [];
                      if(!v.mpg_c || v.mpg_c === '0' || v.mpg_c === 0) vehicleProblems.push('mpg_c');
                      if(!v.mpg_h || v.mpg_h === '0' || v.mpg_h === 0) vehicleProblems.push('mpg_h');
                      if(!v.vehicle_class) vehicleProblems.push('vehicle_class');
                      if(!v.cyl) vehicleProblems.push('cyl');
                      if(!v.year || v.year === 0 || v.year === '0') vehicleProblems.push('year');
                      if(v.is_phev || v.is_bev) {
                        if (!v.battery_kwh || v.battery_kwh === "0"){
                          vehicleProblems.push('battery_kwh');
                        }
                      }
                      
                      if(vehicleProblems.length > 0) {
                        v.incompleteColumns = vehicleProblems;
                        incompleteVehicleIds.push(v.pkid);
                      }
                    return v;
                  })
                  setIncompleteIds(incompleteVehicleIds);
                  setVehicles(data.data);
                  setImmutableVehicles(JSON.parse(JSON.stringify(data.data)))
                }
                else {
                  alert(upfitRes.message);
                }
                setReload(false);
              });
          }
          setReload(false)
        }
        else {
          alert(SERVER_ERRORED_MESSAGE);
          setReload(false);
        }
      })
      .catch((err) => {
        console.error(err);
        window.alert(SERVER_ERRORED_MESSAGE);
      });
  }, [apiURL, db, user.token, vehicleClasses,  userSettings, reload]);

  useEffect(() => {
    const url = `${apiURL}getCandidates?ident=${db}`;
    fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
        .then((resp) => {return resp.json();})
        .then((data) => {
            const bevs = data.data.filter((c) => {if((c.evsa_selected || c.ionev_selected) && (c.is_bev))return c.vehicle_class;return null});
            const phevs = data.data.filter((c) => {if((c.evsa_selected || c.ionev_selected) && (c.is_phev))return c.vehicle_class;return null});
            const ices = data.data.filter((c) => {if((c.evsa_selected || c.ionev_selected) && c.is_ice)return c.vehicle_class;else return null});
            setBEVCandidates(bevs);
            setPHEVCandidates(phevs);
            setICECandidates(ices);
        })
        .catch((error) => {
          console.error("Error: " + error);
          window.alert(SERVER_ERRORED_MESSAGE);
        });
  }, [apiURL, db, user.token]);

  useEffect(() => {
    const url = `${apiURL}getUpfits?ident=${db}`;
    fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
    .then((resp) => {return resp.json();})
    .then((data) => {
        if (data.status === "success") {
          setUpfits(data.data)
        }
        else {
          alert(SERVER_ERRORED_MESSAGE);
        }
    })
    .catch((error) => {
      console.error("Error: " + error);
      window.alert(SERVER_ERRORED_MESSAGE);
    });
  }, [apiURL, db, user.token])

  function runAnalytics() {
    const url = apiURL + `runAnalytics?dbName=${db}`;
    // this only re-runs regular ezEV, not sensitivity
    const obj = { company_ident: props.db, baseline: true, isTD: props.dbSettings.is_td };
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(obj),
      }).then((data) => {
        if (data.status === 200) {
          window.alert("Vehicles and product results updated");
          setReload(true);
        } else {
          window.alert(SERVER_ERRORED_MESSAGE);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  function updateVehicles(data) {
    const url = apiURL + "updateVehicles/";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      return resp.json();
    })
    .then((data) => {
      if (data.status === "success") {
        window.alert("Vehicles list updated.");
        setReload(true);
      } else {
        window.alert(SERVER_ERRORED_MESSAGE);
      }
    })
  }

  function handleRefresh() {
    const url = `${apiURL}isAnalyticsProcessing?dbName=${db}`;
    fetch(url, {
      headers: {Authorization: `Bearer ${user.token}`}
    }).then((resp) => resp.json())
      .then((data) => {
        let processing = data.data[0].analytics_processing;
        if(!processing){
          let confirmation = true;
            if(!props.dbUiUpdating) {
              confirmation = window.confirm("This database's UI should remain static. Running analytics may result in unwanted changes for viewers. \nAre you sure you wish to continue?")
            }
            if(confirmation) {
              runAnalytics();
              window.alert("Updating Analytics.");
              return;
            } else {
              return;
            }

        }
        else{
          window.alert(ANALYTICS_IN_PROCESS_MESSAGE);
        }
      })
      .catch((err) => {
        window.alert(SERVER_ERRORED_MESSAGE);
        return;
      });
  }

  function handleSave() {
    let incompleteVehiclesList = []
    let vcls = vehicles.filter((v) => v.mutated === true);
    //Check for errors only on vehicles that are being updated
    vcls.forEach((v) => {
      let vehicleProblems = []
      if(!v.mpg_c || v.mpg_c === '0' || v.mpg_c === 0) vehicleProblems.push('MPG (City)');
      if(!v.mpg_h || v.mpg_h === '0' || v.mpg_h === 0) vehicleProblems.push('MPG (Hwy)');
      if(!v.vehicle_class) vehicleProblems.push('Class');
      if(!v.cyl) vehicleProblems.push('Cylinders');
      if(!v.year || v.year === 0 || v.year === '0') vehicleProblems.push('Year');
      if(v.is_phev || v.is_bev) {
        if (!v.battery_kwh || v.battery_kwh === "0"){
            vehicleProblems.push('Total Battery (kWh)');
        }
        }
      if(vehicleProblems.length > 0) {
        let vehicleId = (v.user_defined_vin && v.user_defined_vin !== 'null') ? v.user_defined_vin : v.vin
        incompleteVehiclesList.push(`${vehicleId} - ${vehicleProblems.join(', ')}`)
      }
    })
    //If there is incomplete vehicles, make sure user confirms save before sending to API
    if(incompleteVehiclesList.length > 0) {
      let confirmMessage = `Some of the vehicles you are trying to save are incomplete.\n• ${incompleteVehiclesList.join(',\n• ')} \n\nDo you want to save incomplete vehicles?`
      if (window.confirm(confirmMessage)) {
        setIncompleteVehicles([]);
      } else {
        return setIncompleteVehicles(incompleteVehiclesList);
      }
    } else {
      setIncompleteVehicles([])
    }
    vcls.forEach((v) => {
      // Normalize upfits shape before passing to API
      let vcl_upfits = []
      v.required_upfits.forEach((u) => {
        vcl_upfits.push({upfit_id: u.id, required: true});
      })
      v.optional_upfits.forEach((u) => {
        // Avoid duplicates, prefer required over optional
        if (!vcl_upfits.find(el => el.upfit_id === u.id)) {
          vcl_upfits.push({upfit_id: u.id, required: false});
        }
      })
      v.upfits = vcl_upfits;
    })
    vcls = conformPostRequest(userSettings, vcls);
    vcls.forEach((v) => {
      return roundObjValues(v)
    })
    let data = { dbName: db, vehicles: vcls};
    let confirmation = true;
    if(!props.dbUiUpdating) {
      confirmation = window.confirm("This database's UI should remain static. Changing vehicle values may result in unwanted changes for viewers. \nAre you sure you wish to continue?")
    }
    if(confirmation) {
      updateVehicles(data);
    } else {
      return;
    }
  }

  function _getTableIndex(vin) {
    const idxArr = vehicles.map((v) => {
      return v.vin;
    });
    return idxArr.indexOf(vin);
  }

  function handleClassChange(c, vin) {
    skipPageResetRef.current = false
    let vcls = [...vehicles];
    let row = _getTableIndex(vin);
    let vcl = vehicles[row];
    vcl["vehicle_class"] = c;
    vcl["mutated"] = true;
    setVehicles(vcls);
  }

  function handleCategoryChange(c, id) {
    skipPageResetRef.current = false
    let vcls = [...vehicles];
    let row = _getTableIndex(id);
    let vcl = vcls[row];
    vcl["is_ld"] = c === "Light Duty" ? true : false;
    vcl["mutated"] = true;
    setVehicles(vcls);
  }

  function handleBoolChange(col, vin) {
    skipPageResetRef.current = false
    let vcls = [...vehicles];
    let row = _getTableIndex(vin);
    let vcl = vcls[row];
    vcl[col] ? (vcl[col] = false) : (vcl[col] = true);
    vcl["mutated"] = true;
    setVehicles(vcls);
  }

  function handleInputChange(e) {
    const ALPHANUMERIC_REGEX = new RegExp("^[\\w\\-\\(\\) ]*$") //Prevents all special characters except for " - _ ( ) "
    if(e.target.getAttribute('type') === 'text' && ALPHANUMERIC_REGEX.test(e.target.value) === false) {
      e.preventDefault();
      return;
    }
    skipPageResetRef.current = false
    const vcls = [...vehicles];
    let val = e.target.value;
    const vin = e.target.getAttribute("vin");
    let col = e.target.getAttribute("accessor");
    let row = _getTableIndex(vin);
    let vcl = vehicles[row];
    if(e.target.getAttribute('type') === 'number') {
      val = formatDecimal(val, 4)
    }
    vcl[col] = val;
    vcl["mutated"] = true;
    setVehicles(vcls);
  }

  function handleRequiredUpfitsChange(e, vin) {
    skipPageResetRef.current = false;
    let vcls = [...vehicles];
    let row = _getTableIndex(vin);
    let vcl = vcls[row];
    let selectedUpfits = [];
    e.forEach((u) => {
      selectedUpfits.push(upfits.find(el => el.id === u.value));
    })
    vcl.required_upfits = selectedUpfits;
    vcl.optional_upfits = vcl.optional_upfits.filter(u => !vcl.required_upfits.find(e => e.id === u.id))
    vcl['mutated'] = true;
    setVehicles(vcls)
  }

  function handleOptionalUpfitsChange(e, vin) {
    skipPageResetRef.current = false;
    let vcls = [...vehicles];
    let row = _getTableIndex(vin);
    let vcl = vcls[row];
    let selectedUpfits = [];
    e.forEach((u) => {
      selectedUpfits.push(upfits.find(el => el.id === u.value));
    })
    vcl.optional_upfits = selectedUpfits;
    vcl['mutated'] = true;
    setVehicles(vcls)
  }

  function getVehicleRequiredUpfits(vin) {
    let vcls = [...vehicles];
    let row = _getTableIndex(vin);
    let vcl = vcls[row];
    return vcl.required_upfits;
  }

  const formatSelectedUpfits = (selectedUpfits) => {
    let upfitsArr = [];
    if (selectedUpfits) {
      selectedUpfits.map(u => {
        return upfitsArr.push({value: u.id, label: u.name})
      })
    }
    return upfitsArr;
}

  const RunAnalyticsToolTipView = () => {
    return (
      <S.AdminToolTipContainer displayTooltip={showRunAnalyticsHover}>
        {RUN_ANALYTICS_BUTTON_TOOL_TIP}
      </S.AdminToolTipContainer>)
  }

  const VehicleProblemsView = (props) => {
    const problems = vehicleProblems(props.vehicle);
    if(problems.length < 1)return null;
    return (
      <S.VehicleProblemToolTipContainer pageX={`${mousePos.x+TOOLTIP_OFFSET_LEFT}px`} pageY={`${mousePos.y+TOOLTIP_OFFSET_TOP}px`} showVehicleProblems={showVehicleProblems}>
        {problems.map((p, i)=>{return(<S.InfoText key={i}>{p}</S.InfoText>)})}
      </S.VehicleProblemToolTipContainer>)
  } 

  const handleMouseIn = (vin) => {
    const v = vehicles.find((v) => v.vin === vin);
    setShowVehicleProblems(true);
    setHoveredVehicle(v);
  }

  const handleMouseOut = (vin) => {
    setShowVehicleProblems(false);
    setHoveredVehicle(null);
  }

  //applying styled components to the input element strips custom attrs, so vin and accessor become nulls
  const BoolTableCell = useCallback((props) =>
      <input
        className="swt-admin-table-input"
        type="checkbox"
        id={props.vin}
        vin={props.vin} 
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        onChange={props.handleOnClick}
        checked={props.bool}
      />,[]);

  const InputTableCell = useCallback((props) => 
      <input className="swt-admin-input"
             style={props.styles}
             key={props.vin}
             title={props.label}
             value={`${props.label}`}
             vin={props.vin}
             accessor={props.accessor}
             onKeyDown={props.onKeyDown}
             onPaste={props.onPaste}
             onChange={props.handleOnChange}
             onWheel={(e) => e.target.blur()}
             type={props.type}
             onMouseOver={props.onMouseOver ? ()=>props.onMouseOver(props.vin) : ()=>{}}
             onMouseOut={props.onMouseOver ? ()=>props.onMouseOut(props.vin) : ()=>{}}
             data-testid={`${props.accessor}-input-testid`}
             disable={props.disable}
      />,[]);

  function vehicleProblems(v){
    const arr = [];
    if(!v) return arr;
    const vehicle = immutableVehicles.find((b) => b.vin === v.vin);
    if(!vehicle) return arr;
    vehicle.hasPHEVCandidate = false;
    vehicle.hasBEVCandidate = false;
    vehicle.hasICECandidate = false;
    vehicle.hasBatteryComp = false;
    if(bevCandidates && iceCandidates && phevCandidates){
      const bevClasses = bevCandidates.map((b) => {return b.vehicle_class});
      const phevClasses = phevCandidates.map((p) => {return p.vehicle_class});
      const iceClasses = iceCandidates.map((i) => {return i.vehicle_class});
      vehicle.hasPHEVCandidate = phevClasses.indexOf(vehicle.vehicle_class) > -1 ? true : false;
      vehicle.hasBEVCandidate = bevClasses.indexOf(vehicle.vehicle_class) > -1 ? true : false;
      vehicle.hasICECandidate = iceClasses.indexOf(vehicle.vehicle_class) > -1 ? true : false;
    }

    if(vehicle.is_bev && bevCandidates){
      const b = bevCandidates.filter((c)=> vehicle.battery_kwh >= (c.battery_capacity * EV_CAND_BATTERY_BOUND_MIN) && vehicle.battery_kwh <= (c.battery_capacity * EV_CAND_BATTERY_BOUND_MAX));
      if(b.length > 0)vehicle.hasBatteryComp = true;
    }
    if(vehicle.is_phev && phevCandidates){
      const p = phevCandidates.filter((c)=> vehicle.battery_kwh >= (c.battery_capacity * EV_CAND_BATTERY_BOUND_MIN) && vehicle.battery_kwh <= (c.battery_capacity * EV_CAND_BATTERY_BOUND_MAX));
      if(p.length > 0)vehicle.hasBatteryComp = true;
    }
    if(vehicle.is_diesel || vehicle.is_gasoline){
      vehicle.hasBatteryComp = true;
    }
    if(!vehicle.hasICECandidate)arr.push("No Corresponding ICE Candidates");
    if(!vehicle.hasBEVCandidate)arr.push("No Corresponding BEV Candidates");
    if(!vehicle.hasPHEVCandidate)arr.push("No Corresponding PHEV Candidates");
    if(!vehicle.hasBatteryComp)arr.push("No EV Candidate With Comparable Battery Capacity");
    return arr;
  }
  const filteredVehicles = useMemo(() => {
    if(!vehicles) return null;
    if(!showIncompleteVehicles) return vehicles;
    if(showIncompleteVehicles) {
      let filteredIncompleteVehicles = vehicles.filter((v) => incompleteIds.includes(v.pkid))
      filteredIncompleteVehicles.forEach((v) => {
        let vehicleProblems = [];
        if(!v.mpg_c || v.mpg_c === '0' || v.mpg_c === 0) vehicleProblems.push('mpg_c');
        if(!v.mpg_h || v.mpg_h === '0' || v.mpg_h === 0) vehicleProblems.push('mpg_h');
        if(!v.vehicle_class) vehicleProblems.push('vehicle_class');
        if(!v.cyl) vehicleProblems.push('cyl');
        if(!v.year || v.year === 0 || v.year === '0') vehicleProblems.push('year');
        if(v.is_phev || v.is_bev) {
          if (!v.battery_kwh || v.battery_kwh === "0"){
            vehicleProblems.push('battery_kwh');
          }
        }
        v.incompleteColumns = vehicleProblems;
        return v;
      })
      return filteredIncompleteVehicles;
    }
  //eslint-disable-next-line
  },[showIncompleteVehicles, vehicles])

  const mappedVehicles = useMemo(() =>{
    // TODO: Once FX receieves the same attribute overhaul that TD did, need to go in and update the disabled columns to allow them to be edited if applicable to FX.
    if(!vehicles || !matchingVehicles)return null;
    if(vehicles.length < 1 || !vehicleClasses || vehicleClasses.length < 1)return [];
    
    //this deep clones our vehicles object, but cannot be extended 
    //to complex members of the object, ie: Dates, etc.
    //needed so that we're not swapping bools 
    //and other attrs with react elements on the canonical vehicles obj
    const vcls = JSON.parse(JSON.stringify(filteredVehicles));
    const matchingVins = matchingVehicles.map((v) => {return v.vin})

    return vcls.filter((v) => {
      if(matchingVins.indexOf(v.vin) < 0)return null;
      v.key = v.vin;
      
      //inmutable columns
      //handle gvwr strings
      if (v.gvwr === null || v.gvwr === "None") v.gvwr = "";
      v.gvwr = v.gvwr.split(":");
      v.gvwr = v.gvwr[0];

      //bool toggles
      v.is_phev = <BoolTableCell vin={v.vin} accessor={"is_phev"} bool={v.is_phev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.is_bev = <BoolTableCell vin={v.vin} accessor={"is_bev"} bool={v.is_bev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.is_gasoline =<BoolTableCell vin={v.vin} accessor={"is_gasoline"} bool={v.is_gasoline} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.is_cng =<BoolTableCell vin={v.vin} accessor={"is_cng"} bool={v.is_cng} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.is_propane =<BoolTableCell vin={v.vin} accessor={"is_propane"} bool={v.is_propane} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>; 
      v.is_diesel =<BoolTableCell vin={v.vin} accessor={"is_diesel"} bool={v.is_diesel} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>; 
      v.selected = <BoolTableCell vin={v.vin} accessor={"selected"} bool={v.selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.is_ezio_selected = <BoolTableCell vin={v.vin} accessor={"is_ezio_selected"} bool={v.is_ezio_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;
      v.owned = <BoolTableCell vin={v.vin} accessor={"owned"} bool={v.owned} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("vin"))}}/>;

      //text inputs
      v.asset_id = <InputTableCell styles={v.styles} label={(v.asset_id !== null && v.asset_id !== undefined) ? v.asset_id : ""} vin={v.vin} accessor={"asset_id"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
      if ((v.user_defined_vin === null || v.user_defined_vin === 'null') && v.vin !== null) { v.user_defined_vin = v.vin; }
      if ((v.user_defined_vin === null || v.user_defined_vin === 'null') && v.vin === null) { v.user_defined_vin = "Missing VIN"; }
      v.user_defined_vin = <InputTableCell onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} styles={v.styles} label={v.user_defined_vin} vin={v.vin} accessor={"user_defined_vin"} handleOnChange={function(el){handleInputChange(el)}} type="text" />
      v.make = <InputTableCell styles={v.styles} label={v.make ? v.make : ""} vin={v.vin} accessor={"make"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
      v.model = <InputTableCell styles={v.styles} label={v.model ? v.model : ""} vin={v.vin} accessor={"model"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>

      //numeric inputs
      v.year = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.year ?? ""} vin={v.vin} accessor={"year"} handleOnChange={function(el){handleInputChange(el)}} type="number" />
      v.mpg_c = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.mpg_c ?? ""} vin={v.vin} accessor={"mpg_c"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.mpg_h = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles}label={v.mpg_h ?? ""} vin={v.vin} accessor={"mpg_h"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.life_cycle = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.life_cycle ?? ""} vin={v.vin} accessor={"life_cycle"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.maint_per_km = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.maint_per_km ?? ""} vin={v.vin} accessor={"maint_per_km"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.insurance = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.insurance ?? ""} vin={v.vin} accessor={"insurance"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.actual_purchase_price = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.actual_purchase_price ?? ""} vin={v.vin} accessor={"actual_purchase_price"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.battery_usable_kwh = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.battery_usable_kwh ?? ""} vin={v.vin} accessor={"battery_usable_kwh"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.battery_kwh = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.battery_kwh ?? ""} vin={v.vin} accessor={"battery_kwh"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.forecasted_scc = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.forecasted_scc ?? ""} vin={v.vin} accessor={"forecasted_scc"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.backcasted_scc = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.backcasted_scc ?? ""} vin={v.vin} accessor={"backcasted_scc"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.forecasted_fuel_cost = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.forecasted_fuel_cost ?? ""} vin={v.vin} accessor={"forecasted_fuel_cost"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.backcasted_fuel_cost = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.backcasted_fuel_cost ?? ""} vin={v.vin} accessor={"backcasted_fuel_cost"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.forecasted_ghg_kwh_gm = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.forecasted_ghg_kwh_gm ?? ""} vin={v.vin} accessor={"forecasted_ghg_kwh_gm"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.backcasted_ghg_kwh_gm = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.backcasted_ghg_kwh_gm ?? ""} vin={v.vin} accessor={"backcasted_ghg_kwh_gm"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.forecasted_kwh_cost = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.forecasted_kwh_cost ?? ""} vin={v.vin} accessor={"forecasted_kwh_cost"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.backcasted_kwh_cost = <InputTableCell disable ={!props.isTelematics} onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.backcasted_kwh_cost ?? ""} vin={v.vin} accessor={"backcasted_kwh_cost"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.disp = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.disp ?? ""} vin={v.vin} accessor={"disp"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.cyl = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.cyl ?? ""} vin={v.vin} accessor={"cyl"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.charge_to = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.charge_to ?? ""} vin={v.vin} accessor={"charge_to"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
      v.discharge_to = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={v.styles} label={v.discharge_to ?? ""} vin={v.vin} accessor={"discharge_to"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>


      //vehicle classes dropdown
      v.vehicle_class = <VehicleClassDropdown
                          key={v.vin}
                          id={v.vin}
                          styles={v.styles}
                          class={v.vehicle_class}
                          defaultValue={determineVehicleClassValue(v.vehicle_class, vehicleClasses)}
                          handleClassChange={handleClassChange}
                          vehicleClasses={vehicleClasses}/>

      // category dropdown
      v.is_ld = <CategoryDropdown
        id={v.vin}
        key={v.vin}
        defaultValue={v.is_ld === true ? "Light Duty" : "Medium/Heavy Duty"}
        handleCategoryChange={handleCategoryChange}/>

      // Upfits dropdown
      v.required_upfits = <UpfitsDropdown
          key={v.vin}
          id={v.vin}
          upfits={upfits}
          selectedUpfits={formatSelectedUpfits(v.required_upfits)}
          handleChange={handleRequiredUpfitsChange}
          />
      v.optional_upfits = <UpfitsDropdown
          key={v.vin}
          id={v.vin}
          upfits={upfits}
          requiredUpfits={getVehicleRequiredUpfits(v.vin)}
          selectedUpfits={formatSelectedUpfits(v.optional_upfits)}
          handleChange={handleOptionalUpfitsChange}
          />
      return v;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, vehicleClasses, matchingVehicles, bevCandidates, iceCandidates, immutableVehicles, upfits, filteredVehicles]);

  return(
    <>
    <div>
      <S.FlexWrapper>
        <S.AdminTableExplainTextPrimary>List of all vehicles in {dbDisplayName}.</S.AdminTableExplainTextPrimary>
        <div style={{display: 'inline-block'}}>
          <S.InfoIcon src="https://images.sawatchlabs.com/info-icon.png" alt="Infomation Icon" onMouseOver={() => setInfoShown(true)} onMouseOut={() => setInfoShown(false)} />
          {infoShown &&
            <S.InfoTextContainer>
              <S.InfoText>
              If a vehicle is not listed here, it is not being analyzed. Vehicles can be removed from the analysis by deselecting the "Selected" button.
              Updates can be made directly in the cells in the table.
              </S.InfoText>
            </S.InfoTextContainer>}
        </div>
      </S.FlexWrapper>
      <S.AdminTableExplainTextSub>This table provides identifying information for all vehicles included in the analysis dashboard for this fleet.</S.AdminTableExplainTextSub>
    </div>
    <S.VehicleTableHeaderSectionWrapper>
      <label>Show Only Incomplete Vehicles</label>
      <S.StyledCheckbox type='checkbox' value={showIncompleteVehicles} onClick={() => setShowIncompleteVehicles(!showIncompleteVehicles)}/>
    </S.VehicleTableHeaderSectionWrapper>

    <S.SearchContainer>
      <Search allValues={vehicles} setMatchingValues={setMatchingVehicles} skipPageResetRef={skipPageResetRef} disabled={(vehicles && bevCandidates && iceCandidates) ? false : true}/>
    </S.SearchContainer>

    <S.VehicleTableHeaderContainer>
       <S.CtaButtonWrapper style={{display: 'flex'}}>
        <S.CtaButton type="submit" onClick={handleSave}>
          Save
        </S.CtaButton> 
        <S.CtaButton type="submit" onClick={handleRefresh} onMouseOver={()=>setShowRunAnalyticsHover(true)} onMouseOut={()=>{setShowRunAnalyticsHover(false)}}>
          Run Analytics
        </S.CtaButton>
        <RunAnalyticsToolTipView />
      </S.CtaButtonWrapper>
      <Suspense fallback={<div></div>}>
        <ExcelDownloadButton
          downloadType={showIncompleteVehicles ? "incompleteVehiclesDownload" : "vehiclesDownload"}
          columnGroups={tableColumns}
          columns={columnsToExcelHeaders(tableColumns, true)}
          data={rowsToData(tableColumns, filteredVehicles, ((a, b) => a.asset_id < b.asset_id ? 1 : -1), true)}
          dbDisplayName={dbDisplayName}
          dbSettings={dbSettings}
          userSettings={userSettings}
        />
      </Suspense>
        
    </S.VehicleTableHeaderContainer>
    <div>
      <S.VehicleTableLegendItemWrapper>
        <VehicleProblemsView vehicle={hoveredVehicle}/>
      </S.VehicleTableLegendItemWrapper> 
    </div>
    {incompleteVehicles.length > 0 &&
      <S.VehicleTableErrorContainer>
        <b>List of vehicles and the required values they are missing:</b>
        {incompleteVehicles.map((v, idx) => {
          return (<div key={idx}>{v}</div>)
        })}
      </S.VehicleTableErrorContainer>
    }
    {(!mappedVehicles || !bevCandidates || !iceCandidates || reload) && <Loading />}
    {(mappedVehicles && bevCandidates && iceCandidates) && 
    <AdminTableView
      stickyCols={1}
      colGroups={5}
      columns={tableColumns}
      sortBy={{id: 'selected', desc: true}}
      data={reload ? [] : mappedVehicles}
      skipPageResetRef={skipPageResetRef}
      noDataMessage={'No vehicles to display.'}
      showIncompleteVehicles={showIncompleteVehicles}
    />}
    </>
  );
}