import { AVOIDED_TABLE_TYPE, GENERATED_TABLE_TYPE, PDF_TYPE, UNITS_POUNDS } from "../swt-emit";

function AddCommas(value) {
    let formattedValue = value.toString()
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
}

function sortByDate( a, b ) {
    if ( a.localized_date < b.localized_date )return -1;
    if ( a.localized_date > b.localized_date )return 1;
    return 0;
}

export function decideVinDisplay (vcl) {
    if(vcl) {
    if(typeof vcl.user_defined_vin === "undefined" || vcl.user_defined_vin === null || vcl.user_defined_vin === 'null' || !vcl.user_defined_vin) {
        if(vcl.vin) return vcl.vin;
        else return "Not Provided"; // Handling case of no VIN in FX util DBs - LS 3/26/25 
    } else {
      return vcl.user_defined_vin
    }
    }
  }
  
  export function vehicleIdDisplay (vcl) {
    if(vcl) {
    if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
    if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
    if (vcl.vin) return vcl.vin
    }
    // If none of these are provided 
    return "-"
  }

export function formatMonthlyGraphLabels(d) {
    if(!d) return
    let month = d.split('/')[0]
    let year = d.split('/')[1].slice(2)
    return `${month}/${year}`
}

// TODO: combine the formatData and formatSummaryData functions. Clean up references to charting library, as this is no longer an issue.
export function FormatData(accessor, value, rowValues, isCng = false) {
    const dollarValues = ['fuelCostSavings'];
    const valuesToIntegers = ['emit', 'idle', 'ghgTons', 'ghgTonsSavings', 'ghgLbs', 'ghgLbsSavings', 'noxLbs', 'noxLbsSavings', 'coLbs', 'coLbsSavings', 'CO2Tons', 'CO2TonsSavings', 'CO2Lbs', 'CO2LbsSavings', 'pm10Grams', 'pm10GramsSavings', 'pm25Grams', 'pm25GramsSavings', 'fuelGallonsSavings', 'ch4Lbs', 'ch4Tons', 'ch4LbsSavings', 'ch4TonsSavings', 'bioCO2Lbs', 'bioCO2Tons', 'bioCO2LbsSavings', 'bioCO2TonsSavings'];
    const unsupportedCngValues = ['ghgTonsSavings', 'ghgLbsSavings', 'noxLbsSavings', 'coLbsSavings', 'CO2TonsSavings', 'CO2LbsSavings', 'pm10GramsSavings', 'pm25GramsSavings', 'fuelGallonsSavings', 'fuelCostSavings', 'bioCO2LbsSavings', 'bioCO2TonsSavings', 'ch4LbsSavings', 'ch4TonsSavings',];
    const graphDataValues = ['ghg', 'phevGHG', 'bevGHG', 'dieselGHG', 'gasolineGHG', 'cngGHG','dailyTotalEmissions', 'totalDailyEmissionReductions', 'weeklyGHG', 'totalWeeklyEmissionReductions', 'totalMonthlyEmissionReductions', 'weeklyGHGReduction', 'monthlyGHGReduction', 'weeklyBarData', 'monthlyBarData', 'weeklyTotalEmissions', 'monthlyTotalEmissions', 'weeklyGHG', 'monthlyGHG', 'weeklyGHGReduction', 'monthlyGHGReduction', 'weeklyTotalBevGHG', 'monthlyTotalBevGHG', 'weeklyTotalBevGHGReduction' ,'monthlyTotalBevGHGReduction', 'weeklyTotalDieselGHG', 'monthlyTotalDieselGHG', 'weeklyTotalGasolineGHG', 'monthlyTotalGasolineGHG','weeklyTotalCNGGHG', 'monthlyTotalCNGGHG', 'weeklyTotalPhevGHG', 'weeklyTotalPhevGHGReduction', 'monthlyTotalPhevGHGReduction', 'monthlyTotalPhevGHG'];
    const valuesToAddCommas = ['miles'];

    if (accessor === 'asset_id') {
        return vehicleIdDisplay(rowValues)
    } else if (accessor === 'vin') {
        return decideVinDisplay(rowValues)
    } else if (value === null || value === undefined || value === 'null' || value === '-' || (unsupportedCngValues.includes(accessor) && isCng)) { // TODO: Once support for calculating emissions reduction for CNG vehicles is added, this last conditional MUST BE REMOVED to ensure values are propogated in the table.
        return "-";
    } else if (dollarValues.includes(accessor)) {
        value = Math.round(value * 100) / 100;
        return `$${AddCommas(value.toFixed(0))}`;
    } else if (valuesToAddCommas.includes(accessor)) { 
        return AddCommas(value);
    //React Chart js does not handle values with commas. Need to be returned as a number.
    } else if (graphDataValues.includes(accessor)) {
        value = Math.round(value * 10) / 10;
        return value;
    } else if (valuesToIntegers.includes(accessor)) {
        if (value === 0) {
            return '0';
        } else if (value < 10) {
            return value < 0.1 ? '<0.1' : AddCommas((Math.round(value * 10) / 10).toFixed(1));
        } else {
            return AddCommas(Math.round(value));
        }
    } else {
        return value;
    }
}

export function FormatSummaryData (accessor, value) {
    const valuesToIntegers = ['emissions', 'emissionReductions', 'ghg', 'ghgSaved', 'co2', 'co2Saved','coLbs', 'coLbsSaved', 'noxLbs', 'noxLbsSaved', 'mpg', 'fuelGallonsSaved', 'pm10Grams', 'pm10GramsSaved', 'pm25Grams', 'pm25GramsSaved','mileage', 'ch4', 'bioCO2', 'ch4Saved', 'bioCO2Saved'];
    const pieGraphValues = ['pieGraphData']
    const valuesToRoundedDollarValue = ['fuelCostSaved'];

    if (value === null || value === undefined) {
        return '-';
    } else if (valuesToIntegers.includes(accessor)) {
        if (value === 0) {
            return '0';
        } else if (value < 10) {
            return value < 0.1 ? '<0.1' : AddCommas((Math.round(value*10)/10).toFixed(1));
        } else {
            return AddCommas(Math.round(value));
        }
    //React Chart js does not handle values with commas. Need to be returned as a number.
    } else if (pieGraphValues.includes(accessor)) {
        value = Math.round(value * 10) / 10;
        return value;
    } else if (valuesToRoundedDollarValue.includes(accessor)) {
        value = Math.round(value);
        return `$${AddCommas(value)}`;
    } else {
        return AddCommas(value);
    }
}

export function CompileIntoWeeks(events, key, beginDate, endDate) {
    // Helper function to get the ISO week number for a given date
    const getISOWeekNumber = function(date) {
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNum = utcDate.getUTCDay() || 7;
        utcDate.setUTCDate(utcDate.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));
        return Math.ceil((((utcDate - yearStart) / 86400000) + 1) / 7);
    };

    // Helper function to get the Sunday (start date) of the week for a given date string
    const getWeekStartDate = function(dateString) {
        let date = new Date(Date.parse(dateString));
        let sunday = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNum = sunday.getUTCDay() || 7;
        sunday.setUTCDate(sunday.getUTCDate() + 1 - dayNum);
        return sunday;
    };

    // Object to store week aggregations, keyed by the week's Sunday ISO date string
    const weeklyAggregations = {};

    // Iterate through each day in the date range to identify unique week start dates
    let currentDate = new Date(beginDate);
    while (currentDate <= new Date(endDate)) {
        let weekStartDate = getWeekStartDate(currentDate);
        let weekNumber = getISOWeekNumber(weekStartDate);
        let weekStartKey = weekStartDate.toISOString().split('T')[0]; // ISO date string as key

        if (!weeklyAggregations[weekStartKey]) {
            weeklyAggregations[weekStartKey] = {
                woy: weekNumber,
                sunday: weekStartDate,
                [key]: 0 // Initialize the specified key to 0
            };
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Convert the weeklyAggregations object into an array for easier processing
    const weeklyAggregationArray = Object.values(weeklyAggregations);

    // Sort events by date for consistent aggregation
    const sortedEvents = events.sort(sortByDate);

    // Aggregate event values into the corresponding week objects
    sortedEvents.forEach(event => {
        let weekStartDate = getWeekStartDate(event.localized_date);
        let weekStartKey = weekStartDate.toISOString().split('T')[0];
        if (weeklyAggregations[weekStartKey]) {
            weeklyAggregations[weekStartKey][key] += event[key];
        }
    });

    return weeklyAggregationArray;
}

export function CompileIntoMonths(events, key, beginDate, endDate) {
    // Helper function to get the month and year in "MM/YYYY" format
    const getMonthYearString = function(dateString) {
        const date = new Date(Date.parse(dateString));
        return `${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const currentDate = new Date(beginDate);
    let uniqueMonthYears = [];

    // Iterate through each day within the date range to find unique months
    while (currentDate <= new Date(endDate)) {
        const monthYear = getMonthYearString(currentDate);
        if (!uniqueMonthYears.includes(monthYear)) {
            uniqueMonthYears.push(monthYear);
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Initialize an array to store month objects with aggregated values
    const monthlyAggregations = [];
    uniqueMonthYears.forEach(monthYear => {
        const month = {};
        month['moy'] = monthYear;
        month[key] = 0;
        monthlyAggregations.push(month)
    });

    // Sort events by date for consistent aggregation
    const sortedEvents = events.sort(sortByDate);

    // Aggregate event values into the corresponding month objects
    sortedEvents.forEach(event => {
        const eventMonthYear = getMonthYearString(event.localized_date);
        monthlyAggregations.forEach(monthAggregation => {
            if (monthAggregation.moy === eventMonthYear) {
                monthAggregation[key] += event[key];
            }
        });
    });
    return monthlyAggregations;
}

export function formatMonthLabelShort(monthYear) {
    if (typeof (monthYear) === 'string') {
        const [month, year] = monthYear.split("/");
        const zeroBasedMonth = parseInt(month) - 1; // Subtract 1 from the month (0-based date creation, but the index coming in is 1-based)
        const date = new Date(year, zeroBasedMonth, 1); // Create a Date object
        const options = { year: '2-digit', month: '2-digit' };
        return date.toLocaleDateString('en-US', options); // Output example: "10/24"
    } else return null;
}

export function formatMonthLabelLong(monthYear) {
    if (typeof (monthYear) === 'string') {
        const [month, year] = monthYear.split("/");
        const zeroBasedMonth = parseInt(month) - 1;
        const date = new Date(year, zeroBasedMonth, 1); // Create a Date object
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options); // Output example: "January 2024"
    } else return null;
}

export function formatWeekLabelShort(week) {
    if (week instanceof Date) {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
        return week.toLocaleDateString('en-US', options); // Output example: "01/07/24"
    } else return null;
}

export function formatWeekLabelLong(week) {
    if (week instanceof Date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return week.toLocaleDateString('en-US', options); // Output example: "January 7, 2024"
    } else return null;
}

export function filterDataTypes(data, dataTypes) {
    const filteredTypes = dataTypes.filter(d => data.find(e => e[d.id] !== 0 && e[d.id] !== undefined && e[d.id] !== null));
    let totalNumber = 0;

    if (data?.[0]) filteredTypes.forEach(t => totalNumber += data[0][t.id]);
    return [filteredTypes, totalNumber];
}

export function sanitizeDownloadString(str) {
    // Replace all forward slashes, underscores, and spaces with dashes
    const replaceWithDashes = str.replace(/[\s/_]/g, "-");
    // Remove non-alphanumeric characters, excluding dashes
    const sanitized = replaceWithDashes.replace(/[^a-zA-Z0-9-]/g, "");
    return sanitized;
}

export function compileHomeViewGraphData(graphData, viewDataByMonth, splitByDrivetrain, showDiscreteData) {
    let homeViewGraphData = [];

    if (viewDataByMonth) {
        graphData["monthlyLabels"]?.forEach((value, index) => {
            let newMonth = { month: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default)
                newMonth['gasolineGenerated'] = graphData[showDiscreteData ? "monthlyGasolineGHG" : "monthlyTotalGasolineGHG"][index];
                newMonth['dieselGenerated'] = graphData[showDiscreteData ? "monthlyDieselGHG" : "monthlyTotalDieselGHG"][index];
                newMonth['cngGenerated'] = graphData[showDiscreteData ? "monthlyCNGGHG" : "monthlyTotalCNGGHG"][index];
                newMonth['bevGenerated'] = graphData[showDiscreteData ? "monthlyBevGHG" : "monthlyTotalBevGHG"][index];
                newMonth['phevGenerated'] = graphData[showDiscreteData ? "monthlyPhevGHG" : "monthlyTotalPhevGHG"][index];
                newMonth['bevAvoided'] = -1 * graphData[showDiscreteData ? "monthlyBevGHGReduction" : "monthlyTotalBevGHGReduction"][index];
                newMonth['phevAvoided'] = -1 * graphData[showDiscreteData ? "monthlyPhevGHGReduction" : "monthlyTotalPhevGHGReduction"][index];
            } else {
                newMonth['generated'] = graphData[showDiscreteData ? "monthlyGHG" : "monthlyTotalEmissions"][index];
                newMonth['avoided'] = -1 * graphData[showDiscreteData ? "monthlyGHGReduction" : "totalMonthlyEmissionReductions"][index];
            }
            homeViewGraphData.push(newMonth);
        });
    } else {
        graphData["weeklyLabels"]?.forEach((value, index) => {
            let newWeek = { week: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default) 
                newWeek['gasolineGenerated'] = graphData[showDiscreteData ? "weeklyGasolineGHG" : "weeklyTotalGasolineGHG"][index];
                newWeek['dieselGenerated'] = graphData[showDiscreteData ? "weeklyDieselGHG" : "weeklyTotalDieselGHG"][index];
                newWeek['cngGenerated'] = graphData[showDiscreteData ? "weeklyCNGGHG" : "weeklyTotalCNGGHG"][index];
                newWeek['bevGenerated'] = graphData[showDiscreteData ? "weeklyBevGHG" : "weeklyTotalBevGHG"][index];
                newWeek['phevGenerated'] = graphData[showDiscreteData ? "weeklyPhevGHG" : "weeklyTotalPhevGHG"][index];
                newWeek['bevAvoided'] = -1 * graphData[showDiscreteData ? "weeklyBevGHGReduction" : "weeklyTotalBevGHGReduction"][index];
                newWeek['phevAvoided'] = -1 * graphData[showDiscreteData ? "weeklyPhevGHGReduction" : "weeklyTotalPhevGHGReduction"][index];
            } else {
                newWeek['generated'] = graphData[showDiscreteData ? "weeklyGHG" : "weeklyTotalEmissions"][index];
                newWeek['avoided'] = -1 * graphData[showDiscreteData ? "weeklyGHGReduction" : "totalWeeklyEmissionReductions"][index];
            }
            homeViewGraphData.push(newWeek);
        });
    }

    return homeViewGraphData;
}

export function compileGeneratedEmissionsViewGraphData(graphData, viewDataByMonth, splitByDrivetrain, showDiscreteData) {
    let generatedViewGraphData = [];

    if (viewDataByMonth) {

        graphData["monthlyLabels"]?.forEach((value, index) => {
            let newMonth = { month: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default)
                newMonth['gasolineGenerated'] = graphData[showDiscreteData ? "monthlyGasolineGHG" : "monthlyTotalGasolineGHG"][index];
                newMonth['dieselGenerated'] = graphData[showDiscreteData ? "monthlyDieselGHG" : "monthlyTotalDieselGHG"][index];
                newMonth['bevGenerated'] = graphData[showDiscreteData ? "monthlyBevGHG" : "monthlyTotalBevGHG"][index];
                newMonth['phevGenerated'] = graphData[showDiscreteData ? "monthlyPhevGHG" : "monthlyTotalPhevGHG"][index];
                newMonth['cngGenerated'] = graphData[showDiscreteData ? "monthlyCNGGHG" : "monthlyTotalCNGGHG"][index];
            } else {
                newMonth['generated'] = graphData[showDiscreteData ? "monthlyGHG" : "monthlyTotalEmissions"][index];
            }
            generatedViewGraphData.push(newMonth);
        });
    } else {
        graphData["weeklyLabels"]?.forEach((value, index) => {
            let newWeek = { week: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default) 
                newWeek['gasolineGenerated'] = graphData[showDiscreteData ? "weeklyGasolineGHG" : "weeklyTotalGasolineGHG"][index];
                newWeek['dieselGenerated'] = graphData[showDiscreteData ? "weeklyDieselGHG" : "weeklyTotalDieselGHG"][index];
                newWeek['bevGenerated'] = graphData[showDiscreteData ? "weeklyBevGHG" : "weeklyTotalBevGHG"][index];
                newWeek['phevGenerated'] = graphData[showDiscreteData ? "weeklyPhevGHG" : "weeklyTotalPhevGHG"][index];
                newWeek['cngGenerated'] = graphData[showDiscreteData ? "weeklyCNGGHG" : "weeklyTotalCNGGHG"][index];
            } else {
                newWeek['generated'] = graphData[showDiscreteData ? "weeklyGHG" : "weeklyTotalEmissions"][index];
            }
            generatedViewGraphData.push(newWeek);
        });
    }
    return generatedViewGraphData;
}

export function compileAvoidedEmissionsViewGraphData(graphData, viewDataByMonth, splitByDrivetrain, showDiscreteData) {
    let avoidedViewGraphData = [];

    if (viewDataByMonth) {
        graphData["monthlyLabels"]?.forEach((value, index) => {
            let newMonth = { month: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default)
                newMonth['bevAvoided'] = graphData[showDiscreteData ? "monthlyBevGHGReduction" : "monthlyTotalBevGHGReduction"][index];
                newMonth['phevAvoided'] = graphData[showDiscreteData ? "monthlyPhevGHGReduction" : "monthlyTotalPhevGHGReduction"][index];
            } else {
                newMonth['avoided'] = graphData[showDiscreteData ? "monthlyGHGReduction" : "totalMonthlyEmissionReductions"][index];
            }
            avoidedViewGraphData.push(newMonth);
        });
    } else {
        graphData["weeklyLabels"]?.forEach((value, index) => {
            let newWeek = { week: value }

            if (splitByDrivetrain) { // aggregate data simply by generated vs. avoided, if not splitting by drivetrain (default) 
                newWeek['bevAvoided'] = graphData[showDiscreteData ? "weeklyBevGHGReduction" : "weeklyTotalBevGHGReduction"][index];
                newWeek['phevAvoided'] = graphData[showDiscreteData ? "weeklyPhevGHGReduction" : "weeklyTotalPhevGHGReduction"][index];
            } else {
                newWeek['avoided'] = graphData[showDiscreteData ? "weeklyGHGReduction" : "totalWeeklyEmissionReductions"][index];
            }
            avoidedViewGraphData.push(newWeek);
        });
    }
    return avoidedViewGraphData;
}

export const compileFleetCompositionCardData = (emissionsSummary) => [
    { breakdown: true, gasoline: emissionsSummary.ice, diesel: emissionsSummary.diesel, bev: emissionsSummary.bev, phev: emissionsSummary.phev, cng: emissionsSummary.cng },
];

export const compileFleetMileageBreakdownData = (emissionsSummary) => [{
    breakdown: true,
    trad: emissionsSummary.tradFuelMiles,
    alt: emissionsSummary.altFuelMiles
}];

export const compileGeneratedEmissionsByDrivetrainData = (emissionsSummary, units) => {
    const bevEmissions = units === UNITS_POUNDS ? emissionsSummary.bevGHGLbs : emissionsSummary.bevGHGTons;
    const phevEmissions = units === UNITS_POUNDS ? emissionsSummary.phevGHGLbs : emissionsSummary.phevGHGTons;
    const gasolineEmissions = units === UNITS_POUNDS ? emissionsSummary.gasolineGHGLbs : emissionsSummary.gasolineGHGTons;
    const dieselEmissions = units === UNITS_POUNDS ? emissionsSummary.dieselGHGLbs : emissionsSummary.dieselGHGTons;
    const cngEmissions = units === UNITS_POUNDS ? emissionsSummary.cngGHGLbs : emissionsSummary.cngGHGTons;

    return [
        { breakdown: true, gasoline: gasolineEmissions, diesel: dieselEmissions, bev: bevEmissions, phev: phevEmissions, cng: cngEmissions },
    ];
}

export const compileDistanceBreakdownByDrivetrainData = (emissionsSummary) => [
    { breakdown: true, gasoline: emissionsSummary.gasolineMiles, diesel: emissionsSummary.dieselMiles, bev: emissionsSummary.bevMiles, phev: emissionsSummary.phevMiles, cng: emissionsSummary.cngMiles }
];

export const compileAltFuelCompositionCardData = (emissionsSummary) => [
    { breakdown: true, bev: emissionsSummary.bev, phev: emissionsSummary.phev, cng: emissionsSummary.cng }
]

export const compileDistanceBreakdownByAltDrivetrainData = (emissionsSummary) => [
    { breakdown: true, bev: emissionsSummary.bevMiles, phev: emissionsSummary.phevMiles, cng: emissionsSummary.cngMiles }
]

export const compileAvoidedEmissionsByDrivetrainData = (emissionsSummary, units) => {
    const bevEmissionsAvoided = units === UNITS_POUNDS ? emissionsSummary.bevGHGLbsSaved : emissionsSummary.bevGHGTonsSaved;
    const phevEmissionsAvoided = units === UNITS_POUNDS ? emissionsSummary.phevGHGLbsSaved : emissionsSummary.phevGHGTonsSaved;

    return [
        { breakdown: true, bev: bevEmissionsAvoided, phev: phevEmissionsAvoided }
    ];
}

// TODO: Remove the below copy once CNG emissions reduction calculation is added to the product, and remove the tooltip from the avoided emissions page in the browser & PDF.
export const cngWarningTooltipCopy = (isPdf = false) => {
  let copy =  'Support for avoided emissions from CNG vehicle operations is coming soon. Avoided emissions data for these vehicles is not currently reflected in the graph';
  if (!isPdf) copy += ', table,';
  copy += ' and statistics.';
  return copy;
}
  

export function buildDownloadFilename(dbDisplayName, type) {
    let fileName = "sawatch-labs-emit-";
    if (type === AVOIDED_TABLE_TYPE) {
        fileName += "avoided-";
    }
    else if (type === GENERATED_TABLE_TYPE) {
        fileName += "generated-";
    }
    
    fileName += `emissions-report-${dbDisplayName}-${(new Date()).toLocaleDateString()}`;
    fileName = sanitizeDownloadString(fileName);
    fileName += type === PDF_TYPE ? '.pdf' : '.xlsx';

    return fileName;
}

export function hasAltFuelVehicles(allVehicleData) {
    return allVehicleData.filter((d) => d.is_phev === true || d.is_bev === true || d.is_cng === true).length > 0;
}