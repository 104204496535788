import styled from 'styled-components';
import { dark_gray, darkest_gray, off_black } from '../../styles/emit-styles/ColorScheme';
import { InfoCardStyled } from '../../styles/emit-styles/CardStyles';

const Value = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${off_black};
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${darkest_gray};
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${darkest_gray};
`;

const Note = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${dark_gray};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
`;

const InfoCard = ({ primaryValuePrefix, primaryValue, primaryLabel, subtitle, note, height, width, minWidth, maxWidth }) => {
  return (
    <InfoCardStyled
      height={height}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      disableSelection
    >
        <Subtitle>{subtitle}</Subtitle>
        <TitleWrapper>
            <Value>{primaryValuePrefix}{primaryValue}</Value>
            <Label>{primaryLabel}</Label>
        </TitleWrapper>
      {note && <Note>{note}</Note>}
    </InfoCardStyled>
  );
};

export default InfoCard;