import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import { usePDF } from '@react-pdf/renderer';
import LoadingIndeterminate from './emit-components/LoadingIndeterminate';
import AboutView from './emit-components/AboutView';
import EmitProcessor from './emit-components/emit-processor';
import ProductHeader from './emit-components/ProductHeader';
import "../component-css/emit-stylesheets/swt-emit.css";
import PdfReport from './emit-components/PdfReport';
import { CompileIntoWeeks, CompileIntoMonths, hasAltFuelVehicles } from './emit-components/UtilityFunctions';
import NavBar from './emit-components/NavBar';
import { ContentContainer, ProductWrapper } from '../styles/emit-styles/Emit-styles';
import * as S from '../styles/emit-styles/Emit-styles';
import HomeView from './emit-components/HomeView';
import { alt_fuels_color, alt_fuels_color_hover, bev_color, bev_color_hover, blue_green, blue_green_hover, diesel_color, diesel_color_hover, gasoline_color, gasoline_color_hover, cng_color, cng_color_hover, ice_fuels_color, ice_fuels_color_hover, phev_color, phev_color_hover, rust_red, rust_red_hover } from "../styles/emit-styles/ColorScheme";
import GeneratedEmissionsView from './emit-components/GeneratedEmissionsView';
import AvoidedEmissionsView from './emit-components/AvoidedEmissionsView';

// ** MACROS **

const POUNDS2TONS = function (lbs) { return lbs * 0.0005 };
const LITERS2GALS = function (liters) { return liters * 0.264172 };
const KM2MILES = function (km) { return km * 0.621371 };
const POUNDS2GRAMS = function (lbs) { return lbs * 453.592 };
//const GRAMS2POUNDS = function (grams) { return grams * 0.00220462 };

let default_fuel_cost = 4;

//constants
const ALL_VEHICLES_GROUP = "swt-vehicles";
// const USE_MONTHS_BREAKPOINT = 5;
export const UNITS_POUNDS = "lbs";
export const UNITS_TONS = "tons";
export const UNITS_GRAMS = "g";
export const UNITS_GALLONS = "gals";

export const AVOIDED_TABLE_TYPE = 'avoidedTable';
export const GENERATED_TABLE_TYPE = 'generatedTable';
export const PDF_TYPE = 'pdfReport';

export const altDrivetrains = [
  { id: "bev", label: "BEV", longLabel: "Battery Electric", color: bev_color, colorHover: bev_color_hover },
  { id: "phev", label: "PHEV", longLabel: "Plug-In Hybrid Electric", color: phev_color, colorHover: phev_color_hover },
  { id: "cng", label: "CNG", longLabel: "Compressed Natural Gas", color: cng_color, colorHover: cng_color_hover },
];

export const fleetDrivetrains = [
  { id: "gasoline", label: "Gasoline", color: gasoline_color, colorHover: gasoline_color_hover },
  { id: "diesel", label: "Diesel", color: diesel_color, colorHover: diesel_color_hover },
  ...altDrivetrains
];

export const fleetCondensedDrivetrains = [
  { id: "trad", label: "Traditional Fuel", color: ice_fuels_color, colorHover: ice_fuels_color_hover },
  { id: "alt", label: "Alternative Fuel", color: alt_fuels_color, colorHover: alt_fuels_color_hover },
];

export const combinedEmissionsBars = [
  {
    id: "generated",
    label: "Generated Emissions",
    color: rust_red,
    hoverColor: rust_red_hover,
  },
  {
    id: "avoided",
    label: "Avoided Emissions",
    color: blue_green,
    hoverColor: blue_green_hover,
    valueMultipler: -1
  }
]

export const emissionsByDrivetrainBars = [
  {
    id: "gasolineGenerated",
    label: "Gasoline",
    color: gasoline_color,
    hoverColor: gasoline_color_hover,
  },
  {
    id: "dieselGenerated",
    label: "Diesel",
    color: diesel_color,
    hoverColor: diesel_color_hover,
  },
  {
    id: "bevGenerated",
    label: "BEV",
    color: bev_color,
    hoverColor: bev_color_hover,
  },
  {
    id: "phevGenerated",
    label: "PHEV",
    color: phev_color,
    hoverColor: phev_color_hover,
  },
  {
    id: "cngGenerated",
    label: "CNG",
    color: cng_color,
    hoverColor: cng_color_hover,
  },
  {
    id: "bevAvoided",
    label: "BEV",
    color: bev_color,
    hoverColor: bev_color_hover,
    valueMultipler: -1
  },
  {
    id: "phevAvoided",
    label: "PHEV",
    color: phev_color,
    hoverColor: phev_color_hover,
    valueMultipler: -1
  }
]

export default function Emit(props) {
  const { user, apiURL, dbName, basename, dbDisplayName, secrets } = props;

  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [groups, _setGroups] = useState([]);
  const [group, _setGroup] = useState(null);
  const [groupDisplayName, setGroupDisplayName] = useState('Loading...')
  const [vehicleClasses, _setVehicleClasses] = useState([]);
  const [emissionData, _setEmissionData] = useState(null);
  const [entireFleetVehicleData, setEntireFleetVehicleData] = useState([]);
  const [calcByFuel, _setCalcByFuel] = useState(true);
  const [displayInLbs, _setDisplayInLbs] = useState(false);
  const [settings, _setSettings] = useState([]);
  const [idleData, _setIdleData] = useState([]);
  const [graphData, _setGraphData] = useState(null);
  const [selectedVehicleClasses, _setSelectedVehicleClasses] = useState([undefined]);
  const [matchingData, setMatchingData] = useState([]);
  const [matchingAltFuelData, setMatchingAltFuelData] = useState([])
  const [altFuelData, setAltFuelData] = useState(null);
  const [showEmissionsByDrivetrain, setShowEmissionsByDrivetrain] = useState(false);
  const [emissionsSummary, setEmissionsSummary] = useState([]);
  const [methodology, setMethodology] = useState({ value: 'moves3', label: 'MOVES3' }) //2 options: { value: 'ghgprotocol_2.0.0', label: 'GHG Protocol 2.0.0'} & { value: 'moves3', label: 'MOVES3' }
  const [isTd, setIsTd] = useState();
  const [hasAltFuels, setHasAltFuels] = useState(null);


  const showMonths = useMemo(() => {
    // Logic is sound but we are turning off weekly data display for now - JJ 3/3/25
    // if (graphData && graphData?.monthlyStartDates?.length >= USE_MONTHS_BREAKPOINT) return true;
    // return false;
    return true;
    //eslint-disable-next-line
  }, [graphData]);

  const req = useMemo(() => {
    return {
      user: user,
      apiURL: apiURL,
      emitApi: secrets.emitApi,
      dbName: dbName,
      beginDate: beginDate,
      endDate: endDate,
      group: group,
      selectedVehicleClasses: selectedVehicleClasses,
    }
  }, [dbName, apiURL, beginDate, endDate, user, group, selectedVehicleClasses, secrets]);

  useEffect(() => {
    _getBounds(req, function (bounds) {
      setBeginDate(bounds.beginDate);
      setEndDate(bounds.endDate);
      setMinDate(bounds.beginDate);
      setMaxDate(bounds.endDate)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let r = {
      user: user, dbName: dbName, apiURL: apiURL
    }
    const handleGroups = (groups) => { _setGroup(groups[0].id); _setGroups(groups) };
    _getGroups(r, handleGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _getSettings(req, function (data) { _setSettings(data); default_fuel_cost = data.fuel_cost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _getVehicleClasses(req, function (data) { _setSelectedVehicleClasses(data); _setVehicleClasses(data.slice()) });
    _getEntireFleetVehicleData(req, setEntireFleetVehicleData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      //Check to see if there are alt fuel vehicles in entire fleet
      setHasAltFuels(hasAltFuelVehicles(entireFleetVehicleData));
  }, [entireFleetVehicleData])

  useEffect(() => {
    setIsTd(settings.is_td);
  },[settings])

  const _conformEmissionData = async (data, setDataCallback) => {
    let conformedData = [];
    let altFuelConformedData = [];
    if (data.length > 0 && data[0] !== null) {

      //Filter out equipment
      conformedData = data.filter(r => (
        typeof r.vehicle_class !== "undefined" &&
        r.vehicle_class !== 'Equipment'
      ));
      //Filter for selectedVehicleClasses
      conformedData = _filterForSelectedClasses(req, data);

      //preprocessVehicleList
      const processor = new EmitProcessor(req, methodology.value, settings);
      processor.preprocessVehicleList(conformedData, methodology.value)
        .then((res) => {
          processor.setPreprocessedVehicles(res);
          //calc emissions values
          conformedData = conformedData.map(async (r) => {

            let result = res.find((v) =>  v.vin === r.vin);
            r['bioCO2Lbs'] = result['bioCO2Lbs'];
            r['ch4Lbs'] = result['ch4Lbs'];
            r['soxLbs'] = result['soxLbs'];
            r['vocLbs']= result['vocLbs'];
            r['pm10(tbw)Lbs'] = result['pm10(tbw)Lbs'];
            r['pm25(tbw)Lbs'] = result['pm25(tbw)Lbs']
            r.fuel_cost = r.fuel_cost ? r.fuel_cost : default_fuel_cost;

            // Addition of uuid is to allow search matching in the event that vehicles don't have VINs
            // These will change on every render but searching is still stable
            // NOTE: In place to accommodate FX util DBs - LS 3/26/25
            r.uuid = crypto.randomUUID();
            r.backcasted_fuel_cost = r.backcasted_fuel_cost ? r.backcasted_fuel_cost : default_fuel_cost;

            //TODO this is duplicated in emit-processor and should be consolidated
            if (!r.is_diesel && !r.is_bev && !r.is_phev && !r.is_propane && !r.is_cng) r.is_gasoline = true;
            r = await processor.calculateEmissions(r, { calcByFuel: calcByFuel });
            if (!isFinite(r.emit) && r.km !== 0) r.emit = 100;
            //Assign vehicle fuel type text value
            if (r.is_gasoline) r.drivetrain = 'Gasoline';
            if (r.is_diesel) r.drivetrain = 'Diesel';
            if (r.is_bev) r.drivetrain = 'BEV';
            if (r.is_phev) r.drivetrain = 'PHEV';
            if (r.is_cng) r.drivetrain = 'CNG';
            r.vin = (r.user_defined_vin && r.user_defined_vin !== 'null') ? r.user_defined_vin : r.vin;
            r.user_defined_vin = (r.user_defined_vin || r.user_defined_vin === 'null') ? r.user_defined_vin : r.vin;
            return r;
          });
          Promise.all(conformedData).then((res) => {
            //Filter PHEV/BEV/CNG data for alt fuel detail tab.
            altFuelConformedData = res.filter((d) => d.is_phev || d.is_bev || d.is_cng);
            if (altFuelConformedData) setAltFuelData(altFuelConformedData)
            if (setDataCallback) setDataCallback(res);
          });
        });
    } else {
      setDataCallback([])
    }
  }

  function getProductData() {
    if (beginDate && endDate && group && selectedVehicleClasses && calcByFuel) {
      //getting the idle data out of daily summary and into the
      //table data ep requires a join Matt can't get to work accurately
      //so in the interests of getting it all working, we have two fetch calls
      _setEmissionData(null); //Setting emission data to null will activate loading bars until _getEmissionData function is complete. 
      _getIdleData(req, _setIdleData);
      _getEmissionData(req, (data) => { _conformEmissionData(data, _setEmissionData); });
    }
  }

  useEffect(() => {
    if(beginDate && endDate && group && selectedVehicleClasses && calcByFuel && emissionsSummary){
      _getGraphData(req, displayInLbs, settings, emissionsSummary, _setGraphData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emissionsSummary]);

  useEffect(() => {
    getProductData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleClasses, groups, beginDate, endDate]);

  const mappedData = useMemo(() => {
    if (!emissionData) { return null }
    const immutableData = JSON.parse(JSON.stringify(emissionData))
    const matchedData = matchingData.map((d) => { return d.uuid })
    return immutableData.filter((d) => {
      if (matchedData.indexOf(d.uuid) < 0) { return null }
      return d
    })
  }, [emissionData, matchingData])

  const mappedAltFuelData = useMemo(() => {
    if (!altFuelData) { return null }
    const immutableData = JSON.parse(JSON.stringify(altFuelData))
    const matchedData = matchingAltFuelData.map((d) => { return d.uuid })

    return immutableData.filter((d) => {
      if (matchedData.indexOf(d.uuid) < 0) { return null }
      return d;
    })

  }, [altFuelData, matchingAltFuelData]);

  const handleNoDataMessageText = useCallback((tab, type) => {
    let noDataMessage = '';
    let pdfReportNoDataMessage = ''
    if (tab === 'home' || tab === 'generated-emissions') {
      noDataMessage = 'No data to display. Try adjusting your selected date range or filters.'
      pdfReportNoDataMessage = 'No data to display. Try adjusting your selected date range or filters.'
    }
    //No data messaging for alternative fuel vehicle related tabs.
    if (tab === 'avoided-emissions') {
      if (!hasAltFuels) {
        noDataMessage = 'No alternative fuel vehicles in this fleet.'
        pdfReportNoDataMessage = 'There are no alternative fuel vehicles in this fleet. If you have questions, please contact your administrator.'
      } else if (!altFuelData || altFuelData.length < 1) {
        noDataMessage = 'No alternative fuel vehicle data to display. Try adjusting your selected date range or filters.'
        pdfReportNoDataMessage = 'There is no alternative fuel vehicle data based on your current filters. Try adjusting your date range or filters to determine your emission reductions.'
      } else {
        noDataMessage = 'No data to display. Try adjusting your selected date range or filters.'
        pdfReportNoDataMessage = 'No data to display. Try adjusting your selected date range or filters.'
      }
    }
    if (type === PDF_TYPE) {
      return pdfReportNoDataMessage
    } else {
      return noDataMessage
    }
  }, [altFuelData, hasAltFuels])

  const dataDisplayCheck = useCallback((tab) => {
    if (graphData === null || graphData.length < 1) {
      return false
    } else {
      if (tab === 'home') {
        if (graphData.monthlyGHG.every((d) => d === 0) && graphData.monthlyGHGReduction.every((d) => d === 0)) {
          return false;
        } else {
          return true;
        }
      }
      if(tab === 'generated-emissions') {
        if (graphData.monthlyGHG.every((d) => d === 0)) {
          return false;
        } else {
          return true;
        }
      }
      if (tab === 'avoided-emissions') {
        if (graphData.monthlyGHGReduction.every((d) => d === 0)) {
          return false;
        } else {
          return true;
        }
      }
    }
  }, [graphData])

  useEffect(() => {
    let dateRange = '';
    if (beginDate !== null && endDate !== null) dateRange = `${beginDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`;
    _setGraphData(null); //Graph data is refetched after emissionsSummary is updated. Setting graphData to null keeps loading indicator on screen until all data is loaded.
    const processor = new EmitProcessor(req, null, settings);
    const summary = processor.summarizeEmissions(emissionData, idleData, settings, dateRange);
    setEmissionsSummary(summary);
  },[emissionData, idleData, settings, beginDate, endDate, calcByFuel, req])

  const [instance, updateInstance] = usePDF({
    document: _pdfReport(emissionsSummary, graphData, 
      { selectedVehicleClasses: selectedVehicleClasses, vehicleClasses: vehicleClasses, group: groupDisplayName, displayInLbs: displayInLbs, methodology: methodology },
      handleNoDataMessageText,
      dataDisplayCheck,
      showMonths,
      isTd,
      hasAltFuels,
      dbDisplayName
    )
  })

  useEffect(() => {
    // Our current handling of state makes it necessary to provide all params via this call to updateInstance
    // Ezev does not do this but it also uses a setTimeout hook to seemingly wait for the state to be correct, which is not ideal
    // This ensures that the necessary data is provided to the PDF on every render
    if(emissionsSummary && graphData) {
    updateInstance(_pdfReport(emissionsSummary, graphData,
      { selectedVehicleClasses: selectedVehicleClasses, vehicleClasses: vehicleClasses, group: groupDisplayName, displayInLbs: displayInLbs, methodology: methodology },
      handleNoDataMessageText,
      dataDisplayCheck,
      showMonths,
      isTd,
      hasAltFuels,
      dbDisplayName
    ))
  }
    //eslint-disable-next-line
  }, [displayInLbs, showEmissionsByDrivetrain, selectedVehicleClasses, groupDisplayName, dataDisplayCheck, handleNoDataMessageText, vehicleClasses, showMonths, methodology]);

  useEffect(() => {
    let selectedGroup = groups.find((g) => { return g.id === group })
    if (selectedGroup) { setGroupDisplayName(selectedGroup.name) }
  }, [group, groups])


  useEffect(() => {
    getProductData()

    //eslint-disable-next-line
  }, [group, showEmissionsByDrivetrain, selectedVehicleClasses, displayInLbs, methodology])

  return (
    <ProductWrapper>
      <Router basename={basename}>
        <ProductHeader fleetDisplayName={dbDisplayName} />
        <NavBar
          dataLoading={!emissionData || !graphData || selectedVehicleClasses[0] === undefined || group === null}
          maxDate={maxDate}
          minDate={minDate}
          beginDate={beginDate}
          endDate={endDate}
          handleBeginDateChange={(d) => setBeginDate(d)}
          handleEndDateChange={(d) => setEndDate(d)}
          vehicleClasses={vehicleClasses}
          selectedVehicleClasses={selectedVehicleClasses}
          group={group ? group : ''}
          groups={groups}
          handleUnitChange={(u) => _setDisplayInLbs(u)}
          handleGroupChange={(g) => _setGroup(g)}
          handleModelChange={(v) => _setCalcByFuel(v)}
          handleClassChange={(c) => _setSelectedVehicleClasses(c)}
          toggleShowEmissionsByDrivetrain={(t) => setShowEmissionsByDrivetrain(t)}
          calcByFuel={calcByFuel}
          displayInLbs={displayInLbs}
          showEmissionsByDrivetrain={showEmissionsByDrivetrain}
          downloadUrl={instance.url}
          dbDisplayName={dbDisplayName}
          methodology={methodology}
          setMethodology={setMethodology}
        />
        <ContentContainer>
          {(!emissionData || !graphData || !emissionsSummary) ? <LoadingIndeterminate/> :
            <Switch>
              <Route exact path="/emit">
                <Redirect to={`/emit/home`} />
              </Route>
              <Route exact path="/emit/home">
                {(dataDisplayCheck('home') === false) ?
                  <S.NoDataMessage>{handleNoDataMessageText('home')}</S.NoDataMessage>
                  :
                  <HomeView
                    emissionsSummary={emissionsSummary}
                    displayInLbs={displayInLbs}
                    graphData={graphData}
                    defaultToMonthView={showMonths}
                    hasAltFuels={hasAltFuels}
                  />
                }
              </Route>
              <Route exact path='/emit/generated-emissions'>
                {(dataDisplayCheck('generated-emissions') === false) ?
                  <S.NoDataMessage>{handleNoDataMessageText('generated-emissions')}</S.NoDataMessage>
                  :
                  <GeneratedEmissionsView
                    emissionsSummary={emissionsSummary}
                    displayInLbs={displayInLbs}
                    beginDate={beginDate}
                    endDate={endDate}
                    mappedData={mappedData}
                    rawData={emissionData}
                    graphData={graphData}
                    group={group}
                    groups={groups}
                    selectedVehicleClasses={selectedVehicleClasses}
                    vehicleClasses={vehicleClasses}
                    dbDisplayName={dbDisplayName}
                    handleNoDataMessageText={(t) => handleNoDataMessageText(t)}
                    setMatchingValues={setMatchingData}
                    defaultToMonthView={showMonths}
                    methodology={methodology}
                    isTd={isTd}
                  />
                }
              </Route>
              <Route exact path='/emit/avoided-emissions'>
                {(dataDisplayCheck('avoided-emissions') === false) ?
                  <S.NoDataMessage>{handleNoDataMessageText('avoided-emissions')}</S.NoDataMessage>
                  :
                  <AvoidedEmissionsView
                    emissionsSummary={emissionsSummary}
                    displayInLbs={displayInLbs}
                    beginDate={beginDate}
                    endDate={endDate}
                    mappedData={mappedAltFuelData}
                    rawData={altFuelData}
                    graphData={graphData}
                    group={group}
                    groups={groups}
                    selectedVehicleClasses={selectedVehicleClasses}
                    vehicleClasses={vehicleClasses}
                    dbDisplayName={dbDisplayName}
                    handleNoDataMessageText={(t) => handleNoDataMessageText(t)}
                    setMatchingValues={setMatchingAltFuelData}
                    defaultToMonthView={showMonths}
                    methodology={methodology}
                    isTd={isTd}
                  />
                }
              </Route>
              <Route exact path="/emit/about">
                <AboutView methodology={methodology} isTd={isTd} />
              </Route>
            </Switch>}
        </ContentContainer>
      </Router>
    </ProductWrapper>
  );
}

// ** PRIVATE FUNCTIONS **

function _pdfReport(emissionsSummary, graphData, controlsData, noDataMessage, dataDisplayCheck, showMonths, isTd, hasAltFuels, dbDisplayName) {
  return (
    <PdfReport
      selectedVehicleClasses={controlsData.selectedVehicleClasses}
      vehicleClasses={controlsData.vehicleClasses}
      displayInLbs={controlsData.displayInLbs}
      group={controlsData.group}
      emissionsSummary={emissionsSummary}
      handleNoDataMessageText={noDataMessage}
      dataDisplayCheck={dataDisplayCheck}
      viewDataByMonth={showMonths}
      graphData={graphData}
      methodology={controlsData.methodology}
      isTd={isTd}
      hasAltFuels={hasAltFuels}
      dbDisplayName={dbDisplayName}
    />
  );
}

function _getBounds(req, cb) {
  if (req.beginDate || req.endDate) return;
  fetch(`${req.apiURL}getBounds?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        const begin = new Date(data.data[0].min)
        const end = new Date(data.data[0].max)
        if (cb) cb({ beginDate: begin, endDate: end });
      }
    });
}

function _getGroups(req, cb) {
  const url = `${req.apiURL}getGroups?dbName=${req.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        const idx = data.data.findIndex(g => g.id === ALL_VEHICLES_GROUP);
        if (idx > -1) {
          const obj = data.data[idx];
          data.data.splice(idx, 1);
          data.data.unshift(obj);
        };
        cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}


function _getGraphData(req, displayInLbs, settings, emissionsSummary, cb) {
  const processor = new EmitProcessor(req, null, settings)

  function calculateTotalEmissions(data) {
    let total = 0;
    let totalArr = [];
    data.forEach((item) => {
      total += item;
      totalArr.push(total)
    })
    return totalArr;
  }

  function cumulativeTotalTieOut(array, tableTerminalValue){
    const arrTermination = Math.max(...array);
    //if the table value is less than the graph value 
    if(tableTerminalValue <= arrTermination){
      return array.map((a) => {return a <= tableTerminalValue ? a : tableTerminalValue});
    }
    //if the table term value is greater than the array, plus up array term
    return array.map((a) => {
      if(a <= tableTerminalValue && a < arrTermination)return a;
      if(a >= arrTermination) return tableTerminalValue;
      return a;
    });
  }

  function calculateDailyReductions(reductionsArray0, reductionsArray1) {
    let totalArray = [];
    let total = 0;
    for (let i = 0; i < reductionsArray0.length; i++) {
      let value = reductionsArray0[i];
      if (reductionsArray1) value = (reductionsArray0[i] + reductionsArray1[i]);
      total += value;
      totalArray.push(total);
    }
    return totalArray
  }

  //Set state back to null before fetch to render loading indicator.
  if (cb) cb(null)
  fetch(`${req.apiURL}emitGraphData?dbName=${req.dbName}&group=${req.group}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}&selectedVehicleClasses=${req.selectedVehicleClasses}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      // These checks should be reviewed in the future once the API ep is updated and returns a single response type when there is no data. 
      let dataCheck = data.data.every((d) => d.fu_liters === 0 && d.km === 0)
      if (data.data.length === 0 || dataCheck) {
        if (cb) cb([]);
      } else {
        
        const graphData = {}

        const graphGasolineLiters = data.data.reduce((sum, x)=> sum+x.gasoline_liters, 0);
        const graphDieselLiters = data.data.reduce((sum, x) => sum + x.diesel_liters, 0);
        const graphPhevLiters = data.data.reduce((sum, x) => sum + x.phev_liters, 0);
        const graphBevLiters = data.data.reduce((sum, x) => sum + x.bev_liter_reduction, 0);
        const graphCNGLiters = data.data.reduce((sum, x) => sum + x.cng_liters, 0);
        
        const graphBevKWH = data.data.reduce((sum, x) => sum + x.bev_kwh, 0);
        const graphPhevKWH = data.data.reduce((sum, x) => sum + x.phev_kwh, 0);

        //TODO These values are likely to be NaN or Infinity for some fuel types with minimal or 0 travel yet
        // those conditions are handled in the functions that consume cof values
        //TODO Write QA/QC Tests for these dumb cof patterns
        const gasolineCof = emissionsSummary.gasolineGHGLbs / LITERS2GALS(graphGasolineLiters);
        const dieselCof = emissionsSummary.dieselGHGLbs / LITERS2GALS(graphDieselLiters);
        const phevCof = emissionsSummary.phevCO2LbsGasoline / LITERS2GALS(graphPhevLiters);
        const cngCof = emissionsSummary.cngGHGLbs / LITERS2GALS(graphCNGLiters);
        const bevCof = (emissionsSummary.bevGHGLbs+emissionsSummary.bevGHGLbsSaved) / LITERS2GALS(graphBevLiters);
        
        const bevImpliedGHGPerKwh = POUNDS2GRAMS(emissionsSummary.bevGHGLbs) / graphBevKWH;
        const phevImpliedGHGPerKwh = POUNDS2GRAMS(emissionsSummary.phevCO2LbsKWHGeneration) / graphPhevKWH;

        graphData.bevGHG = data.data.map((f) => { return (processor.kwhToGHGLbs(f.bev_kwh, bevImpliedGHGPerKwh)) });
        graphData.gasolineGHG = data.data.map((f) => { return (processor.co2LbsPerLiter(f.gasoline_liters, gasolineCof)) })
        graphData.dieselGHG = data.data.map((f) => { return (processor.co2LbsPerLiterDiesel(f.diesel_liters, dieselCof)) })

        //there is single CNG cof in the emission calc, so doesn't have the blending problem of bev, gas & diesel.
        //including cng cof or completeness
        graphData.cngGHG = data.data.map((f) => { return (processor.co2LbsPerLiterCNG(f.cng_liters, cngCof))})

        graphData.phevGHG = data.data.map((f) => { return (processor.co2LbsPerLiter(f.phev_liters, phevCof) + processor.kwhToGHGLbs(f.phev_kwh, phevImpliedGHGPerKwh)) });

        graphData.phevGHGReduction = data.data.map((f) => { return (processor.co2LbsPerLiterAvoided(f.phev_liter_reduction, phevCof) - processor.kwhToGHGLbs(f.phev_kwh, phevImpliedGHGPerKwh)) });
        graphData.bevGHGReduction = data.data.map((f) => { return (processor.co2LbsPerLiterAvoided(f.bev_liter_reduction, bevCof) - processor.kwhToGHGLbs(f.bev_kwh, bevImpliedGHGPerKwh)) });
        graphData.totalGHGReduction = graphData.bevGHGReduction.map((f, idx) => { return f + graphData.phevGHGReduction[idx] })

        graphData.ghg = [];
        const ghgObjs = [];
        for (let i = 0; i < graphData.gasolineGHG.length; i++) {
          graphData.ghg[i] = parseFloat(graphData.bevGHG[i]) + parseFloat(graphData.gasolineGHG[i]) + parseFloat(graphData.dieselGHG[i]) + parseFloat(graphData.phevGHG[i] + parseFloat(graphData.cngGHG[i]));
          ghgObjs.push({ localized_date: data.data[i].localized_date, ghg: graphData.ghg[i], ghgReduction: graphData.totalGHGReduction[i], phevGHG: graphData.phevGHG[i], phevGHGReduction: graphData.phevGHGReduction[i], gasolineGHG: graphData.gasolineGHG[i], bevGHG: graphData.bevGHG[i], bevGHGReduction: graphData.bevGHGReduction[i], dieselGHG: graphData.dieselGHG[i], cngGHG: graphData.cngGHG[i] })
        }
        const weeklyGHG = CompileIntoWeeks(ghgObjs, 'ghg', req.beginDate, req.endDate);
        const weeklyGHGReduction = CompileIntoWeeks(ghgObjs, 'ghgReduction', req.beginDate, req.endDate);
        const monthlyGHG = CompileIntoMonths(ghgObjs, 'ghg', req.beginDate, req.endDate);
        const monthlyGHGReduction = CompileIntoMonths(ghgObjs, 'ghgReduction', req.beginDate, req.endDate);
        //PHEV*****
        const weeklyPhevGHG = CompileIntoWeeks(ghgObjs, 'phevGHG', req.beginDate, req.endDate)
        const weeklyPhevGHGReduction = CompileIntoWeeks(ghgObjs, 'phevGHGReduction', req.beginDate, req.endDate)
        const monthlyPhevGHG = CompileIntoMonths(ghgObjs, 'phevGHG', req.beginDate, req.endDate)
        const monthlyPhevGHGReduction = CompileIntoMonths(ghgObjs, 'phevGHGReduction', req.beginDate, req.endDate)
        //BEV*********
        const weeklyBevGHG = CompileIntoWeeks(ghgObjs, 'bevGHG', req.beginDate, req.endDate)
        const weeklyBevGHGReduction = CompileIntoWeeks(ghgObjs, 'bevGHGReduction', req.beginDate, req.endDate)
        const monthlyBevGHG = CompileIntoMonths(ghgObjs, 'bevGHG', req.beginDate, req.endDate)
        const monthlyBevGHGReduction = CompileIntoMonths(ghgObjs, 'bevGHGReduction', req.beginDate, req.endDate)
        //GASOLINE******
        const weeklyGasolineGHG = CompileIntoWeeks(ghgObjs, 'gasolineGHG', req.beginDate, req.endDate)
        const monthlyGasolineGHG = CompileIntoMonths(ghgObjs, 'gasolineGHG', req.beginDate, req.endDate)
        //DIESEL********
        const weeklyDieselGHG = CompileIntoWeeks(ghgObjs, 'dieselGHG', req.beginDate, req.endDate)
        const monthlyDieselGHG = CompileIntoMonths(ghgObjs, 'dieselGHG', req.beginDate, req.endDate)
        //CNG********
        const weeklyCNGGHG = CompileIntoWeeks(ghgObjs, 'cngGHG', req.beginDate, req.endDate)
        const monthlyCNGGHG = CompileIntoMonths(ghgObjs, 'cngGHG', req.beginDate, req.endDate)

        //GASOLINE*******
        graphData.weeklyGasolineGHG = weeklyGasolineGHG.map((g) => { return g.gasolineGHG })
        graphData.monthlyGasolineGHG = monthlyGasolineGHG.map((g) => { return g.gasolineGHG })
        //BEV*********
        graphData.weeklyBevGHG = weeklyBevGHG.map((g) => { return g.bevGHG })
        graphData.weeklyBevGHGReduction = weeklyBevGHGReduction.map((g) => { return g.bevGHGReduction })
        graphData.monthlyBevGHG = monthlyBevGHG.map((g) => { return g.bevGHG })
        graphData.monthlyBevGHGReduction = monthlyBevGHGReduction.map((g) => { return g.bevGHGReduction })
        //PHEV***********
        graphData.weeklyPhevGHG = weeklyPhevGHG.map((g) => { return g.phevGHG })
        graphData.weeklyPhevGHGReduction = weeklyPhevGHGReduction.map((g) => { return g.phevGHGReduction })
        graphData.monthlyPhevGHG = monthlyPhevGHG.map((g) => { return g.phevGHG })
        graphData.monthlyPhevGHGReduction = monthlyPhevGHGReduction.map((g) => { return g.phevGHGReduction })
        //Diesel*******
        graphData.weeklyDieselGHG = weeklyDieselGHG.map((g) => { return g.dieselGHG })
        graphData.monthlyDieselGHG = monthlyDieselGHG.map((g) => { return g.dieselGHG })
        //CNG*******
        graphData.weeklyCNGGHG = weeklyCNGGHG.map((g) => { return g.cngGHG })
        graphData.monthlyCNGGHG = monthlyCNGGHG.map((g) => { return g.cngGHG })

        graphData.weeklyGHG = weeklyGHG.map((g) => { return g.ghg });
        graphData.weeklyGHGReduction = weeklyGHGReduction.map((g) => { return g.ghgReduction });

        graphData.monthlyGHG = monthlyGHG.map((g) => { return g.ghg });
        graphData.monthlyGHGReduction = monthlyGHGReduction.map((g) => { return g.ghgReduction });

        graphData.weeklyStartDates = weeklyGHG.map((g) => { return g.sunday });
        graphData.monthlyStartDates = monthlyGHG.map((g) => { return g.moy });

        graphData.miles = data.data.map((f) => { return (KM2MILES(f.km)) });
        graphData.ratio = data.data.map((f) => { return (f.fu_liters_actual / f.km) });
        graphData.dailyLabels = data.data.map((f) => {
          let s = f.localized_date.split('T');
          return s[0]
        });
        graphData.weeklyLabels = graphData.weeklyStartDates;
        graphData.monthlyLabels = graphData.monthlyStartDates;


        if (displayInLbs === false) {
          graphData.phevGHGReduction = graphData.phevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.bevGHGReduction = graphData.bevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.totalGHGReduction = graphData.totalGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.gasolineGHG = graphData.gasolineGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.dieselGHG = graphData.dieselGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.cngGHG = graphData.cngGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.phevGHG = graphData.phevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.bevGHG = graphData.bevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });

          graphData.ghg = [];
          for (let i = 0; i < graphData.gasolineGHG.length; i++) {
            graphData.ghg[i] = parseFloat(graphData.bevGHG[i]) + parseFloat(graphData.gasolineGHG[i]) + parseFloat(graphData.cngGHG[i]) + parseFloat(graphData.dieselGHG[i]) + parseFloat(graphData.phevGHG[i]);
          }
          graphData.weeklyGHG = graphData.weeklyGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.weeklyGHGReduction = graphData.weeklyGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.monthlyGHG = graphData.monthlyGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          graphData.monthlyGHGReduction = graphData.monthlyGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) });
          //PHEV******
          graphData.weeklyPhevGHG = graphData.weeklyPhevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.weeklyPhevGHGReduction = graphData.weeklyPhevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyPhevGHG = graphData.monthlyPhevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyPhevGHGReduction = graphData.monthlyPhevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          //GASOLINE******
          graphData.weeklyGasolineGHG = graphData.weeklyGasolineGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyGasolineGHG = graphData.monthlyGasolineGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          //BEV*********
          graphData.weeklyBevGHG = graphData.weeklyBevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.weeklyBevGHGReduction = graphData.weeklyBevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyBevGHG = graphData.monthlyBevGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyBevGHGReduction = graphData.monthlyBevGHGReduction.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          //DIESEL********
          graphData.weeklyDieselGHG = graphData.weeklyDieselGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyDieselGHG = graphData.monthlyDieselGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          //CNG********
          graphData.weeklyCNGGHG = graphData.weeklyCNGGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })
          graphData.monthlyCNGGHG = graphData.monthlyCNGGHG.map((g) => { return POUNDS2TONS(parseFloat(g)) })

        }

        graphData.dailyTotalEmissions = calculateTotalEmissions(graphData.ghg);
        graphData.weeklyTotalEmissions = calculateTotalEmissions(graphData.weeklyGHG);
        graphData.monthlyTotalEmissions = calculateTotalEmissions(graphData.monthlyGHG);

        graphData.weeklyTotalPhevGHG = calculateTotalEmissions(graphData.weeklyPhevGHG)
        graphData.weeklyTotalPhevGHGReduction = calculateTotalEmissions(graphData.weeklyPhevGHGReduction)
        graphData.monthlyTotalPhevGHG = calculateTotalEmissions(graphData.monthlyPhevGHG)
        graphData.monthlyTotalPhevGHGReduction = calculateTotalEmissions(graphData.monthlyPhevGHGReduction)
       
        graphData.weeklyTotalBevGHG = calculateTotalEmissions(graphData.weeklyBevGHG)
        graphData.weeklyTotalBevGHGReduction = calculateTotalEmissions(graphData.weeklyBevGHGReduction)
        graphData.monthlyTotalBevGHG = calculateTotalEmissions(graphData.monthlyBevGHG)
        graphData.monthlyTotalBevGHGReduction = calculateTotalEmissions(graphData.monthlyBevGHGReduction)

        graphData.weeklyTotalGasolineGHG = calculateTotalEmissions(graphData.weeklyGasolineGHG)
        graphData.monthlyTotalGasolineGHG = calculateTotalEmissions(graphData.monthlyGasolineGHG)

        graphData.weeklyTotalCNGGHG = calculateTotalEmissions(graphData.weeklyCNGGHG)
        graphData.monthlyTotalCNGGHG = calculateTotalEmissions(graphData.monthlyCNGGHG)

        graphData.weeklyTotalDieselGHG = calculateTotalEmissions(graphData.weeklyDieselGHG)
        graphData.monthlyTotalDieselGHG = calculateTotalEmissions(graphData.monthlyDieselGHG)

        graphData.totalDailyEmissionReductions = calculateDailyReductions(graphData.phevGHGReduction, graphData.bevGHGReduction);
        graphData.totalWeeklyEmissionReductions = calculateDailyReductions(graphData.weeklyGHGReduction);
        graphData.totalMonthlyEmissionReductions = calculateDailyReductions(graphData.monthlyGHGReduction);
        
        //use the cumulative tie out function to make sure the terminal values on our cumulative
        //graphs always match our table values(despite rounding and bucketing differences)
        graphData.dailyTotalEmissions = cumulativeTotalTieOut(graphData.dailyTotalEmissions, displayInLbs ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons);
        graphData.weeklyTotalEmissions = cumulativeTotalTieOut(graphData.weeklyTotalEmissions, displayInLbs ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons);
        graphData.monthlyTotalEmissions = cumulativeTotalTieOut(graphData.monthlyTotalEmissions, displayInLbs ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons);

        graphData.totalDailyEmissionReductions = cumulativeTotalTieOut(graphData.totalDailyEmissionReductions, displayInLbs ? emissionsSummary.ghgLbsSaved : emissionsSummary.ghgTonsSaved);
        graphData.totalWeeklyEmissionReductions = cumulativeTotalTieOut(graphData.totalWeeklyEmissionReductions, displayInLbs ? emissionsSummary.ghgLbsSaved : emissionsSummary.ghgTonsSaved);
        graphData.totalMonthlyEmissionReductions = cumulativeTotalTieOut(graphData.totalMonthlyEmissionReductions, displayInLbs ? emissionsSummary.ghgLbsSaved : emissionsSummary.ghgTonsSaved);

        if (cb) cb(graphData);
      }
    })
}

function _getVehicleClasses(req, cb) {
  fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        let arr = data.data.filter((d) => {return (d.vehicle_class !== '' && d.vehicle_class !== null)}).map((e) => {
          return e.vehicle_class
        });
        if (cb) cb(arr);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _filterForSelectedClasses(req, data) {
  let arr = [];
  //if the component returned a "false" empty list(call has not finished but nothing is selected)
  if (typeof req.selectedVehicleClasses[0] === "undefined") {
    arr = [];
    // arr = data.data
  }
  //if there are zero or more selections
  else {
    data.forEach((e) => {
      if (req.selectedVehicleClasses.indexOf(e.vehicle_class) > -1) {
        arr.push(e);
      }
    });
  }
  return arr;
}

function _getEmissionData(req, cb) {
  fetch(`${req.apiURL}emitDataForFleet?dbName=${req.dbName}&group=${req.group}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        if (cb) cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

//Used to get all vehicles in fleet with no control filters which is needed for the handling of the no data messages.
function _getEntireFleetVehicleData(req, cb) {
  fetch(`${req.apiURL}emitDataForFleet?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        if (cb) cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getIdleData(req, cb) {
  fetch(`${req.apiURL}idleDataForFleet?dbName=${req.dbName}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getSettings(req, cb) {
  fetch(`${req.apiURL}getSettings?dbName=${req.dbName}`,
    { headers: { Authorization: `Bearer ${req.user.token}` } })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        cb(data.data[0]);
      }
    })
    .catch((error) => console.error("Error: " + error));
}

function _formatDateParams(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}