const KMS_TO_MILES = 0.621371;
const MILES_TO_KMS = 1.60934;
const LITERS_TO_GALS = 0.264172;
const GALS_TO_LITERS = 3.78541;
const LBS_TO_KGS = 0.453592;
const KGS_TO_LBS = 2.20462;
const TONS_TO_METRIC_TONS = 0.907185;
const METRIC_TONS_TO_TONS = 1.10231;
const MPG_TO_KMPL = 0.4251437;
const KMPL_TO_MPG = 2.352145;
// these values are correct - converting fuel cost per gal to per liter and vv
const PER_GAL_TO_PER_LITER = 0.264172
const PER_LITER_TO_PER_GAL = 3.785412;
// these values are correct - converting maint cost per mile to per km and vv
const PER_MILE_TO_PER_KM = 0.621371;
const PER_KM_TO_PER_MILE = 1.60934

const dollarValues = ['insurance', 'forecasted_insurance', 'backcasted_insurance', 'scc', 'forecasted_scc', 'backcasted_scc', 'tco', 'rec_lt_net_savings',
                      'rec_lt_ops_savings', 'rec_yearly_tco', 'rec_yr_tco_reduction',
                      'comp_ice_yearly_cost', 'est_current_yearly_tco', 'est_yearly_fuel_cost',
                      'net_price', 'maint_per_km', 'forecasted_maint_per_km', 'backcasted_maint_per_km',
                      'est_current_yearly_ops_cost', 'yearly_cost_ev',
                      'fuel_cost', 'forecasted_fuel_cost', 'backcasted_fuel_cost', 'forecasted_kwh_cost', 'backcasted_kwh_cost',
                      'ice_repair_per15k', 'ev_repair_per15k', 'phev_repair_per15k', 'yearly_insurance',
                      'local_kwh_cost', 'price', 'phev_purchase_price', 'bev_purchase_price', 'ice_purchase_price'];
const mileVals = ['ice_repair_per15k', 'ev_repair_per15k', 'phev_repair_per15k'];
const volumeVals = ['fuel_cost', 'forecasted_fuel_cost', 'backcasted_fuel_cost'];
const nestedObjs = ['upfits', 'required_upfits', 'optional_upfits'];
const liquidFuelEcon = ['mpg_c', 'mpg_h'];
const perKmVals = ['maint_per_km', 'forecasted_maint_per_km', 'backcasted_maint_per_km'];
const tonVals = ['scc', 'forecasted_scc', 'backcasted_scc'];

const mileConstraints = ['bevRepairPer15k', 'iceRepairPer15k', 'phevRepairPer15k'];
const kmConstraints = ['evYrKmTarget'];
const tonConstraints = ['scc'];
const volumeConstraints = ['fuelCost'];
const currencyConstraints = ['fuelCost', 'localKwhCost', 'scc', 'yearlyInsurance', 'bevRepairPer15k', 'iceRepairPer15k', 'phevRepairPer15k'];

export function processSettingsConstraints(userSettings, resp) {
    if(!resp)return;
    Object.keys(resp).forEach((k) => {
        if(mileConstraints.includes(k)) {
            let min = formatPricePerMile(userSettings, resp[k].MIN);
            let max = formatPricePerMile(userSettings, resp[k].MAX);
            resp[k].MIN = min;
            resp[k].MAX = max;
        }
        if(kmConstraints.includes(k)) {
            let min = formatDistance(userSettings, resp[k].MIN);
            let max = formatDistance(userSettings, resp[k].MAX);
            resp[k].MIN = min;
            resp[k].MAX = max;
        }
        if(tonConstraints.includes(k)) {
            let min = formatTons(userSettings, resp[k].MIN);
            let max = formatTons(userSettings, resp[k].MAX);
            resp[k].MIN = min;
            resp[k].MAX = max;
        }
        if(volumeConstraints.includes(k)) {
            let min = formatPricePerGal(userSettings, resp[k].MIN);
            let max = formatPricePerGal(userSettings, resp[k].MAX);
            resp[k].MIN = min;
            resp[k].MAX = max;
        }
        if(currencyConstraints.includes(k)) {
            let min = formatCurrency(userSettings, resp[k].MIN);
            let max = formatCurrency(userSettings, resp[k].MAX);
            resp[k].MIN = min;
            resp[k].MAX = max;
        }
    })
    return resp;
}

export function processApiResponse(userSettings, resp, processCurrency = true) {
    if(!resp)return;
    Object.keys(resp).forEach((k) => {
        if (mileVals.includes(k)) {
            let val = formatPricePerMile(userSettings, resp[k])
            resp[k] = val;
        }
        if(liquidFuelEcon.includes(k)) {
            let fuelEcon = formatLiquidFuelEcon(userSettings, resp[k])
            resp[k] = fuelEcon
        }
        
        if (volumeVals.includes(k)) {
            let val = formatPricePerGal(userSettings, resp[k]);
            resp[k] = val;
        }
        if(tonVals.includes(k)) {
            let scc = formatTons(userSettings, resp[k])
            resp[k] = scc;
        }
        if(k.includes('km')){
            if (perKmVals.includes(k)) { // Special case, km in denominator so apply inverse
                let val = formatPricePerKm(userSettings, resp[k]);
                resp[k] = val;
            }
            else {
                let dist = formatDistance(userSettings, resp[k])
                resp[k] = dist
            }
        }
        if(k.includes('celsius')){
            let temp = formatTemperature(userSettings, resp[k])
            resp[k] = temp
        }
        if(k.includes('ts')){
            let ts = formatTimestamp(userSettings, resp[k])
            resp[k] = ts
        }
        if(k.includes('liters')){
            // 'volumeVals' list makes this confusing
            let lq = formatLtrs(userSettings, resp[k])
            resp[k] = lq
        }
        if(k.includes('gal')){
            // eg rec_lt_gals_saved, rec_fuel_reduction_gal
            let lq = formatGals(userSettings, resp[k])
            resp[k] = lq
        }
        if(processCurrency && dollarValues.includes(k)){
            let cost = formatCurrency(userSettings, resp[k])
            resp[k] = cost
        }
        if(k.includes('lbs') || k.includes('weight')){
            let weight = formatLbs(userSettings, resp[k])
            resp[k] = weight
        }
        if(nestedObjs.includes(k)){
            // TEMPORARILY deal with arrays of objects - only applies to upfits
            // DISCUSS pattern for this and refactor out
            resp[k].forEach((upfit) => {
                Object.keys(upfit).forEach((u) => {
                    if(processCurrency && u === "price"){
                        let cost = formatCurrency(userSettings, upfit[u])
                        upfit[u] = cost
                    }
                    if(u === "weight"){
                        let w = formatLbs(userSettings, upfit[u])
                        upfit[u] = w
                    }
                })
            })
        }
    })
    return resp;
  }

function formatTemperature(settings, value){
    if(value === null || value === undefined) return value;
    // Need this to be a numeric value for use throughout product
    if(settings.use_celsius)return parseFloat(value);
    return celsiusToFahrenheit(value)
}

function formatDistance(settings, value){
    if(!value)return value;
    if(settings.use_kms)return value;
    return kmsToMiles(value)
}

function formatPricePerKm(settings, value) {
    if(!value)return value;
    if(settings.use_kms)return value;
    return pricePerKmToPerMile(value);
}

export function formatPricePerMile(settings, value) {
    if(!value)return value;
    if(!settings.use_kms)return value;
    return pricePerMileToPerKm(value)
}

function formatLiquidFuelEcon(settings, value) {
    // mpg to user selected units
    if(!value) return value;
    if(settings.liquid_fuel_economy === 'km/gallon') return milesToKms(value)
    if(settings.liquid_fuel_economy === 'MPG') return value
    if(settings.liquid_fuel_economy === 'km/L') return mpgToKmPerLiter(value)
    return value
}

function militaryToStandardHours(value) {
    if (value === 0)
        return 12;
    if (value > 12) {
        return value - 12;
    }
    return value;
}

function amPm(hour) {
    return hour > 11 && hour < 24 ? 'pm' : 'am'
}

function addLeadingZero(value) {
    if (value > 9) return value;
    else return `0${value}`;
}

function formatDate(settings, value) {
    switch(settings.date_display){
        case 'dd/MM/yy':
            return `${('0' + value.getDate()).slice(-2)}/${('0' + (value.getMonth()+1)).slice(-2)}/${value.getFullYear().toString().substring(2, 4)}`;
        case 'dd/MM/yyyy':
            return `${('0' + value.getDate()).slice(-2)}/${('0' + (value.getMonth()+1)).slice(-2)}/${value.getFullYear()}`;
        case 'MM/dd/yy':
            return `${('0' + (value.getMonth()+1)).slice(-2)}/${('0' + value.getDate()).slice(-2)}/${value.getFullYear().toString().substring(2, 4)}`;
        case 'MM/dd/yyyy':
            return `${('0' + (value.getMonth()+1)).slice(-2)}/${('0' + value.getDate()).slice(-2)}/${value.getFullYear()}`;
        case 'yy/MM/dd':
            return `${value.getFullYear().toString().substring(2, 4)}/${('0' + (value.getMonth()+1)).slice(-2)}/${('0' + value.getDate()).slice(-2)}`;
        case 'yyyy/MM/dd':
            return `${value.getFullYear()}/${('0' + (value.getMonth()+1)).slice(-2)}/${('0' + value.getDate()).slice(-2)}`;
        default:
            return `${('0' + (value.getMonth()+1)).slice(-2)}/${('0' + value.getDate()).slice(-2)}/${value.getFullYear().toString().substring(2, 4)}`;
    }
}

function formatTime(settings, value) {
    switch(settings.ts_display){
        case "hh:mm:ss xm":
            return `${addLeadingZero(militaryToStandardHours(value.getHours()))}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())} ${amPm(value.getHours())}`
        case "hh:mm:ss":
            return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())}`;
        case "hh:mm xm":
            return `${addLeadingZero(militaryToStandardHours(value.getHours()))}:${addLeadingZero(value.getMinutes())} ${amPm(value.getHours())}` 
        case "hh:mm":
            return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}`;
        default:
            return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())}`;
    }
}

export function formatTimestamp(settings, value){
    if(!value)return value;
    let validDate = null;
    if (value instanceof Date) {
        validDate = value;
    } else if (Date.parse(value)) {
        validDate = new Date(value);
    } else {
        return value;
    }

    const date = formatDate(settings, validDate);
    const time = formatTime(settings, validDate);

    return {"date": date, "time": time};
}

function formatLtrs(settings, value){
    if(!value)return value;
    if(settings.use_liters)return value;
    return ltrsToGals(value)
}

function formatGals(settings, value){
    if(!value)return value;
    if(!settings.use_liters)return value;
    return galsToLtrs(value)
}

function formatPricePerGal(settings, value) {
    if(!value) return value;
    if(!settings.use_liters)return value;
    return pricePerGalToPerLiter(value);
}

function pricePerGalToPerLiter(value){
    return (value * PER_GAL_TO_PER_LITER);
}

function pricePerLiterToPerGallon(value){
    return (value * PER_LITER_TO_PER_GAL);
}

function pricePerMileToPerKm(value) {
    return (value * PER_MILE_TO_PER_KM);
}

function pricePerKmToPerMile(value) {
    return (value * PER_KM_TO_PER_MILE);
}

function formatCurrency(settings, value){
    if(!value)return value;
    if(settings.db_currency === settings.currency)return value;
    // if user currency !== db currency, convert using applicable conversion rate
    let conversion_rate = 1;
    if(settings.db_currency === 'EUR' && settings.currency === 'USD'){
        conversion_rate = settings.to_usd;
    }
    if(settings.db_currency === 'USD' && settings.currency === 'EUR'){
        conversion_rate = settings.to_eur;
    }
    return (value*conversion_rate);
}

function formatLbs(settings, value){
    if(!value)return value;
    if(settings.use_kgs)return lbsToKgs(value);
    return value;
}

function formatTons(settings, value){
    if(!value) return value;
    if(settings.use_kgs)return tonsToMetricTons(value);
    return value;
}

function celsiusToFahrenheit(value){
    return (value * 9/5) + 32
}

function kmsToMiles(value){
    return (value * KMS_TO_MILES)
}

function milesToKms(value) {
    return (value * MILES_TO_KMS);
}
function mpgToKmPerLiter(value){
    return (value * MPG_TO_KMPL)
}

function ltrsToGals(value){
    return (value * LITERS_TO_GALS)
}

function galsToLtrs(value){
    return (value * GALS_TO_LITERS)
}

function lbsToKgs(value){
    return (value * LBS_TO_KGS);
}

function kgsToLbs(value) {
    return (value * KGS_TO_LBS)
}

function tonsToMetricTons(value) {
    return (value * TONS_TO_METRIC_TONS);
}

function metricTonsToTons(value) {
    return (value * METRIC_TONS_TO_TONS);
}
function kmPerLiterToMpg(value){
    return (value * KMPL_TO_MPG)
}

export function conformPostRequest(settings, data) {
    data.forEach((d) => {
        Object.keys(d).forEach((k) => {
            if (liquidFuelEcon.includes(k)) {
                // converting back to MPG to save to db
                if (d[k]) {
                    if(settings.liquid_fuel_economy === 'km/gallon') d[k] = kmsToMiles(d[k])
                    if(settings.liquid_fuel_economy === 'MPG') d[k] = Number(d[k])
                    if(settings.liquid_fuel_economy === 'km/L') d[k] = kmPerLiterToMpg(d[k])
                }
            }
            if (k === 'weight' && settings.use_kgs) {
                let weight = kgsToLbs(d[k])
                d[k] = weight
            }
            if (k === 'tank_liters' && !settings.use_liters) {
                let val = galsToLtrs(d[k])
                d[k] = val
            }
            // why do the rest check for d[k] truthy first?
            if (d[k] && volumeVals.includes(k) && settings.use_liters) {
                let vol = pricePerLiterToPerGallon(d[k]);
                d[k] = vol;
            }
            if (d[k] && mileVals.includes(k) && settings.use_kms) {
                let dist = pricePerKmToPerMile(d[k]);
                d[k] = dist;
            }
            if (d[k] && tonVals.includes(k) && settings.use_kgs) {
                let weight = metricTonsToTons(d[k]);
                d[k] = weight;
            }
            if (d[k] && k.includes('km') && !settings.use_kms) {
                if (perKmVals.includes(k)) {
                    let val = pricePerMileToPerKm(d[k])
                    d[k] = val;
                }
                else {
                    let dist = milesToKms(d[k])
                    d[k] = dist;
                }
            }
            // Not covered vs conformUnits: liters (only covers tank_liters), gal,
            // lbs (only includes weight), celsius, ts, nested obj? (upfits)
            // dollar values (not needed while only core settings using this)
        })
    })
    return data
}