import { DateTime } from "luxon";
import { NO_CANDS_IN_CLASS_REC } from "../HelperFunctions";

const KMS_TO_MILES = 0.621371;
const MILES_TO_KMS = 1.60934;
const LITERS_TO_GALS = 0.264172;
const GALS_TO_LITERS = 3.78541;
const LBS_TO_KGS = 0.453592;
const LBS_TO_US_TONS = 0.0005;
const KGS_TO_METRIC_TONS = 0.001;
const TONS_TO_METRIC_TONS = 0.907185;
// this value is correct - converting fuel cost per gal to per liter
const PER_GAL_TO_PER_LITER = 0.264172
// this value is correct - converting maint cost per mile to per km and vv
const PER_MILE_TO_PER_KM = 0.621371;
const PER_KM_TO_PER_MILE = 1.60934

export function processApiResponse(userSettings, resp) {
    if(!resp)return resp;
    // Note: add PHEV maint per 15k to these lists when it is addded to product
    const dollarValues = ['insurance', 'scc', 'tco', 'rec_lt_net_savings',
                          'rec_lt_ops_savings', 'rec_yearly_tco', 'rec_yr_tco_reduction',
                          'comp_ice_yearly_cost', 'est_current_yearly_tco', 'est_yearly_fuel_cost',
                          'msrp', 'net_price', 'maint_per_km', 'expected_residual_value',
                          'est_current_yearly_ops_cost', 'yearly_cost_ev',
                          'fuel_cost', 'kwh_cost', 'forecasted_kwh_cost', 'peak_kwh_cost', 'offpeak_kwh_cost',
                          'yearly_insurance', 'local_kwh_cost', 'ice_repair_per15k', 'ev_repair_per15k', 'kwhRate'];
    const mileValues = ['ice_repair_per15k', 'ev_repair_per15k'];
    const perKmVals = ["maint_per_km"];
    const nestedObjs = ['upfits', 'required_upfits', 'optional_upfits'];

    Object.keys(resp).forEach((k) => {
        // if the vehicle has no candidates in class, the lifetime TCO savings needs to be converted to a nullish value now, for effective processing later on.
        // TODO: scope out feasibility/consequences of setting this value as null directly in the vehicle_summary table when analytics is run.
        if (k === "rec_lt_net_savings" && resp["recommendation"] === NO_CANDS_IN_CLASS_REC) {
            resp[k] = null;
        }
        if (mileValues.includes(k)) {
            let dist = formatPricePerMile(userSettings, resp[k]);
            resp[k] = dist;
        }
        if(k === 'fuel_cost') {
            let fuel_cost_liters = formatPricePerGal(userSettings, resp[k]);
            resp[k] = fuel_cost_liters;
        }
        if(k === 'scc') {
            let scc = formatTons(userSettings, resp[k])
            resp[k] = scc;
        }
        if(k.includes('celsius')){
            let temp = formatTemperature(userSettings, resp[k])
            resp[k] = temp
        }
        if(k.includes('km')){
            if(perKmVals.includes(k)) { // Special case, km in denominator so apply inverse
                let val = formatPricePerKm(userSettings, resp[k]);
                resp[k] = val;
            }
            else {
                let dist = formatDistance(userSettings, resp[k])
                resp[k] = dist
            }
        }
        if(k.includes('_ts')){

            let ts = formatTimestamp(userSettings, DateTime.fromISO(resp[k]).toUTC())
            resp[k] = ts
        }
        if(k.includes('liters')){
            let lq = formatLtrs(userSettings, resp[k])
            resp[k] = lq
        }
        if(k.includes('gal')){
            // eg rec_lt_gals_saved, rec_fuel_reduction_gal
            let lq = formatGals(userSettings, resp[k])
            resp[k] = lq
        }
        if(dollarValues.includes(k)){
            let cost = formatCurrency(userSettings, resp[k])
            resp[k] = cost
        }
        if(k.includes('lbs')){
            let weight = formatLbs(userSettings, resp[k])
            resp[k] = weight
        }
        if(nestedObjs.includes(k)){
            // TEMPORARILY deal with arrays of objects - only applies to upfits
            // DISCUSS pattern for this and refactor out
            resp[k].forEach((upfit) => {
                Object.keys(upfit).forEach((u) => {
                    if(u === "price"){
                        let cost = formatCurrency(userSettings, upfit[u])
                        upfit[u] = cost
                    }
                    if(u === "weight"){
                        let w = formatLbs(userSettings, upfit[u])
                        upfit[u] = w
                    }
                })
            })
        }
    })
    return resp;
  }

export function processRateTime(settings, rateObj) {
    switch(settings.ts_display) {
        case "hh:mm:ss xm":
            rateObj.startTimestamp = formatRateStandardTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTimeSeconds('stop', rateObj);
            break;
        case "hh:mm:ss":
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
            break;
        case "hh:mm xm":
            rateObj.startTimestamp = formatRateStandardTime('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTime('stop', rateObj);
            break;
        case "hh:mm":
            rateObj.startTimestamp = formatRateMilitaryTime('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTime('stop', rateObj);
            break;
        default:
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
    }
    return rateObj;
}

export function ghgConversionForSummary(userSettings,value) {
    if (!value) return value;
    if (userSettings.use_kgs) return kgsToMetricTons(value);
    return lbsToUsTons(value);
}

function formatRateMilitaryTime(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}`;
}

function formatRateMilitaryTimeSeconds(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}:00`;
}

function formatRateStandardTime(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])} ${amPm(rateObj[`${key}Hour`])}`;
}

function formatRateStandardTimeSeconds(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])}:00 ${amPm(rateObj[`${key}Hour`])}`;
}

function militaryToStandardHours(value) {
    if (value === 0)
        return 12;
    if (value > 12) {
        return value - 12;
    }
    return value;
}

function amPm(hour) {
    return hour > 11 && hour < 24 ? 'pm' : 'am'
}

function addLeadingZero(value) {
    if (value > 9) return value;
    else return `0${value}`;
}

function formatTemperature(settings, value){
    if(value === undefined || value === null)return value;
    // Need this to be a numeric value for use throughout product
    if(settings.use_celsius)return parseFloat(value);
    return celsiusToFahrenheit(value)
}

function formatDistance(settings, value){
    if(!value)return value;
    if(settings.use_kms)return value;
    return kmsToMiles(value)
}

function formatPricePerKm(settings, value) {
    if(!value)return value;
    if(settings.use_kms)return value;
    return pricePerKmToPerMile(value);
}

export function formatPricePerMile(settings, value) {
    if(!value)return value;
    if(!settings.use_kms)return value;
    return pricePerMileToPerKm(value)
}

export function formatDateForReport(value,settings) {
    // Function to display a prettified version of the provided date
    const date = DateTime.fromFormat(value, settings.date_display);
    if (!date.isValid) return null;

    let monthFirst = true;
    if ((settings.date_display === 'dd/MM/yy') || (settings.date_display === 'dd/MM/yyyy')) monthFirst = false;
    if (monthFirst) {
        // Format as "Month Day, YYYY"
        return `${date.toLocaleString({ month: "long" })} ${date.day}, ${date.year}`;
    } else {
        // Format as "Day Month, YYYY"
        return `${date.day} ${date.toLocaleString({ month: "long" })}, ${date.year}`;
    }
}

function formatDate(settings, value,excludeDay,forceShortYear) {
    // You can override the year display with forceShortYear (used in the SVP energy use graph labels)
    // Similarly, you can hide display of the day with excludeDay
    
    const day = ('0' + value.day).slice(-2);
    const month = ('0' + (value.month)).slice(-2);
    const shortYear = value.year.toString().substring(2, 4);
    const fullYear = value.year;

    switch(settings.date_display){
        case 'dd/MM/yy':
            return `${excludeDay ? "" : (day+"/")}${month}/${shortYear}`;
        case 'dd/MM/yyyy':
            return `${excludeDay ? "" : (day+"/")}${month}/${forceShortYear ? shortYear : fullYear}`;
        case 'MM/dd/yy':
            return `${month}/${excludeDay ? "" : (day+"/")}${shortYear}`;
        case 'MM/dd/yyyy':
            return `${month}/${excludeDay ? "" : (day+"/")}${forceShortYear ? shortYear : fullYear}`;
        case 'yy/MM/dd':
            return `${shortYear}/${month}${excludeDay ? "" : ("/"+day)}`;
        case 'yyyy/MM/dd':
            return `${forceShortYear ? shortYear : fullYear}/${month}${excludeDay ? "" : ("/"+day)}`;
        default:
            return `${month}/${excludeDay ? "" : (day+"/")}${shortYear}`;
    }
}

function formatTime(settings, value) {
    switch(settings.ts_display){
        case "hh:mm:ss xm":
            return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)} ${amPm(value.hour)}`
        case "hh:mm:ss":
            return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
        case "hh:mm xm":
            return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)} ${amPm(value.hour)}` 
        case "hh:mm":
            return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}`;
        default:
            return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
    }
}

// Format Luxon DateTime object to respect user settings
export function formatTimestamp(settings, value, excludeDay = false, forceShortYear = false){
    if(!value)return value;
    const date = formatDate(settings, value, excludeDay, forceShortYear);
    const time = formatTime(settings, value);

    return {"date": date, "time": time};
}

function formatLtrs(settings, value){
    if(!value)return value;
    if(settings.use_liters)return value;
    return ltrsToGals(value)
}

function formatGals(settings, value){
    if(!value)return value;
    if(!settings.use_liters)return value;
    return galsToLtrs(value)
}

function formatPricePerGal(settings, value) {
    if(!value) return value;
    if(!settings.use_liters)return value;
    return pricePerGalToPerLiter(value);
}

function formatCurrency(settings, value){
    if(!value)return value;
    if(settings.db_currency === settings.currency)return value;
    // if user currency !== db currency, convert using applicable conversion rate
    let conversion_rate = 1;
    if(settings.db_currency === 'EUR' && settings.currency === 'USD'){
        conversion_rate = settings.to_usd;
    }
    if(settings.db_currency === 'USD' && settings.currency === 'EUR'){
        conversion_rate = settings.to_eur;
    }
    return (value*conversion_rate);
}

function formatLbs(settings, value){
    if(!value)return value;
    if(settings.use_kgs)return lbsToKgs(value);
    return value;
}

function formatTons(settings, value){
    if(!value) return value;
    if(settings.use_kgs)return usTonsToMetricTons(value);
    return value;
}

function celsiusToFahrenheit(value){
    return (value * 9/5) + 32
}

function kmsToMiles(value){
    return (value * KMS_TO_MILES)
}

function milesToKms(value) {
    return (value * MILES_TO_KMS);
}

function ltrsToGals(value){
    return (value * LITERS_TO_GALS)
}

function galsToLtrs(value){
    return (value * GALS_TO_LITERS)
}

function pricePerGalToPerLiter(value){
    return (value * PER_GAL_TO_PER_LITER);
}

function pricePerMileToPerKm(value) {
    return (value * PER_MILE_TO_PER_KM);
}

function pricePerKmToPerMile(value) {
    return (value * PER_KM_TO_PER_MILE);
}

function lbsToKgs(value){
    return (value * LBS_TO_KGS);
}


function lbsToUsTons(value){
    return (value * LBS_TO_US_TONS);
}
function kgsToMetricTons(value){
    return (value * KGS_TO_METRIC_TONS);
}

function usTonsToMetricTons(value) {
    return (value * TONS_TO_METRIC_TONS);
}

export function conformPostRequest(settings, data) {
    data.forEach((c) => {
        Object.keys(c).forEach((k) => {
            if (k.includes('km')) {
                if (!settings.use_kms) {
                    let dist = milesToKms(c[k])
                    c[k] = dist;
                } 
            }
        })
    })
    return data
}