import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import emitLogo from '../../images/emit-light.png';
import SawatchLogo from '../../images/sawatch-logo-white.png';
import { sawatch_blue, white } from '../../styles/emit-styles/ColorScheme';

const styles = StyleSheet.create({
    headerContainer: {
        backgroundColor: sawatch_blue,
        position: 'absolute',
        height: 60,
        color: white,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100vw',
        paddingHorizontal: 25
    },
    logo: {
        width: 'auto',
        height: 20
    },
    headerTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    label:{
        fontSize: 20,
        fontWeight: 600
    },
    dbName: {
        fontSize: 16,
        fontWeight: 500
    },
    sawatchLogo: {
        height: 35,
        width: 'auto',
        marginBottom: 2
    },
    lhsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center'
    }
})

export default function PdfHeader({dbDisplayName}){
    return(
        <View style={styles.headerContainer} fixed>
            <View style={styles.lhsContainer}>
                <Image style={styles.logo} src={emitLogo} />
                <View style={styles.headerTextWrapper}>
                    <Text style={styles.label}>Emissions Report</Text>
                    {dbDisplayName && <Text style={styles.dbName}>{dbDisplayName}</Text>}
                </View>
            </View>
            <Image style={styles.sawatchLogo} src={SawatchLogo} alt='Sawatch Logo' />
        </View>
    )
}