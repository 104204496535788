import React from 'react';
import { FormatCommas } from './FormatAttributes';

export default function InfoCardInputs(props) {
  // TODO: specify the below inputs are fleet-level values, but vehicle-specific values override these when available
  // ie life_cycle, insurance, fuel_cost, kwh_cost (local_kwh_cost), ghg_kwh_gm, scc
  var repair = FormatCommas(props.inputs.ice_repair_per15k);
  var ev_maint = FormatCommas(props.inputs.ev_repair_per15k);
  var insurance = FormatCommas(props.inputs.yearly_insurance);

  return(
    <div className="ezev-information-card single">
      <div className="ezev-card-content">
        <p>Operational Input: <span data-testid="operationalInput-testid" className="ezEV-table-text-right-aligned">Fuel Transaction Data</span></p>
        <div className="ezev-divider"></div>
        <p>Gas Price: <span id="office_id" className="ezEV-table-text-right-aligned" data-testid="gas-price-value-testid">
            {props.inputs && props.inputs.fuel_cost &&
              `${props.userSettings.currency_symbol}${props.inputs.fuel_cost.toFixed(2)} per ${props.userSettings.use_liters ? 'liter' : 'gal'}`
            }
        </span></p>
        <div className="ezev-divider"></div>
        

        <p>GHG Emissions from Electricity Generation: <span className="ezEV-table-text-right-aligned">{FormatCommas(props.inputs.ghg_kwh_gm)} g/kWh</span></p>
        <div className="ezev-divider"></div>
        <p>Social Cost of Carbon: <span className="ezEV-table-text-right-aligned" data-testid="scc-value-testid">{props.userSettings.currency_symbol}{FormatCommas(props.inputs.scc)} per {props.userSettings.ton_labels.longSingular.toLowerCase()}</span></p>
        <div className="ezev-divider"></div>
        <p>ICE Maintenance Cost: <span className="ezEV-table-text-right-aligned" data-testid="ice-maint-value-testid">{props.userSettings.currency_symbol}{repair} per 15,000 {props.userSettings.distance_labels.longPlural.toLowerCase()}</span></p>
        <div className="ezev-divider"></div>
        <p>EV Maintenance Cost: <span className="ezEV-table-text-right-aligned" data-testid="ev-maint-value-testid">{props.userSettings.currency_symbol}{ev_maint} per 15,000 {props.userSettings.distance_labels.longPlural.toLowerCase()}</span></p>
        <div className="ezev-divider"></div>
        <p>Insurance: <span className="ezEV-table-text-right-aligned" data-testid="insurance-yearly-value-testid">{props.userSettings.currency_symbol}{insurance} per year</span></p>
        <div className="ezev-divider"></div>
        <p>Vehicle Lifecycle: <span className="ezEV-table-text-right-aligned">{props.inputs.life_cycle} years</span></p>
        <div className="ezev-divider"></div>
        <p>Charge To Light Duty: <span className="ezEV-table-text-right-aligned">{props.inputs.charge_to_ld}%</span></p>
        <div className="ezev-divider"></div>
        <p>Discharge To Light Duty: <span className="ezEV-table-text-right-aligned">{props.inputs.discharge_to_ld}%</span></p>
        <div className="ezev-divider"></div>
        <p>Charge To Medium/Heavy Duty: <span className="ezEV-table-text-right-aligned">{props.inputs.charge_to_mdhd}%</span></p>
        <div className="ezev-divider"></div>
        <p>Discharge To Medium/Heavy Duty: <span className="ezEV-table-text-right-aligned">{props.inputs.discharge_to_mdhd}%</span></p>
        <div className="ezev-divider"></div>
      </div>
    </div>
  );
}
