import React from 'react';
import { SMALL_RADIUS } from '../../styles/emit-styles/CardStyles';
import { BarChart, Bar, XAxis, YAxis, Legend } from "recharts";
import { filterDataTypes, FormatSummaryData } from './UtilityFunctions';
import { UNITS_POUNDS, UNITS_TONS } from '../swt-emit';
import ReactPDFChart from 'react-pdf-charts';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { cardSubtitleStyle, cardTitleStyle, cardTitleWrapperStyle, infoCardStyle } from '../../styles/emit-styles/PdfStylesheet';

const styles = StyleSheet.create({
    infoCard: infoCardStyle,
    cardTitle: cardTitleStyle,
    cardSubtitle: cardSubtitleStyle,
    cardTitleWrapper: cardTitleWrapperStyle
});

const PdfDistributionCard = ({ data, dataTypes, height, width, title, subtitle, dataType, displayInLbs }) => {

    const [filteredDataTypes] = filterDataTypes(data, dataTypes);

    let legendFontSize = null;
    if (filteredDataTypes.length >= 5) {
        legendFontSize = 6
    } else if (filteredDataTypes.length >= 4) {
        legendFontSize = 7;
    } else {
        legendFontSize = 8;
    }
    // TODO: Improve this simplistic solution which attemps to prevent the legend from overflowing.
    // Reduce the font size by half a point if displaying emisisons in pounds, or displaying mileage
    if ((displayInLbs && dataType.includes('Emissions')) || dataType.includes('Mileage')) {
        legendFontSize -= 0.5;
    }

    const customLegend = (entry) => {
        const { label } = entry;
        let count = data[0][entry.id]
        let formattedValue = null;
        let formattedLabel = null;

        if (dataType === "allVehicles" || dataType === "altFuelVehicles") {
            formattedValue = count;
        } else if (dataType === "generatedEmissions" || dataType === "avoidedEmissions") {
            formattedValue = FormatSummaryData('ghg', count);
            formattedLabel = displayInLbs ? UNITS_POUNDS : UNITS_TONS;
        } else if (dataType === "simplifiedMileage" || dataType === "allMileage" || dataType === "altFuelMileage") {
            formattedValue = FormatSummaryData('mileage', count);
            formattedLabel = 'mi';
        };
        return `${label} \n (${formattedValue}${formattedLabel ? ` ${formattedLabel}` : ''})`
    }

    const legendTextFormatter = (value) => {
        return (
            <span style={{ fontSize: legendFontSize }}>{value}</span>
        )
    }

    return (
        <View
            style={[styles.infoCard, { width: width, height: height }]}
        >
            <Text style={styles.cardTitleWrapper}>
                <Text style={styles.cardTitle}>{title}</Text> <Text style={styles.cardSubtitle}>{subtitle}</Text>
            </Text>
            <ReactPDFChart>
                <BarChart
                    width={width - 23} // I don't understand why 23 works exactly, but this ensures the bar takes up the full width of the card. Proabbly will need to be tweaked if padding on the info cards is ever adjusted.
                    height={15} // If this is set any higher the bar starts thinking he's somebody.
                    data={data}
                    layout='vertical'
                    margin={{ left: 0, top: 0, right: 0, bottom: 0 }}
                >
                    <XAxis
                        type='number'
                        domain={0} // ensure that the bars take the full width available to them
                        hide // don't show ticks
                    />
                    <YAxis
                        dataKey="breakdown"
                        type="category"
                        hide // don't show ticks
                    />
                    {filteredDataTypes.map((entry, index) => {
                        const elementCount = filteredDataTypes.length;
                        return (<Bar
                            isAnimationActive={false}
                            key={index}
                            dataKey={entry.id}
                            fill={entry.color}
                            radius={_calculateRadius(index, elementCount)}
                            name={customLegend(entry)}
                            stackId={0}
                            barSize={6}
                        />)
                    })}
                    <Legend
                        iconType='circle'
                        iconSize={8}
                        formatter={legendTextFormatter}
                    />
                </BarChart>
            </ReactPDFChart>
        </View>
    );
};

export default PdfDistributionCard;

const _calculateRadius = (index, elementCount) => {
    const r = SMALL_RADIUS;
    const lastIndex = elementCount - 1;
    let radiusArray = 0
    if (index === 0 && elementCount === 1) radiusArray = [r, r, r, r];
    else if (index === 0 && elementCount > 1) radiusArray = [r, 0, 0, r];
    else if (index === lastIndex) radiusArray = [0, r, r, 0];
    return radiusArray;
}