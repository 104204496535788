import React, { useEffect, useState } from 'react';
import { InfoCardStyled, SMALL_RADIUS, CardTitle, CardSubtitle, CardTitleWrapper, TRANSITION_LENGTH_INT, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';
import { HoverContent, ChartHover, HoverTitle } from '../../styles/emit-styles/ChartStyles';
import { BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, Surface, Symbols } from "recharts";
import { useRef } from 'react';
import { cngWarningTooltipCopy, filterDataTypes, FormatSummaryData } from './UtilityFunctions';
import { UNITS_POUNDS, UNITS_TONS } from '../swt-emit';
import styled from 'styled-components';
import InfoTooltip from './InfoTooltip';

const StyledLegend = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const DistributionCard = ({ data, dataTypes, height, width, minWidth, maxWidth, title, subtitle, useLongLabels, dataType, colorHoverValues, hideLegend, onlyLegend, displayInLbs, hasCngVehicles }) => {
    const [hoveredBar, setHoveredBar] = useState(null);
    const [filteredDataTypes, setFilteredDataTypes] = useState([]);
    const [totalOfData, setTotalOfData] = useState(0);

    useEffect(() => {
        let [filteredTypes, totalNumber] = filterDataTypes(data, dataTypes);
        setFilteredDataTypes(filteredTypes);
        setTotalOfData(totalNumber);
    }, [dataTypes, data]);

    // the following nifty logic block allows for the fade in/fade out effects on the tooltip.
    // This was surprising difficult to create in recharts natively, due to the behavior of the tooltip being that
    // it is immediately destroyed when the user moves away from the target element it's being displayed for.
    const [tooltipActive, setTooltipActive] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const timeoutRef = useRef(null);

    const handleMouseEnter = (index) => {
        setHoveredBar(index);
        clearTimeout(timeoutRef.current); // Clear any pending timeouts
        setTooltipActive(true);
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setHoveredBar(null)
        setTooltipVisible(false); // Immediately start to fade the tooltip

        timeoutRef.current = setTimeout(() => {
            setTooltipActive(false); // After fade transition ends, set tooltipActive to false
        }, TRANSITION_LENGTH_INT);
    };

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current); // Clear timeout on unmount
    },);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload?.[0]) {
            const pl = payload[0];
            const dt = dataTypes.find(t => t.id === pl.dataKey);
            const label = (useLongLabels && dt?.longLabel) ?? dt?.label; // use long labels if enabled and longLabel value was provided
            const color = dt?.color;
            const value = pl.value;
            if (label && value) {
                const roundedPercentage = Math.round((value / totalOfData) * 100) || "<1"; // if rounded value comes out to 0, display less than 1 %
                const percentage = Math.round((value / totalOfData) * 1000) / 10 || "<1"; // if rounded value comes out to 0, display less than 1 %

                if (dataType === "allVehicles" || dataType === "altFuelVehicles") {
                    return (
                        <ChartHover>
                            <div><HoverContent colorOverride={colorHoverValues && color}>{label} Vehicles: </HoverContent><HoverTitle colorOverride={colorHoverValues && color}>{value}</HoverTitle></div>
                            <div><HoverContent>{roundedPercentage}% of {dataType === "allVehicles" ? "Fleet" : "Alternative Fuel Vehicles"}</HoverContent></div>
                        </ChartHover>
                    );
                } else if (dataType === "generatedEmissions" || dataType === "avoidedEmissions") {
                    return (
                        <ChartHover>
                            <div><HoverContent colorOverride={colorHoverValues && color}>{label} Vehicles: <HoverTitle colorOverride={colorHoverValues && color}>{FormatSummaryData('ghg',value)}</HoverTitle> {displayInLbs ? UNITS_POUNDS : UNITS_TONS}</HoverContent></div>
                            <div><HoverContent>{percentage}% of {dataType === "generatedEmissions" ? "Total" : "Avoided"} Emissions</HoverContent></div>
                        </ChartHover>
                    );
                } else if (dataType === "simplifiedMileage" || dataType === "allMileage" || dataType === "altFuelMileage") {
                    return (
                        <ChartHover>
                            <div><HoverContent colorOverride={colorHoverValues && color}>{label} Vehicles: <HoverTitle colorOverride={colorHoverValues && color}>{FormatSummaryData('mileage',value)}</HoverTitle> miles</HoverContent></div>
                            <div><HoverContent>{percentage}% of {dataType === "totalMileage" ? "Total" : "Alternative Fuel Vehicle"} Mileage</HoverContent></div>
                        </ChartHover>
                    );
                }
            };
        }
        return null;
    }

    const CustomLegend = ({ payload }) => {
        return <StyledLegend>
            {payload.map(entry => {
                return (
                    <div
                        key={entry.id}
                        style={{
                            display: 'flex',
                            gap: 3,
                            alignItems: 'center'
                        }}
                    >
                        <Surface width={14} height={14} >
                            <Symbols cx={7} cy={7} type="circle" size={100} fill={entry.color} />
                        </Surface>
                        <div
                            style={{ color: entry.color }}
                        >
                            {entry.value}
                        </div>
                    </div>
                );
            })}
            {hasCngVehicles && <InfoTooltip description={cngWarningTooltipCopy()}/>}
        </StyledLegend>
    };

    return (
        <InfoCardStyled
            height={height}
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
        >
            {onlyLegend ?
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width="100%"
                        height="100%"
                        data={data}
                        margin={{ right: 0, left: 0 }}
                    >
                        <Legend
                            content={CustomLegend}
                            iconSize={12}
                            payload={filteredDataTypes.map(bar => ({
                                id: bar.id,
                                value: bar.label,
                                color: bar.color
                            }))}
                        />
                    </BarChart>
                </ResponsiveContainer>
                :
                <>
                    <CardTitleWrapper>
                        <CardTitle>{title}</CardTitle>
                        <CardSubtitle>{subtitle}</CardSubtitle>
                    </CardTitleWrapper>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width="100%"
                            height="100%"
                            data={data}
                            margin={{ right: 0, left: 0 }}
                            layout='vertical'
                        >
                            <XAxis
                                type='number'
                                domain={0} // ensure that the bars take the full width available to them
                                hide // don't show ticks
                            />
                            <YAxis
                                dataKey="breakdown"
                                type="category"
                                hide // don't show ticks
                            />
                            {filteredDataTypes.map((entry, index) => {
                                const elementCount = filteredDataTypes.length;
                                return (<Bar
                                    key={index}
                                    dataKey={entry.id}
                                    fill={hoveredBar === index ? entry.colorHover : entry.color}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    radius={_calculateRadius(index, elementCount)}
                                    name={entry.value}
                                    stackId={0}
                                    barSize={10}
                                    style={{ cursor: 'pointer', width: 'min-content' }} // cursor to show the pointer hover, min-content to ensure the bar does not take up extra space it doesn't need.
                                />)
                            })}
                            {!hideLegend && <Legend
                                content={CustomLegend}
                                iconSize={12}
                                payload={filteredDataTypes.map(bar => ({
                                    id: bar.id,
                                    value: bar.label,
                                    type: 'circle',
                                    color: bar.color
                                }))}
                            />}
                            <Tooltip
                                content={CustomTooltip}
                                cursor={false}
                                shared={false}
                                //allowEscapeViewBox={{ x: false, y: true }} // Including this so we know it's an option if we want to revisit. I'm leaving it off for now because it occasionally falls victim to overflow issues.
                                active={tooltipActive} // force the tooltip to rely on our custom state management, to allow for smooth fade in/fade out.
                                wrapperStyle={{
                                    opacity: tooltipVisible ? 1 : 0, // Initially hidden
                                    transition: `opacity ${TRANSITION_LENGTH} ease, transform 0.4s ease-in-out` // Add opacity transition (transform @ 0.4s is default, but it's easier to just declare it again than try to pull in existing wrapper styling)
                                }}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </>
            }
        </InfoCardStyled>
    );
};

export default DistributionCard;

const _calculateRadius = (index, elementCount) => {
    const r = SMALL_RADIUS;
    const lastIndex = elementCount - 1;
    let radiusArray = 0
    if (index === 0 && elementCount === 1) radiusArray = [r, r, r, r];
    else if (index === 0 && elementCount > 1) radiusArray = [r, 0, 0, r];
    else if (index === lastIndex) radiusArray = [0, r, r, 0];
    return radiusArray;
}