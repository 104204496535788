import React, { useState, useEffect, useMemo, useCallback, useRef, Suspense } from "react";
//import styled from 'styled-components/macro';
import ImageUploading from 'react-images-uploading';
import VehicleClassDropdown from "../../dev-tool-components/VehicleClassDropdown.jsx";
import GvwrDropdown from "./GvwrDropdown.jsx";
import CandidateCreationModal from "./CandidateCreationModal";
import AdminTableView from "./AdminTableView";
import Search from "./search";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import Loading from "../swt-loading";
import {formatDateTimeUTC, preventNonIntegers, preventPasteNonIntegers, roundObjValues, formatDecimal, convertISOToExcelSerial, determineVehicleClassValue} from '../UtilityFunctions';
import UpfitsDropdown from "../../dev-tool-components/UpfitsDropdown.jsx";
import { processApiResponse, conformPostRequest } from "../../utility-components/ConformUnits.js";
import { columnsToExcelHeaders, rowsToData, cdrCandidatesTableColumns } from "./TableHelpers";
import CategoryDropdown from "./CategoryDropdown.jsx";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

const MAX_CAND_IMAGE_SIZE = 700000; //max size in bytes allowed by image uploader
const MAX_SECONDS_TO_WAIT_ON_UPLOAD =  5;

const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

const gvwrValues = Array.apply(null, new Array(8)).map((a, idx)=>{return {id: `Class ${idx+1}`, name: `Class ${idx+1}`}});
gvwrValues.unshift({id: -1, name: "None"});


export default function SuperAdminCandidatesTable(props){
    const { apiURL, user, db } = props;
    const [candidates, setCandidates] = useState(null);
    const [immutableCandidates, setImmutableCandidates] = useState(null);
    const [matchingCandidates, setMatchingCandidates] = useState(null);
    const [vehicleClasses, setVehicleClasses] = useState(null);
    const [reload, setReload] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [upfits, setUpfits] = useState([]);
    const [imageUploadCounter, setImageUploadCounter] = useState(0);
    const skipPageResetRef = useRef(false);
    const [incompleteVehicles, setIncompleteVehicles] = useState([])
    const userSettings = props.user.userSettings;
    const dbSettings = props.dbSettings;

    const tableColumns = cdrCandidatesTableColumns(dbSettings,userSettings);

    useEffect(() => {
        const url = `${apiURL}getCDRCandidates`;
        fetch(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
            .then((resp) => {
                return resp.json();
            })
            .then((data) => {
                if (data.status === "success") {
                  data.data.map((d) => {
                    d.ymm = `${d.year} ${d.make} ${d.model}`
                    d.upfits = d.upfits === null ? [] : d.upfits
                    d = processApiResponse(userSettings, d, false)
                    d = roundObjValues(d)
                    return d
                  })
                  setCandidates(data.data);
                  const noMutate = JSON.parse(JSON.stringify(data.data.map((c)=>{
                      c.sortable_update_timestamp = convertISOToExcelSerial(c.last_updated + "Z",false); // the timestamp is in UTC, but doesn't come from the API with that made explicit, and won't work with the ISO to Serial function without the Z notation
                      return c}
                  )))
                  setImmutableCandidates(noMutate);
                  setReload(false);
                }
                else {
                  alert(SERVER_ERRORED_MESSAGE);
                }
            })
            .catch((error) => console.error("Error: " + error));
    }, [apiURL, db, user.token, reload, userSettings]);

    useEffect(() => {
      const url = `${apiURL}getVehicleClasses?dbName=${db}`;
      fetch(url, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          if (data.status === "success") {
            setVehicleClasses(data.data);
          }
          else {
            alert(SERVER_ERRORED_MESSAGE);
          }
        });
    }, [apiURL, db, user.token]);

    useEffect(() => {
      const url = `${apiURL}getCDRUpfits?ident=${db}`;
      fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
          .then((resp) => {
              return resp.json();
          })
          .then((data) => {
            if(data.status === "success"){
              setUpfits(data.data);
              setReload(false);
            }
            else {
              alert(SERVER_ERRORED_MESSAGE);
            }
          })
          .catch((error) => console.error("Error: " + error));
      }, [apiURL, db, user.token, reload]);

    function updateCandidateImage(candidateId, image) {
        setImageUploadCounter(imageUploadCounter+1)
        let formData = new FormData();
        formData.append("id", candidateId);
        formData.append("image", image[0].file);
        formData.append("editingUserEmail",user.email);
        fetch(`${apiURL}updateCDRCandidateImage`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
            body: formData
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === "success") {
            setImageUploadCounter(imageUploadCounter-1);
            alert("Candidate image successfully updated.");
          }
          else {
            alert(SERVER_ERRORED_MESSAGE);
          }
        });
    }

    function handleDeleteClick(candidate) {

      const answer = window.confirm(
        `Are you sure you wish to delete the candidate: ${candidate.year.props.label} ${candidate.make.props.label} ${candidate.model.props.label}?`
      );
      if (answer) {
        fetch(`${apiURL}deleteCDRCandidate`, {
          method: "DELETE",
          body: JSON.stringify({ candidateId: candidate.id }),
          headers: {
              Accept: "application/json",
                  "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
          }
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === 'success') {
            alert("Candidate successfully deleted.")
          }
          else {
            alert(SERVER_ERRORED_MESSAGE)
          }
        });
        setReload(true);
      }
    }

    function updateCandidates() {
      //get changed cands
      let cands = candidates.filter((c) => c.mutated);
      const imageUpdates = cands.filter((c) => c.imageMutated);
      imageUpdates.forEach((c) => {
          updateCandidateImage(c.id, c.image);
      });
      //reset the image counter after some time so we're not forever blocked on fails
      setTimeout(()=>{setImageUploadCounter(0)}, MAX_SECONDS_TO_WAIT_ON_UPLOAD*1000);

      let incompleteVehiclesList = []
      //Check that any EV's have battery capacity set before sending to API.
      cands.forEach((c) => {
        if(c.is_phev || c.is_bev) {
          if (!c.battery_kwh || c.battery_kwh === "0"){
              incompleteVehiclesList.push(`${c.year} ${c.make} ${c.model}`)
          }
          }
      })
      if(incompleteVehiclesList.length > 0) {
        setIncompleteVehicles(incompleteVehiclesList)
        window.alert(`Update incomplete. Please update the vehicles listed below.`)
        return
      } else {
        setIncompleteVehicles([])
      }

      
      //dump image payload as we handle via seperate call
      cands.forEach((c) => {
        c.image = null
        // Normalize upfits before sending to API
        if(c.upfits !== undefined && c.upfits !== null && c.upfits.length > 0) {
          let normalizedUpfits = []
          c.upfits.forEach((u) => {
            normalizedUpfits.push(u.id)
          })
          c.upfits = normalizedUpfits;
        }
        else {
          c.upfits = null;
        }
      });

      cands = conformPostRequest(userSettings, cands);
      cands.forEach((c) => {
        return roundObjValues(c)
      })
      fetch(`${apiURL}updateCDRCandidates`, {
          method: "POST",
          headers: {
              Accept: "application/json",
                  "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
        },
          body: JSON.stringify({
              editingUserEmail: user.email,
              candidates: JSON.stringify(cands),
        }),
      })
          .then((resp) => resp.json())
          .then((data) => {
              if (data.status === "success") {
                  window.alert("Candidates were succesfully updated");
                  setReload(true);
              } else
                  window.alert(SERVER_ERRORED_MESSAGE);
          });
    }

      function addCandidate(candidate) {
        let cand = conformPostRequest(userSettings, [candidate])
        cand.forEach((c) => {
          return roundObjValues(c)
        })
        fetch(`${apiURL}createCDRCandidate`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            editingUserEmail: user.email,
            candidate: cand[0]
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === "success") {
              window.alert("Candidate was succesfully added");
              setReload(true);
            }
            else
              window.alert(SERVER_ERRORED_MESSAGE);
          });
      }

      const handleSave = () => {
        updateCandidates();
      };

      const formatSelectedUpfits = (selectedUpfits) => {
        let upfitsArr = [];
        if (selectedUpfits) {
          selectedUpfits.map(u => {
            return upfitsArr.push({value: u.id, label: u.name})
          })
        }
        return upfitsArr;
    }

      function _getTableIndex(id) {
        const idxArr = candidates.map((c) => {
          return c.id;
        });
        return idxArr.indexOf(id);
      }

      function handleGVWRChange(c, id){
        skipPageResetRef.current = false;
        let cands = [...candidates];  
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand["gvwr"] = c;
        cand["mutated"] = true;
        setCandidates(cands); 
      }

      function handleClassChange(c, id) {
        skipPageResetRef.current = false;
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand["vehicle_class"] = c;
        cand["mutated"] = true;
        setCandidates(cands);
      }

      function handleCategoryChange(c, id) {
        skipPageResetRef.current = false
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand["is_ld"] = c === "Light Duty" ? true : false;
        cand["mutated"] = true;
        setCandidates(cands);
      }

      function handleBoolChange(col, id) {
        skipPageResetRef.current = false;
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand[col] ? (cand[col] = false) : (cand[col] = true);
        cand["mutated"] = true;
        setCandidates(cands);
      }

      function handleInputChange(e) {
        const ALPHANUMERIC_REGEX = new RegExp("^[\\w\\-\\(\\) ]*$") //Prevents all special characters except for " - _ ( ) "
        if(e.target.getAttribute('type') === 'text' && ALPHANUMERIC_REGEX.test(e.target.value) === false) {
          e.preventDefault();
          return;
        }
        skipPageResetRef.current = false;
        const cands = [...candidates];
        let val = e.target.value;
        const id = e.target.getAttribute("id");
        let col = e.target.getAttribute("accessor");
        let row = _getTableIndex(id);
        let cand = cands[row];
        if(e.target.getAttribute('type') === 'number') {
          val = formatDecimal(val, 4)
        }
        cand[col] = val;
        cand["mutated"] = true;
        setCandidates(cands);
      };

      const handleImageChange = (imageList, id) => {
        skipPageResetRef.current = false;
        // data for submit
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand.image = imageList;
        cand.mutated = true;
        cand.imageMutated = true;
        cand.image_url = null;
        setCandidates(cands);
      };

      function handleUpfitsChange(e, id) {
        skipPageResetRef.current = false;
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let vcl = cands[row];
        let selectedUpfits = [];
        e.forEach((u) => {
          selectedUpfits.push(upfits.find(el => el.id === u.value));
        })
        vcl.upfits = selectedUpfits;
        vcl['upfits'] = selectedUpfits;
        vcl['mutated'] = true;
        setCandidates(cands)
      }

      const DriveTrainDropdown = useCallback((props) => {
          return (
            <select
              value={''}
              onChange={(e) => {}}
              >
            {''}
          </select>)
      }, []);
      //applying styled components to the input element strips custom attrs, so vin and accessor become nulls
      const BoolTableCell = useCallback((props) =>
        <input
            className="swt-admin-table-input"
            type="checkbox"
            id={props.id} 
            name={props.name}
            accessor={props.accessor} 
            style={{color: props.bool ? "black" : "transparent"}} 
            onChange={props.handleOnClick}
            checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
            disabled={typeof props.bool === "undefined" ? true : false}
        />,[]);

      const TableButton = useCallback((props) => 
      <S.InnerTableButton color={props.color} onClick={props.handleClick}>
        {props.label}
      </S.InnerTableButton>
      ,[])

      const ImageUploadCell = (props) => 
        <ImageUploading
            style={props.styles}
            value={props.images}
            image={props.image}
            maxFileSize={MAX_CAND_IMAGE_SIZE}
            url={props.url}
            onChange={function(il){handleImageChange(il, props.id)}}>
            {({
                onImageUpload,
                onImageUpdate,
                isDragging,
                dragProps,
                errors
            }) => (            
                <div>
                  {
                  ((!props.url && !props.image) || props.url === "https://images.sawatchlabs.com/rt_vehicles/white_block.png")
                  ?
                  <S.InnerTableButton
                    height={'40px'}
                    width={'110px'}
                    style={isDragging ? {color:"green"} : undefined}
                    onClick={onImageUpload}
                    {...dragProps}>
                      Click or Drag Here
                  </S.InnerTableButton>
                  :
                  <S.CentereredFlexContainer key={props.id} onClick={onImageUpdate}>
                    {!errors && !props.image && props.url && <S.AdminCandidateTarget src={props.url} alt=""/> }
                    {!errors && props.image && <S.AdminCandidateTarget src={props.image.dataURL} alt=""/> }
                    {errors && 
                      <S.AdminCandidateError>
                        {errors.acceptType && <span>Wrong File Type</span>}
                        {errors.maxFileSize && <span>File Too Large</span>}
                      </S.AdminCandidateError>
                    }
                  </S.CentereredFlexContainer>
                  }
                </div>
            )}          
        </ImageUploading>;

      const InputTableCell = useCallback((props) => 
        <input className="swt-admin-input"
            style={props.styles}
            key={props.id}
            title={props.label}
            value={`${props.label}`}
            onKeyDown={props.onKeyDown}
            onPaste={props.onPaste}
            id={props.id}
            accessor={props.accessor}
            onChange={props.handleOnChange}
            onWheel={(e) => e.target.blur()}
            type={props.type}
        />,[]);

      const mappedCandidates = useMemo(()=> {
        if(!candidates || !matchingCandidates || imageUploadCounter > 0)return null;
        if(candidates.length < 1 || !vehicleClasses || vehicleClasses.length < 1)return [];
        
        //this deep clones our object, but cannot be extended 
        //to complex members of the object, ie: Dates, etc.
        //needed so that we're not swapping bools 
        //and other attrs with react elements on the canonical obj
        const cands = JSON.parse(JSON.stringify(candidates));
        const matchingCands = matchingCandidates.map((c) => {return c.id});

        return cands.filter((c) => {
          if(matchingCands.indexOf(c.id) < 0)return null;
          const is_ev = c.is_bev || c.is_phev; // Used to determine if cells should be disabled (for ionEV inputs)

          //dates
          c.last_updated_original = JSON.parse(JSON.stringify(c.last_updated)); //Preserve original date to use for sorting
          c.last_updated = formatDateTimeUTC(c.last_updated);

          //bool toggles
          c.is_phev = <BoolTableCell name="powertrain" id={c.id} accessor={"is_phev"} bool={c.is_phev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_bev = <BoolTableCell name="powertrain" id={c.id} accessor={"is_bev"} bool={c.is_bev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_ld = c.is_ld === null ? true : c.is_ld;
          c.evsa_selected = <BoolTableCell id={c.id} accessor={"evsa_selected"} bool={c.evsa_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.ionev_selected = <BoolTableCell id={c.id} accessor={"ionev_selected"} bool={c.ionev_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;

          //bools that don't exist on the table yet
          c.is_cng = <BoolTableCell name="powertrain" id={c.id} accessor={"is_cng"} bool={c.is_cng} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_rng = <BoolTableCell name="powertrain" id={c.id} accessor={"is_rng"} bool={c.is_rng} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_diesel = <BoolTableCell name="powertrain" id={c.id} accessor={"is_diesel"} bool={c.is_diesel} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_gasoline = <BoolTableCell name="powertrain" id={c.id} accessor={"is_gasoline"} bool={c.is_gasoline} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_hydrogen_fuelcell = <BoolTableCell name="powertrain" id={c.id} accessor={"is_hydrogen_fuelcell"} bool={c.is_hydrogen_fuelcell} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_hydrogen_lion_hybrid = <BoolTableCell name="powertrain" id={c.id} accessor={"is_hydrogen_lion_hybrid"} bool={c.is_hydrogen_lion_hybrid} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
          c.is_propane = <BoolTableCell name="powertrain" id={c.id} accessor={"is_propane"} bool={c.is_propane} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;

          //images
          c.image = <ImageUploadCell key={c.id} styles={{width:"200px"}} url={c.image_url} id={c.id} label={c.image_url} accessor={"image"} image={c.image && c.image.length > 0 ? c.image[0] : undefined} handleOnChange={function(e){/*console.log(e)*/}}/>

          //inputs
          c.year = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.year ?? ""} id={c.id} accessor={"year"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.make = <InputTableCell label={c.make ? c.make : ""} id={c.id} accessor={"make"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
          c.model = <InputTableCell label={c.model ? c.model : ""} id={c.id} accessor={"model"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
          c.battery_kwh= <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} disable={!is_ev} label={c.battery_kwh ?? ""} id={c.id} accessor={"battery_kwh"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.battery_kwh_usable = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} disable={!is_ev} label={c.battery_kwh_usable ?? ""} id={c.id} accessor={"battery_kwh_usable"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.net_price = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.net_price ?? ""} id={c.id} accessor={"net_price"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.forecasted_maint_per_km = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.forecasted_maint_per_km ?? ""} id={c.id} accessor={"forecasted_maint_per_km"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.backcasted_maint_per_km = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.backcasted_maint_per_km ?? ""} id={c.id} accessor={"backcasted_maint_per_km"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>

          c.forecasted_insurance = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.forecasted_insurance ?? ""} id={c.id} accessor={"forecasted_insurance"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.backcasted_insurance = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.backcasted_insurance ?? ""} id={c.id} accessor={"backcasted_insurance"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.mpg_c = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} disable={is_ev} label={c.mpg_c ?? ""} id={c.id} accessor={"mpg_c"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          c.mpg_h = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} disable={is_ev} label={c.mpg_h ?? ""} id={c.id} accessor={"mpg_h"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>

          //vehicle classes dropdown
          c.vehicle_class = <VehicleClassDropdown
              key={c.id}
              id={c.id}
              class={c.vehicle_class}
              defaultValue={determineVehicleClassValue(c.vehicle_class, vehicleClasses.map((vc)=>{return vc.vehicle_class}))}
              handleClassChange={handleClassChange}
              vehicleClasses={vehicleClasses.map((vc)=>{return vc.vehicle_class})}/>
          
          // category dropdown
          c.is_ld = <CategoryDropdown
            id={c.id}
            key={c.id}
            defaultValue={c.is_ld === true ? "Light Duty" : "Medium/Heavy Duty"}
            handleCategoryChange={handleCategoryChange}/>

          c.upfits = <UpfitsDropdown
              key={c.id}
              id={c.id}
              upfits={upfits}
              selectedUpfits={formatSelectedUpfits(c.upfits)}
              handleChange={handleUpfitsChange}
          />

          c.gvwr = <GvwrDropdown
              key={c.id}
              id={c.id}
              options={gvwrValues}
              defaultValue={c.gvwr}
              handleChange={(e) => {handleGVWRChange(e, c.id)}}
              />

          c.delButton = <TableButton label={"Delete"} color={'#e01f1f'} handleClick={function(e){handleDeleteClick(c)}} />
          return c;
        });
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [candidates, matchingCandidates, vehicleClasses, imageUploadCounter])
 
      // Filter out columns that shouldn't be shown in interface
      const filteredColumns = () => {
        tableColumns.forEach((headerGroup) => {
          headerGroup.columns = headerGroup.columns.filter(c => c.showInInterface);
        })
        return tableColumns
      }

      return(
            <>
              <S.CandidatesTableHeaderContainer>
                  <div>
                    <S.AdminTableExplainTextPrimary>List of all candidates.</S.AdminTableExplainTextPrimary>
                    <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                    <S.SearchContainer>
                      <Search allValues={candidates} setMatchingValues={setMatchingCandidates} skipPageResetRef={skipPageResetRef} disabled={candidates ? false : true}/>
                    </S.SearchContainer>
                    {showModal && (
                      <CandidateCreationModal
                        dbSettings={dbSettings}
                        userSettings={userSettings}
                        setShowModal={setShowModal}
                        vehicleClasses={vehicleClasses.map((vc)=>{return vc.vehicle_class})}
                        selectDriveTrainType={DriveTrainDropdown}
                        sanitizeData={function(c){return c}}
                        addCandidate={addCandidate}
                        upfits={upfits}
                        cdrModal={true}
                        existingCandidates={candidates}
                      />
                    )}
                    <S.CtaButtonWrapper>
                      <S.CtaButton onClick={() => setShowModal(true)} id="add">Add</S.CtaButton>
                      <S.CtaButton type="submit" onClick={handleSave}>Update</S.CtaButton>
                    </S.CtaButtonWrapper>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <ExcelDownloadButton
                      downloadType={"cdrCandidatesDownload"}
                      columnGroups={tableColumns}
                      columns={columnsToExcelHeaders(tableColumns, true)}
                      data={rowsToData(tableColumns, immutableCandidates, ((a, b) => a.year < b.year ? 1 : -1), true)}
                      dbSettings={dbSettings}
                      userSettings={userSettings}
                    />
                  </Suspense>
                </S.CandidatesTableHeaderContainer>
                {incompleteVehicles.length > 0 &&
                <div><b>Vehicles that need 'Total Battery (kWh)' values:</b>
                  {incompleteVehicles.map((v) => {
                    return (
                      <div key={`${v}-${Math.random()}`}>{v}</div>
                    )
                  })}</div>
                }
                {(!mappedCandidates || reload || imageUploadCounter > 0)&& <Loading />}
                {(mappedCandidates && !reload && imageUploadCounter === 0) && 
                <AdminTableView
                    columns={filteredColumns()}
                    data={mappedCandidates}
                    stickyCols={3}
                    colGroups={6}
                    skipPageResetRef={skipPageResetRef}
                />}
          </>
      );
}