import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from '../../images/Download.svg';
import { blue_green, blue_green_hover, white, sawatch_blue } from '../../styles/emit-styles/ColorScheme';
import { MEDIUM_RADIUS, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';
import { buildDownloadFilename, FormatData } from './UtilityFunctions';
import exceljs from 'exceljs';
import FileSaver  from 'file-saver';
import { logoBase64 } from './Logo';
import { AVOIDED_TABLE_TYPE } from '../swt-emit';

const DOWNLOAD_BUTTON_COLOR = blue_green;
const HEADER_COLOR = sawatch_blue.slice(1)

const DownloadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding-left: 4px;
    padding-right: 8px;
    background-color: ${DOWNLOAD_BUTTON_COLOR};
    color: ${white};
    cursor: pointer;
    font-weight: 500;
    height: ${({ height }) => height || '32px'};
    width: ${({ width }) => width || '128px'}; 
    border-radius: ${MEDIUM_RADIUS}px;
    transition: background-color ${TRANSITION_LENGTH} ease;
    &:hover{
      background-color: ${blue_green_hover};
    }
    font-size: 16px;
`;

const IconWrapper = styled.div`
  fill: ${white};
  height: 20px;
  width: 20px;
`;

const ExcelDownloadButton = ({width, height, label, dbDisplayName, beginDate, endDate, csvType, group, vehicleClasses, selectedVehicleClasses, displayInLbs, displayUnits, emissionsData, isTd, cols, methodology}) => {
    const formattedData = formatTableData(beginDate, endDate, csvType, group, vehicleClasses, selectedVehicleClasses, displayInLbs, dbDisplayName, displayUnits, emissionsData, isTd, cols, methodology);
    const filename = buildDownloadFilename(dbDisplayName, csvType);
    return (
        <DownloadContainer
            onClick={()=>execExcelDownload(formattedData, filename)}
            target="_blank"
            width={width}
            height={height}
        >
            <IconWrapper>
                <DownloadIcon />
            </IconWrapper>
            <div>{label}</div>
        </DownloadContainer>
    )
};

export default ExcelDownloadButton;


async function saveFile(workbook, filename) {
    const buf = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename);
}

function adjustColumnWidth(worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    });
  }

function createExcelDoc(formattedData) {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('sheet1', {views: [{showGridLines:false}]});
    const imgId = workbook.addImage({
        base64: logoBase64,
        extension: 'png'
    });
    worksheet.addRows(formattedData.data);

    // Set style of title block headers
    worksheet.getCell('A1').font = {
        bold: true,
        size: 14
    };
    worksheet.getCell('A2').font = {
        bold: true
    };
    worksheet.getCell(`A${formattedData.headerCount-2}`).value = {
        text: 'sawatchlabs.com',
        hyperlink: 'http://www.sawatchlabs.com'
    };
    worksheet.getCell(`A${formattedData.headerCount-2}`).font = {
        underline: true,
        color: { argb: HEADER_COLOR }
    }

    // Set font and fill for table headers
    worksheet.getRow(formattedData.headerCount).font = { bold: true, color: { argb: "FFFFFF" }};
    worksheet.getRow(formattedData.headerCount).eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: HEADER_COLOR} }
    });

    adjustColumnWidth(worksheet);

    // Add image to cell B2 and preserve size
    worksheet.addImage(imgId, {
        tl: { col: 1, row: 1 }, 
        ext: { width: 241, height: 132 }, 
        editAs: 'absolute'
    });

    // Add borders to and format cells
    let moneyCol = '';
    let yearCol = '';
    worksheet.eachRow((row, rowNum) => {
        if (rowNum >= formattedData.headerCount) {
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                    };
                cell.alignment = { horizontal: 'left' };
                if (cell.value === 'Fuel Savings') {
                    moneyCol = cell.address.substring(0, 1);
                }
                if (cell.value === 'Year') {
                    yearCol = cell.address.substring(0, 1);
                }

                if (typeof(cell.value) === 'number') {
                    if (cell.address.substring(0, 1) === moneyCol) {
                        // Format Fuel Cost Savings as currency
                        cell.numFmt = '$#,##0';
                    }
                    else if (cell.value % 1 === 0) {
                        // Format any whole numbers
                        if (cell.address.substring(0, 1) !== yearCol) { 
                            // Don't add formatting to year
                            cell.numFmt = '#,##0';
                        }
                    }
                    else {
                        cell.numFmt = '#,##0.0';
                    }
                }
            });
            row.commit();
        }
    });

    return workbook;
}

function execExcelDownload(formattedData, filename) {
    const workbook = createExcelDoc(formattedData);
    saveFile(workbook, filename);
}

function formatTableData(beginDate, endDate, csvType, group, vehicleClasses, selectedVehicleClasses, displayInLbs, dbDisplayName, displayUnits, emissionsData, isTd, cols, methodology) {
    const emissionData = JSON.parse(JSON.stringify(emissionsData))

    const csvHeaders = cols.map((c) => {
        let colLabel = c.simpleHeader ? c.simpleHeader : c.Header;
        return (
            { label: colLabel, key: c.accessor }
        )
    })

        const dateStringOptions = {year: '2-digit', month: '2-digit', day: '2-digit'}
      
        let selectedClasses = (selectedVehicleClasses.length === vehicleClasses.length) ? 'All Classes' : selectedVehicleClasses.join(", ");

        let emptyArr = [''];
        let headers = [];
        let accessors = [];
        let reportName = []
        if (csvType === AVOIDED_TABLE_TYPE) {
            reportName.push('Avoided Emissions Report - ' + dbDisplayName);
        }
        else {
            reportName.push('Emissions Report - ' + dbDisplayName);
        }

        let formatArr = [
            ['Sawatch Labs - Emit'],
            reportName,
            ['Download Date: ' + (new Date()).toLocaleDateString('en-US', dateStringOptions)],
            [`Date Range: ${(beginDate) ? beginDate.toLocaleDateString('en-US', dateStringOptions): '--'} - ${(endDate) ? endDate.toLocaleDateString('en-US', dateStringOptions): '--'}`],
            [`Methodology: ${methodology.label}`],
            [`Group: ${group}`],
            [`Vehicle Classes: ${selectedClasses}`],
            [`Display Units (Carbon Dioxide & GHG): ${displayUnits}`],
            emptyArr,
            emptyArr,
            headers,
        ]

        if(csvHeaders) {
            csvHeaders.map(item => {
                headers.push(item.label)
                accessors.push(item.key)
                return null;
            })
        }
        
        const headerCount = formatArr.length;
        if(emissionData.length < 1) {
            formatArr.push(['No data to display'])
        } else {
        emissionData.map(data => {
            let row = []
            accessors.map(value => {
            // Kind of hacky but didn't see a better way. Remove when vin/user defined vin issue is resolved
            if (value === 'user_defined_vin' && data[value] === 'null') {
                value = 'vin'
            }
            // None check
            if(data[value] === null || data[value] === undefined || data[value] === 'null' || data[value] === 'VIN Decoding Error' || data[value] === '') {
                if (value === 'vin' || value === 'user_defined_vin')
                    data[value] = 'Not Provided';
                else 
                    data[value] = '-'
            }            
            
            let val = FormatData(value, data[value], data, data['is_cng']);

            // Clean up number strings so they can be converted to number type
            if (typeof(val) === 'string') {
                if (val.includes(',')) {
                    val = val.replace(',', '');
                }
                if (val.includes('$')) {
                    val = val.replace('$', '');
                }
            }
            // If val can't be converted to number just push the string
            if (isNaN(Number(val))) {
                row.push(val);
            }
            // Otherwise convert string to number type
            else {
                row.push(Number(val));
            }
              
              return null;
            });
            formatArr.push(row);
            return null;
        })
        }
        return {"data": formatArr, "headerCount": headerCount};
}