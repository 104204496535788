export const off_black = 'hsl(210, 5%, 15%)';
export const darkest_gray = 'hsl(210, 5%, 30%)';
export const dark_gray = 'hsl(210, 5%, 45%)';
export const light_gray = 'hsl(210, 5%, 60%)';
export const stroke_gray = 'hsl(210, 5%, 90%)';
export const background_gray = 'hsl(210, 2%, 98%)';
export const white = 'hsl(0, 0%, 100%)';
export const sawatch_blue= '#12365b';

export const blue_green = 'hsl(188, 40%, 35%)'; // main app color. Also represents consolidated alt fuel vehicles.
export const blue_green_hover = 'hsl(188, 40%, 30%)';
export const blue_green_semitransparent = 'hsl(188 40% 30% / 0.25)'; // Used in popover dropdowns

export const rust_red = 'hsl(20, 95%, 40%)'; // Represents consolidated ICE vehicles
export const rust_red_hover = 'hsl(20, 95%, 35%)';

export const gasoline_color = 'hsl(15, 55%, 50%)';
export const gasoline_color_hover = 'hsl(15, 55%, 45%)';
export const diesel_color = 'hsl(43, 100%, 45%)';
export const diesel_color_hover = 'hsl(43, 100%, 40%)';
export const cng_color = 'hsl(235, 40%, 40%)';
export const cng_color_hover = 'hsl(235, 40%, 35%)';
export const bev_color = 'hsl(170, 30%, 50%)';
export const bev_color_hover = 'hsl(170, 30%, 45%)';
export const phev_color = 'hsl(195, 85%, 30%)';
export const phev_color_hover = 'hsl(195, 85%, 25%)';

export const ice_fuels_color = 'hsl(29, 77.5%, 47.5%)';
export const ice_fuels_color_hover = 'hsl(29, 77.5%, 42.5%)';
export const alt_fuels_color = 'hsl(210, 51.5%, 40%)'; // this color is equalized from the BEV, PHEV, and CNG colors, but titled slightly toward purple on the hue because otherwise it looked too similar to the PHEV color.
export const alt_fuels_color_hover = 'hsl(210, 51.5%, 35%)';