import styled from 'styled-components/macro';
import * as ColorScheme from './ColorScheme';

export const AdminTableRow = styled.tr`
  height: 50px;
  color: ${(props) => props.styles?.color ? props.styles.color : null};
  background-color: #fff;
  &:nth-child(odd) {
    background-color: var(--ezev-light-grey);
  }
`;

export const AdminTableContainer = styled.div`
  max-height: 440px;
  overflow: auto;
  position: relative;
`

export const AdminTable = styled.table`
  border-collapse: collapse;
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue","Arial","sans-serif";
  width: 100%;
`;

export const AdminTableHeadRow = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;
`

export const AdminTableHeaderLabelWrapper = styled.div`
display: flex;
align-items: center;
`

export const AdminTableHeaderExplainTextPopup = styled.div`
  position: absolute;
  top: 50px;
  left: ${(props) => props.cursorCoords ? (props.cursorCoords.x + 45) : 0}px;
  width: 16rem;
`

export const AdminTableHeaderExplainTextInnerWrapper = styled.div`
  color: #74787d;
  font-size: 12px;
  line-height: 1.38;
  letter-spacing: 0.11px;
  text-align: left;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  background-color: white;
  border: 1px solid grey;
  max-width: 15rem;
  padding: 10px;
  overflow-wrap: break-word;
  height: fit-content;
  white-space: pre-line;
`

export const AdminTableGroupHeader = styled.th`
  height: ${(props) => props.isGroup ? '40px' : '80px'};
  background-color: ${(props) => props.backgroundColor};
  border-left: ${(props) => props.addBorder ? `1px solid ${ColorScheme.medium_gray}` : '0px'};
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    z-index: 10;
  }

  ${(props) => {
      return`
        &:nth-child(-n+${props.colGroups}) {
          position: sticky; 
          left: 0;
          z-index: 5;
        }
      `
  }}
`


export const AdminTableHeader = styled.th`
  height: ${(props) => props.isGroup ? '40px' : '80px'};
  background-color: ${(props) => props.backgroundColor};
  border-left: ${(props) => props.addBorder ? `1px solid ${ColorScheme.medium_gray}` : '0px'};
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    z-index: 10;
  }

  ${(props) => {
    switch (props.stickyCols) {
      case 3:
        return`
          &:nth-child(-n+3) {
            position: sticky; 
            left: 0;
            z-index: 5; 
            background-color: ${props.backgroundColor};
          }

          &:nth-child(2) {
            left: ${props?.widthsArr ? `${props.widthsArr[0]}px` : '250px'};
          }

          &:nth-child(3) {
          left: ${props?.widthsArr ? `${props.widthsArr[0] + props.widthsArr[1]}px` : '500px'};
          }
        `
      case 2: 
        return`
          &:nth-child(-n+2) {
            position: sticky; 
            left: 0;
            z-index: 3; 
            background-color: #72afc4;
          }

          &:nth-child(2) {
            left: ${props?.widthsArr ? `${props.widthsArr[0]}px` : '250px'};
          }
        `
      case 1:
        return`
          &:nth-child(-n+1) {
            position: sticky; 
            left: 0;
            z-index: 3; 
            background-color: #72afc4;
          }
        `
        default:
          return null;
    }}
    }
`;

export const SectionSeparator = styled.div`
    width: 70%;
    margin-top: 10px;
    border-bottom: 1px solid ${ColorScheme.medium_gray};
`;

export const AdminTableCell = styled.td`
  color: ${(props) => props.color ? props.color : null};
  font-weight: ${(props) => props.color !== 'initial' ? 'bold' : 'normal'};
  border-left: ${(props) => props.addBorder ? `1px solid ${ColorScheme.medium_gray}` : '0px'};
  pointer-events: ${(props) => props.disable ? 'none' : ''};
  padding: 0 30px;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  justify-content: ${(props) => props.checkbox === "true" ? 'center' : 'normal'};
  background-color: ${(props) => props.disable ? `${ColorScheme.lightest_gray}` : props.backgroundColor ? props.backgroundColor : 'initial'};
  white-space: pre-wrap; /* Preserve whitespace and wrap */
  word-break: break-word; /* Break long words */
  ${(props) => {
    switch (props.stickyCols) {
      case 3:
        return`
          &:nth-child(-n+3) {
            position: sticky; 
            left: 0;
            z-index: 3; 
            background-color: inherit;
          }
          
          &:nth-child(2) {
            left: ${props?.widthsArr ? `${props.widthsArr[0]}px` : '250px'};
          }
          
          &:nth-child(3) {
            left: ${props?.widthsArr ? `${props.widthsArr[0] + props.widthsArr[1]}px` : '500px'};
          }
        `
      case 2: 
        return`
          &:nth-child(-n+2) {
            position: sticky; 
            left: 0;
            z-index: 3; 
            background-color: inherit;
          }

          &:nth-child(2) {
            left: ${props?.widthsArr ? `${props.widthsArr[0]}px` : '250px'};
          }
        `
      case 1:
        return`
          &:nth-child(-n+1) {
            position: sticky; 
            left: 0;
            z-index: 3; 
            background-color: inherit;
          }
        `
        default:
          return null;
    }}
    }
`;

export const CandidateCreationInput = styled.input`
  border: 0px;
  min-width: 100px;
  background-color: transparent;
`;

export const CandidateCreationSelect = styled.select`
  min-width: 200px;
  max-width: 200px;
`;


export const CDRStatusMarker = styled.div`
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "transparent"};
  width: 20px;
  height: 20px;
  border-radius: 12.5px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CandidateCreationModal = styled.div`
  position: fixed;
  background: white;
  border-radius: 0.25rem;
  top: 60%;
  left: 50%;
  width: 500px;
  height: auto;
  overflow: scroll;
  padding: 15px 30px 30px 30px;
  transform: translate(-50%, -60%);
`;

export const CandidateCreationFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 210px;
`;

export const CandidateCreationLabel = styled.label`
  min-width: 160px;
`;

export const ManagementMenuItem = styled.li`
  font-size: 15px;
  margin-top: 15px;
  margin-right: 15px;
  text-align: left;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

export const ManagementMenuList = styled.ul`
  list-style-type: none;
`;
 
export const PaginationDetails = styled.span`
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
`;

export const ManagementMenuTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0px;
  margin-left: 15px;
`;

export const PaginationButton = styled.button`
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;
  &:disabled {
    cursor:default;
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(16, 16, 16, 0.2);
  }
  &:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out .2s;
  }
`;
export const SearchContainer = styled.div`
  margin: 10px 0;
  height: 34px;
`;

export const SearchBar = styled.div`
  background-color: white;
  color: grey;
`;

export const SearchInput = styled.input`
    width: 245px;
    height: 33px;
    border: 1px solid;
    border-color: rgb(187, 187, 187);
    border-radius: 5px;
    margin: 0;
    padding: 0;
    text-indent: 5px;
    font-size: 16px;
`;

export const TableNoDataMessage = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  line-height: 1.3;
`;

export const UpdateButton = styled.button`
  background-color: white;
  width: ${props => props.width ? props.width : '115px'};
  height: 35px;
  padding: 0 10px;
  margin-right: 15px;
  border: .7px solid #979a9e;
  border-radius: 9px;
  background-color: ${props => props.selected ? '#f5f5f5' : '#fff'};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  transition: all ease-in .1s;
  border-color: ${props => props.selected ? '#262627' : '#979a9e'};

  &:hover {
    transition: all ease-in .2s;
    border-color: #262627;
    color: ${props => props.selected ? '#74787d' : '#262627'};
    cursor: ${props => props.selected ? 'default' : 'pointer'};
  }
`;

export const UserManagementMenuContainer = styled.div`
  position: fixed;
  z-index: 15001;
  border-radius: 0px 0px 4px 4px;
  border-style: solid;
  border-color: var(--battleship-grey);
  border-width: 1px;
  right: 4px;
  top: 80px;
  width: 300px;
  background-color: var(--light-grey);
  opacity: 0.98;
`;

export const VehicleTableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 15px;
`

export const CandidatesTableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
`
export const VehicleTableLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const VehicleTableLegendItemWrapper = styled.div`
  display: flex;
`;

export const VehicleTableErrorContainer = styled.div`
  padding-bottom: 7px;
`;

export const VehicleTableHeaderSectionWrapper = styled.div`
    display: flex;
    margin-top: 5px;
    align-items: center;
`

export const StyledCheckbox = styled.input`
    margin: 0 4px;
    height: 15px;
    width: 15px;
`

export const VehicleTableColorLegendLabel = styled.p`
  color: ${props => props.styles?.color ? props.styles.color : null};
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;

export const VehicleTableColorLegendItem = styled.p`
  color: ${props => props.styles?.color ? props.styles.color : null};
  font-size: 13px;
  margin: 5px 30px 5px 0;
`;

export const VehicleProblemToolTipContainer = styled.div`
  position: absolute;
  left: ${props => props.pageX ? props.pageX : 0};
  top: ${props => props.pageY ? props.pageY : 0};
  z-index: 1000;
  border: 1px solid rgba(55, 55, 55, 0.95);
  border-radius: 5px;
  background-color: rgba(255,255,255,0.95);
  padding: 5px;
  display: ${props => props.showVehicleProblems ? 'block' : 'none'};
`;

export const AdminToolTipContainer = styled.div`
  position: absolute;
  top: -55px;
  right: -230px;
  z-index: 1000;
  width: 300px;
  border: 1px solid rgba(55, 55, 55, 0.95);
  border-radius: 5px;
  background-color: rgba(255,255,255,0.95);
  padding: 5px;
  display: ${props => props.displayTooltip ? 'block' : 'none'};
`;

export const AdminTableExplainTextSub = styled.p`
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 1.38;
letter-spacing: 0;
color: #74787d;
margin: 0;
padding: 0;
`;

export const AdminTableExplainTextPrimary = styled.p`
opacity: .9;
font-size: 17px;
font-weight: 500;
font-style: normal;
font-stretch: normal;
line-height: 1.29;
letter-spacing: .1px;
color: #47484a;
margin: 0;
`;

export const AdminTableExplainList = styled.ul`
padding-left: 10px;
margin-bottom: 10px;
margin-top: 8px;
`;

export const AdminTableExplainBullets = styled.li`
font-size: 14px;
font-style: normal;
margin: 0;
padding: 0;
line-height: 1.29;
`

export const AdminTableColumnSearchBar = styled.input`
width: 135px;
height: 20px;
border: 1px solid #828b94;
border-radius: 6px;
background-color: #ffffff;
font-size: 11px;
font-weight: 500;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: 0.15px;
color: #74787d;
margin-top: 5px;
`

export const AdminTableSortArrows = styled.span`
  padding-left: 4px;
`

export const SortArrowUp = styled.img`
  padding-bottom: 5px;
`;

export const CtaButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
`;

export const CtaButton = styled.button`
  width: 150px;
  height: 50px;
  background-color: ${props => props.disabled ? 'var(--disabled-grey)' : props.backgroundColor ? props.backgroundColor : 'var(--ezev-bluish)'};
  border: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: white;
  text-decoration: none;
  transition: all ease-in .1s;
  &:hover {
    ${props => !props.disabled && `
      transition: all ease-in .2s;
      background-color: var(--ezev-deep-aqua);
      cursor: pointer;
    `}
  }

  @media (max-width: 920px) {
    width: 120px;
    font-size: 17px;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
`

export const TableHeaderTextWrapper = styled.div`
  margin: 10px 0;
`

export const InnerTableButton = styled.button`
width: ${(props) => props.width ? props.width : '80px'};
height: ${(props) => props.height ? props.height : '30px'};
background-color: ${props => props.disabled ? 'var(--disabled-grey)' : 'var(--white)'};
border: ${props => props.disabled ? 'var(--black)' : (props.color ? `1px solid ${props.color}` : '1px solid var(--ezev-bluish)')};
border-radius: 5px;
font-size: 12px;
font-weight: bold;
text-align: center;
letter-spacing: .3px;
color: ${props => props.disabled ? 'var(--white)' : (props.color ? props.color : 'var(--ezev-bluish)')};
cursor: ${props => props.disabled ? null : "pointer"};
font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
transition: all ease-in .2s;
white-space: pre-line;

&:hover {
    ${props => !props.disabled && `
      background-color: ${props.color || 'var(--ezev-bluish)'};
      color: var(--white);
      transform: scale(1.05);
      transition: all ease-in 0.2s;
    `}
  }
`
export const TableHeaderContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const ModalDisplay = styled.div`
  z-index: 24;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  height: auto;
  max-height: 500px;
  overflow: auto;
  width: 600px;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px 30px 40px 30px;
  margin-top: 5rem;

  @media screen and (max-width: 850px) {
    margin-left: 80px;
  }
`

export const ModalCloseButton = styled.button`
  height: 30px;
  width: 70px;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: white;
  color: grey;
  font-size: 14px;
  cursor: pointer;
  margin-left: 90%;
`

export const ModalHeader = styled.h2`
  padding-top: 15px;
  color: rgb(65, 63, 63);
  font-size: 20px;
  text-align: center;
  font-weight: normal;
  margin-top: 0;
`
export const ModalErrorText = styled.p`
  font-size: 14px;
  color: red;
  margin: 0;
`

export const ModalInfoTextWrapper = styled.div`
  padding-bottom: 15px;
  padding-left: 30px;
`
export const ModalInfoText = styled.p`
  fontSize: 16px;
  padding: 0;
  margin: 0;
  padding-top: 5px;
`

export const DatabaseModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ModalForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`

export const ModalFormErrorMsg = styled.p`
  color: red;
  font-size: 13px;
  text-align: center;
  margin: 0;
`

export const ModalFormFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`

export const HeaderRule = styled.hr`
  width: 70%;
  height: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #F5F5F5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`

export const ModalFormInputWrapper = styled.div`
  display: flex;
  row-gap: 1px;
  padding: 2px 0;
  flex-direction: column;
  width: 50%;
`
export const ModalFormInputLabel = styled.label`
  padding: 2px 0 1px;
  white-space: nowrap;
  font-size: 14px;
  color: #47484a;
`

export const ModalFormInput = styled.input`
  color: #47484a;
  border: 1px solid #bbb;
  border-color: ${(props) => props.invalid ? 'red' : '#bbb'};
  border-radius: 5px;
  height: 35px;
  padding-left: 5px;
  width: ${(props) => props.width ? props.width : '90%'};
  font-size: 15px;
  &:focus {
    outline-color: ${(props) => props.invalid && 'red'};
  }
`

export const ModalFormError = styled.div`
  color: red;
  /* width: ${(props) => props.width ? props.width : '90%'}; */
  font-size: 15px;
`

export const ModalFormSelect = styled.select`
  color: #47484a;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  width: ${(props) => props.width ? props.width : '92%'};
  font-size: 15px;
`

export const ModalFormButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  padding-right: 10px;
`

export const ModalFormInputGroupLabel = styled.h2`
  margin: 5px 0 10px 0;
  padding: 0;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .1px;
  color: #47484a;
`

export const ModalSaveButton = styled.button`
width: 135px;
height: 45px;
background-color: var(--ezev-blue-green);
margin-right: 15px;
border: none;
border-radius: 35px;
font-size: 20px;
font-weight: 500;
letter-spacing: .3px;
text-align: center;
color: white;
margin: 0 auto;
text-decoration: none;
transition: all ease-in .1s;

&:disabled {
  background-color: #0000000d;
  color: #00000045;
  
  &:hover {
    background-color: #0000000d;
    color: #00000045;
    transform: scale(1);
  }
}
&:hover {
  transition: all ease-in .2s;
  transform: scale(1.02);
  background-color: var(--ezev-dark-turquoise);
  cursor: pointer;
}
`

export const ModalResetButton = styled.button`
width: 135px;
height: 45px;
background-color: var(--ezev-battleship-grey);
margin-right: 15px;
border: none;
border-radius: 35px;
font-size: 20px;
font-weight: 500;
letter-spacing: .3px;
text-align: center;
color: white;
margin: 0 auto;
text-decoration: none;
transition: all ease-in .1s;

&:disabled {
  background-color: #0000000d;
  color: #00000045;
  
  &:hover {
    background-color: #0000000d;
    color: #00000045;
    transform: scale(1);
  }
}
&:hover {
  transition: all ease-in .2s;
  transform: scale(1.02);
  background-color: var(--ezev-charcoal-grey);
  cursor: pointer;
}
`

export const DatabaseModalFormInnerWrapper = styled.div`
  width: 550px;
`

export const SettingsTitle = styled.h3`
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
  font-size: 32px;
  color: #47484a;
  font-weight: 500;
`

export const SettingsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue","Arial","sans-serif";
`

export const SettingsFormWrapper = styled.div`
  display: grid;
  grid-template-areas: "a a";
  grid-column-gap: 40px;
  grid-row-gap: 5px;
  grid-auto-columns: 50%;
  padding-bottom: 20px;
`

export const SettingsInput = styled.input`
  aria-label: ${(props) => props["aria-label"] ? props["aria-label"]: 'input'};
  data-testid: ${(props) => props["data-testid"] ? props["data-testid"]: 'input-testid'};
  color: #47484a;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 25px;
  font-size: 15px;
  padding-left: 5px;
  background-color: ${(props) => props.readOnly ? '#EBEBE4' : 'white'};
`

export const SettingsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width ? props.width : '70%'};
`

export const SettingsDropdown = styled.select`
  color: #4a4a4a;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 25px;
  font-size: 16px;
  width: 50%;
  background-color: ${(props) => props.disabled ? 'rgb(235, 235, 228)' : 'inherit'};
`

export const SettingsInputLabel = styled.label`
  padding: 4px 0 2px;
  white-space: nowrap;
  font-size: 15px;
  color: #47484a;
`

export const AdminContainer = styled.div`
  width: 90%;
  // Adjust the margin-left to add 64px, while still centering the content in its container
  // @TODO: This is a less than ideal workaround so that we don't need to adjust all of the products' CSS to the new menu bar. 
  // Should be removed once all products' CSS is overhauled. -NK 4/1/24
  margin-left: calc(3% + 64px); 
  margin-right: auto;
  margin-bottom: 3%;
`

export const AdminContentWrapper = styled.div`
  border: 1px solid grey;
  border-top: none;
  padding: 3rem 2.5rem;
  min-height: 24rem;
  justify-content: center;
  overflow: auto;
`

export const CentereredFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InfoIcon = styled.img`
  height: 12px;
  width: 12px;
  padding-left: 2px;
  display: inline-block;
`

export const InfoTextContainer = styled.div`
  padding: 8px;
  margin-left: 10px;
  border: 1px solid var(--ezev-bluey-grey);
  box-shadow: 2px 2px 4px var(--ezev-charcoal-grey);
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 13px;
  display: inline-block;
  color: var(--ezev-charcoal-grey);
  position: absolute;
`
export const InfoText = styled.p`
  margin: 0;
`

export const FlexWrapper = styled.div`
  display: flex;
`

export const AdminCandidateTarget = styled.img`
  width: auto;
  height: 50px;
  min-width: 45px;
  &:hover {
    transition: all ease-in .2s;
    background: #bfbfbf;
  }
`;
export const AdminCandidateError = styled.div`
  width: auto;
  height: 50px;
  min-width: 45px;
  padding-top: 30px;
  cursor: pointer;
`;

export const UserSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 350px;
  row-gap: 3px;
  flex-wrap: wrap;
  column-gap: 10px;
  white-space: pre;
`

export const UserSettingsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

export const UserSettingsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const UserSettingsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  `

export const FleetSettingsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 15px;
  font-size: 15px;
  color: ${(props) => props.color ? props.color : 'inherit'}
`

export const FleetSettingsInfoPopup = styled.div`
  padding: 8px;
  border: 1px solid #979a9e;
  box-shadow: 2px 2px 4px #47484a;
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 13px;
  top: ${(props) => `${props.position.topPosition}px`};
  left: ${(props) => `${props.position.leftPosition}px`};
  color: #47484a;
  position: absolute;
`

///Location edit tool components

export const LocationEditContainer = styled.div`
  display: flex;
  justfiy-content: center;
  flex-direction: column;
  width: 100%;
`

export const UpdateLocationMapSatelliteControl = styled.div`
  position: absolute;
  height: 30px;
  width: 120px;
  bottom: 10px;
  right: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  column-gap: 5px;
  white-space: pre;
`

export const LocationEditMapLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  z-index: 20;
  padding: 5px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
  height: min-content;
  width: fit-content;
`
export const LegendItem = styled.div`
  display: flex;
  align-items: center;
`

export const LegendColorCircle = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 10px;
  margin-right: 7px;
  background-color: ${props => props.color ? props.color : 'black'};
`

export const LegendText = styled.p`
  font-size: 14px;
  padding: 0;
  margin: 0;
`

export const LocationEditBtnWrapper = styled.div`
  display: flex; 
  gap: 20px;
  margin-bottom: 10px;
`

export const UpdateLocationMapContainer = styled.div`
  margin: 20px 0 ;
`

export const UpdateLocationMapText = styled.p`
  padding: 5px 0;
  margin: 0;
`

export const UpdateLocationMapSubText = styled.p`
  padding-bottom: 4px;
  font-size: 14px;
  margin: 0;
`
export const CobrandingText = styled.div`
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 15px;
`

export const CobrandingTipsList = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
`

export const Bold = styled.div`
  font-weight: bold;
  margin-top: 2px;
`

export const HeaderDemo = styled.div`
  position: relative;
  height: 80px;
  width: 475px;
  margin-bottom: 10px;
  background-image: linear-gradient(
    -90deg,
    ${ColorScheme.sawatch_blue} 95%,
    rgba(0,0,0,0)
  );
`

export const SectionWrapper = styled.div`
  border-top: 2px solid ${ColorScheme.medium_gray};
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
`

export const CobrandingDemoText = styled.div`
  font-size: 14px;
  font-style: italic;
  width: 475px;
  text-align: right;
`
export const CobrandingTextSection = styled.div`
  max-width: 70%;
`

export const CobrandingActionSection = styled.div`
  height: 190px;
  border: 2px solid ${ColorScheme.medium_gray};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const CobrandingImageUploadButton = styled.button`
width: 150px;
height: 50px;
border: 1px solid ${props => props.color ? props.color : ColorScheme.blue_gray};
font-size: 14px;
text-align: center;
letter-spacing: .3px;
background-color: ${ColorScheme.white};
color: ${props => props.color ? props.color : `${ColorScheme.blue_gray}`};
cursor: pointer;
font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
transition: all ease-in .2s;
white-space: pre-line;

&:hover {
    border-color: ${ColorScheme.dark_aqua};
    color: ${ColorScheme.dark_aqua};
    transition: all ease 0.25s;
  }
`