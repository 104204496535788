export const Roles = {
    BasicUser: 0,
    FleetAdmin: 1,
    PartnerAdmin: 2,
    SuperAdmin: 3,
    SuperAdminDeveloper: 4
  } // Enumerate roles ascending based on access level
  
  class User {
    // NOTE this file does not copy over to dashboard in deploy!
    // It needs to be managed manually.
    constructor(){
      this.username = null;
      this.admin = false;
      this.email = null;
      this.authenticated = false;
      this.token = null;
      this.primaryDb = null;
      this.account = null;
      this.developer = false;
      this.basicUser = true;
      this.fleetAdmin = false;
      this.partnerAdmin = false;
      this.superAdmin = false;
      this.superAdminDeveloper = false;
      this.userSettings = {}
    }
  }

  export const assignUserRole = (user) => {
    // Assign role enum based on role flags
    if (user.basic_user && user.fleet_admin && user.partner_admin && user.super_admin && user.developer) return {enum: Roles.SuperAdminDeveloper, humanReadable: "Developer"};
    else if (user.basic_user && user.fleet_admin && user.partner_admin && user.super_admin) return {enum: Roles.SuperAdmin, humanReadable: "Super Admin"};
    else if (user.basic_user && user.fleet_admin && user.partner_admin) return {enum: Roles.PartnerAdmin, humanReadable: "Partner Admin"};
    else if (user.basic_user && user.fleet_admin) return {enum: Roles.FleetAdmin, humanReadable: "Fleet Admin"};
    else return {enum: Roles.BasicUser, humanReadable: "Basic User"};
  }

  export const createUser = (data) => {
    let u = new User();
    u.authenticated = true;
    if (data.username) u.email = data.username;
    if (data.primary_db) u.primaryDb = data.primary_db;
    if (data.token) u.token = data.token;
    if (data.admin) u.admin = data.admin; // TODO: remove once old admin flag is fully deprecated - LS 2/5/24
    if (data.super_admin) u.superAdmin = data.super_admin; // TODO: remove once ezEV/ionEV respect new access level pattern - LS 2/5/24
    if (data.primary_db) u.primaryDb = data.primary_db;
    
    u.role = assignUserRole(data).enum;
    return u;
  };
  
  export default User;
  
