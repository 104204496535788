import { MidGrid } from "../../styles/emit-styles/Emit-styles";
import CardGrid from "./CardGrid";
import DistributionCard from "./DistributionCard";
import GraphCard from "./GraphCard";
import { compileFleetCompositionCardData, compileFleetMileageBreakdownData, compileHomeViewGraphData, FormatSummaryData } from "./UtilityFunctions";
import { useEffect, useState } from "react";
import { combinedEmissionsBars, emissionsByDrivetrainBars, fleetCondensedDrivetrains, fleetDrivetrains, UNITS_GALLONS, UNITS_POUNDS, UNITS_TONS } from "../swt-emit";

const HomeView = ({ emissionsSummary, displayInLbs, graphData, defaultToMonthView, hasAltFuels }) => {
    // TODO: Need to store viewDataByMonth, showDiscreteData, and splitByDrivetrain at the app level so that display preferences are maintained even when the graph component is destroyed (ie. user navigated to another page or updates app-level controls)
    const [viewDataByMonth, setViewDataByMonth] = useState(defaultToMonthView);
    const [combinedEmissionsData, setCombinedEmissionsData] = useState([]);
    const [hasReductionData, setHasReductionData] = useState(false);
    const [showDiscreteData, setShowDiscreteData] = useState(true);
    const [splitByDrivetrain, setSplitByDrivetrain] = useState(false);
    const [drivetrainsWithData, setDrivetrainsWithData] = useState({});

    useEffect(() => {
        setCombinedEmissionsData([]); // wipe graph data array
        setHasReductionData(false); // reset assumption of reduction data
        if (emissionsSummary.ghgTonsSaved !== 0) setHasReductionData(true); // used to determine display of the reference line

        setDrivetrainsWithData(splitByDrivetrain ?
            {
                "gasolineGenerated": emissionsSummary.gasolineGHGTons !== 0,
                "dieselGenerated": emissionsSummary.dieselGHGTons !== 0,
                "cngGenerated": emissionsSummary.dieselGHGTons !== 0,
                "bevGenerated": emissionsSummary.bevGHGTons !== 0,
                "phevGenerated": emissionsSummary.phevGHGTons !== 0,
                "bevAvoided": emissionsSummary.bevGHGTonsSaved !== 0,
                "phevAvoided": emissionsSummary.phevGHGTonsSaved !== 0,
            } : {
                "generated": emissionsSummary.ghgTons !== 0,
                "avoided": emissionsSummary.ghgTonsSaved !== 0,
            }
        );

        const updatedGraphData = compileHomeViewGraphData(graphData, viewDataByMonth, splitByDrivetrain, showDiscreteData);
        setCombinedEmissionsData(updatedGraphData);
    }, [graphData, emissionsSummary, viewDataByMonth, showDiscreteData, splitByDrivetrain]);
    
    const units = displayInLbs ? UNITS_POUNDS : UNITS_TONS;
    let emissions = emissionsSummary.ghgTons;
    let emissionReductions = emissionsSummary.ghgTonsSaved;
    let costSavings = emissionsSummary.fuelCostSaved;
    let fuelGallonsSaved = emissionsSummary.fuelGallonsSaved;
    if (units === UNITS_POUNDS) {
        emissions = emissionsSummary.ghgLbs;
        emissionReductions = emissionsSummary.ghgLbsSaved;
    }

    const homeCardGridData = [
        {
            primaryValue: FormatSummaryData('emissions', emissions),
            primaryLabel: units,
            subtitle: "Generated GHG Emissions",
        },
        {
            primaryValue: hasAltFuels ? FormatSummaryData('emissionReductions', emissionReductions) : '-',
            primaryLabel: units,
            subtitle: "Avoided GHG Emissions",
        },
        {
            primaryValue: hasAltFuels ? FormatSummaryData('fuelCostSaved', costSavings) : '-',
            subtitle: "Total Fuel Savings",
        },
        {
            primaryValue: hasAltFuels ? FormatSummaryData('fuelGallonsSaved', fuelGallonsSaved) : '-',
            primaryLabel: UNITS_GALLONS,
            subtitle: "Total Fuel Reduction",
        }
    ]

    const fleetCompositionCardData = compileFleetCompositionCardData(emissionsSummary);

    const fleetMileageBreakdownData = compileFleetMileageBreakdownData(emissionsSummary);

    return (
        <MidGrid columnLayout="minmax(460px, 1fr) 2fr">
            <div>
                <CardGrid
                    cardInfoArray={homeCardGridData}
                    cardHeight="130px"
                    cardMaxWidth="250px"
                    cardMinWidth="215px"
                    gridLayout="minmax(215px,250px) minmax(215px,250px)"
                />
                <DistributionCard
                    title="Fleet Composition"
                    subtitle="All Vehicles"
                    height="120px"
                    maxWidth="100%"
                    dataTypes={fleetDrivetrains}
                    data={fleetCompositionCardData}
                    dataType="allVehicles"
                    colorHoverValues
                    useLongLabels
                />
                <DistributionCard
                    title="Mileage Breakdown" //TODO: Update to be 'distance' or something once we update this product to respect metric settings
                    subtitle="By Drivetrain"
                    height="120px"
                    maxWidth="700px"
                    dataTypes={fleetCondensedDrivetrains}
                    data={fleetMileageBreakdownData}
                    dataType="simplifiedMileage"
                    colorHoverValues
                />
            </div>
            <GraphCard
                height="572px"
                width="100%"
                minWidth='550px'
                title={`${viewDataByMonth ? "Monthly" : "Weekly"} Emissions`}
                subtitle={`${splitByDrivetrain ? "By Drivetrain" : ""}${(splitByDrivetrain && !showDiscreteData) ? " " : ""}${!showDiscreteData ? "(Cumulative)" : ""}`}
                data={combinedEmissionsData}
                barInfo={splitByDrivetrain ? emissionsByDrivetrainBars : combinedEmissionsBars}
                yAxisLabel={`GHG (${units})`}
                xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                units={units}
                drivetrainsWithData={drivetrainsWithData}
                hasReductionData={hasReductionData}
                viewDataByMonth={viewDataByMonth}
                showDiscreteData={showDiscreteData}
                splitByDrivetrain={splitByDrivetrain}
                setViewDataByMonth={setViewDataByMonth}
                setShowDiscreteData={setShowDiscreteData}
                setSplitByDrivetrain={setSplitByDrivetrain}
                combinedView
            />
        </MidGrid>
    )
};

export default HomeView;