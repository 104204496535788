import React, { useState, useEffect, Suspense } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import HeaderSingleVcl from './HeaderSingleVcl';
import { processApiResponse } from './utils/ConformUnits';
import { calcEffectiveBattery, scoredOnExplanation } from './HelperFunctions';
import Loading from './loading';
const secrets = require('../../settings.json');

// code-split imports
const SingleVclPage = React.lazy(() => import("./SingleVclPage"));

const SingleVclController = (props) => {
  const [vclResults, setVclResults] = useState(null);
  const [candidateResults, setCandidateResults] = useState(null);
  const [parkingData, setParkingData] = useState(null);
  const [energyGraphData, setEnergyGraphData] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { vin } = useParams();

  useEffect(() => {
    props.getAnalyticsProcessingState(false);
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activity, props.parkingData, props.energyGraphData]);

  const getData = () => {
    const vinFromPath = vin;

    // Check if location.state exists; if not, use the VIN from the pathname
    if (!location.state && vinFromPath) {
      location.state = { vcl: vinFromPath };  // Set location.state manually if not present
    }

    if (!location.state || !props.activity || !props.energyGraphData || !props.parkingData) return;

    if (setVclData()) {
      getCandidateData();
      getParkingData();
    }
  };

  const setVclData = () => {
    // TODO: Update this to be in swt-ezev-td.js. Should just select correct vehicle and pass to SingleVclController.js
    const vehicleVin = location.state.vcl;
    const vcl = props.activity.find(v => v.vin === vehicleVin);

    if (vcl) {
      setVclResults(vcl);
      setEnergyGraphData(props.energyGraphData[vehicleVin]);  // graph energy graph data for the selected VIN
      return true;
    } else {
      console.error("Vehicle not found. Redirecting to landing page...");
      history.push('/');
      return false;
    }
  };

  // Simplified data fetch and processing
  const getCandidateData = () => {
    const vcl = location.state.vcl;
    const url = `${props.apiURL}getezevcandidateresults`;
    const queries = `?ident=${props.dbName}&vin=${vcl}`;

    fetch(`${url}${queries}`, {
      headers: { 'Authorization': `Bearer ${props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        const candidatesData = data?.data?.[0]?.json || {};  
        const { bevs = [], phevs = [] } = candidatesData;

        // Process each candidate list and map their upfits
        [bevs, phevs].forEach((candidates) => {
          candidates.forEach(d => {
            const cand = props.candidates.find(c => c.id === d.id);
            if (cand?.upfits) d.upfits = cand.upfits;
            processApiResponse(props.user.userSettings, d);
          });
        });
        setCandidateResults(candidatesData);
      })
      .catch(error => console.error("Error fetching candidate data: ", error));
  };

  const getParkingData = () => {
    const vehicleVin = location.state.vcl;
    setParkingData(props.parkingData[vehicleVin] || []);
  };

  const mapCandidateAttributes = (candResults) => {
    // Map candidate attributes to candidate results
    candResults.forEach(c => {
      const cand = props.candidates.find(e => e.id === c.id) || props.candidates.find(e => e.ymm === c.ymm);
      if (!cand) {
        c.effective_battery_kwh = '-';
        return;
      }
      c.price = cand.net_price;
      c.forecasted_maint_per_km = cand.forecasted_maint_per_km;
      c.forecasted_insurance = cand.forecasted_insurance;
      c.usable_kwh = cand.usable_kwh !== undefined ? Math.round(cand.usable_kwh * 10) / 10 : '-';
      c.effective_battery_kwh = calcEffectiveBattery(vclResults, props.settings, cand.usable_kwh);
    });
  }

  let explanation, candResults, component;
  // NOTE: Below kludge in place to support CO SFM versioning.
  // TODO: Remove when SFM is no longer supported - LS 3/20/25
  const useLegacyAttributes = secrets.legacy_attribute_dbs.includes(props.dbName)
  const iceCompMatcher = useLegacyAttributes ? 'ice_comp_ymm' : 'ice_comp_id';
  const candMatcher = useLegacyAttributes ? 'ymm' : 'id';
  const iceCandidate = props.candidates.find(c => c[candMatcher] === vclResults?.[iceCompMatcher]);

  if (!vclResults || !candidateResults || !parkingData) {
    component = (
      <>
        <HeaderSingleVcl vcl={{ asset_id: '' }} />
        <div className="ezev-loading-wrapper">
          <Loading />
        </div>
      </>
    );
  } else {
    explanation = scoredOnExplanation(vclResults.recommendation, vclResults.scored_on, vclResults.is_ld, false);

    candResults = [
      ...candidateResults.phevs.map(item => ({ ...item, is_phev: true, is_bev: false })),
      ...candidateResults.bevs.map(item => ({ ...item, is_phev: false, is_bev: true }))
    ];

    mapCandidateAttributes(candResults);

    component = (
      <SingleVclPage
        vcl={vclResults}
        scoredOnText={explanation}
        localKwh={props.localKwh}
        parking={parkingData}
        cands={candResults}
        iceCandidate={iceCandidate}
        settings={props.settings}
        userSettings={props.user.userSettings}
        dbDisplayName={props.dbDisplayName}
        dbName={props.dbName}
        apiURL={props.apiURL}
        user={props.user}
        energyGraphData={energyGraphData}
        products={props.products}
        cobrandingLogoUrl={props.cobrandingLogoUrl}
      />
    );
  }

  return (
    <div>
      <Suspense fallback={<HeaderSingleVcl vcl={{ asset_id: '' }} />}>
        {component}
      </Suspense>
    </div>
  );
};

export default SingleVclController;