import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import { background_gray, dark_gray } from './ColorScheme';

// const SMALL_VIEW_BREAKPOINT = "880px";
// const MEDIUM_VIEW_BREAKPOINT = "1100px";

// Styles
export const ProductWrapper = styled.div`
    background-color: ${background_gray};
    padding-top: 36px;
    padding-left: 96px;
    padding-right: 36px;
    padding-bottom: 36px;
    height: 100%;
    //height: calc(100vh - 72px); Adding but currently commenting this out because it causes the white bar to be displayed across the entire app when developing locally. This should be reinstated and the above line removed once we implement local repo emulation of the dashboard environment.
    width: 100%;
    // Putting a couple overrides here, otherwise we have problems in dashboard context
    // For the font smoothing, the dashboard override makes the Inter font appear really thin.
    // TODO: When we transition to using this font across the board, we'll want to remove that effect from the main dashbaord css sheet and then we can remove the inverse modifier here
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    overflow: scroll;
`;

export const ContentContainer = styled.div`
    min-height: 600px;
    min-width: 800px;
    /* padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 30px; */
`;

export const NavContainer = styled.nav`
    display: flex;
    text-align: center;
`;

export const HiddenElement = styled.div`
    display: none;
`;

export const NoDataMessage = styled.p`
font-size: 17px;
color: ${dark_gray};
white-space: nowrap;
margin: 0;
padding: 0;
text-align: center;
margin-top: 10px;
margin-bottom: -20px;
padding-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MidGrid = styled.div`
    display: grid;
    grid-template-columns: ${({ columnLayout }) => columnLayout || 'auto'};
    gap: 24px;
    height: min-content;
    max-width: 100%;
`;

export const FilterPopoverContainer = styled.div`
    position: absolute;
    padding: 16px;
    right: 0;
    top: 40px;
    height: ${({height}) => height ?? 'auto'};
    width: ${({width}) => width ?? '330px'};
    display: ${({openPopover}) => openPopover ? 'flex' : 'none'};
    flex-direction: column;
    border: 1px solid rgb(187, 187, 187);
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(5px);
    transition: background-color 0.3s ease;
    border-radius: 10px;
`;