import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Search from "./search";
import Loading from "../swt-loading";
import UpfitsCreationModal from "./UpfitsCreationModal";
import { processApiResponse, conformPostRequest } from "../../utility-components/ConformUnits";
import { preventNonIntegers, preventPasteNonIntegers, roundObjValues, formatDecimal } from "../UtilityFunctions";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { upfitsTableColumns } from "./TableHelpers";

const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

export default function SuperAdminUpfitsTable(props) {
  const [upfits, setUpfits] = useState(null);
  const [reload, setReload] = useState(true);
  const [matchingUpfits, setMatchingUpfits] = React.useState(null);
  const [showModal, setShowModal] = useState(false);

  const skipPageResetRef = useRef(false)

  const { apiURL, user, dbSettings } = props;

  const tableColumns = upfitsTableColumns(dbSettings,user.userSettings);

  useEffect(() => {
    const url = `${apiURL}getCDRUpfits`;
    fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
        .then((resp) => {
            return resp.json();
        })
        .then((data) => {
          if(data.status === "success") {
            data.data.map((c) => {
              c = processApiResponse(user.userSettings, c, false)
              c = roundObjValues(c)
              return c
            })
            setUpfits(data.data);
            setReload(false);
          }
          else {
            alert(SERVER_ERRORED_MESSAGE);
          }
        })
        .catch((error) => console.error("Error: " + error));
}, [apiURL, user.token, reload, user.userSettings]);

  const InputTableCell = useCallback((props) => 
  <input className="swt-admin-input"
         style={props.styles}
         key={props.id}
         value={`${props.label}`}
         title={props.label}
         onKeyDown={props.onKeyDown}
         onPaste={props.onPaste}
         id={props.id}
         accessor={props.accessor}
         onChange={props.handleOnChange}
         onWheel={(e) => e.target.blur()}
         type={props.type}
  />,[]);

  function addCDRUpfit(upfit) {
    let upft = conformPostRequest(user.userSettings, [upfit])
    upft.forEach((u) => {
      return roundObjValues(u)
    })
    
    fetch(`${apiURL}addCDRUpfit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        upfit: JSON.stringify(upft[0]),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          window.alert("Upfit was succesfully added");
          setReload(true);
        }else
          window.alert(SERVER_ERRORED_MESSAGE);
      });
  }

  function updateCDRUpfits() {
    let upfts = upfits.filter((u) => u.mutated);
    upfts = conformPostRequest(user.userSettings, upfts)
    upfts.forEach((u) => {
      return roundObjValues(u)
    })

    if(upfts.length < 1){
      window.alert("There are no upfits subject to update.");
      return;
    }
    fetch(`${props.apiURL}updateCDRUpfits`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        upfits: JSON.stringify(upfts),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          window.alert("Upfits were succesfully updated");
          setReload(true);
        } else
          window.alert(SERVER_ERRORED_MESSAGE);
      });
  }

  function _getTableIndex(id) {
    const idxArr = upfits.map((c) => {
      return c.id;
    });
    return idxArr.indexOf(id);
  }

  const handleSave = () => {
    updateCDRUpfits();
  };

  function handleInputChange(el) {
    const ALPHANUMERIC_REGEX = new RegExp("^[\\w\\-\\(\\) ]*$") //Prevents all special characters except for " - _ ( ) "
    if(el.target.getAttribute('type') === 'text' && ALPHANUMERIC_REGEX.test(el.target.value) === false) {
      el.preventDefault();
      return;
    }
    skipPageResetRef.current = false
    const upfts = [...upfits];
    let val = el.target.value;
    const id = el.target.getAttribute("id");
    let col = el.target.getAttribute("accessor");
    let row = _getTableIndex(id);
    let u = upfts[row];
    if(el.target.getAttribute('type') === 'number') {
      val = formatDecimal(val, 4)
    }
    u[col] = val;
    u["mutated"] = true;
    setUpfits(upfts);
  }

    const mappedUpfits = useMemo(()=> {
      if(!upfits || !matchingUpfits)return null;
      if(upfits.length < 1)return [];

      const upfts = JSON.parse(JSON.stringify(upfits));
      const matchingUpfts = matchingUpfits.map((c) => {return c.id});
      return upfts.filter((u) => {
          if(matchingUpfts.indexOf(u.id) < 0)return null;

              //inputs
              u.name = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={u.name ?? ""} id={u.id} accessor={"name"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
              u.price = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={u.price ?? ""} id={u.id} accessor={"price"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
              u.weight = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={u.weight ?? ""} id={u.id} accessor={"weight"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          return u;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upfits, matchingUpfits]);

    return (
        <>
          <S.TableHeaderContainer>
            <S.AdminTableExplainTextPrimary>List of all upfits.</S.AdminTableExplainTextPrimary>
            {/* <S.AdminTableExplainTextSub>Assign vehicle access by selecting groups.</S.AdminTableExplainTextSub> */}
            <S.SearchContainer>
              <Search allValues={upfits} setMatchingValues={setMatchingUpfits} skipPageResetRef={skipPageResetRef} disabled={upfits ? false : true}/>
            </S.SearchContainer>
            {showModal && (
                      <UpfitsCreationModal
                        userSettings={user.userSettings}
                        dbSettings={dbSettings}
                        setShowModal={setShowModal}
                        // sanitizeData={sanitizeData}
                        addUpfit={addCDRUpfit}
                      />
            )}
            <S.CtaButtonWrapper>
              <S.CtaButton onClick={() => setShowModal(true)} id="add">Add</S.CtaButton>
              <S.CtaButton type="submit" onClick={handleSave}>
                Save
              </S.CtaButton>          
          </S.CtaButtonWrapper>
          </S.TableHeaderContainer>

          {(!mappedUpfits)&& <Loading />}
                {(mappedUpfits) && 
                <AdminTableView
                    stickyCols={0}
                    columns={tableColumns}
                    sortBy={{id: 'name', desc: true}}
                    data={reload ? [] : mappedUpfits}
                    skipPageResetRef={skipPageResetRef}
                    noDataMessage={'No upfits to display. Click "Add" to create one.'}
                />}
        </>
    )
}

