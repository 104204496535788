import * as S from '../../../../styles/core-styles/AdminTools-styles';

const _formatUpfits = (upfits) => {
    let upfitsString = "";
    if (upfits.length > 0) {
      const upfitNames = upfits.map((u) => { return u.name });
      upfitsString = upfitNames.join(', ');
    } else {
        upfitsString = "-";
    }
    return upfitsString;
}

export const _inputSort = (rowA, rowB, columnId) => {
    const a = (rowA.values[columnId].props.type === 'text' && rowA.values[columnId].props.label) ? rowA.values[columnId].props.label.toLowerCase() : rowA.values[columnId].props.label;
    const b = (rowB.values[columnId].props.type === 'text' && rowB.values[columnId].props.label) ? rowB.values[columnId].props.label.toLowerCase() : rowB.values[columnId].props.label;
    if (a && !b) {
        return 1;
    } else if (!a && b) {
        return -1;
    } else {
        return a > b ? 1 : -1;
    }
}

export const _dropdownSort = (rowA, rowB, columnId) => {
    //Used for Vehicle class & GVWR(dropdown) & integrations(cdr database settings table) columns
    const a = rowA.values[columnId].props.defaultValue;
    const b = rowB.values[columnId].props.defaultValue;
    if (a && !b) {
        return -1;
    } else if (!a && b) {
        return 1;
    } else {
        return a > b ? 1 : -1;
    }
}

const _upfitsSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId].props.selectedUpfits.length;
    const b = rowB.values[columnId].props.selectedUpfits.length;
    return a > b ? 1 : -1;
}

export const _boolSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId].props.bool;
    const b = rowB.values[columnId].props.bool;
    return a > b ? 1 : -1;
}

const _sortColumns = (columns) => {
    const columnsInDownload = columns.filter((column) => column.showInDownload);
    const leadingColumn = columnsInDownload.find((column) => column.leadingColumn);
    const remainingColumns = columnsInDownload.filter((column) => !column.leadingColumn);
    const sortedColumns = leadingColumn ? [leadingColumn, ...remainingColumns] : columnsInDownload;
    return sortedColumns;
}

const _dateTimeSort = (rowA, rowB) => {
    const a = rowA.original.sortable_timestamp;
    const b = rowB.original.sortable_timestamp;
    return a > b ? 1 : -1;
}

const _candsTableDateTimeSort = (rowA, rowB) => {
    const a = new Date(rowA.original.last_updated);
    const b = new Date(rowB.original.last_updated);
    return a > b ? 1 : -1;
}

const _cdrCandsTableDateTimeSort = (rowA, rowB) => {
    const a = new Date(rowA.original.last_updated_original)
    const b = new Date(rowB.original.last_updated_original)
    return a > b ? 1 : -1;
}

const _userRoleSort = (rowA, rowB) => {
    // Directly compare the enum values
    const a = rowA.original.role.enum;
    const b = rowB.original.role.enum;
    return a > b ? 1 : -1;
  };


export const _stringSort = (rowA, rowB, columnId) => {
    //Some cell data is formatted as objects and needs to be further drilled down to access value.
    //null values are object type so need to check for that before using toLowerCase
    let a;
    if (typeof rowA.values[columnId] !== 'object') {
        a = rowA.values[columnId].toLowerCase();
    } else {
        a = (rowA.values[columnId] === null) ? '' : rowA.values[columnId].props.title.toLowerCase()
    }

    let b;
    if (typeof rowB.values[columnId] !== 'object') {
        b = rowB.values[columnId].toLowerCase();
    } else {
        b = (rowB.values[columnId] === null) ? '' : rowB.values[columnId].props.title.toLowerCase()
    }
    return a > b ? 1 : -1;
  }

export const _numberSort = (rowA, rowB, columnId) => {
    const a = (typeof rowA.values[columnId] !== 'object' || rowA.values[columnId] === null) ? rowA.values[columnId] : rowA.values[columnId].props.title;
    const b = (typeof rowB.values[columnId] !== 'object' || rowB.values[columnId] === null) ? rowB.values[columnId] : rowB.values[columnId].props.title;

    return Number(a) > Number(b) ? 1 : -1;
}

function _dateSort(rowA, rowB) {
    let a = new Date(rowA.original.original_timestamp);
    let b = new Date(rowB.original.original_timestamp);
    return a > b ? 1 : -1
}

function SearchColumnFilter({ column: { filterValue, setFilter, Header} }) {
    return (
      <S.AdminTableColumnSearchBar
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search by ${Header}`}
      />
    );
  }

  function customSearchInputTypeColumns(rows, colId, searchValue, type) {
    if (type === 'inputNumber') {
        let filteredRows = rows.filter((r) => r.values[colId].props.label.toString().includes(searchValue));
        return filteredRows;
    } else if (type === 'dropdown') {
        let filteredRows = rows.filter((r) => r.values[colId].props.defaultValue.toLowerCase().includes(searchValue.toLowerCase()));
        return filteredRows;
    } else {
        let filteredRows = rows.filter((r) => r.values[colId].props.label.toLowerCase().includes(searchValue.toLowerCase()));
        return filteredRows;
    }
  }

export const adminUsersTableColumns = [
    {Header: "Email", accessor: "email" , Filter: SearchColumnFilter, sortType: _stringSort},
    {Header: "Last Login", accessor: "last_login", width: 200, sortType: _dateSort},
    {Header: "Primary Database", accessor: 'primary_db', width: 200, sortType: _stringSort},
    {Header: "Highest Access Level", accessor: "role_text", width: 100, sortType: _userRoleSort},
    {Header: "ezEV", accessor: "ezev-td", sortType: _boolSort, width: 90}, // Very specifically setting the ezEV columns to NOT include the data type - inside AdminUsers.jsx we will determine which column to show, there will only ever be one ezEV column shown 
    {Header: "ezEV", accessor: "ezev-fx", sortType: _boolSort, width: 90},
    {Header: "ionEV", accessor: "ionev", sortType: _boolSort, width: 90},
    {Header: "Emit", accessor: "emit", sortType: _boolSort, width: 90},
    {Header: "ezIO", accessor: "evse_map", sortType: _boolSort, width: 90},
    {Header: "Speedn", accessor: "speedn", sortType: _boolSort, width: 100},
    {Header: "Idlin", accessor: "idling", sortType: _boolSort, width: 90},
    {Header: "Shifted", accessor: "shifted", sortType: _boolSort, width: 95},
    {Header: "Fueln", accessor: "fueln", sortType: _boolSort, width: 90},
    {Header: "Admin", accessor: "admin", sortType: _boolSort, width: 90},
    {Header: "Dev Tools", accessor: "dev_tools", sortType: _boolSort, width: 90},
    {Header: 'Location Edit Tool', accessor: 'location_edit_tool', sortType: _boolSort, width: 130},
    {Header: "Update Groups", accessor: "update_groups", disableSortBy: true, canSort: false, width: 180},
    {Header: "Delete User", accessor: "delete_user", disableSortBy: true, canSort: false, width: 180}
]

export const upfitsTableColumns = (dbSettings,userSettings) => [
    {Header: "Upfit Name", accessor: "name", width: 500, sortType: _inputSort},
    {Header: `Price (${dbSettings.currency_symbol})`, accessor: "price", sortType: _inputSort},
    {Header: `Weight\n(${userSettings.weight_labels.shortPlural})`, accessor: "weight", sortType: _inputSort},
];

export const seasonalRatesTableColumns = (dbSettings) => [
    {Header: "Rate Name", accessor: "nickname", showInDownload: true, width: 200, sortType: _stringSort},
    {Header: `Rate (${dbSettings.currency_symbol})`, accessor: "kwhRate", showInDownload: true, width: 150, sortType: _numberSort},
    {Header: "Date Duration", accessor: "duration", showInDownload: true, width: 200, sortType: _stringSort},
    {Header: "Active Days", accessor: "rateDOW", showInDownload: true, width: 300, sortType: _stringSort},
    {Header: "Active Times", accessor: "rateTOU", showInDownload: true, width: 200, sortType: _stringSort},
    {Header: "Edit Rate", accessor: "edit", showInDownload: false, disableSortBy: true, canSort: false, width: 170},
    {Header: "Delete Rate", accessor: "delete", showInDownload: false, disableSortBy: true, canSort: false, width: 170},
];

export const candidatesTableColumns = (dbSettings, userSettings) => [
    {
        Header: "General Info",
        isGroup: true,
        columns: [
            {Header: "YMM", accessor: "ymm", showInDownload: true, showInInterface: true, leadingColumn: true, sortType: _inputSort, width: 300},
            {Header: "ezEV/ezIO Selected", accessor: "evsa_selected", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "ionEV Selected", accessor: "ionev_selected", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Image", accessor: "image", showInDownload: false, showInInterface: true, sortType: _inputSort, width: 200},
            {Header: "Class", accessor: "vehicle_class", showInDownload: true, showInInterface: true, sortType: _dropdownSort, width: 325},
            {Header: "Category", accessor: "is_ld", showInDownload: true, showInInterface: true, sortType: _dropdownSort, width: 235},
            {Header: "Upfits", accessor: "upfits", showInDownload: true, showInInterface: true, sortType: _upfitsSort, width: 350},
        ],
    },
    {
        Header: "Fuel Types",
        isGroup: true,
        addBorder: true,
        columns: [
            {Header: "BEV", accessor: "is_bev", addBorder: true, showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "PHEV", accessor: "is_phev", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "ICE", accessor: "is_ice", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Gasoline", accessor: "is_gasoline", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Diesel", accessor: "is_diesel", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "CNG", accessor: "is_cng", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Propane", accessor: "is_propane", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
        ],
    },
    {
        Header: 'Battery Capacities',
        isGroup: true,
        addBorder: true,
        explainText: 'These represent the battery capacities of EV candidates, which are used to determine charging metrics of possible EV replacements in EVSA. These only apply to BEVs and PHEVs.',
        cursor: 'pointer',
        columns: [
            {Header: "Total Battery\n(kWh)", accessor: "battery_capacity", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            {Header: "Usable Battery\n(kWh)", accessor: "usable_kwh", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
        ]
    },
    {
        Header: 'Liquid Fuel Economy',
        isGroup: true,
        addBorder: true,
        explainText: 'These represent the liquid fuel economy of comparable ICE candidates, and are used to determine fuel use and cost metrics of ICE candidates in ionEV. These only apply to ICEs.',
        cursor: 'pointer',
        columns: [
            {Header: `${userSettings.liquid_fuel_economy} (City)`, accessor: "mpg_c", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
            {Header: `${userSettings.liquid_fuel_economy} (Hwy)`, accessor: "mpg_h", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
        ]
    },
    {
        Header: 'Candidate Cost Metrics',
        isGroup: true,
        addBorder: true,
        explainText: 'These inputs represent the cost metrics of comparable and replacement candidates. These are used to calculate TCO of candidates in EVSA (forecasted) and ionEV (backcasted).',
        cursor: 'pointer',
        columns: [
            {Header: `Net Price (${dbSettings.currency_symbol})`, accessor: "net_price", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            {Header: `Forecasted Insurance\n(Yearly) (${dbSettings.currency_symbol})`, accessor: "forecasted_insurance", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Forecasted Maintenance Cost per ${userSettings.distance_labels.shortSingular} (${dbSettings.currency_symbol})`, accessor: "forecasted_maint_per_km", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted Insurance\n(Yearly) (${dbSettings.currency_symbol})`, accessor: "backcasted_insurance", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted Maintenance Cost per ${userSettings.distance_labels.shortSingular} (${dbSettings.currency_symbol})`, accessor: "backcasted_maint_per_km", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
        ]
    },
    {
        Header: "Diagnostic",
        isGroup: true,
        addBorder: true,
        columns: [
            {Header: 'Candidate ID', accessor: "id", addBorder: true, showInDownload: true, showInInterface: true, sortType: _stringSort, width: 400},
            {Header: 'Date Last Updated', accessor: 'last_updated_dateTime', showInDownload: false, showInInterface: true, sortType: _candsTableDateTimeSort, width: 200},
            {Header: 'Date Last Updated', accessor: 'last_updated_sortable_timestamp', showInDownload: true, showInInterface:false},
            {Header: 'Editing User', accessor: 'editing_user_email', showInDownload: true, showInInterface: true, sortType: _stringSort, width: 250},
            {Header: "Delete", accessor: "delButton", showInDownload: false, showInInterface: true, sortType:"basic", width: 160},
        ],
    }
];

export const vehiclesTableColumns = (dbSettings, userSettings) => [
    {
        Header: "General Info",
        isGroup: true,
        columns: [
            {Header: "User-Defined VIN", accessor: "user_defined_vin", leadingColumn: true, showInDownload: true, sortType: _inputSort, prevColWidth: '150px'},
            {Header: "Selected", accessor: "selected", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "ezIO Selected", accessor: "is_ezio_selected", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "VIN", accessor: "vin", showInDownload: true, sortType: _stringSort},
            {Header: "Asset ID", accessor: "asset_id", showInDownload: true, sortType: _inputSort, Filter: SearchColumnFilter, filter: (rows, colId, searchValue) => customSearchInputTypeColumns(rows, colId, searchValue)},
            {Header: "Year", accessor: "year", showInDownload: true, sortType: _inputSort, Filter: SearchColumnFilter, filter: (rows, colId, searchValue) => customSearchInputTypeColumns(rows, colId, searchValue, 'inputNumber'), width: 175},
            {Header: "Make", accessor: "make", showInDownload: true,  sortType: _inputSort, Filter: SearchColumnFilter, filter: (rows, colId, searchValue) => customSearchInputTypeColumns(rows, colId, searchValue)},
            {Header: "Model", accessor: "model", showInDownload: true, sortType: _inputSort, Filter: SearchColumnFilter, filter: (rows, colId, searchValue) => customSearchInputTypeColumns(rows, colId, searchValue)},
            {Header: "Class", accessor: "vehicle_class", showInDownload: true, sortType: _dropdownSort, width: 325, Filter: SearchColumnFilter, filter: (rows, colId, searchValue) => customSearchInputTypeColumns(rows, colId, searchValue, 'dropdown')},
            {Header: "Category", accessor: "is_ld", showInDownload: true, sortType: _dropdownSort, width: 235},
            {Header: "Owned", accessor: "owned", showInDownload: true, sortType: _boolSort, width: 150},
            {Header: "GVWR", accessor: "gvwr", showInDownload: true, sortType: _stringSort, width: 200},
            {Header: "Required Upfits", accessor: "required_upfits", showInDownload: true, sortType: _upfitsSort, width: 450},
            {Header: "Optional Upfits", accessor: "optional_upfits", showInDownload: true, sortType: _upfitsSort, width: 450},
            {Header: "Description", accessor: 'description', showInDownload: true, width: 300, sortType: _stringSort},
            {Header: "Provider", accessor: "telematics_provider", showInDownload: true, sortType: _stringSort, width: 250},
        ]
    },
    {
        Header: 'Fuel Types',
        isGroup: true,
        addBorder: true,
        columns: [
            {Header: "BEV", accessor: "is_bev", addBorder: true, showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "PHEV", accessor: "is_phev", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "Gasoline", accessor: "is_gasoline", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "Diesel", accessor: "is_diesel", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "CNG", accessor: "is_cng", showInDownload: true, sortType: _boolSort, width: 120},
            {Header: "Propane", accessor: "is_propane", showInDownload: true, sortType: _boolSort, width: 120},
        ]
    },
    {
        Header: 'Operational Modeling Inputs',
        isGroup: true,
        addBorder: true,
        explainText: 'These are actual inputs tied to the observed vehicle, and are used to calculate and model various operational metrics including fuel/energy usage and charging metrics.',
        cursor: 'pointer',
        columns: [
            {Header: `${userSettings.liquid_fuel_economy} (City)`, accessor: "mpg_c", addBorder: true, showInDownload: true, sortType: _inputSort, width: 120},
            {Header: `${userSettings.liquid_fuel_economy} (Hwy)`, accessor: "mpg_h", showInDownload: true, sortType: _inputSort, width: 120},
            {Header: "Cylinders", accessor: "cyl", showInDownload: true, sortType: _inputSort, width: 125},
            {Header: "Displacement (Liters)", accessor: "disp", showInDownload: true, sortType: _inputSort, width: 150},
            {Header: "Total Battery\n(kWh)", accessor: "battery_kwh", showInDownload: true, sortType: _inputSort, width: 120},
            {Header: "Usable Battery\n(kWh)", accessor: "battery_usable_kwh", showInDownload: true, sortType: _inputSort, width: 120},
            {Header: "Charge To %", accessor: "charge_to", showInDownload: true, sortType: _inputSort, width: 120},
            {Header: "Discharge To %", accessor: "discharge_to", showInDownload: true, sortType: _inputSort, width: 120},
        ]
    },
    {
        Header: 'Vehicle Cost Metrics',
        isGroup: true,
        addBorder: true,
        explainText: 'These inputs represent the cost metrics of the vehicle under observation, and are used to calculate TCO of the owned vehicle in ionEV. See candidates table for cost metrics that inform candidate TCO.',
        cursor: 'pointer',
        columns: [
            {Header: `Actual Purchase Price (${dbSettings.currency_symbol})`, accessor: "actual_purchase_price", addBorder:true, showInDownload: true, sortType: _inputSort, width: 200},
            {Header: `Maintenance Cost per ${userSettings.distance_labels.shortSingular} (${dbSettings.currency_symbol})`, accessor: "maint_per_km", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Insurance\n(Yearly) (${dbSettings.currency_symbol})`, accessor: "insurance", showInDownload: true, sortType: _inputSort, width: 150},

        ]
    },
    {
        Header: 'Vehicle Operational Contexts',
        isGroup: true,
        addBorder: true,
        explainText: 'These inputs represent the operational/environmental context of the vehicle under observation. These inputs will be applied in EVSA (forecasted) and ionEV (backcasted) to generate cost analysis comparisons for both vehicles and comparable candidates.',
        cursor: 'pointer',
        columns: [
            {Header: "Life Cycle", accessor: "life_cycle", addBorder: true, showInDownload: true, sortType: _inputSort, width: 150},
            {Header: `Forecasted Fuel Cost per ${userSettings.liquid_volume_labels.longSingular} (${dbSettings.currency_symbol})`, accessor: "forecasted_fuel_cost", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Forecasted kWh Cost (${dbSettings.currency_symbol})`, accessor: "forecasted_kwh_cost", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: "Forecasted Elec Gen GHG grams/kWh", accessor: "forecasted_ghg_kwh_gm", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Forecasted SCC (${userSettings.ton_labels.longPlural}) (${dbSettings.currency_symbol})`, accessor: "forecasted_scc", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted Fuel Cost per ${userSettings.liquid_volume_labels.longSingular} (${dbSettings.currency_symbol})`, accessor: "backcasted_fuel_cost", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted kWh Cost (${dbSettings.currency_symbol})`, accessor: "backcasted_kwh_cost", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: "Backcasted Elec Gen GHG grams/kWh", accessor: "backcasted_ghg_kwh_gm", showInDownload: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted SCC (${userSettings.ton_labels.longPlural}) (${dbSettings.currency_symbol})`, accessor: "backcasted_scc", showInDownload: true, sortType: _inputSort, width: 185},
        ]
    }
];

export const cdrCandidatesTableColumns = (dbSettings, userSettings) => [
    {
        Header: "General Info",
        isGroup: true,
        columns: [
            {Header: "Year", accessor: "year", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
            {Header: "Make", accessor: "make", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            {Header: "Model", accessor: "model", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 200},
            {Header: "ezEV/ezIO Selected", accessor: "evsa_selected", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "ionEV Selected", accessor: "ionev_selected", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Image", accessor: "image", showInDownload: false, showInInterface: true, sortType: _inputSort, width: 200},
            {Header: "Class", accessor: "vehicle_class", showInDownload: true, showInInterface: true, sortType: _dropdownSort, width: 325},
            {Header: "Category", accessor: "is_ld", showInDownload: true, showInInterface: true, sortType: _dropdownSort, width: 235},
            {Header: "GVWR", accessor: "gvwr", showInDownload: true, showInInterface: true, sortType: _dropdownSort, width: 150},
            {Header: "Upfits", accessor: "upfits", showInDownload: true, showInInterface: true, sortType: _upfitsSort, width: 350},
            // NOTE: The below are not used anywhere in the stack for candidates. Commenting out to reduce noise in the table. Leaving in case we decide we want them in the future - LS 2/24/25
            // {Header: "Cylinders", accessor: "cyl", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
            // {Header: "Displacement (Liters)", accessor: "disp", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            // {Header: `Tank ${userSettings.liquid_volume_labels.longPlural}`, accessor: "tank_liters", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
        ]
    },
    {
        Header: "Fuel Types",
        isGroup: true,
        addBorder: true,
        columns: [
            {Header: "BEV", accessor: "is_bev", addBorder: true, showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "PHEV", accessor: "is_phev", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Gasoline", accessor: "is_gasoline", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Diesel", accessor: "is_diesel", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "CNG", accessor: "is_cng", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "RNG", accessor: "is_rng", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Propane", accessor: "is_propane", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 120},
            {Header: "Hydrogen Fuel Cell", accessor: "is_hydrogen_fuelcell", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 150},
            {Header: "Hydrogen/Battery Hybrid", accessor: "is_hydrogen_lion_hybrid", showInDownload: true, showInInterface: true, sortType: _boolSort, width: 180},
        ]
    },
    {
        Header: 'Battery Capacities',
        isGroup: true,
        addBorder: true,
        explainText: 'These represent the battery capacities of EV candidates, which are used to determine charging metrics of possible EV replacements in EVSA. These only apply to BEVs and PHEVs.',
        cursor: 'pointer',
        columns: [
            {Header: "Total Battery\n(kWh)", accessor: "battery_kwh", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            {Header: "Usable Battery\n(kWh)", accessor: "battery_kwh_usable", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
        ]
    },
    {
        Header: 'Liquid Fuel Econ',
        isGroup: true,
        addBorder: true,
        explainText: 'These represent the liquid fuel economy of comparable ICE candidates, and are used to determine fuel use and cost metrics of ICE candidates in ionEV. These only apply to ICEs.',
        cursor: 'pointer',
        columns: [
            {Header: `${userSettings.liquid_fuel_economy} (City)`, accessor: "mpg_c", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
            {Header: `${userSettings.liquid_fuel_economy} (Hwy)`, accessor: "mpg_h", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 120},
        ]
    },
    {
        Header: 'Candidate Cost Metrics',
        isGroup: true,
        addBorder: true,
        explainText: 'These inputs represent the cost metrics of comparable and replacement candidates. These are used to calculate TCO of candidates in EVSA (forecasted) and ionEV (backcasted).',
        cursor: 'pointer',
        columns: [
            {Header: `Net Price (${dbSettings.currency_symbol})`, accessor: "net_price", addBorder: true, showInDownload: true, showInInterface: true, sortType: _inputSort, width: 150},
            {Header: `Forecasted Insurance\n(Yearly) (${dbSettings.currency_symbol})`, accessor: "forecasted_insurance", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Forecasted Maintenance Cost per ${userSettings.distance_labels.shortSingular} (${dbSettings.currency_symbol})`, accessor: "forecasted_maint_per_km", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted Insurance\n(Yearly) (${dbSettings.currency_symbol})`, accessor: "backcasted_insurance", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
            {Header: `Backcasted Maintenance Cost per ${userSettings.distance_labels.shortSingular} (${dbSettings.currency_symbol})`, accessor: "backcasted_maint_per_km", showInDownload: true, showInInterface: true, sortType: _inputSort, width: 185},
        ]
    },
    {
        Header: "Diagnostic",
        isGroup: true,
        addBorder: true,
        columns: [
            {Header: "Last Updated", accessor: "last_updated", addBorder: true, showInDownload: false, showInInterface: true, sortType: _cdrCandsTableDateTimeSort, width: 200},
            {Header: "Last Updated", accessor: "sortable_update_timestamp", showInDownload: true, showInInterface:false},
            {Header: "Editing User", accessor: "editing_user_email", showInDownload: true, showInInterface: true, sortType: _stringSort, width: 200},
            {Header: 'Candidate ID', accessor: 'id', showInDownload: true, showInInterface: true, sortType: "alphanumeric", width: 400},
            {Header: "Delete", accessor: "delButton", showInDownload: false, showInInterface: true, sortType: "basic", width: 160},
        ]
    }
];

export const cdrVehicleClassesTableColumns = (userSettings) => [
    {Header: "Class", accessor: "vehicle_class", sortType:_inputSort, width: 200},
    {Header: "Light Duty", accessor: "is_ld", sortType:_boolSort, width: 150, prevColWidth: '200px'},
    {Header: `EV Curb ${userSettings.weight_labels.shortPlural}`, accessor: "ev_curb_lbs", sortType:"basic", width: 150},
    {Header: `EV Loaded ${userSettings.weight_labels.shortPlural}`, accessor: "ev_loaded_lbs", sortType:"basic", width: 150},
    {Header: `ICE Curb ${userSettings.weight_labels.shortPlural}`, accessor: "ice_curb_lbs", sortType:"basic", width: 150},
    {Header: `ICE Loaded ${userSettings.weight_labels.shortPlural}`, accessor: "ice_loaded_lbs", sortType:"basic", width: 150},
    {Header: `${userSettings.liquid_fuel_economy} (City)`, accessor: "mpg_c", sortType:"basic", width: 120},
    {Header: `${userSettings.liquid_fuel_economy} (Hwy)`, accessor: "mpg_h", sortType:"basic", width: 120},
    {Header: "Default Rate", accessor: "default_rate", sortType:"basic", width: 150},
    {Header: "Level 2 Draw", accessor: "level_2_rate", sortType:"basic", width: 130},
    {Header: "Level 3 Draw", accessor: "level_3_rate", sortType:"basic", width: 130},
    {Header: "Battery Capacity (kWh)", accessor: "battery_kwh", sortType:"basic", width: 140},
    {Header: "Battery Usable (kWh)", accessor: "battery_usable_kwh", sortType:"basic", width: 130},
    {Header: "Cylinders", accessor: "cyl", sortType:"basic", width: 150},
    {Header: "Displacement (Liters)", accessor: "disp", sortType:"basic", width: 150},
    //Dollar sign is hard coded in headers because values are only displayed as USD and do not respect user/db settings currency at this time. - JJ 
    {Header: `BEV Purchase Price ($)`, accessor: "bev_purchase_price", sortType:"basic", width: 150},
    {Header: `PHEV Purchase Price ($)`, accessor: "phev_purchase_price", sortType:"basic", width: 150},
    {Header: `ICE Purchase Price ($)`, accessor: "ice_purchase_price", sortType:"basic", width: 150}
];

export const changeLogTableColumns = (dbSettings, userSettings) => [
    {Header: 'Date', accessor: 'dateTime', showInDownload: false, showInInterface: true, sortType: _dateTimeSort},
    {Header: 'Date', accessor: 'sortable_timestamp', showInDownload: true, showInInterface:false},
    {Header: 'Editing User', accessor: 'email', showInDownload: true, showInInterface: true, sortType: _stringSort},
    {Header: `Fuel Cost (${dbSettings.currency_symbol}/${userSettings.liquid_volume_labels.shortSingular})`, accessor: 'fuel_cost', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: `Electricity Rate (${dbSettings.currency_symbol}/kWh)`, accessor: 'local_kwh_cost', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Charge To % (Light Duty)', accessor: 'charge_to_ld', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Charge To % (MD/HD)', accessor: 'charge_to_mdhd', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Discharge To % (Light Duty)', accessor: 'discharge_to_ld', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Discharge To % (MD/HD)', accessor: 'discharge_to_mdhd', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Dwell Hours Ceiling', accessor: 'dwell_hours_ceiling', showInDownload: true, showInInterface: true, width: 100, sortType: _numberSort},
    {Header: 'Dwell Hours Floor', accessor: 'dwell_hours_floor', showInDownload: true, showInInterface: true, width: 100, sortType: _numberSort},
    {Header: 'Default Electrification Percent', accessor: 'electrification_pct', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Overall Threshold', accessor: 'overall_threshold', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Parking Threshold', accessor: 'parking_threshold', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Economics Threshold', accessor: 'economics_threshold', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Energy Threshold', accessor: 'energy_threshold', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: `EV Yearly Target (${userSettings.distance_labels.longPlural})`, accessor: 'ev_yr_km_target', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Electricity gen GHG grams/kWh', accessor: 'ghg_kwh_gm', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Vehicle Life Cycle', accessor: 'life_cycle', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Maximum Percent of Missed Opportunities', accessor: 'max_pct_missed_opps_target', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Max SOC for Missed Opportunity in BEVs', accessor: 'max_soc_missed_opp', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Max SOC for Missed Opportunity in PHEVs', accessor: 'max_soc_missed_opp_phev', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: `BEV Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, accessor: 'ev_repair_per15k', showInDownload: true, showInInterface: true, width: 200, sortType: _numberSort},
    {Header: `PHEV Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, accessor: 'phev_repair_per15k', showInDownload: true, showInInterface: true, width: 200, sortType: _numberSort},
    {Header: `ICE Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, accessor: 'ice_repair_per15k', showInDownload: true, showInInterface: true, width: 200, sortType: _numberSort},
    {Header: `Social Cost of Carbon (${dbSettings.currency_symbol}/${userSettings.ton_labels.longSingular})`, accessor: 'scc', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: `Yearly Insurance (${dbSettings.currency_symbol})`, accessor: 'yearly_insurance', showInDownload: true, showInInterface: true, width: 150, sortType: _numberSort},
    {Header: 'Get Diagnostic Data', accessor: 'get_diagnostic', showInDownload: true, showInInterface: true, width: 150},
    {Header: 'Get Location Data', accessor: 'get_location', showInDownload: true, showInInterface: true, width: 150},
    {Header: 'Vehicles Default Selected', accessor: 'default_selected', showInDownload: true, showInInterface: true, width: 150},
    {Header: 'Operational Fit', accessor: 'operational_fit', showInDownload: true, showInInterface: true, width: 150},
];

export function columnsToExcelHeaders(columns, hasHeaderGroups=false) {
    let cols = [];
    if(hasHeaderGroups) {
        columns.forEach((c) => {
            cols = cols.concat(c.columns);
        })
    }
    else {
        cols = columns;
    }
    let sortedColumns = _sortColumns(cols);
    sortedColumns = sortedColumns.map((column) => column.Header.replace("\n"," "));
    return sortedColumns;
}

export function rowsToData(columns, rows, sort, hasHeaderGroups=false) {
    if (rows === undefined || rows === null) rows = [];
    let cols = [];
    if(hasHeaderGroups) {
        columns.forEach((c) => {
            cols = cols.concat(c.columns);
        })
    }
    else {
        cols = columns;
    }
    const filteredColumns = _sortColumns(cols);
    let sortedRows = rows.slice().sort(sort);
    sortedRows = sortedRows.map(
        (row) => filteredColumns.map(
            (column) => {
                const accessorValue = row[column.accessor];

                if (column.accessor === "upfits" || column.accessor === "optional_upfits" || column.accessor === "required_upfits") {
                    return _formatUpfits(accessorValue);
                } else if (column.accessor === "is_ld") {
                    return accessorValue ? "Light Duty" : "Medium/Heavy Duty";
                } else if (accessorValue === true) {
                    return "True";
                } else if (accessorValue === false) {
                    return "False";
                } else if (column.accessor === "evsa_selected" || column.accessor === "ionev_selected") {
                    if (accessorValue === null) return "False";
                    else return accessorValue;
                } else {
                    return accessorValue;
                }
            }
        )
    );
    return sortedRows;
}
