import { MidGrid } from "../../styles/emit-styles/Emit-styles";
import CardGrid from "./CardGrid";
import DistributionCard from "./DistributionCard";
import GraphCard from "./GraphCard";
import { compileDistanceBreakdownByDrivetrainData, compileFleetCompositionCardData, compileGeneratedEmissionsByDrivetrainData, compileGeneratedEmissionsViewGraphData, FormatSummaryData } from "./UtilityFunctions";
import { combinedEmissionsBars, emissionsByDrivetrainBars, fleetDrivetrains, GENERATED_TABLE_TYPE, UNITS_GRAMS, UNITS_POUNDS, UNITS_TONS } from "../swt-emit";
import TableCard from "./TableCard";
import { useEffect, useState } from "react";


const GeneratedEmissionsView = ({ emissionsSummary, displayInLbs, dbDisplayName, group, beginDate, endDate, selectedVehicleClasses, vehicleClasses, mappedData, rawData, handleNoDataMessageText, setMatchingValues, graphData, defaultToMonthView, isTd, methodology }) => {
    const [viewDataByMonth, setViewDataByMonth] = useState(defaultToMonthView);
    const [generatedEmissionsData, setGeneratedEmissionsData] = useState([]);
    const [showDiscreteData, setShowDiscreteData] = useState(true);
    const [splitByDrivetrain, setSplitByDrivetrain] = useState(false);
    const [drivetrainsWithData, setDrivetrainsWithData] = useState({});

    useEffect(() => {
        setGeneratedEmissionsData([]); // wipe graph data array

        setDrivetrainsWithData(splitByDrivetrain ?
            {
                "gasolineGenerated": emissionsSummary.gasolineGHGTons !== 0,
                "dieselGenerated": emissionsSummary.dieselGHGTons !== 0,
                "bevGenerated": emissionsSummary.bevGHGTons !== 0,
                "phevGenerated": emissionsSummary.phevGHGTons !== 0,
                "cngGenerated": emissionsSummary.cngGHGTons !== 0,
            } : {
                "generated": emissionsSummary.ghgTons !== 0,
            }
        );

        const updatedGraphData = compileGeneratedEmissionsViewGraphData(graphData,viewDataByMonth,splitByDrivetrain,showDiscreteData);
        setGeneratedEmissionsData(updatedGraphData);
    }, [graphData, emissionsSummary, viewDataByMonth, showDiscreteData, splitByDrivetrain]);

    const units = displayInLbs ? UNITS_POUNDS : UNITS_TONS;

    const ghg = units === UNITS_POUNDS ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons;
    const co2 = units === UNITS_POUNDS ? emissionsSummary.CO2Lbs : emissionsSummary.CO2Tons;
    const ch4 = units === UNITS_POUNDS ? emissionsSummary.ch4Lbs : emissionsSummary.ch4Tons;
    const bioCO2 = units === UNITS_POUNDS ? emissionsSummary.bioCO2Lbs : emissionsSummary.bioCO2Tons;

    const emissionsCardData = () => {
        if (methodology?.value !== 'moves3') {
            return ([
                {
                    primaryValue: FormatSummaryData('ghg', ghg),
                    primaryLabel: units,
                    subtitle: "GHG Emissions",
                    note: "(Including GHG from kWh gen.)"
                },
                {
                    primaryValue: FormatSummaryData('co2', co2),
                    primaryLabel: units,
                    subtitle: "Carbon Dioxide",
                    note: "(Tailpipe)"
                },
                {
                    primaryValue: FormatSummaryData('ch4', ch4),
                    primaryLabel: units,
                    subtitle: "Methane",
                    note: "(Tailpipe)"
                },
                {
                    primaryValue: FormatSummaryData('noxLbs', emissionsSummary.noxLbs),
                    primaryLabel: UNITS_POUNDS,
                    subtitle: "Nitrous Oxide",
                    note: "(Tailpipe)"
                },
                {
                    primaryValue: FormatSummaryData('bioCO2', bioCO2),
                    primaryLabel: units,
                    subtitle: <div>Biofuel CO<sub>2</sub></div>,
                    note: "(Tailpipe)"
                }
            ])
        } else {
            return (
                [
                    {
                        primaryValue: FormatSummaryData('ghg', ghg),
                        primaryLabel: units,
                        subtitle: "GHG Emissions",
                        note: "(Including GHG from kWh gen.)"
                    },
                    {
                        primaryValue: FormatSummaryData('co2', co2),
                        primaryLabel: units,
                        subtitle: "Carbon Dioxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('coLbs', emissionsSummary.coLbs),
                        primaryLabel: UNITS_POUNDS,
                        subtitle: "Carbon Monoxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('noxLbs', emissionsSummary.noxLbs),
                        primaryLabel: UNITS_POUNDS,
                        subtitle: "Nitrous Oxide",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('pm10Grams', emissionsSummary.pm10Grams),
                        primaryLabel: UNITS_GRAMS,
                        subtitle: "Particulate Matter 10\u00B5m",
                        note: "(Tailpipe)"
                    },
                    {
                        primaryValue: FormatSummaryData('pm25Grams', emissionsSummary.pm25Grams),
                        primaryLabel: UNITS_GRAMS,
                        subtitle: "Particulate Matter 2.5\u00B5m",
                        note: "(Tailpipe)"
                    },
                ]
            )
        }
    }  


    const fleetCompositionCardData = compileFleetCompositionCardData(emissionsSummary);

    const generatedEmissionsByDrivetrainData = compileGeneratedEmissionsByDrivetrainData(emissionsSummary, units);

    const distanceBreakdownByDrivetrainData = compileDistanceBreakdownByDrivetrainData(emissionsSummary);

    return (
        <>
            <CardGrid
                cardInfoArray={emissionsCardData()}
                cardMinWidth="215px"
                cardMaxWidth="250px"
                cardHeight="130px"
                gridLayout="repeat(auto-fit, minmax(215px,1fr))"
            />
            <MidGrid
                columnLayout="2fr minmax(350px, 1fr)"
            >
                <GraphCard
                    height="auto"
                    minWidth="500px"
                    maxWidth="1200px"
                    title={`${viewDataByMonth ? "Monthly" : "Weekly"} Emissions`}
                    subtitle={`${splitByDrivetrain ? "By Drivetrain" : ""}${(splitByDrivetrain && !showDiscreteData) ? " " : ""}${!showDiscreteData ? "(Cumulative)" : ""}`}
                    data={generatedEmissionsData}
                    barInfo={splitByDrivetrain ? emissionsByDrivetrainBars : combinedEmissionsBars}
                    yAxisLabel={`GHG (${units})`}
                    xAxisKey="month"
                    units={units}
                    showHoverLabels={false}
                    viewDataByMonth= {viewDataByMonth}
                    xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                    showDiscreteData={showDiscreteData}
                    splitByDrivetrain={splitByDrivetrain}
                    setViewDataByMonth={setViewDataByMonth}
                    setShowDiscreteData={setShowDiscreteData}
                    setSplitByDrivetrain={setSplitByDrivetrain}
                    drivetrainsWithData={drivetrainsWithData}
                />
                <div>
                    <DistributionCard
                        title="Fleet Composition"
                        subtitle="All Vehicles"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={fleetDrivetrains}
                        data={fleetCompositionCardData}
                        dataType="allVehicles"
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        title="Mileage Breakdown"
                        subtitle="By Drivetrain"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={fleetDrivetrains}
                        data={distanceBreakdownByDrivetrainData}
                        dataType="allMileage"
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        title="Cumulative Emissions"
                        subtitle="By Drivetrain"
                        height="105px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={fleetDrivetrains}
                        data={generatedEmissionsByDrivetrainData}
                        dataType="generatedEmissions"
                        displayInLbs={displayInLbs}
                        colorHoverValues
                        useLongLabels
                        hideLegend
                    />
                    <DistributionCard
                        height="55px"
                        minWidth="350px"
                        maxWidth="700px"
                        dataTypes={fleetDrivetrains}
                        data={fleetCompositionCardData}
                        onlyLegend
                    />
                </div>
            </MidGrid>
            <TableCard
                height="600px"
                width="auto"
                title="Emissions Data"
                subtitle="for all fleet vehicles"
                tableType={GENERATED_TABLE_TYPE}
                beginDate={beginDate}
                endDate={endDate}
                mappedData={mappedData}
                rawData={rawData}
                group={group}
                selectedVehicleClasses={selectedVehicleClasses}
                vehicleClasses={vehicleClasses}
                displayInLbs={displayInLbs}
                dbDisplayName={dbDisplayName}
                displayUnits={displayInLbs ? UNITS_POUNDS : UNITS_TONS}
                handleNoDataMessageText={handleNoDataMessageText}
                setMatchingValues={setMatchingValues}
                methodology={methodology}
                isTd={isTd}
            />
        </>
    )
};

export default GeneratedEmissionsView;