import styled from 'styled-components';
import InfoCard from './InfoCard';

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: ${({ gridLayout }) => gridLayout || 'auto'};
  column-gap: 24px;
  width: 100%;
  justify-content: space-between;
`;

const CardGrid = ({ cardInfoArray, cardHeight, cardWidth, cardMinWidth, cardMaxWidth, gridLayout }) => {

  return (
    <GridStyle
      gridLayout={gridLayout}
    >
      {cardInfoArray.map((card, index) => (
          <InfoCard
            key={index}
            primaryValuePrefix={card.primaryValuePrefix}
            primaryValue={card.primaryValue}
            primaryLabel={card.primaryLabel}
            subtitle={card.subtitle}
            note={card.note}
            width={cardWidth}
            maxWidth={cardMaxWidth}
            minWidth={cardMinWidth}
            height={cardHeight}
          />
        ))}
    </GridStyle>
  );
};

export default CardGrid;