import { Font } from "@react-pdf/renderer";
import { PDF_RADIUS} from "./CardStyles"
import { background_gray, dark_gray, darkest_gray, off_black, stroke_gray, white } from "./ColorScheme"

// Adding a custom font from Google turned out to be not as simple as the react-pdf docs make it out to be. Fortunately, fßollowing these instructions makes it relatively easy:
// https://stackoverflow.com/questions/70126411/how-to-add-custom-font-in-react-pdf-pdf
Font.register({
    family: 'Inter',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
        fontWeight: 400, // Regular
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
        fontWeight: 500, // Medium
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
        fontWeight: 600, // SemiBold
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
        fontWeight: 700, // Bold
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v18/UcCM3FwrK3iLTcvneQg7Ca725JhhKnNqk4j1ebLhAm8SrXTc2dthjZ-Ek-7MeA.ttf',
        fontStyle: 'italic'
      }
    ],
  });


export const pageStyle = {
  backgroundColor: background_gray,
  padding: "85 25 60 25",
  fontFamily: 'Inter',
  fontWeight: 400
}

export const infoCardStyle = {
    backgroundColor: white,
    border: `1.5 solid ${stroke_gray}`,
    padding: 10,
    borderRadius: PDF_RADIUS,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 4,
    fontFamily: 'Inter',
}

export const cardTitleWrapperStyle = {
    // Not sure why this is necessary, exactly, but if it's not present the text line will take up extra white space on top. 
    // Maybe something with the inter font, I think.
    marginTop: -4
}

export const cardTitleStyle = {
    fontSize: 13,
    fontWeight: 500,
    color: off_black
}

export const cardSubtitleStyle = {
    fontSize: 12,
    fontWeight: 400,
    color: darkest_gray
}

export const cardBodyStyle = {
    fontSize: 11,
    fontWeight: 400,
    color: dark_gray
}