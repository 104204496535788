import { useEffect, useState } from "react";
import styled from "styled-components";
import { darkest_gray, off_black, stroke_gray, white, blue_green, blue_green_hover, light_gray } from "../../styles/emit-styles/ColorScheme";
import DatePicker from "react-datepicker";
import { MEDIUM_RADIUS } from "../../styles/emit-styles/CardStyles";

export const DateRangeDropdownOption = styled.div`
    font-weight: 500;
    color: ${darkest_gray};
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: ${off_black}
    }
`;

export const DatePickerWrapper = styled.div`
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const StyledDatePicker = styled(DatePicker)`
    box-sizing: border-box;
    height: 38px;
    padding: 0px;
    margin: 0px;
    width: 100px;
    margin-right: ${({ marginRight }) => marginRight || 0}; // hacky way of spacing out the date pickers without them glitching out. Shouldn't be necessary when we transition to sawatch pickers.
    background-color: ${white};
    border: 1px solid ${stroke_gray};
    color: ${darkest_gray};
    font-size: 16px;
    font-family: inherit;
    letter-spacing: 0.15px;
    text-indent: 7px;
    border-radius: ${MEDIUM_RADIUS}px;
    cursor: pointer;
`;

const DateRangeOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`

const SectionSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${stroke_gray};
`;

const ControlButton = styled.button`
  margin-top: 8px;
  margin-bottom: 2px;
  width: 100px;
  height: 25px;
  background: ${blue_green};
  border-radius: ${MEDIUM_RADIUS}px;
  border: none;
  color: ${white};
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  &:disabled {
    background-color: ${light_gray};
    cursor: initial;
    
    &:hover {
    background-color: ${light_gray};
    }
  }
  &:hover {
    background-color: ${blue_green_hover};
  }
`;

const CustomDateRangeContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ControlButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
`



const DateRangeControls = ({ handleBeginDateChange, handleEndDateChange, beginDate, endDate, minDate, maxDate, setOpenDateRangePopover }) => {
    const [showCustomDatePickers, setShowCustomDatePickers] = useState(false);
    const [customBeginDate, setCustomBeginDate] = useState(beginDate);
    const [customEndDate, setCustomEndDate] = useState(endDate)
    const [disableButton, setDisableButton] = useState(false);

    const THIRTY_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 30;

    useEffect(() => {
        setCustomBeginDate(beginDate);
        setCustomEndDate(endDate);
    },[beginDate, endDate])

    function dateRangeShortcut(type, initialDate) {

        let currentYear = new Date().getFullYear();
        let startDate = minDate;
        let endDate = maxDate;

        setShowCustomDatePickers(false);
        let initDate = initialDate ?? Date.now()
        if (type === 'allData') {
            handleBeginDateChange(new Date(minDate));
        }
        if (type === 'lastWeek') {
            let day = new Date().getDay();
            startDate = new Date(initDate - ((day - 1) + 7) * 24 * 60 * 60 * 1000);
            endDate = new Date(startDate)
            endDate.setDate(startDate.getDate() + 6);
        }
        if (type === 'lastMonth') {
            let currentMonth = new Date().getMonth();
            let lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
            endDate = new Date(currentYear, currentMonth, 0)
            startDate = new Date(currentYear, lastMonth, 1)
        }
        if (type === 'lastYear') {
            let lastYear = currentYear - 1;
            startDate = new Date(lastYear, 0, 1);
            endDate = new Date(lastYear, 11, 31);
        }
        if (type === 'yearToDate') {
            endDate = new Date();
            startDate = new Date(currentYear, 0, 1);
        }
        if (type === 'lastQuarter') {
            let today = new Date();
            let quarter = Math.floor((today.getMonth() + 3) / 3);

            if (quarter === 1) {
                //If first quarter, go back to q4 of last year
                let lastYear = currentYear - 1;
                endDate = new Date(lastYear, 11, 31);
                startDate = new Date(lastYear, 9, 1);
            } else if (quarter === 2) {
                //Quarter 1 date range
                endDate = new Date(currentYear, 2, 31);
                startDate = new Date(currentYear, 0, 1);
            } else if (quarter === 3) {
                //Quarter 2 date range
                endDate = new Date(currentYear, 5, 30);
                startDate = new Date(currentYear, 3, 1);
            } else {
                //Quarter 3 date range
                endDate = new Date(currentYear, 8, 30);
                startDate = new Date(currentYear, 6, 1);
            }
        }
        handleBeginDateChange(startDate)
        handleEndDateChange(endDate);
        setOpenDateRangePopover(false);
    }

    function handleCustomDateRangeSubmit() {
        handleBeginDateChange(customBeginDate)
        handleEndDateChange(customEndDate)
        setOpenDateRangePopover(false);
        setShowCustomDatePickers(false);
    }
    useEffect(() => {
    if (JSON.stringify(beginDate) === JSON.stringify(customBeginDate) && JSON.stringify(endDate) === JSON.stringify(customEndDate)) {
        setDisableButton(true);
    } else {
        setDisableButton(false);
    }
    },[customBeginDate, customEndDate, beginDate, endDate])

    function handleCustomDateRangeReset() {
        setCustomBeginDate(beginDate);
        setCustomEndDate(endDate);
    }

    return (
        <DateRangeOptionWrapper>
            {/* Disabling Last Week option until weekly data displays are supported again - JJ 3/3/25 */}
            {/* <DateRangeDropdownOption onClick={() => dateRangeShortcut('lastWeek')}>Last Week</DateRangeDropdownOption> */}
            <DateRangeDropdownOption onClick={() => dateRangeShortcut('lastMonth')}>Last Month</DateRangeDropdownOption>
            <DateRangeDropdownOption onClick={() => dateRangeShortcut('lastQuarter')}>Last Quarter</DateRangeDropdownOption>
            <DateRangeDropdownOption onClick={() => dateRangeShortcut('lastYear')}>Last Year</DateRangeDropdownOption>
            <SectionSeparator />
            <DateRangeDropdownOption onClick={() => dateRangeShortcut('yearToDate')}>Year to Date</DateRangeDropdownOption>
            <DateRangeDropdownOption onClick={() => dateRangeShortcut('allData')}>All Data</DateRangeDropdownOption>
            <SectionSeparator />
            <DateRangeDropdownOption onClick={() => setShowCustomDatePickers(!showCustomDatePickers)}>Custom</DateRangeDropdownOption>
            {showCustomDatePickers &&
            <CustomDateRangeContainer>
                <DatePickerWrapper>
                    <StyledDatePicker
                        selected={customBeginDate}
                        onChange={(date) => { setCustomBeginDate(date) }}
                        minDate={minDate}
                        maxDate={new Date(customEndDate.getTime() - THIRTY_DAYS_IN_MS)}
                        selectsStart
                        dateFormat="MM/dd/yy"
                        startDate={customBeginDate}
                        showMonthDropdown
                        useShortMonthInDropdown
                        marginRight='8px'
                    />
                    <StyledDatePicker
                        selected={customEndDate}
                        onChange={(date) => { setCustomEndDate(date) }}
                        selectsEnd
                        dateFormat="MM/dd/yy"
                        endDate={customEndDate}
                        minDate={new Date(customBeginDate.getTime() + THIRTY_DAYS_IN_MS)}
                        maxDate={maxDate}
                        showMonthDropdown
                        useShortMonthInDropdown
                    />
                </DatePickerWrapper>
                <ControlButtonContainer>
                    <ControlButton disabled={disableButton} onClick={() => handleCustomDateRangeReset()}>Reset</ControlButton>
                    <ControlButton disabled={disableButton} onClick={() => handleCustomDateRangeSubmit()}>Apply</ControlButton>
                </ControlButtonContainer>
            </CustomDateRangeContainer>
            }
        </DateRangeOptionWrapper>

    )
}

export default DateRangeControls;